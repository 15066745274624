import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import nodeUtils from '../js/nodeUtils.js'

// import nodeUtilsc from '../data/tileset.json'
import RtcComp from './RtcComp';



import {
	DebugTilesRenderer,
  TilesRenderer,
	NONE,
	SCREEN_ERROR,
	GEOMETRIC_ERROR,
	DISTANCE,
	DEPTH,
	RELATIVE_DEPTH,
	IS_LEAF,
	RANDOM_COLOR,
} from '3d-tiles-renderer';


import { VRButton } from 'three/examples/jsm/webxr/VRButton.js';
import { XRControllerModelFactory } from 'three/examples/jsm/webxr/XRControllerModelFactory.js';

import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js';

import { FlyOrbitControls } from '../js/tiled/FlyOrbitControls';

import {
  Scene,
	DirectionalLight,
	AmbientLight,
	WebGLRenderer,
	PerspectiveCamera,
	Box3,
  Sphere,
	Raycaster,
	Mesh,
	MeshBasicMaterial,
	Group,
	TorusBufferGeometry,
  RingBufferGeometry,
	sRGBEncoding,
	GridHelper,
	BufferGeometry,
	Float32BufferAttribute,
	LineBasicMaterial,
	AdditiveBlending,
	Line,
	Vector3,
} from 'three';
import { fromPairs } from 'lodash';

const ALL_HITS = 1;
const FIRST_HIT_ONLY = 2;

var QRCode = require('qrcode.react');

let camera,  scene, renderer, tiles;
let workspace;
let box, grid;
let raycaster, fwdVector;
let offsetParent;
let controller, controllerGrip;
const upVector = new Vector3( 0, 1, 0 );





class TestTilesVR extends Component {
  constructor(props) {
    super(props);

     global.isslave = window.location.pathname.includes('client'); 
        

    global.ismaster = window.location.pathname.includes('master'); 
    

    let isstereo = window.location.pathname.startsWith('/3dstereo');
        
     this.state = {
      fullScreen: false,
      showvirtualgamepad: !nodeUtils.isSlave() && !isstereo,
      texttospeechreceived : "",
      stereoOffset : nodeUtils.ReadFloat("stereoOffset",0),
      stereoOffset2 : nodeUtils.ReadFloat("stereoOffset2",0),
      StereoEnabled :window.location.pathname.startsWith('/3dstereo'),
      message: ""
      // StereoEnabled :this.props.match.params.mode === '3d'
    }; 

    this.showgui = false;

    // this.StereoEnabled = window.location.pathname.startsWith('/3dstereo'); 
    // this.StereoEnabled = this.props.match.params.mode == '3d'; 

    this.tasks = [];

   
    global.appmode = "3d";

    this.updateAlways = false;

    this.loadlocal = window.location.pathname.includes('loc'); ;

    this.allowrotvert = false;

    this.movetype = "fps";

    this.modelename = this.props.match.params.name

    // const onButtonChangeHandler = (button, gamepad) => this.onGamePadButton(button);;
    // const onAxesChangeHandler = (axes, gamepad) => this.onGamePadAxe(axes);
    // const onConnectionChangeHandler = (gamepad) => {
    //   console.log("gamepad!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!");
    //   this.state.showvirtualgamepad = false;
    //   this.setState(this.state);
    // }
    
    
     
    // this.myGamepadInstance = new Gamepad(
    //   onButtonChangeHandler,
    //   onAxesChangeHandler,
    //   onConnectionChangeHandler
    // );

    // global.isslave = window.location.pathname.startsWith('/3dclient'); 
        

    // global.ismaster = window.location.pathname.startsWith('/3dmaster');

    // this.joystickon = false;
    // this.joystickon2 = false;
    // this.nbjoystickon = 0;

    // this.joystickangle = 0;
    // this.joystickangleUp = 0;
		// this.joystickz = 0;
    // this.joysticky = 0;
    // this.joystickx = 0;

    // this.joyspeed = 0.05;
    // this.joyrotspeed = 0.02;

    // this.lefttriggeron = false;
    // this.righttriggeron = false;

    // this.stereoOffset = 0.01;

    // this.clock = new Clock();
    
    // this.testButton = this.testButton.bind(this);
    // this.testButton2 = this.testButton2.bind(this);

    this.animate = this.animate.bind(this);
    this.render2 = this.render2.bind(this);
    // this.renderScene = this.renderScene.bind(this);
    // this.reinstantiateTiles = this.reinstantiateTiles.bind(this);
    // this.browsenow = this.browsenow.bind(this);
    // this.setCameraPosition = this.setCameraPosition.bind(this);
    // this.setCameraRotation = this.setCameraRotation.bind(this);
    // this.onrMoveJoystick = this.onrMoveJoystick.bind(this);
    // this.onrMoveJoystick2 = this.onrMoveJoystick2.bind(this);
    // this.onEndJoystick = this.onEndJoystick.bind(this);
    // this.onStartJoystick = this.onStartJoystick.bind(this);
    // this.onEndJoystick2 = this.onEndJoystick.bind(this);
    // this.onStartJoystick2 = this.onStartJoystick.bind(this);
    // this.updateJoyCamera = this.updateJoyCamera.bind(this);
    // this.resetCamera = this.resetCamera.bind(this);
    this.onWindowResize = this.onWindowResize.bind(this);

    // this.onGamePadAxe = this.onGamePadAxe.bind(this);
    // this.onGamePadButton = this.onGamePadButton.bind(this);

    this.internalmessageReceived = this.internalmessageReceived.bind(this);
    this.animateOnce = this.animateOnce.bind(this);
    // this.moveUp = this.moveUp.bind(this);
    global.needsRerender = true;

    // console.log("1111111111111111111111111111",this.props.match.params.name)

    this.modelename = this.props.match.params.name

    let resolution = 1;
    if (this.isstereo) resolution = 2;

    this.params = {
      'displayBoxBounds': false,
	    'colorMode': 0,
	    'displayGrid': true,          
    };

    var self = this;

    global.myEmitter.on('3dbrowsenow', function (data) {
        self.browsenow(data.browseEvent);
   
       
    });

    global.myEmitter.on('messagereceived', function (data) {
      self.internalmessageReceived(data);
     
  });


    

  }

  internalmessageReceived(message){
    console.log('3dmessagereceived ',message.internalmessage.getText());
    // console.log('messagereceived type',message.internalmessage.type);
    // console.log('messagereceived!!!!! message',message.internalmessage.message);

    
    this.state.texttospeechreceived = message.internalmessage.getText();
    this.setState(this.state);
    
  }



  browsenow(browseEvent){
      // console.log("browseEventbrowseEvent",browseEvent)

      this.setCameraPosition(browseEvent.data.data.position)  


      console.log("browseEventbrowseEvent rotation",browseEvent.data.data.rotation._y)
      this.setCameraRotation(browseEvent.data.data.rotation)

      this.animateOnce();
  }




  init(){


    this.scene = new Scene();

    let width = window.innerWidth;

    if (this.state.StereoEnabled) width = width/2;

    this.renderer = new WebGLRenderer( { antialias: true } );
    this.renderer.setPixelRatio( window.devicePixelRatio );
    this.renderer.setSize( width, window.innerHeight );
    console.log("size ",width)
    this.renderer.setClearColor( 0x151c1f );
    this.renderer.outputEncoding = sRGBEncoding;   
    this.renderer.xr.enabled = true;

    document.getElementById("tileContainer").appendChild( this.renderer.domElement );

    // console.log("tileContainer",nodeUtils.getHeight("tileContainer"));

    this.renderer.domElement.tabIndex = 1;

       
    this.workspace = new Group();
    this.scene.add( this.workspace );

    this.grid = new GridHelper( 500, 500, 0xffffff, 0xffffff );
    this.grid.material.transparent = true;
    this.grid.material.opacity = 0.5;
    this.grid.material.depthWrite = false;

    this.workspace.add( this.grid );

    this.camera = new PerspectiveCamera( 60, window.innerWidth / window.innerHeight, 0.1, 4000 );
    // this.camera.position.set( 0, 0, -500);
    this.camera.position.set( 0, 1, 0);
    // this.camera.position.set( 0, 1, 500);
    // this.camera.rotation.set( 0,  Math.PI/2,0 );  
    // this.camera.position.set( 10, 0, 10);

    this.workspace.add( this.camera );

    // this.controls = new FlyOrbitControls( this.camera, this.renderer.domElement );
    // this.controls.screenSpacePanning = false;
    // controls.minDistance = 1;
    // controls.maxDistance = 2000;

    // lights
    // const dirLight = new DirectionalLight( 0xffffff );
    // dirLight.position.set( 1, 2, 3 );
    // this.scene.add( dirLight );

    const ambLight = new AmbientLight( 0xffffff, 1 );
    this.scene.add( ambLight );

	  // tile set
	  this.box = new Box3();  
    this.sphere = new Sphere();

    this.offsetParent = new Group();
    // this.offsetParent.rotation.x = Math.PI / 2;

    this.scene.add( this.offsetParent );
    this.cameraz = 0;
    this.cameray = 1;

    this.url =  'https://data2.gigascope.net/3d/chartres_streetart_lapin/tileset.json';

    if (this.modelename == "streetart1") {
      this.url = "https://data2.gigascope.net/3d/chartres_streetart_lapin/tileset.json";
      this.offsetParent.position.set( -2, -4.65, 2.5 );
      this.offsetParent.rotation.set( -Math.PI/2, 0, 2*Math.PI/3 );
      this.cameraz = 0;
    }
    if (this.modelename == "gerberoy") {
      this.url = "https://data2.gigascope.net/3d/gerberoygiga/tileset.json";
      // this.offsetParent.position.set( 100, 300, 600 );
      // this.offsetParent.rotation.set( -Math.PI/4,3* Math.PI/4, Math.PI/4);
      this.offsetParent.rotation.set( 0,1, 0.5)
      
      this.cameray = 400;
      this.cameraz = 2000;
      // this.camera.rotation.set(-Math.PI/4,  0,0 );  
    }
    // this.tiles = new TilesRenderer( 'https://data2.gigascope.net/3d/gerberoygiga/tileset.json' );
    // this.url =  'https://data2.gigascope.net/3d/gerberoygiga/tileset.json';
    // this.url =  'https://data2.gigascope.net/3d/chartres_streetart_lapin/tileset.json';
    // this.url =  'http://nasa-ammos.github.io/3DTilesRendererJS/example/data/tileset.json';
    // this.url =  'https://raw.githubusercontent.com/NASA-AMMOS/3DTilesSampleData/master/msl-dingo-gap/0528_0260184_to_s64o256_colorize/scene-tileset.json';
    this.tiles = new TilesRenderer(this.url);
    // this.tiles = new TilesRenderer( 'http://nasa-ammos.github.io/3DTilesRendererJS/example/data/tileset.json' );
    // this.tiles= new TilesRenderer( 'https://raw.githubusercontent.com/NASA-AMMOS/3DTilesSampleData/master/msl-dingo-gap/0528_0260184_to_s64o256_colorize/0528_0260184_to_s64o256_colorize/0528_0260184_to_s64o256_colorize_tileset.json' );

    // this.tiles = new TilesRenderer( '../datavr/tileset.json' );

    // this.tiles.fetchOptions.mode = 'cors';
      
    const dracoLoader = new DRACOLoader();
    dracoLoader.setDecoderPath( 'https://unpkg.com/three@0.123.0/examples/js/libs/draco/gltf/' );

    const loader = new GLTFLoader( this.tiles.manager );
    loader.setDRACOLoader( dracoLoader );

    this.tiles.fetchOptions.mode = 'cors';
    this.tiles.manager.addHandler( /\.gltf$/, loader );

    this.tiles.setCamera( this.camera );
	  this.tiles.setResolutionFromRenderer( this.camera, this.renderer );

    	// We define a custom scheduling callback to handle also active WebXR sessions
    const tilesSchedulingCB = func => {

      this.tasks.push( func );

    };

    // We set our scheduling callback for tiles downloading and parsing
    this.tiles.downloadQueue.schedulingCallback = tilesSchedulingCB;
    this.tiles.parseQueue.schedulingCallback = tilesSchedulingCB;

    this.tiles.lruCache.maxSize = 1200;
    this.tiles.lruCache.minSize = 900;

    this.tiles.errorTarget = 16;

    this.tiles.displayBoxBounds = true;

    this.tiles.loadSiblings = true;

    this.offsetParent.add( this.tiles.group );

    

    // this.offsetParent.position.set( -2, 5.5, 0 );
    // if (this.url.includes('chartres_streetart_lapin')){
    //   // this.offsetParent.position.set( -2, -4.7, 2 );
    //   this.offsetParent.position.set( -2, -4.65, 2.5 );
    //   this.offsetParent.rotation.set( -Math.PI/2, 0, 2*Math.PI/3 );
    // }  else

    // if (this.url.includes('chartres_streetart_lapin3')){
    //   this.offsetParent.position.set( -2, 5, 0 );
    //   this.offsetParent.rotation.set( 0, 0, 2*Math.PI/3 );
    // } else if (this.url.includes('msl-dingo-gap')){
    //   // this.offsetParent.position.set( 0, 0, 60 );
    //   // this.offsetParent.rotation.set( Math.PI/2, 0, 0 );
    // } else
    // {
    //   // this.offsetParent.position.set( 0,0, 0 );
    // // this.offsetParent.rotation.set( Math.PI/2, 0, 0 );
    // }
    

    // this.offsetParent.rotation.set( Math.PI/2, 0, 2*Math.PI/3 );

    // this.offsetParent.position.setX(this.offsetParent.position.x-50);
      // this.offsetParent.position.setY(this.offsetParent.position.y-50);

    // Raycasting init
    this.raycaster = new Raycaster();
    this.fwdVector = new Vector3( 0, 0, 1 );


    this.rayIntersectMat = new MeshBasicMaterial( { color: 0xb2dfdb } );
    this.intersectRing = new Mesh( new TorusBufferGeometry( 1.5, 0.2, 16, 100 ), this.rayIntersectMat );
    this.intersectRing.visible = false;
    this.scene.add( this.intersectRing );

    // vr setup

    let self = this;
    
    this.controller = this.renderer.xr.getController( 0 );
    this.controller.addEventListener( 'selectstart', () => {

      if ( this.intersectRing.visible ) {

        this.workspace.position.copy( this.intersectRing.position );
  
      }

    } );


    this.controller.addEventListener( 'connected', function ( event ) {

      this.controllerActive = true;
      this.add(self.buildController( event.data ) );

    } );
    this.controller.addEventListener( 'disconnected', function () {

      this.controllerActive = false;
      this.remove( this.children[ 0 ] );

    } );

    this.workspace.add( this.controller );

    const controllerModelFactory = new XRControllerModelFactory();
    controllerGrip = this.renderer.xr.getControllerGrip( 0 );
    controllerGrip.add( controllerModelFactory.createControllerModel( controllerGrip ) );
    this.workspace.add( controllerGrip );

    this.onWindowResize();

  // GUI
	// const gui = new dat.GUI();
	// gui.width = 300;
	// gui.add( this.params, 'displayGrid' );
	// gui.add( this.params, 'displayBoxBounds' );
	// gui.add( this.params, 'colorMode', {

	// 	NONE,
	// 	SCREEN_ERROR,
	// 	GEOMETRIC_ERROR,
	// 	DISTANCE,
	// 	DEPTH,
	// 	RELATIVE_DEPTH,
	// 	IS_LEAF,
	// 	RANDOM_COLOR,

	// } );
	// gui.open();


	// this.reinstantiateTiles();


    
  }

  onWindowResize() {

    console.log("onWindowResizeonWindowResize")
   

      let width = window.innerWidth;

      this.camera.updateProjectionMatrix();
      this.renderer.setPixelRatio( window.devicePixelRatio );
      this.renderer.setSize( window.innerWidth, window.innerHeight );
    
      this.camera.aspect = window.innerWidth / window.innerHeight;
      this.camera.updateProjectionMatrix();

       

    // this.animate();

  
  }

  buildController( data ) {

    let geometry, material;
  
    switch ( data.targetRayMode ) {
  
      case 'tracked-pointer':
  
        geometry = new BufferGeometry();
        geometry.setAttribute( 'position', new Float32BufferAttribute( [ 0, 0, 0, 0, 0, - 1 ], 3 ) );
        geometry.setAttribute( 'color', new Float32BufferAttribute( [ 0.5, 0.5, 0.5, 0, 0, 0 ], 3 ) );
  
        material = new LineBasicMaterial( {
          vertexColors: true,
          blending: AdditiveBlending,
          depthWrite: false,
          transparent: true,
        } );
  
        return new Line( geometry, material );
  
      case 'gaze':
  
        geometry = new RingBufferGeometry( 0.02, 0.04, 32 ).translate( 0, 0, - 1 );
        material = new MeshBasicMaterial( { opacity: 0.5, transparent: true } );
        return new Mesh( geometry, material );
  
    }
  
  }

  handleCamera() {

    // get the XR camera with a combined frustum for culling
    if ( this.renderer.xr.isPresenting ) {
  
      if ( this.xrSession === null ) { // We setup XR camera once
  
        const xrCamera = this.renderer.xr.getCamera( this.camera );
  
        // remove all cameras so we can use the VR camera instead
        this.tiles.cameras.forEach( c => this.tiles.deleteCamera( c ) );
        this.tiles.setCamera( xrCamera );
  
        const leftCam = xrCamera.cameras[ 0 ];
        if ( leftCam ) {
  
          this.tiles.setResolution( xrCamera, leftCam.viewport.z, leftCam.viewport.w );
  
        }
  
        this.xrSession = this.renderer.xr.getSession();
  
      }
  
    } else {
  
      // Reset default camera (exiting WebXR session)
      if ( this.xrSession !== null ) {
  
        this.tiles.cameras.forEach( c => this.tiles.deleteCamera( c ) );
  
        this.tiles.setCamera( this.camera );
        this.tiles.setResolutionFromRenderer( this.camera, this.renderer );
  
        this.camera.position.set( 0, this.cameray, this.cameraz );
  
        this.xrSession = null;
  
      }
  
    }
  
  }
  
  handleTasks() {
  
    for ( let t = 0, l = this.tasks.length; t < l; t ++ ) {
  
      this.tasks[ t ]();
  
    }
    this.tasks.length = 0;
  
  }
  

  render2() {

    

    requestAnimationFrame( this.animate );

    // console.log('render2render2render2render2render2')

    // this.grid.visible = this.params.displayGrid;
    this.grid.visible = true;
  
    // update options
    this.tiles.displayBoxBounds = this.params.displayBoxBounds;
    this.tiles.colorMode = parseFloat( this.params.colorMode );
  
    // update tiles center
    if ( this.tiles.getBounds( this.box ) ) {

      this.box.getCenter( this.tiles.group.position );
      this.tiles.group.position.multiplyScalar( - 1 );
  
    } else if ( this.tiles.getBoundingSphere( this.sphere ) ) {
  
      this.tiles.group.position.copy( this.sphere.center );
      this.tiles.group.position.multiplyScalar( - 1 );
  
    }
  
    this.handleCamera()

    this.handleTasks()
  
    this.tiles.update();
  
  
    if ( this.controller.controllerActive ) {
  
      const { ray } = this.raycaster;
      this.raycaster.firstHitOnly = true;
  
      // get the controller ray
      ray.origin
        .copy( this.controller.position )
        .applyMatrix4( this.workspace.matrixWorld );
        this.controller
        .getWorldDirection( ray.direction )
        .multiplyScalar( - 1 );
  
      const results = this.raycaster.intersectObject( this.tiles.group, true );
      if ( results.length ) {
  
        const hit = results[ 0 ];
  
        hit.face.normal.transformDirection( this.tiles.group.matrixWorld );
        this.intersectRing.position.copy( hit.point );
        this.intersectRing.quaternion.setFromUnitVectors(
          this.fwdVector,
          hit.face.normal,
        );
        this.intersectRing.visible = true;
  
        // scale ring based on distance
        const scale = this.workspace.position.distanceTo( this.intersectRing.position ) * this.camera.fov / 4000;
        this.intersectRing.scale.setScalar( scale );

        if ( hit.face.normal.dot( upVector ) < 0.15 ) {

          // this.intersectRing.visible = false;
  
        }
  
      } else {
  
        this.intersectRing.visible = false;
  
      }
  
    } else {
  
      this.intersectRing.visible = false;
  
    }
    
  
    // render primary view
    this.renderer.render( this.scene, this.camera );

    // this.frameId = window.requestAnimationFrame(this.render2)
  }
  





  resetCamera(){


    

    this.animateOnce();
  }

  start() {
    this.animate()
    // if (!this.frameId) {
    //   this.frameId = requestAnimationFrame(this.render2)
    // }

  }

   ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
   ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
   ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
   ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
   ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

   animateOnce(){
    global.needsRerender = true;
    this.animate();

   }

  animate() {


    this.renderer.setAnimationLoop( this.render2 );
  
  }

  renderScene() {
    
  }
  
  
  componentDidMount() {
    this.init();
    this.start();

    const btn = VRButton.createButton( this.renderer );
    console.log(btn);
    document.body.appendChild(btn );

    window.addEventListener( 'resize', this.onWindowResize, false );
    // if (!nodeUtils.isSlave()) this.myGamepadInstance.start();

    

  }

  componentWillUpdate(nextProps) {



  }

  componentDidUpdate(){

  }

  testButton(){

    console.log("test")
    // this.offsetParent.position.setZ(this.offsetParent.position.z-1);
    this.offsetParent.rotation.x=this.offsetParent.rotation.x-Math.PI/2;
    // this.offsetParent.rotation.z=this.offsetParent.rotation.z-Math.PI/2;

    // this.controls.lock()
    // console.log("this.camera",this.camera);

    // this.stereoOffset += 0.0005;
    // this.stereoCamera.rotateOnWorldAxis(new Vector3(0.0, 1.0, 0.0), 0.0005)

    // this.state.stereoOffset = this.stereoOffset;

    // this.state.message = this.stereoOffset;
    // nodeUtils.SaveFloat("stereoffset",this.stereoOffset)
    // this.setState(this.state);

    
    // alert(this.stereoOffset);

    // this.camera.up.set( 0, 0, 1 );
    // console.log(this.camera);

    // this.camera.position.setX(-4.5);
    // this.camera.position.setY(-5);
    // this.camera.position.setZ(-2.3);

    // this.camera.rotation.set(0,-2.6,0)
    // // console.log(this.offsetParent.position);
    // this.offsetParent.position.setX(this.offsetParent.position.x-50);
    // this.offsetParent.position.setY(this.offsetParent.position.y-50);

    // console.log(this.offsetParent.position);
    
  }

  
  testButton2(){

    // this.controls.lock()
    
    // console.log("this.camera.position");
    // console.log(this.camera);

    // this.camera.position.setX(48);
    // this.camera.position.setY(-38);
    // this.camera.position.setZ(44);

    // this.camera.rotateOnWorldAxis(new Vector3(1.0, 0.0, 0.0), 1)
    // this.camera.rotateOnWorldAxis(new Vector3(0.0, 1.0, 0.0), 0.5)

    this.stereoOffset -= 0.0005;
    this.stereoCamera.rotateOnWorldAxis(new Vector3(0.0, 1.0, 0.0), -0.0005)

    this.state.stereoOffset = this.stereoOffset;
    this.state.message = this.stereoOffset;
    this.setState(this.state);

    console.log(this.stereoOffset);
    

    // alert(this.stereoOffset);

    // this.camera.rotation.set(this.camera.rotation.x,this.camera.rotation.y,0)
    // this.camera.rotation.set(0,0.2,0)
    // console.log(this.offsetParent.position);
    // this.offsetParent.position.setX(this.offsetParent.position.x-50);
    // this.offsetParent.position.setY(this.offsetParent.position.y-50);

    // console.log(this.offsetParent.position);
    
  }

  showmessage(message){
    this.state.message = message;
    this.setState(this.state);
  }





  
  render() {

    // const {lightboxtitre,lightboxurl,isplaying} = this.state;
    // console.log(lightboxtitre)

    const { fullScreen,texttospeechreceived,stereoOffset,StereoEnabled,message,showvirtualgamepad } = this.state;
    const joystick = this.joystick;

    let url = "https://gigascopejs.firebaseapp.com/3dclient/" + this.modelename;

    let righticon = 'topright1 whiteBackground clickeable padding075em';

    let lefticon = 'topleft1 whiteBackground clickeable padding075em';
    return (
      
      
      // (lightboxurl != "") &&
      <div id="3dcontainer">
        
        {/* <div  className="infront">
        <button   onClick={() => this.testButton()} >
                Test Button
            </button>
        </div> */}
         <div id="tileContainer" style={{ width: "50%", height: "100%" }}/>

            
         

      </div>
      
    );
  }
}

export default TestTilesVR;
