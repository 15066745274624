import React, { Component } from 'react';
import { BrowseEvent } from '../js/browsinghistory.js';
import firebase from '../Firebase';
import { collection, addDoc } from "firebase/firestore"; 

class MessageListener extends Component {
    constructor(props) {
      super(props);
      this.ref = collection(global.db,'internalmessages');
      this.unsubscribe = null;
      // console.log('MessageListener');
      this.state = {
        internalmessages: []
      };
    }
    
    onCollectionUpdate = (querySnapshot) => {

       console.log("messagereceived!!!")
    

      
        const internalmessages = [];
        // console.log(querySnapshot);
        querySnapshot.forEach((doc) => {
          const { type,
            message} = doc.data();
            internalmessages.push({
                type, // DocumentSnapshot
                message
          });
        });

        // console.log('internalmessages',internalmessages);

        internalmessages.forEach((im) => {

         
    

            switch (im.type) {
                case 'sync':

                  var browseEvent = new BrowseEvent(JSON.parse(im.message));
                  // console.log('browseEvent ',browseEvent);
                  // console.log('browseEventbrowseEvent',browseEvent.getthetype());

                  switch (browseEvent.getthetype()){

                    case 'endMove':
                      if (global.isslave){
                   
                     
                  
                        if (window.thepano && !browseEvent.isFromThisUser() && browseEvent.isFromCurrentPano()){
                          // window.thepano.omap.getView().fit(browseEvent.getExtent());
                          
                          window.thepano.setPosition(browseEvent.getPosition());
                        }
    
                        /*var test = global.user != null;
                        if (test) test = global.user.email == 'cyrilpreiss@gmail.com'
    
                        if (!test && window.thepano && !browseEvent.isFromThisUser()){
                          window.thepano.omap.getView().fit(browseEvent.getExtent());
                        }*/
                      }
                    break;
                    case 'endMove3D':
                      if (global.isslave){
                   
                     
                        console.log("endMove3D",browseEvent)

                        global.myEmitter.emit('3dbrowsenow', {browseEvent: browseEvent});
                        
                      }
                    break;
                    case 'speechtotext':
                      if (global.isslave){
                   
                     
                   // global.myEmitter.emit('messagereceived', {"type": im.type,"message":im.message});
                        global.myEmitter.emit('messagereceived', {"internalmessage":browseEvent});
                        // console.log("messagereceived2",im)
                        
    
                        /*var test = global.user != null;
                        if (test) test = global.user.email == 'cyrilpreiss@gmail.com'
    
                        if (!test && window.thepano && !browseEvent.isFromThisUser()){
                          window.thepano.omap.getView().fit(browseEvent.getExtent());
                        }*/
                      }
                    break;


                  }

                  
                  
                    break;
                case 'speechtotext':
                  if (global.isslave){
                    var browseEvent = new BrowseEvent(JSON.parse(im.message));
                     console.log('browseEvent speechtotext',browseEvent.getText());
                  }
                  break;
                default:
                    break;
            }
            
        });
      
       

      }

      componentDidMount() {
     //   this.unsubscribe = this.ref.onSnapshot(this.onCollectionUpdate);
      }

      render() {
        return (
            <div></div>
        );
      }
      
}

export default MessageListener;