import { Canvas } from '@react-three/fiber'
import { CameraControls, Environment, MeshTransmissionMaterial } from '@react-three/drei'
import { Splat } from './splat'
import { Suspense } from "react";

export default function SplatComponent() {
    // this.src =  window.splat
  return (
    <div style={{ width: "100vw", height: "100vh" }}>
        <Canvas dpr={[1, 1.5]} camera={{ position: [0, 1.5, 5], fov: 50 }} >
      <color attach="background" args={['#151520']} />
      <Suspense fallback={null}>
        <Splat
            scale={1}
            rotation={[0, Math.PI, 0]}
            // src="https://data2.gigascope.net/splats/dog-photos.splat"
                  // https://twitter.com/the_ross_man/status/1726815140535009785
            // src="bureaux171123_100k.splat"
            // src="https://data2.gigascope.net/splats/ritz_horloge_lightroom_100k.splat"
            src={window.splat}
        />
      </Suspense>
      
      {/* <mesh position={[0.4, 0.2, 0.8]}>
        <sphereGeometry args={[0.45, 32, 32]} />
        <MeshTransmissionMaterial
          backside
          backsideThickness={1}
          resolution={128}
          thickness={0.2}
          anisotropicBlur={0.1}
          chromaticAberration={0.1}
          color="#406080"
          toneMapped={false}
          distortion={100}
          envMapIntensity={1}
        />
      </mesh> */}
      {/* <Environment preset="apartment" /> */}
      <CameraControls
        enablePan={false}
        minAzimuthAngle={-Math.PI / 4}
        maxAzimuthAngle={Math.PI / 2}
        minPolarAngle={0}
        maxPolarAngle={Math.PI / 2}
      />
    </Canvas>
    </div>
    
  )
}
