import React, { Component } from 'react';

import Char2D from './Char2D'

import nodeUtils from '../js/nodeUtils.js'


class BackOfficeComp extends Component {
  constructor(props) {
    super(props);


    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    // this.toggleFullscreen = this.toggleFullscreen.bind(this);


    
     this.state = {
       texttosend:''
     }
      
    // }; 

    
  }
  
  componentDidMount() {
   
  }

  componentWillUpdate(nextProps, nextState) {
    

      this.setState(
        this.state
      )
    
 
  }

  button1(){
    console.log("bouton1")

    // console.log("trad",)

    // console.log("trad",nodeUtils.translateText(this.state.texttosend,'en'))

    // nodeUtils.translateText(this.state.texttosend,'en').then((value) => console.log("trad",value));
    
    // sayText('...Bonjour...',1,4,2);	
    // nodeUtils.sendMessager("eb419390-c795-11eb-8568-075bdb4298e2",{"type":"message","content":"hello there"});

    nodeUtils.sendMessager("eb419390-c795-11eb-8568-075bdb4298e2",{"type":"avatarsay","content":this.state.texttosend});
    // nodeUtils.sendMessager("eb419390-c795-11eb-8568-075bdb4298e2",{"type":"hideavatar"});
  }

  button2(){
    console.log("bouton1")
    
    // sayText('...Bonjour...',1,4,2);	
    // nodeUtils.sendMessager("eb419390-c795-11eb-8568-075bdb4298e2",{"type":"message","content":"hello there"});

    nodeUtils.sendMessager("eb419390-c795-11eb-8568-075bdb4298e2",{"type":"showavatar"});
  }

  handleSubmit(event) {
    // alert('Le nom a été soumis : ' + this.state.value);
    console.log("event.target.value",event.target.value)
    nodeUtils.sendMessager("eb419390-c795-11eb-8568-075bdb4298e2",{"type":"message","content":event.target.value});
  }

  handleChange(event) {
    // console.log("tt" )
    // console.log(event.target.value )
    console.log('this.state.texttosend',this.state.texttosend)
    if (event.target.value != this.state.texttosend) {
      // console.log("hh")
      // this.setState({texttosend: event.target.value});
      this.state.texttosend = event.target.value
    }  
  }
 

  render() {

   
   
    return (
      <div id="backoffice">
        <button   onClick={() => this.button1()} >
                Bouton1
            </button>
            <button   onClick={() => this.button2()} >
                Bouton2
            </button>
            <form  onSubmit={e => {           e.preventDefault();    this.button1()     }} >
        <label>
          Nom :
          <input type="text" value="Comment vas tu" onChange={this.handleChange}/>
        </label>
        <input type="submit" value="Envoyer" />
      </form>
      </div>
      
    );
  }
}

export default BackOfficeComp;
