import React, { Component } from 'react';
import Container from 'react-bootstrap/Container'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import parse from 'html-react-parser';

import AskIdMessage from './AskIdMessageComp';



import firebase from '../Firebase';
import { Bookmark } from '../js/Bookmark';

import nodeUtils from '../js/nodeUtils.js'

// import nodeUtils from '../js/nodeUtils.js'

class SubtilteComp extends Component {
  constructor(props) {
    super(props);
     
    
     this.state = {
       shown:true,
       text: props.text,

    }; 

    

    let thiscomp = this;

    // firebase.auth().onAuthStateChanged(function(user) {
    //   // if (this.state.bookmark){
    //     if (user) {

    //       thiscomp.state.time = Date.now()
    //       thiscomp.setState(
    //         thiscomp.state
    //       )

    //       nodeUtils.setVisible('bkinfo',true)
    //       nodeUtils.setVisible('bookmarklogin',false)
          
    //       if (nodeUtils.ReadString("InfoBig") == "1") thiscomp.toggleBig();
    //       else thiscomp.toggleSmall();
           
         
        
    //   } else {
    //     console.log('No user is signed in.')
        
    //   }
      // }
      
    // });
  }
  
  componentDidMount() {
  // if (nodeUtils.ReadString("InfoBig") == "1") this.toggleBig();
    // this.updateContent();
    
  }

  componentWillUpdate(nextProps, nextState) {


    if (nextProps.text != this.state.text){

      this.state.text = nextProps.text
          
      // this.state.bookmark =nextProps.bookmark;
      // this.state.shown = true
      // this.state.bookmarkindex = nextProps.bookmarkindex

      // this.state.showlogin = !this.canAccessBookmark(nextProps.bookmark)

      // this.state.isaudio = global.isvisitAudio;

      this.setState(
        this.state
      )
    }
      
    
      // nodeUtils.setVisible('bookmarksinfobottom',true)

      // if (nextProps.bookmark) nodeUtils.showBookmarkInfo();

    // }    
    // nodeUtils.setVisible('bookmarksinfodiv',true);

  }





  componentDidUpdate(){

    if (this.state.shown){
      // this.updateContent()
    } else {
      // nodeUtils.setVisible('bookmarksinfodiv',false);
    }
    

    
  }


  close(){


    // nodeUtils.setVisible('bookmarksinfodiv',false);
    // nodeUtils.setVisible("bookmarksinfoplus",false);
    // nodeUtils.setVisible("bookmarksinfomoins",false);
    // this.state.shown = false;
    // this.state.bookmarkindex = -1;
    // this.isclosing = true;
    
    // this.setState(
    //   this.state
    // )
    // nodeUtils.setVisible('bookmarksinfobottom',false)
/*     this.state.bookmark = "null";
    this.state.shown = false;
    
    this.setState(
      this.state
    ) */
  }





  render() {


    const {text} = this.state;

    

    return (
      // (this.state.bookmark)?
        
      
      <div id="subtitlediv" className="subtitle clickeable" >
        <div className="d-flex justify-content-between">

        {/* < div className="d-flex">
          <div ><FontAwesomeIcon className="closebicon" icon="times"  onClick={() => showBookmarkInfoDiv(false)}/></div>
          <div id="bookmarksinfoplus" > <FontAwesomeIcon icon="search-plus"  onClick={() => this.toggleBig()}/></div>
          <div id="bookmarksinfomoins" ><FontAwesomeIcon icon="search-minus"  onClick={() => this.toggleSmall()}/></div>
          <div ><FontAwesomeIcon icon="search-plus"  onClick={() => readCurrentBookmark()}/></div>
        </div> */}

        
          
          
        {/* </div>
            <div className="bookmarkInfoTitre"   >{this.state.bookmark.label}</div>            
            
          <div> */}

         
          <div id="">
              {text} 
            </div>
            
        
          
        
        
           
            {/* <div id="bookmarkInfoTextid" className="bookmarkInfoText" >{parse(this.BookmarkInfoText())}</div> */}
           {/*  <div id="bookmarkInfoTextid" className="bookmarkInfoText" >{parse(this.state.bookmark.text)}</div> */}
 
           {/*  <div id="bookmarkInfoTextid" className="bookmarkInfoText"  dangerouslySetInnerHTML={this.BookmarkInfoText()}/> */}
           </div>
      </div>
      // :
      //   <div></div>
/*       <div className="bookmarkInfoTitre">{this.state.bookmark.label}</div>:           
      <div className="bookmarkInfoTitre">fail</div> */
      
    );
  }
}

export default SubtilteComp;
