import React, { Component } from 'react';
import Container from 'react-bootstrap/Container'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import parse from 'html-react-parser';

import nodeUtils from '../js/nodeUtils.js'

class ChoixAudioComp extends Component {
  constructor(props) {
    super(props);
    
    this.panorama = props.panorama;

     this.state = {
       shown:false,
       panorama: props.panorama,
       titre : props.panorama.titre,
       url : props.panorama.url,
       panoname :props.panorama.panoname,
    }; 
  }
  
  componentDidMount() {
  // if (nodeUtils.ReadString("InfoBig") == "1") this.toggleBig();
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextProps.panorama != this.state.panorama ){     
   
     this.state.panorama =nextProps.panorama;
     this.state.url=nextProps.panorama.url;
     this.state.panoname=nextProps.panorama.panoname;
     this.setState(
       this.state
     )  

   }

 }

  componentDidUpdate(){
   
  }

  close(){
   
  }

  startAudio(){

  }
  

  render() {

    let startVisit = this.props.startVisit;

    const {url, panoname,titre} = this.state;
  
    return (      

       
      
      <div  id="gigascopephotologin" className="infront full d-flex justify-content-center align-items-center">
            <div className="panoinfomessagebox darkmessageBox">

            <div className="d-flex flex-column align-items-center ">

                <h4 className="panoinfomessagetitre">{titre}</h4>

                <div >
                     <img  className="panoinfomessageimg" src={url + panoname + "/thumb.jpg"} />
                </div>

                <button onClick={() => startVisit(false)}>Visite classique</button>
               
                <button onClick={() => startVisit(true)}>Visite audio</button>
                
                
                </div>
                </div>
            </div>
            
         

      
    );
  }
}

export default ChoixAudioComp;
