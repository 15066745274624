import { AutoPath } from './AutoPath.js';
import { Position } from './Position.js';

import nodeUtils from '../js/nodeUtils.js'

export class AudioEvent{

    constructor(parent,audioevent){
      this.parent = parent;
      this.audioevent = audioevent;
      this.active = false;

      if (this.isAutoPath()){
        this.autoPath = new AutoPath(this,{pathpoints: this.audioevent.path});
      }
    }

    update(time){

      if(!this.active){
        if (this.shouldBeActive(time)){
          this.activate();
        }        
      } else {
        if (!this.shouldBeActive(time)){
          this.deactivate();
        }        
      }
    }

    endPos(){
      switch (this.type()){
        case 'move':
          return new Position(this.audioevent.posx,this.audioevent.posy,this.audioevent.zoom)
          break;
        case 'path':
          return this.autoPath.endPos();
      } 
    }

    isPositionEvent(){
      switch (this.type()){
        case 'pause':
          return false;
        case 'move':
          return true;
        case 'photo':
          return false;
        case 'path':
          return true;
      } 
    }


    type(){
      return this.audioevent.type;
    }

    isAutoPath(){
      return this.type() == 'path'
    }

    activate(){
      console.log("activate",this)
      this.active = true;
      switch (this.audioevent.type){
        case 'pause':
          this.parent.pauseAudio();
          break;
        case 'move':
          window.thepano.flyToDuration([this.audioevent.posx,this.audioevent.posy],this.audioevent.zoom,this.audioevent.speed*1000)
          break;
        case 'photo':
          this.parent.parent.props.showPhoto(true,this.audioevent)

          // this.parent.parent.props.showLightBox(true,this.audioevent.duration,this.audioevent.url,this.audioevent.titre,this.audioevent.position)
          break;
        case 'path':
          this.autoPath.start();
          // window.thepano.setAutoMove({pathpoints: this.audioevent.path})
          break;
      } 
    }

    autoupdate(){
      if (this.active) return
      switch (this.audioevent.type){
        case 'path':
          this.autoPath.update();
          break;
      } 
    }

    getTime(){
      return this.parent.getTime();
    }

    deactivate(){
      console.log("deactivate",this)
      this.active = false;
      switch (this.audioevent.type){
        case 'pause':
          this.parent.playAudio();
          break;
      case 'photo':
        this.parent.parent.props.showPhoto(false)
        break;
      } 
    }

    play(){
      if (!this.active) return

      switch (this.audioevent.type){
      case 'path':
        this.autoPath.play();
        break;
      }
    }

    time(){
      return this.audioevent.time;
    }

    isPause(){
      return this.audioevent.type == 'pause';
    }

    shouldBeActive(time){
      return time > this.starttime() && time <= this.endtime();
    }

    starttime(){
      return this.audioevent.time;
    }

    endtime(){
      return this.audioevent.time + this.duration();
    }

    duration(){
      switch (this.audioevent.type){
        case 'pause':
          return this.audioevent.duration;
        case 'move':
          return 0.5;
        case 'photo':
          return this.audioevent.duration;
        case 'path':
          return this.autoPath.duration();
      } 
    }

    checkIfEvent(){
      this.audioevents.forEach(element => {
        if (element.type == 'pause'){
          // let delta = (element.time - this.getTime())
          // if (this.currentmoveelement != element && delta < 1 && delta > 0) {
          //   this.currentmoveelement = element;
          //   window.thepano.flyTo2([element.posx,element.posy],element.zoom)
          // }
          
        } else 
        if (element.type == 'move'){
          let delta = (element.time - this.getTime())
          if (this.currentmoveelement != element && delta < 1 && delta > 0) {
            this.currentmoveelement = element;
            window.thepano.flyTo2([element.posx,element.posy],element.zoom)
          }
          
        } else if (element.type == 'photo'){
          let delta = (element.time - this.getTime())
          if (this.currentphotoelement != element && delta < 1 && delta > 0) {
            this.currentphotoelement = element;
            console.log('showLightBox currentphotoelement',this.currentphotoelement)
            this.props.askshowLightBox(true,element.duration,element.url,element.titre)
            
          } else
          if (this.currentphotoelement == element && -delta > element.duration) {
            console.log('showLightBox currentphotoelement2',this.currentphotoelement)
            
            this.props.askshowLightBox(false)
            this.currentphotoelement = null;
          }
        } else if (element.type == 'path'){
          let delta = (element.time - this.getTime())
          if (this.currentpathelement != element && delta < 1 && delta > 0) {
            this.currentpathelement = element;
            window.thepano.setAutoMove({pathpoints: element.path})
          }
        }
        
      });  
    }

}