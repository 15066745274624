import { useEffect } from 'react';
import {
    Link
} from "react-router-dom";
function Char2D() {
    useEffect(() => {
        function loadEmbedFile() {
            var sc = document.createElement('script');
            sc.type = "text/javascript";
            sc.src = "//vhss-d.oddcast.com/vhost_embed_functions_v4.php?acc=5626448&js=1";
            sc.onload = function () {
                callEmebedFunction();
            }
            document.head.appendChild(sc);
        }
        function callEmebedFunction() {

            var interval = setInterval(() => {
                if (typeof AC_VHost_Embed == 'function') {
                    clearInterval(interval);
                    var script = document.createElement('script');
                    script.type = "text/javascript";
                    script.innerHTML = 'AC_VHost_Embed(8354790,150,200,"",1,1,2745850,0,1,1,"z0YZLAvXadtxKazRMN3zrjvHyBMQy1Aw",1)';
                    document.getElementById('embed').appendChild(script);
                }
            }, 10)
        }
        const scripts = document.head.getElementsByTagName('script');
        if (scripts.length > 0) {
            var alreadyAdded = false;
            for (var i = 0; i < scripts.length; i++) {
                if (scripts[i].src.includes("vhost_embed_functions_v4.php")) {
                    alreadyAdded = true;
                    callEmebedFunction();
                }
                if (scripts.length === i + 1 && !alreadyAdded) {
                    loadEmbedFile();
                }
            }
        }
        else {
            loadEmbedFile();
        }
    }, []);
    return (
        <div className="avatar">
            
            <div id="embed" className="Comment"></div>
            
        </div>

    );
}
export default Char2D;