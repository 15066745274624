import { Position } from "./Position";

var getCurvePoints = require("cardinal-spline-js").getCurvePoints;

export class AutoPath{
    label = "";
    pos = [0,0];
    zoom = 1;

    constructor(parent,data){
        this.parent = parent;
        this.setAutoMove(data);
    }

    setAutoMove(data){

        
        this.doStartAutoMove = true;
        this.isautoMove = true;
        // console.log(data)
    
        let inPoints = [];
        let nbsegments = data.pathpoints.length-1;
        
        global.duration = 1000/60;
        // let nbpoints = Math.round((data.pathpoints[1].time - data.pathpoints[0].time)*1000/global.duration);
        // global.duration = (data.pathpoints[1].time - data.pathpoints[0].time)*1000/nbpoints;
    
        // nbpoints = 10;
    
        data.pathpoints.forEach(element => {
            inPoints.push(element.posx);
            inPoints.push(element.posy);
        });
        
    
        this.pathPoints = [];

        // this.pathPoints.push({x:curvedpoints[xx],y:curvedpoints[yy],zoom:zoomini + zoominc * i,duration:global.duration,time:timeini+timeinc*i});

    
        // window.thepano.flyToDuration([data.pathpoints[0].posx,data.pathpoints[0].posy],data.pathpoints[0].zoom,1000);
        var j;
        for (j = 0; j < nbsegments; j++) {
            let nbpoints = Math.round((data.pathpoints[j+1].time - data.pathpoints[j].time)*1000/global.duration);
            // nbpoints = 3;
    
            console.log("nbpoints",nbpoints)
    
            let curvedpoints = getCurvePoints(inPoints,0.5, nbpoints);
            
            var i;
            let zoomini = data.pathpoints[j].zoom
            let zoomfinal = data.pathpoints[j+1].zoom
    
            let zoominc = (zoomfinal - zoomini)/nbpoints
    
            let timeini = data.pathpoints[j].time
            let timefinal = data.pathpoints[j+1].time
            let timeinc = (timefinal - timeini)/nbpoints
    
            for (i = 0; i < nbpoints; i++) {
                let decalage = j*nbpoints * 2;
                let xx = i*2+decalage;
                let yy = i*2 + 1 +decalage;
                // console.log(xx,yy)
                this.pathPoints.push({x:curvedpoints[xx],y:curvedpoints[yy],zoom:zoomini + zoominc * i,duration:global.duration,time:timeini+timeinc*i});
            }
        }
    
        let lastone = data.pathpoints[data.pathpoints.length-1];
        this.lastpoint = {x:lastone.posx,y:lastone.posy,zoom:lastone.zoom,duration:global.duration,time:lastone.time}
        this.pathPoints.push(this.lastpoint);
    
        // let nbpoints = 3;
        
        // let curvedpoints = getCurvePoints(inPoints,0.5, nbpoints);
    
        // console.log("nbpoints",nbpoints);
        // console.log("curvedpoints",curvedpoints)
    
        // var i;
        // for (i = 0; i < curvedpoints.length/2; i++) {
        // 	this.pathPoints.push({x:curvedpoints[i*2],y:curvedpoints[i*2+1],zoom:data.pathpoints[0].zoom-1,duration:global.duration});
        // }
    
        // console.log("duration",global.duration)
    
        // console.log("data.pathpoints",data.pathpoints);
        
        console.log(this.pathPoints.length)
        // this.AutoMoveNext();
    
        // global.duration = (lastone.time - data.pathpoints[0].time) *1000/ this.pathPoints.length;
    
        // console.log("global.duration",global.duration)
    
        // global.autoMove = setInterval(this.AutoMoveNext, global.duration);
        // requestAnimationFrame(this.AutoMoveNext.bind(this));
    
    }

    endPos(){
        return new Position(this.lastpoint.x,this.lastpoint.y,this.lastpoint.zoom);
    }

    start(){
        // window.thepano.flyToDuration([data.pathpoints[0].posx,data.pathpoints[0].posy],data.pathpoints[0].zoom,1000);
        requestAnimationFrame(this.AutoMoveNext.bind(this));
    }
    
    duration(){
        return this.pathPoints[this.pathPoints.length - 1].time - this.pathPoints[0].time;
    }
    
    pauseAutoMove(){
        if (global.autoMove) clearInterval(global.autoMove);
    }
    
    startAutoMove(){
        if (this.isautoMove) {
            this.doStartAutoMove = false;
            global.autoMove = setInterval(this.AutoMoveNext, global.duration);
        }
    }

    play(){
        requestAnimationFrame(this.AutoMoveNext.bind(this));
    }

    update(){
        this.setCurrentPoint();
    }

    setCurrentPoint(){
        let point = this.pathPoints.find(pathPoint => pathPoint.time > this.parent.getTime());
        // this.flyToDuration([point.x,point.y],point.zoom,point.duration);

        if (point){
            window.thepano.setPosition(new Position(point));
            // window.thepano.setCenter([point.x,point.y]);
            // window.thepano.setZoom(point.zoom);
    
            requestAnimationFrame(this.AutoMoveNext.bind(this));
        }
    }
    
    AutoMoveNext(){
        // if (!global.isplaying) clearInterval(global.autoMove);
    
        var self = this;

        // if (!global.isplaying) return
    
        if (this.pathPoints.length > 0){
            // let point = this.pathPoints.shift()
            
            this.setCurrentPoint();
            
            // this.flyToDuration([point.x,point.y],point.zoom,100);
        } else {
            window.thepano.isautoMove = false;
            clearInterval(global.autoMove);
        }
    }

}