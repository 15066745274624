import React, { Component } from 'react';
import Container from 'react-bootstrap/Container'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Rounded from "react-rounded-image";
import app from 'firebase/compat/app';

/* import { faCoffee } from '@fortawesome/free-solid-svg-icons'
 */

// import ProgressBar from 'react-bootstrap/ProgressBar'
// import { Progress  } from 'reactstrap';


import { withAlert } from 'react-alert';

import RangeSlider from 'react-bootstrap-range-slider';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';

import { AudioObject } from '../js/AudioObject.js';

import { Line } from 'rc-progress';

import nodeUtils from '../js/nodeUtils.js'

// var rose = require('./sound/testrose.mp3')

// const responsive = {
//   other: {
//     breakpoint: { max: 10000, min: 464 },
//     vertical: false,
//   },
//   mobileh: {
//     breakpoint: { max: 464, min: 0 },
//     vertical: true,
//   },
// };

class PlayerComp extends Component {
  constructor(props) {
    super(props);
    this.bookmark = props.bookmark;

    global.isplaying = false;

    this.playsound = false;

    this.seek = 0;
    
     this.state = {
      bookmarks: props.bookmark,
      isplaying: false,
      seek:0,
      label : "",
      progress : 0,
      isready: false,
      time:0,
      isMobileVertical: nodeUtils.isMobileVertical()
    }; 



    this.audioevents = [{type: "photo", duration: 10, time: 3.4, url: "http://cathedrale.gothique.free.fr/image/Paris_Notre-Dame_rose_ouest_1a_envol.jpg"},
    {type: "photo", duration: 20, time: 11.5, url: "http://cathedrale.gothique.free.fr/image/Villard_de_Honnecourt_Humilite.jpeg"},
    {type: "move", time: 35, posx: 4774, posy: -8140, zoom: 7},
    {type: "photo", duration: 20, time: 51.5, url: "http://cathedrale.gothique.free.fr/image/Paris_Notre-Dame_rose_ouest_1d_chute.jpg"}];

    
    // this.audioevents = [{type: "photo", time: 92.7, url: ""}];
    this.currentelementtime = -1;

    this.audioevents = [];

    
    console.log(JSON.stringify(this.audioevents))

    this.togglePlay = this.togglePlay.bind(this);

    let self = this;

    global.myEmitter.on('PauseAudio', function (data) {
      self.togglePlay()
    });

    global.myEmitter.on('PlayAudio', function (data) {
      self.togglePlay()
    });
    
    
  }
  
  componentDidMount() {

    // nodeUtils.setVisible2('bookmarksaudiodivpause',false)
    // nodeUtils.setVisible2('bookmarksaudiodivplay',false)
   
  }

  componentWillUpdate(nextProps) {

    if (nextProps.bookmark != this.state.bookmark ){
               
      this.state.bookmark =nextProps.bookmark;
      this.state.label = nextProps.bookmark.label;
      this.state.shown = true
      this.state.seek = 0
      this.state.isready = true
      
      this.setState(
        this.state
      )

      if (nextProps.bookmark) {

        if (this.sound) {
          this.audioObject.stop();
        }

        if (nextProps.bookmark.audioname && nextProps.bookmark.audioname != ""){

          this.audioObject = new AudioObject(this,nextProps.bookmark);

          console.log("audioObject",this.audioObject.getState(1)) 
          console.log("audioObject",this.audioObject.getState(5)) 
          console.log("audioObject",this.audioObject.getState(6)) 

          this.audioevents = [{type:"audiopause",time:2,pause:3}];

          console.log("stringify",JSON.stringify(this.audioevents)) 

          
          if (this.state.bookmark.audioevents && this.state.bookmark.audioevents!="none" && this.state.bookmark.audioevents!="no"){

            
            this.audioevents = JSON.parse(this.state.bookmark.audioevents);

            this.audioevents = [{type:"audiopause",time:2,pause:3},{type:"path",time:5,path:[{time:5.2,posx:4754,posy:-8415,zoom:6},
              {time:20,posx:8472,posy:-8901,zoom:4},{time:30,posx:7843,posy:-3759,zoom:6}]}];

            // console.log(this.state.bookmark.audioevents) 

          this.audioevents.sort(function (a, b) {
            return parseInt(a.time) - parseInt(b.time);
          });
        }

          // nodeUtils.setVisible2('bookmarksaudiodiv',true);       

          // let audiofile = 'http://37.187.22.210/data/sounds/' + nextProps.bookmark.audioname + '.mp3';
          // let audiofile = './sound/rose.mp3';
          // let audiofile = 'https://www.gigascope.net/sound/' + nextProps.bookmark.audioname + '.mp3';

          let audiofile = window.thepano.getPanoFullPath() + "/Audio/" + nextProps.bookmark.audioname + '.mp3';

          console.log(audiofile)


          // if (nodeUtils.isMe()) alert(audiofile)

          var self = this;

      //     this.sound = new Howl({            
      //       src: [audiofile],
      //       autoplay: global.isplaying,
      //       html5: true, // Force to HTML5 so that the audio can stream in (best for large files).
      //       onplay: function() {
      //         // self.setAudioProgress()
      //         // this.state.seek = 0;
      //         requestAnimationFrame(self.step.bind(self));
      //       },
      //       onload : function() {
      //         self.state.seek = 0;
      //         self.state.length = self.sound.duration();
      //         self.setState(self.state);
      //         console.log(self.state);
      //         self.setProgressBar();
      //       },
      //       /* onend: function() {
      //         console.log('Finished!');
      //       },*/
      //       onloaderror : function(id,error) {
      //         console.log('onloaderror!',error);
      //         if (nodeUtils.isMe()) alert(error)
      //       },
      //  /*     onplayerror : function(id,error) {
      //         console.log('onloaderror!',error);
      //         if (nodeUtils.isMe()) alert('onplayerror!',error)
      //       } */
      //     });

          let test = ":"
          
          // if (global.isplaying) this.audioObject.play();
          
        } else {
          nodeUtils.setVisible2('bookmarksaudiodiv',false);
          // nodeUtils.setVisible2('bookmarksaudiodivpause',false)
          // nodeUtils.setVisible2('bookmarksaudiodivplay',false)
        }
      }

    }
 
  }

  componentDidUpdate(){
    if (global.isplaying){
          
      nodeUtils.setVisible2('bookmarksaudiodivpause',true)
      // nodeUtils.setVisible2('bookmarksaudiodivplay',false)
      // nodeUtils.setVisible2('bookmarksaudiodivaudioon',false)
      // nodeUtils.setMaxHeight("bookmarksinfodiv","10vh");
    } 
    else {
      nodeUtils.setVisible2('bookmarksaudiodivaudioon',false)
      // nodeUtils.setVisible2('bookmarksaudiodivpause',false)
      // nodeUtils.setVisible2('bookmarksaudiodivplay',true)
    }
  }

  setPlayTimeline(){

  }

  playManager(){

  }

  setAudioProgress(){
    let test = this.audioObject.seek()
    console.log(test);
    setTimeout(this.setAudioProgress(), 3000)
    
  }

  setProgressBar(){
    let progress = this.audioObject.getTime() / this.audioObject.duration() * 100
    // let progress = this.audioObject.seek() / this.audioObject.duration() * 100
    this.state.progress = progress;
    // this.state.seek = Math.round(this.audioObject.seek()*10)/10 || 0;
    this.setState(
      this.state
    )
    document.getElementById('position').innerHTML = this.formatTime(Math.round(this.audioObject.getTime()));
  }

  formatTime(secs) {

    // console.log("secs",secs)
    var minutes = Math.floor(secs / 60) || 0;
    var seconds = Math.round(secs - minutes * 60) || 0;

    return minutes + ':' + (seconds < 10 ? '0' : '') + seconds;
  }

  formatTimePercent(value) {

    let secs = value
    if (this.audioObject)  secs = this.audioObject.duration()*value/100
    var minutes = Math.floor(secs / 60) || 0;
    var seconds = Math.round(secs - minutes * 60) || 0;

    return minutes + ':' + (seconds < 10 ? '0' : '') + seconds;
  }


  toggleBookmarks(){
    window.bookmarksVisible = !window.bookmarksVisible;
    nodeUtils.toggleVisibleBookmarks()
  }

  signout() {
    
 /*    nodeUtils.uploadUserHistory() */

  if (nodeUtils.isMe())  app.auth().signOut();
    // firebase.doSignOut()
  }
  

  togglePlay(){

    // nodeUtils.setVisibleFull('bookmarksaudiodivaudioon',false)

    if (nodeUtils.isMe()){
      nodeUtils.setVisible2('gigaphotoinfotext',false)
    }
    
    if (global.isplaying){
      this.audioObject.pause();

      // window.thepano.pauseAutoMove();
      

      // nodeUtils.setVisible2('bookmarksaudiodivpause',false)
      // nodeUtils.setVisible2('bookmarksaudiodivplay',true)
    } 
    else {
      this.audioObject.play();

      // window.thepano.startAutoMove();

      // nodeUtils.setMaxHeight("bookmarksinfodiv","20vh");
      // nodeUtils.setVisible2('bookmarksaudiodivpause',true)
      // nodeUtils.setVisible2('bookmarksaudiodivplay',false)
    }
    // global.isplaying =!global.isplaying;
    // this.state.isplaying = global.isplaying;
    // this.setState(this.state)
  }

  // handleValueChange = value => {
  //   this.setState({ value });
  // };

  addMoveEvent(){
    let start = {type: 'move',time: this.state.seek,posx: Math.round(window.thepano.getCenter()[0]),posy: Math.round(window.thepano.getCenter()[1]),zoom: window.thepano.getZoom()}
    console.log(start)
  }

  addPhotoEvent(){
    let start = {type: 'photo',duration: 5, time: this.state.seek,url: '',titre:''}
    console.log(start)
  }

  startPathEvent(){
    console.log(window.thepano.getExtent())
    console.log(window.thepano.getCenter())
    window.thepano.movePercent(-75,0,-1);
    this.pathStart = this.audioObject.getTime();
    this.pathPoints = [{time: this.pathStart,posx: Math.round(window.thepano.getCenter()[0]),posy: Math.round(window.thepano.getCenter()[1]),zoom: window.thepano.getZoom()}]
  }

  AddPathEvent(){
    this.pathPoints.push({time: this.audioObject.getTime(),posx: Math.round(window.thepano.getCenter()[0]),posy: Math.round(window.thepano.getCenter()[1]),zoom: window.thepano.getZoom()});
    let event = {type: 'path',time: this.pathPoints[0].time,path:this.pathPoints}
    console.log(JSON.stringify(event))
  }
  endPathEvent(){

  }




  backward(){
    this.audioObject.seek(0);
    this.setProgressBar()
  }

  backFive(){
    let newtime = this.audioObject.seek() - 5;
    if (newtime < 0) newtime = 0;
    this.audioObject.seek(newtime);
    this.setProgressBar()
  }

  plus15(){
    let newtime = this.audioObject.seek() + 15;
    if (newtime < this.audioObject.duration()) this.audioObject.seek(newtime);
    this.setProgressBar()    
  }

  showLightBox(){
    this.props.askshowLightBox(true,'http://cathedrale.gothique.free.fr/image/Rosace_ouest_reconstitee.jpg')
  }

  showLightBox(){
    this.props.askshowLightBox(true,'http://cathedrale.gothique.free.fr/image/Rosace_ouest_reconstitee.jpg')
  }

  setProgressValue(value){
    console.log("setProgressValue0",value)
    this.audioObject.setProgressValue(value);
    // this.setProgressBar()
  }

  onMouseUp(){
    console.log('mouseuup')
    this.audioObject.endMovingProgress();
  }

  onMouseDown(){
    this.audioObject.startMovingProgress();
    console.log('mousedown')
  }

  getPlayerUi(backbuttonClassName,label,isplaying,progress,length,isready,nextBookmark,prevBookmark){
    return (
      
      <div className="d-flex align-items-center maxwidth100vw margin05em clickeable">
        {/* <div id="bookmarksaudiodivaudioon"><FontAwesomeIcon className="closebicon" icon="volume-up"  onClick={() => this.togglePlay()}/></div> */}
      <FontAwesomeIcon className={backbuttonClassName} icon="step-backward"  onClick={() => this.backward()}/>
      {!isplaying && <div className="margin2emleft"><FontAwesomeIcon  icon="play"  onClick={() => this.togglePlay()}/></div>}
      {isplaying && <div className="margin2emleft"><FontAwesomeIcon  icon="pause"  onClick={() => this.togglePlay()}/></div>}
{/* 
      <div className="margin2emleft" onClick={() => this.backFive()}>-5s</div>

      <div className="margin2emleft" onClick={() => this.plus15()}>+15s</div> */}

      <div id="position" className="margin2emleft"></div>

      <div className="margin2emleft" onMouseUp={() => this.onMouseUp()} onMouseDown={() => this.onMouseDown()}>
        <RangeSlider 
          value={progress}
          onChange={changeEvent => this.setProgressValue(changeEvent.target.value)}
          tooltipLabel={currentValue => this.formatTimePercent(currentValue)}
          min={0}
          max={{length}}
          tooltipPlacement='top'
          tooltip='off'
          
        />
      </div>

      <div className="margin2emleft">{this.formatTime(length)}</div>

      {/* <Line className="audioprogress" percent={progress} strokeWidth="1" strokeColor="#333333" /> */}

      {/* <FontAwesomeIcon className="margin2emleft" icon="chevron-left"  onClick={() => this.addMoveEvent()}/> */}
      <FontAwesomeIcon className="margin2emleft" icon="chevron-left"  onClick={() => prevBookmark()}/>
      <FontAwesomeIcon className="margin2emleft" icon="chevron-right"  onClick={() => nextBookmark()}/>
      {/* <FontAwesomeIcon className="margin2emleft" icon="chevron-left"  onClick={() => this.startPathEvent()}/>
      <FontAwesomeIcon className="margin2emleft" icon="chevron-right"  onClick={() => this.AddPathEvent()}/> */}

      </div>
    )
      
    
  }
  


  render() {

    let nextBookmark = this.props.nextBookmark;
    let prevBookmark = this.props.prevBookmark;
    let openFirstBookmark = this.props.openFirstBookmark;

    const {label,isplaying,progress,length,isready} = this.state;
    
    let backbuttonClassName = '';
    if (progress == 0) {
      backbuttonClassName += 'disabled';
    }

    let customClassName = 'd-flex align-items-center bookmarkaudioFooter maxwidth100vw clickeable';
    if (this.state.isMobileVertical) {
      customClassName += ' flex-column';
    }

    // console.log(window.innerWidth)

    // console.log("progress",progress)
    // console.log("backbuttonClassName",backbuttonClassName)

    return (
      (isready) ?
      <div id="bookmarksaudiodiv" className={customClassName}> 

        <div className="playerTitre margin05em">{label}</div> 

        <div>
          {this.getPlayerUi(backbuttonClassName,label,isplaying,progress,length,isready,nextBookmark,prevBookmark)}
        </div>  

        

{/*         <div id="bookmarksaudiodivpause"><FontAwesomeIcon className="closebicon" icon="chevron-left"  onClick={() => this.addMoveEvent()}/></div>
        <div id="bookmarksaudiodivpause"><FontAwesomeIcon className="closebicon" icon="chevron-right"  onClick={() => this.addPhotoEvent()}/></div> */}

        {/* <Progress  id="bookmarksaudiodivProgress" className="audioprogress" value={seek} /> */}
        
      </div> :
      <div className="d-flex align-items-center bookmarkaudioFooter maxwidth100vw clickeable"> 
        <button onClick={() => openFirstBookmark()}>Commencer la visite</button>
      </div>
      

      
    );
  }
}

  // step() {
  //   var self = this;

  //   // Get the Howl we want to manipulate.
  //   // var sound = self.playlist[self.index].howl;

  //   // Determine our current seek position.
  //   this.state.seek = Math.round(this.audioObject.seek()*10)/10 || 0;
  //   this.setState(
  //     this.state
  //   )

  //   this.checkIfEvent();

  //   this.setProgressBar();
    

  //   if (global.isplaying) {
  //     requestAnimationFrame(self.step.bind(self));
  //   }
  // }


  // checkIfEvent(){
  //   this.audioevents.forEach(element => {
  //     if (element.type == 'move'){
  //       let delta = (element.time - this.state.seek)
  //       if (this.currentmoveelement != element && delta < 1 && delta > 0) {
  //         this.currentmoveelement = element;
  //         window.thepano.flyTo2([element.posx,element.posy],element.zoom)
  //       }
        
  //     } else if (element.type == 'photo'){
  //       let delta = (element.time - this.state.seek)
  //       if (this.currentphotoelement != element && delta < 1 && delta > 0) {
  //         this.currentphotoelement = element;
  //         console.log('showLightBox currentphotoelement',this.currentphotoelement)
  //         this.props.askshowLightBox(true,element.duration,element.url,element.titre)
          
  //       } else
  //       if (this.currentphotoelement == element && -delta > element.duration) {
  //         console.log('showLightBox currentphotoelement2',this.currentphotoelement)
          
  //         this.props.askshowLightBox(false)
  //         this.currentphotoelement = null;
  //       }
  //     } else if (element.type == 'path'){
  //       let delta = (element.time - this.state.seek)
  //       if (this.currentpathelement != element && delta < 1 && delta > 0) {
  //         this.currentpathelement = element;
  //         window.thepano.setAutoMove({pathpoints: element.path})
  //       }
  //     }
      
  //   });  
  // }

export default PlayerComp;
