//require('./js/giga_imports.js');
//require('./js/Pano.js');
import { Pano } from './Pano.js';
//import { ol } from './ol.js';

//import Popup from "ol/overlay/Popup";
//import SynchronizeCustomZoom from "ol/interaction/SynchronizeCustomZoom";

var filename = ""; 

window.loadPano2 = function (pano,initMaps){

	console.log("loadPano2")
	let options =  {panodata:pano,initMaps:initMaps, ScaleLine:false,ZoomSlider:false,fscreen:false,OverviewMap:false,enableRotation:false,isVR:true,showtoolbar:false,panoindex:2};
	window.thepano2 = new Pano(options);

}

window.getPanoScreenShot = function () {
	return window.thepanoThumb;
}

window.loadPanoThumb = function (pano,positionini){

	// console.log("loadPano2")
	let options =  {panodata:pano,initMaps:true, ScaleLine:false,ZoomSlider:true,fscreen:false,OverviewMap:true,enableRotation:false,isVR:true,showtoolbar:false,panoindex:-1,positionini:positionini};
	window.thepanoThumb = new Pano(options);

}

window.loadPano = function (pano,initMaps,overviewMap = true,zoomslider = true){

	if (window.isNode){
		console.log("loadPano1")
		let options =  {panodata:pano,initMaps:initMaps, ScaleLine:false,ZoomSlider:zoomslider,fscreen:false,OverviewMap:overviewMap,enableRotation:false,isVR:false,showtoolbar:false,panoindex:1};
		window.thepano = new Pano(options);
		return;
	}

	
	
	let Path = pano.path;
	let panoname = pano.panoname;

	// window.consoleM(window.info(),'loadPano','Path',Path);
	// window.consoleM(window.info(),'loadPano','panoname',panoname);

	var thepanosettingsurl = Path + panoname+"/thepanosettings.js";
	
	// window.consoleM(window.info(),'loadPano','thepanosettingsurl',thepanosettingsurl);

    window.panoPath = Path;

    var script = document.createElement("script");
    script.type = "text/javascript";
    script.src = thepanosettingsurl;
    script.onload = function(){
		// window.consoleM(window.info(),window.name,'window.panodatas',window.panodatas);
		// window.consoleM(window.info(),window.name,'window.panodatas initMaps',initMaps);
        let options = {panoname:panoname,panoPath:Path,initMaps:initMaps, ScaleLine:false,ZoomSlider:false,fscreen:false,OverviewMap:false,enableRotation:false,isVR:true,showtoolbar:false};
		if (initMaps) {
			window.setScene('showpano');
			window.thepano = new Pano(options);
			window.consoleM(window.info(),'loadPano','Path',Path);
		//	window.thepano.omap.setTarget("htmlTarget");

			// if (window.thepano) {
			// 	window.thepano.updatePano(options);
			//   } else {
			// 	window.thepano = new Pano(options);
			//   }
		} else {
			window.panoLoaded(new Pano(options));
		}
		
    };
	document.body.appendChild(script);
	

}

window.downloadPano = function (pano,callback) {
	let Path = pano.path;
	let panoname = pano.panoname;

	window.consoleM(window.info(),'loadPano','Path',Path);
	window.consoleM(window.info(),'loadPano','panoname',panoname);

	var thepanosettingsurl = Path + panoname+"/thepanosettings.js";
	
	window.consoleM(window.info(),'loadPano','thepanosettingsurl',thepanosettingsurl);

	window.panoPath = Path;

	var script = document.createElement("script");
    script.type = "text/javascript";
    script.src = thepanosettingsurl;
    script.onload = function(){
		callback()
		
    };
	document.body.appendChild(script);
	

}


window.startScene = function(){
	if (!window.isVR || window.panosettings.autoOpenPano) {

		window.loadPano( { path: window.pathini, panoname :window.panonameini},true);

		// loadPano( { path: "http://www.preiss.fr/pano/", panoname :'napoleon'},true);
	//	loadPano( { path: "http://37.187.22.210/pano/", panoname :'eiffelx2'},true);
	//loadPano( { path: "http://37.187.22.210/pano/", panoname :'operagarnier'},true);
	//loadPano( { path: "http://37.187.22.210/pano/", panoname :'chapelles'},true);
		// loadPano( { path: "http://37.187.22.210/pano/", panoname :'rosenord'},true);
	//loadPano( { path: "../pano/", panoname :'operagarnier'},true);
	// loadPano( { path: "http://37.187.22.210/pano/", panoname :'newsaintechapelle'},true);
		//loadPano( { path: "http://37.187.22.210/pano/", panoname :'horlogeastronomique'},true);
		// loadPano( { path: "http://www.preiss.fr/pano/", panoname :'garnierfoyer'},true);
	}
	else {
		window.showHome();
	}

};

window.showHome = function(){
	window.setScene('home');
	if (!window.panostates.panothumbsloaded) window.loadAllPanos();
}

window.setScene = function(status){

	window.panostates.status = status;
	if (window.panosettings.hideui) window.showControllerUi(false);
	window.removeAllMapElements();

	switch (status){
		case 'showpano':
			window.AframeAction('showThePano', {action:"show"});
			window.AframeAction('window.showHome', {action:"hide"});
		break;
		case 'home':
			window.AframeAction('window.showHome', {action:"show"});
			window.AframeAction('showThePano', {action:"hide"});
		break;
	}

}

window.addFeature = function(enabled) {
	window.isaddingFeature = enabled;
	if(enabled) window.thepano.addInteraction();
	else window.thepano.removeInteraction();
}


window.showControllerUi = function(show){

	let controller = document.querySelector('#ui');

	if (!controller) return;

	controller.setAttribute('raycaster', 'showLine', !show);

	let ui = document.querySelector('#ui');

	ui.setAttribute('visible', show);

	

	window.panostates.controlleruishown = show;
	// this.getControlSettings().clickenabled = state;
}

window.toggleControllerUi = function(){
	window.showControllerUi(!window.panostates.controlleruishown)
}


window.loadAllPanos = function(){
	if (window.panostates.panoindex < window.loadpanodatalist.length) {
		let pano = window.loadpanodatalist[window.panostates.panoindex];
		window.panostates.panoindex++;
		window.loadPano(pano,false);
	} else {
		window.homeReady();
	}
}

function getLoadpanodata(panoname){
	let result;
	window.loadpanodatalist.forEach(element => {
		if (element.panoname === panoname) {
			result = element;
			
		}
	});
	return result;
}

window.homeReady = function(){
	window.panostates.panothumbsloaded = true;
	window.setAllUiItems();
}

window.showPanoThumbDetailed = function(panoname){

}

window.setAllUiItems = function(){
	
	window.panolist.forEach(pano => {
		window.UiItemslist = window.UiItemslist.concat(pano.ui.getAllIiItems());
	});
	window.consoleM(window.info(),window.name,'getAllUiItems','getAllUiItems');
}

window.getRandomUiItems = function(){
	return window.UiItemslist[Math.floor(Math.random()*window.UiItemslist.length)];
}

window.showRandomUiItems = function(){
	window.showUiItem(window.getRandomUiItems());
}

window.showUiItem = function(uiItem){

	let data = getLoadpanodata(uiItem.panoname);

	if (window.thepano && data.panoname === window.thepano.panoname){
		window.thepano.flyToUiItem(uiItem);
	} else {
		window.UiItemtobeShown = uiItem;	
		window.loadPano(data,true);
	}
	
	
}

window.onKey = function(keycode){
	let keytaken = false;
	console.log(keycode)
	switch (keycode){
		case 107:		// +
			keytaken = true;
			window.thepano.incZoom();
		break;
		case 109:		// -
			keytaken = true;
			window.thepano.decZoom();
		break;
		case 104:		// 8
			keytaken = true;
			window.thepano.moveMap({x : 0,y : 3660});
		break;
		case 98:		// 2
			keytaken = true;
			window.thepano.moveMap({x : 0,y : -30});
		break;
		case 100:		// 4
			keytaken = true;
			window.thepano.moveMap({x : -30,y : 0});
		break;
		case 102:		// 6
			keytaken = true;
			window.thepano.moveMap({x : 30,y : 0});
		break;
		case 85:		// u
			keytaken = true;
			// window.toggleControllerUi();
			window.consoleM(window.info(),window.name,'window.getThePanoElement',window.getElementPosition(window.getThePanoElement()));
		break;
		case 66:		// b
			keytaken = true;
			// window.addbookmark();
			window.AframeAction('showThePano', {action:"hide"});
		break;
		case 78:		// n
			keytaken = true;
			// window.showRandomUiItems();
			// window.thepano.ui.SaveNoThumb();
			// window.showPanoDetailed(window.panolist[0]);
			// window.panosettings.mapcontainersize = 2;
			window.setMapElementsSize(2);
			// window.startScene();
		break;
	}
	return keytaken;
}

window.addbookmark = function(){
	
	let pos = window.thepano.getCenter();
	let zoom = window.thepano.getZoom();
	let label = window.nowToHMS();
	
	window.consoleM(window.info(),window.name,'window.addbookmark',pos + " "+zoom + " "+label);

	let uiItemdata = {
		label: label,
		pos: pos,
		zoom: zoom,
		type: 'thumb',
		thumb: 'empty'
	}

	window.thepano.ui.addUiItem(uiItemdata,window.panostates.currentUIGroup);

}


window.panoLoaded = function(pano){
	window.panolist.push(pano);
	window.addPanoThumb(pano);
	window.loadAllPanos();
};

window.addPanoThumb = function(pano){

	let parent = window.getPanosContainer();
	
	let panoThumb = window.getPanoThumb(pano);	

	parent.appendChild(panoThumb);
	
}

window.showPanoDetailed = function(pano){
	let parent = window.getPanoDetailedContainer();
	
	let panoThumbDetailed = window.getPanoThumbDetailed(pano);	

	parent.appendChild(panoThumbDetailed);

	parent.setAttribute('visible', true);
}

window.hidePanoDetailed = function(pano){
	let parent = window.getPanoDetailedContainer();
	
	parent.setAttribute('visible', false);
}


window.getPanoThumb = function(pano){


	let width = pano.getThumbWidth();
	let height = pano.getThumbHeight();

	var elem = document.createElement('a-entity'); 
		elem.setAttribute('width', width);
		elem.setAttribute('height',height);  		
		elem.setAttribute('data-width', width);      
		elem.setAttribute('data-height', height);
		elem.setAttribute('data-textheight', window.panosettings.panothumbs.textheight);
		elem.setAttribute('data-maxwidth',pano.getThumbTextWidth());
		elem.setAttribute('data-thumbsrc', pano.getThumbPath());
		elem.setAttribute('data-label', pano.titre);
		elem.setAttribute('data-window.panoPath', pano.window.panoPath);
		elem.setAttribute('data-panoname', pano.panoname);
		elem.setAttribute('template', {src: '#panoThumbTemplate'}); 
		
		elem.setAttribute('panothumb',{panoPath : pano.window.panoPath, panoname: pano.panoname});

		elem.classList.add('panothumb');

	return elem;
}

window.getPanoThumbDetailed = function(pano){



	let width = window.panosettings.panothumbDetailed.width;
	let height = window.panosettings.panothumbDetailed.height;

	var elem = document.createElement('a-entity'); 
		elem.setAttribute('width', width);
		elem.setAttribute('height',height);  		
		elem.setAttribute('data-width', width);      
		elem.setAttribute('data-height', height);
		elem.setAttribute('data-thumbsheight', window.panosettings.panothumbDetailed.thumbsheight);
		elem.setAttribute('data-textheight', window.panosettings.panothumbDetailed.textheight);
		elem.setAttribute('data-maxwidth',window.panosettings.panothumbDetailed.maxtextwidth);
		elem.setAttribute('data-thumbsrc', pano.getThumbPath());
		elem.setAttribute('data-label', pano.titre);
		elem.setAttribute('data-window.panoPath', pano.panoPath);
		elem.setAttribute('data-panoname', pano.panoname);
		elem.setAttribute('template', {src: '#panoThumbDetailedTemplate'});  
		


	return elem;
}

window.addUiItemToTarget= function(uiItem,target){

	var elem = document.createElement('a-entity');   
	elem.setAttribute('data-uiitemid', uiItem.Id()); 
	elem.setAttribute('template', {src: '#uiButtonTemplate'});        
	target.appendChild(elem); 

}

window.getPanoThumbOld = function(pano){
	let container = window.window.getAGuiFlex(pano.getThumbWidth(),pano.getThumbHeight(),0.2,"column","flexStart","center");

	// var childGuiItem = container.getAttribute("gui-item");

	window.addAText(container,pano.titre,pano.getThumbWidth(),window.panosettings.panothumbs.textheight,pano.getThumbTextWidth(),'center',20);
	// window.addAText(container,pano.titre,pano.getThumbWidth(),window.panosettings.panothumbs.textheight,'center',20);
	window.window.addAGuiImg(container,pano.getThumbWidth(),pano.getThumbHeight(),pano.getThumbPath());

	container.classList.add('panothumb');

	container.setAttribute('panothumb',{panoPath : pano.panoPath, panoname: pano.panoname});

	container.setAttribute('look-at','src','#head');

	return container;
}

	   
window.getUiItemThumb = function(uiItem){

	let thumbw = window.panosettings.ui.thumb.off.width;
    let thumbh = window.panosettings.ui.thumb.off.height;
        let textwidth = thumbw * window.panosettings.ui.thumb.on.thumbimg / 100;
		let textheight = thumbw * window.panosettings.ui.thumb.on.thumblabel / 100;
		// let textwidth = thumbw * window.panosettings.ui.thumb.on.thumbimg / 100;
		// let textheight = thumbw * window.panosettings.ui.thumb.on.thumblabel / 100;

		var elem = document.createElement('a-entity'); 
		elem.setAttribute('width', thumbw);
		elem.setAttribute('height',thumbh);  	
		elem.setAttribute('data-width', thumbw);      
		elem.setAttribute('data-height', thumbw);
		elem.setAttribute('data-textheight', textheight);
		elem.setAttribute('data-thumbsrc', uiItem.thumb);
		elem.setAttribute('data-label', uiItem.label);
		elem.setAttribute('template', {src: '#uiItemThumbTemplate'});   


		
			
/*	let elem = window.window.getAGuiFlex(thumbw,thumbh,0.2,"column","flexStart","center");

	// var childGuiItem = container.getAttribute("gui-item");

	//window.addAText(elem,uiItem.label,width,window.panosettings.panothumbs.textheight,width,'center',20);
	// window.addAText(container,pano.titre,pano.getThumbWidth(),window.panosettings.panothumbs.textheight,'center',20);
	window.window.addAGuiImg(elem,width,width,uiItem.thumb);*/

	


	return elem;
}

window.getTempImg = function(uiItem){

	let thumbw = window.panosettings.ui.thumb.off.width;
    let thumbh = window.panosettings.ui.thumb.off.height;
        let imgwidth = thumbw * (100 - window.panosettings.ui.thumb.imgmargin) / 100;
		let imgheight = thumbh *(100 - window.panosettings.ui.thumb.imgmargin) / 100;
		// let textwidth = thumbw * window.panosettings.ui.thumb.on.thumbimg / 100;
		// let textheight = thumbw * window.panosettings.ui.thumb.on.thumblabel / 100;

		let z = thumbh / 100; 
		let id = uiItem.Id();

		var elem = document.createElement('a-entity'); 
		
		elem.setAttribute('data-uiitemid', id);
		elem.setAttribute('width', thumbw);
		elem.setAttribute('height',thumbh);  		
		elem.setAttribute('data-width', thumbw);      
		elem.setAttribute('data-height', thumbw);
		elem.setAttribute('data-imgwidth', imgwidth);      
		elem.setAttribute('data-imgheight', imgheight);
		elem.setAttribute('data-imgsrc', uiItem.thumb);
		elem.setAttribute('data-z', z);
		elem.setAttribute('data-imgz', 2*z);
		elem.setAttribute('template', {src: '#TempUiThumb'});   

	return elem;
}

window.getTempImg2 = function(uiItem){

	let thumbw = window.panosettings.ui.thumb.off.width;
    let thumbh = window.panosettings.ui.thumb.off.height;
        let imgwidth = thumbw * (100 - window.panosettings.ui.thumb.imgmargin) / 100;
		let imgheight = thumbh *(100 - window.panosettings.ui.thumb.imgmargin) / 100;
		// let textwidth = thumbw * window.panosettings.ui.thumb.on.thumbimg / 100;
		// let textheight = thumbw * window.panosettings.ui.thumb.on.thumblabel / 100;

		let z = thumbh / 100; 

		var elem = document.createElement('a-entity'); 
		elem.setAttribute('data-thumb', uiItem.thumb);
		elem.setAttribute('template', {src: '#link'});   

	return elem;

}

window.window.getAGuiFlex = function(width,height,padding,direction,justify,align){	
	var elem = document.createElement('a-gui-giga-flex-container');      
	elem.setAttribute('item-padding', padding);
	elem.setAttribute('flex-direction', direction);
	elem.setAttribute('justify-content',justify);
	elem.setAttribute('align-items',align);
	elem.setAttribute('width', width);
	elem.setAttribute('height',height);
	//elem.setAttribute('margin',{w: 0,x:0,y:0,z:0});
	//parent.appendChild(elem);
	return elem;
}


window.window.addAGuiImg = function(parent,width,height,src){	
	var elem = document.createElement('a-gui-img');      
	elem.setAttribute('src', src);
	elem.setAttribute('width', width);
	elem.setAttribute('height',height);
	parent.appendChild(elem);
	return elem;
}

window.addAText = function(parent,text,width,height,maxwidth,align,wrapCount){	
// window.addAText = function(parent,text,width,height,align,wrapCount){	

	var elem = document.createElement('a-gui-text');      
	elem.setAttribute('gui-text',{txt: text,maxwidth:maxwidth});
	// elem.setAttribute('gui-text','txt' ,text);
	elem.setAttribute('width', width);
	elem.setAttribute('height',height);
	parent.appendChild(elem);
	return elem;

	// var elem = document.createElement('a-gui-label');      
	// elem.setAttribute('text', text);
	// elem.setAttribute('width', width);
	// elem.setAttribute('height',height);
	// parent.appendChild(elem);
	// return elem;

	// var elem = document.createElement('a-entity');   
	// elem.setAttribute('text-geometry', {value: text, size:0.1});   
	// elem.setAttribute('material', {color: 'red'});   
	
	// parent.appendChild(elem);
	// return elem;

	// var elem = document.createElement('a-entity');   
	// elem.setAttribute('geometry', {primitive: 'plane', height:height,width:width});   
	// elem.setAttribute('width', width);
	// elem.setAttribute('height',height);
	// elem.setAttribute('material', {color: '#111', opacity: '0.2'});   
	// elem.setAttribute('text', {value:text, color: 'white',align:align,wrapCount:wrapCount});   
	// elem.setAttribute('margin',{w: 0,x:1,y:0,z:0});
	
	// parent.appendChild(elem);
	// return elem;

	
}


window.getAGuiItem = function(childElement,type){	

	return {"width":childElement.getAttribute("width"),"height":childElement.getAttribute("height"),"type":type,"fontSize":"100px","margin":{"x":0,"y":0,"z":0,"w":0}}
   /* var elem = document.createElement('gui-item');
    elem.setAttribute('width', childElement.getAttribute("width"));
    elem.setAttribute('height',childElement.getAttribute("height"));
    let margin = childElement.getAttribute("margin");
    if (!margin) margin = {w: 0,x:0,y:0,z:0};
    elem.setAttribute('margin', margin);
    return elem;*/
}


window.getPanosContainer = function(){
	return document.querySelector('#homecontainer');
}

window.getPanoDetailedContainer = function(){
	return document.querySelector('#homepanodetailed');
}

window.EmittoAframe = function(message,messagedata,bool,target=window.sceneEl){
	// var window.name = 'hide';
	// window.consoleM(window.info(),window.name,'window.EmittoAframe message',message);
	// window.consoleM(window.info(),window.name,'window.EmittoAframe messagedata',messagedata);
	if (target) target.emit(message, messagedata, bool);
}

window.AframeAction = function(action,data){
	var messagedata = {action:action,messagedata:data};
	window.EmittoAframe('olmessage', messagedata,false);
}

window.window.addMapElement = function(target,size,zpos){

	var elem = document.createElement('a-entity');  
	elem.setAttribute('template', {src: '#mapelement'});    
	elem.setAttribute('data-divtarget', target);
	elem.setAttribute('data-size', size);	
	elem.setAttribute('data-zpos', zpos);	
	document.querySelector('#htmlmaps').appendChild(elem);
	
	window.mapelements.push(elem);
}

window.removeAllMapElements = function(){
	var target = document.querySelector('#htmlmaps');
	if (target){
		while( target.hasChildNodes() ){
			target.removeChild(target.lastChild);
		}
	}
	
}

window.setMapElementsSize = function(size){
	window.panosettings.mapcontainersize = size;
	var target = document.querySelector('#htmlmaps');
	while( target.hasChildNodes() ){
		target.removeChild(target.lastChild);
	}
	for (var i = 1; i <= window.panosettings.nbmaps; i++) {
		let size = window.panosettings.mapcontainersize * Math.pow(2, i-1);
		let targetdivname = "target" + i;
		window.window.addMapElement('#' + targetdivname,size,-0.01*i);
	}
}

window.zoomout = function(){
	let size = window.panosettings.mapcontainersize + 1;
	if (size < 0) size = 1;
	window.setMapElementsSize(size)
}

window.zoomin = function(){
	let size = window.panosettings.mapcontainersize - 1;
	if (size < 0) size = 1;
	window.setMapElementsSize(size)
}

window.resetZoom = function(){
	window.setMapElementsSize(window.panosettings.mapcontainersizeini)
}

window.addCurvedMapElement = function(target,size,zpos,angle,rotation){

	var elem = document.createElement('a-entity');  
	elem.setAttribute('template', {src: '#mapelementcurved'});    
	elem.setAttribute('data-divtarget', target);
	elem.setAttribute('data-size', size);
	elem.setAttribute('data-angle', angle);	
	elem.setAttribute('data-rotation', rotation);		
	elem.setAttribute('data-zpos', zpos);	
	document.querySelector('#htmlmaps').appendChild(elem);
	window.mapelements.push(elem);
}


// initPanoNew = function(options){

	
// 	   var ImageProperties = readFromLocalstorage(options.panoname + "_ImageProperties");
	
// 	//    window.consoleM(window.info(),window.name,'ImageProperties',ImageProperties);
// 	   if (ImageProperties){
// 			new Pano(options,ImageProperties);
// 	   } 
// 	   else 
// 	   {
// 		var request = new XMLHttpRequest();
// 		// request.open('GET', 'http://www.preiss.fr/pano/'+options.panoname+'/Images/ImageProperties.xml', true);
// 		request.open('GET', window.panoPath +options.panoname+'/Images/ImageProperties.xml', true);
// 		request.send(null);
// 		request.onreadystatechange = function () {
// 			if (request.readyState === 4 && request.status === 200) {
// 				var type = request.getResponseHeader('Content-Type');
// 				if (type.indexOf("text") !== 1) {
// 					saveToLocalstorage(options.panoname + "_ImageProperties",request.responseText)				
// 				//	this.setPage(pano,request.responseText);	
// 					new Pano(options,request.responseText);				
// 					return request.responseText;
// 				}
// 			}
// 		}
// 	   }    
	   
// };







window.getval = function(pano,valname){	
   return window.myMap.get(valname + pano)
};

window.setval = function(pano,valname,value){
//    console.log("valname: "+ valname);
   window.myMap.set(valname + pano,value);
};



var selectedKernel = [
	0, -1, 0,
	-1, 5, -1,
	0, -1, 0
  ];

  /**
       * Apply a convolution kernel to canvas.  This works for any size kernel, but
       * performance starts degrading above 3 x 3.
       * @param {CanvasRenderingContext2D} context Canvas 2d context.
       * @param {Array<number>} kernel Kernel.
       */
      function convolve(context, kernel) {
        var canvas = context.canvas;
        var width = canvas.width;
        var height = canvas.height;

        var size = Math.sqrt(kernel.length);
        var half = Math.floor(size / 2);

        var inputData = context.getImageData(0, 0, width, height).data;

        var output = context.createImageData(width, height);
        var outputData = output.data;

        for (var pixelY = 0; pixelY < height; ++pixelY) {
          var pixelsAbove = pixelY * width;
          for (var pixelX = 0; pixelX < width; ++pixelX) {
            var r = 0, g = 0, b = 0, a = 0;
            for (var kernelY = 0; kernelY < size; ++kernelY) {
              for (var kernelX = 0; kernelX < size; ++kernelX) {
                var weight = kernel[kernelY * size + kernelX];
                var neighborY = Math.min(
                  height - 1, Math.max(0, pixelY + kernelY - half));
                var neighborX = Math.min(
                  width - 1, Math.max(0, pixelX + kernelX - half));
                var inputIndex = (neighborY * width + neighborX) * 4;
                r += inputData[inputIndex] * weight;
                g += inputData[inputIndex + 1] * weight;
                b += inputData[inputIndex + 2] * weight;
                a += inputData[inputIndex + 3] * weight;
              }
            }
            var outputIndex = (pixelsAbove + pixelX) * 4;
            outputData[outputIndex] = r;
            outputData[outputIndex + 1] = g;
            outputData[outputIndex + 2] = b;
            outputData[outputIndex + 3] = kernel.normalized ? a : 255;
          }
        }
        context.putImageData(output, 0, 0);
      };




//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

window.consoleD = function(texte,object){
   var output = '';
   for (var property in object) {
	 output += property + ': ' + object[property]+'; ';
   };
   
   if (window.isVR && window.panosettings.showAframeLog) 	window.AFRAME.log(texte + " " + output);
   console.log(texte +" ::: "+ output);
}

// = function(texte,object){
//    if (object){
// 	   if (showAframeLog) window.AFRAME.log(window.nowToHMS() + texte + " " + object);
// 	   else console.log(window.nowToHMS() +texte +" ::: "+ object);
//    } else {
// 	   if (showAframeLog) window.AFRAME.log(window.nowToHMS() +texte.const + " " + object);
// 	   else if (texte.prototype) console.log(window.nowToHMS() + texte.prototype._fullname +" ::: "+ texte);
//    }  
   
// }

window.consoleM = function(pos,name,texte,object){
	// var thisname = thisn.constructor.window.name;
	
	// console.log('posss',thisLine());
	// console.trace();
	// console.log(thisn.className);

	if (name == 'hide') return;

   if (object != null){
	   if (window.isVR && window.panosettings.showAframeLog) {
		if (window.panosettings.aframedetailed) window.AFRAME.log(window.nowToHMS() + " " + name + " "+ pos + " " + texte + " " + object);
		else window.AFRAME.log(texte + " " + object);
		}
		   
	//    console.log(window.nowToHMS() + " " + name + " "+ pos + " " +texte +" ::: ",object);
   } else {
	   if (window.isVR && window.panosettings.showAframeLog) window.AFRAME.log(window.nowToHMS() + " " + name + " "+ pos + " " +texte.const + " " + object);
	//    else if (texte.prototype) console.log(window.nowToHMS() + " "+ pos + " " + texte.prototype._fullname +" ::: "+ texte);
	//    console.log(window.nowToHMS() + " " + name + " "+ pos + " " +texte +" ::: ",object);
   }  
   
}

window.info = function() {

	try {
		const e = new Error();
	const regex = /\((.*):(\d+):(\d+)\)window.$/
	const match = regex.exec(e.stack.split("\n")[2]);

	let name = match[1];
	let namePosition = name.lastIndexOf('/') + 1;
	if (namePosition != 0) name = name.substr(namePosition);
	name = name.replace('.js','');
		
	// return
	return name + " " +  match[2];
	
	  } catch (e) {
		return "";
	  }
	
  };

function ln() {
	var e = new Error();
	if (!e.stack) try {
	  // IE requires the Error to actually be throw or else the Error's 'stack'
	  // property is undefined.
	  throw e;
	} catch (e) {
	  if (!e.stack) {
		return 0; // IE < 10, likely
	  }
	}
	var stack = e.stack.toString().split(/\r\n|\n/);
	// We want our caller's frame. It's index into |stack| depends on the
	// browser and browser version, so we need to search for the second frame:
	var frameRE = /:(\d+):(?:\d+)[^\d]*window.$/;
	do {
	  var frame = stack.shift();
	} while (!frameRE.exec(frame) && stack.length);
	return frameRE.exec(stack.shift())[1];
  }

function getFirstWord(str) {
	let spacePosition = str.indexOf(' ');
	if (spacePosition === -1)
		return str;
	else
		return str.substr(0, spacePosition);
};

window.nowToHMS = function() {
	return window.msToHMS(Date.now());
}

window.msToHMS = function( ms ) {
    // 1- Convert to seconds:
    var seconds = ms / 1000;
    // 2- Extract hours:
    var hours = parseInt( seconds / 3600 ); // 3,600 seconds in 1 hour
    seconds = seconds % 3600; // seconds remaining after extracting hours
    // 3- Extract minutes:
    var minutes = parseInt( seconds / 60 ); // 60 seconds in 1 minute
    // 4- Keep only seconds not extracted to minutes:
	seconds = seconds % 60;
	return( minutes+":"+seconds.toFixed(2)+" ");
    // return( hours+":"+minutes+":"+seconds+" ");
}



////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////




//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


Pano.uiitem = {};

Pano.uiitem.create = function (tagName, options) {
}

