import React, { Component } from 'react';

import SplatComponent from './splatcomponent';
// import SplatComp from './SplatComp';

import Panotop from './PanotopComp';

import nodeUtils from '../js/nodeUtils.js'



class MySplatComp extends Component {
  constructor(props) {
    super(props);



  window.splat = "https://data2.gigascope.net/splats/" +this.props.match.params.splat + ".splat";

  console.log("thesplat");
  console.log(window.splat)

    //  this.state = {
    //    url:this.url,
    //    title:this.title,
    //    details:this.details
    //  }
      
    // }; 

  }
  
  componentDidMount() {
    
   
  }

  componentWillUpdate(nextProps, nextState) {
    

      this.setState(
        this.state
      )
    
 
  }

  button1(){
    console.log("bouton1")

    // console.log("trad",)

    // console.log("trad",nodeUtils.translateText(this.state.texttosend,'en'))

    // nodeUtils.translateText(this.state.texttosend,'en').then((value) => console.log("trad",value));
    
    // sayText('...Bonjour...',1,4,2);	
    // nodeUtils.sendMessager("eb419390-c795-11eb-8568-075bdb4298e2",{"type":"message","content":"hello there"});

    nodeUtils.sendMessager("eb419390-c795-11eb-8568-075bdb4298e2",{"type":"avatarsay","content":this.state.texttosend});
    // nodeUtils.sendMessager("eb419390-c795-11eb-8568-075bdb4298e2",{"type":"hideavatar"});
  }

  button2(){
    console.log("bouton1")
    
    // sayText('...Bonjour...',1,4,2);	
    // nodeUtils.sendMessager("eb419390-c795-11eb-8568-075bdb4298e2",{"type":"message","content":"hello there"});

    nodeUtils.sendMessager("eb419390-c795-11eb-8568-075bdb4298e2",{"type":"showavatar"});
  }

  handleSubmit(event) {
    // alert('Le nom a été soumis : ' + this.state.value);
    console.log("event.target.value",event.target.value)
   // nodeUtils.sendMessager("eb419390-c795-11eb-8568-075bdb4298e2",{"type":"message","content":event.target.value});
  }

  handleChange(event) {
    // console.log("tt" )
    // console.log(event.target.value )
    console.log('this.state.texttosend',this.state.texttosend)
    if (event.target.value != this.state.texttosend) {
      // console.log("hh")
      // this.setState({texttosend: event.target.value});
      this.state.texttosend = event.target.value
    }  
  } 
  render() {

    // const { url,title,details } = this.state;

   
    return (
      <div className="arksan blackBackground" >

        <SplatComponent/>
        {/* <SplatComp/> */}

        <div className="d-flex infront logotop notclickeable" >
            <img className="logotopright clickeable infront" src={'https://data2.gigascope.net/data/dtwin_logo_512_white.png'} alt="Logo" />   
            {/* <div className="arksantitre notclickeable">
              <div>{title}</div>
              <div className="arksansoustitre">{details}</div>
              
            </div> */}
        </div>
      </div>
      
    );
  }
}

export default MySplatComp;
