import React, { Component } from 'react';
import Container from 'react-bootstrap/Container'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import parse from 'html-react-parser';
import Player from './PlayerComp';

import PinchZoomPan from "react-responsive-pinch-zoom-pan";

import nodeUtils from '../js/nodeUtils.js'



class PhotoComp extends Component {
  constructor(props) {
    super(props);
        
     this.state = {
       shown:true,
       audioevent:props.audioevent,
      //  lightboxtitre: props.lightboxtitre,
      //  lightboxurl: props.lightboxurl
    }; 
  }
  
  componentDidMount() {
  // if (nodeUtils.ReadString("InfoBig") == "1") this.toggleBig();

  // nodeUtils.setOpacity('lightbox',false)
  }

  componentWillUpdate(nextProps) {
     if (nextProps.audioevent != this.state.audioevent ){      
    
      this.state.audioevent =nextProps.audioevent;
      // this.state.location =nextProps.location;
      // this.state.lightboxtitre =nextProps.lightboxtitre;
      this.state.isplaying = global.isplaying;
      this.setState(
        this.state
      )
     

    }
   /*  if (nextProps.bookmark) nodeUtils.showBookmarkInfo();  */

  }

  componentDidUpdate(){
        
  }

  getHtml(text) {
    return {__html: text};
  }

  close(){
    nodeUtils.setOpacity('lightbox',false)
    global.lightboxisshown = false;
  }

  play(){
    global.myEmitter.emit('PlayAudio', {})
    this.state.isplaying = true;
      this.setState(
        this.state
      )
  }
  pause(){
    global.myEmitter.emit('PauseAudio', {})
    this.state.isplaying = false;
      this.setState(
        this.state
      )
  }


  render() {

    const {audioevent,isplaying} = this.state;
    console.log("audioevent.url",audioevent.url)

    return (
      
      
      // (lightboxurl != "") &&

      <div className="infront full d-flex flex-column justify-content-center align-items-center ">
        <div className="d-flex justify-content-between titrelightbox">

        < div className="d-flex ">
          <div >
          {!isplaying && (<FontAwesomeIcon className="closebicon" icon="play"  onClick={() => this.play()}/>)}
          {isplaying && (<FontAwesomeIcon className="closebicon" icon="pause"  onClick={() => this.pause()}/>)}</div>
          </div>

          <div >{audioevent.titre}</div>

          <div>
          <div className="d-flex">
          <FontAwesomeIcon className="nextbicon" icon="times"  onClick={() => this.close()}/>
          </div>
            <div ></div>
          </div>
          
        </div>
        
        <div className="pinchzoom d-flex align-items-center" >
        <PinchZoomPan  position='center' zoomButtons={false}>
                <img alt='Test Image' src={audioevent.url} />
            </PinchZoomPan>
        </div>
              
            
      </div>
      
    );
  }
}

export default PhotoComp;
