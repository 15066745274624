import React, { Component } from 'react';
import { Map, GoogleApiWrapper } from 'google-maps-react';



class MapContainer extends Component {

constructor(props) {
  super(props);

  this.state = {
    bookmark: this.props.bookmark
  }



}

  render() {

    const mapStyles = {
      width: this.props.width,
      height:  this.props.height
    };

    const {bookmark} = this.state;

    const coord = bookmark.getCoordObj();

    console.log('this.props.dimensions',this.props.dimensions)
    

    return (
      <Map
        google={this.props.google}
        zoom={bookmark.getCoordZoom()}
        style={mapStyles}
        initialCenter={coord}
      />
    );
  }


}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyCBjvHlhJmL4_vkQTL9yIb9sZEnZydUGbQ'
})(MapContainer);