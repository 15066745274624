import { Ui } from './ui.js';
//import Zoomify from "../ol/source/Zoomify";
import Zoomify from "ol/source/Zoomify";
import View from "ol/View";
import OlLayer from "ol/Map";
import OlMap from "ol/Map";

import {
	DragRotateAndZoom,
	defaults as defaultInteractions,
  } from 'ol/interaction';

import { altKeyOnly } from "ol/events/condition"

// import OlMap from "../ol/Map";

// import SynchronizeCustomZoom from "./myol-ext"
import Synchronize from "./myolext/interaction/Synchronize.js"
// import Button from "./myol-ext"
// import ol_control_Button from "./myolext/control/Button.js"
// import ol_control_Button from "./myolext/dist/ol-ext.js"

// import Button from "./myolext/dist/ol-ext.js"
import Button from "./myolext/control/Button"
import Notification from "./myolext/control/Notification";

//import Button from "ol/control/Button";
import ScaleLine from "ol/control/ScaleLine";
import ZoomSlider from "ol/control/ZoomSlider";
import FullScreen from "ol/control/FullScreen";


import Polygon from 'ol/geom/Polygon';
import Draw, {createRegularPolygon, createBox} from 'ol/interaction/Draw';
import {Vector as VectorSource} from 'ol/source';
import {Vector as VectorLayer} from 'ol/layer';

import {toJpeg} from 'html-to-image';

import {getBottomLeft, getTopRight} from 'ol/extent';
import {toLonLat} from 'ol/proj';

//import Bar from "./ol-ext";
//import Toggle from "ol/control/Toggle";
import OverviewMap from "ol/control/OverviewMap";

import OlLayerTile from "ol/layer/Tile";
import { Position } from './Position.js';

const events = require('events');
const myEmitter = new events.EventEmitter();

var getCurvePoints = require("cardinal-spline-js").getCurvePoints;

//import * from "./utils.js"
//require "./utils.js";

export function Pano(options){
	var self = this;

	this.setPano(options);
	
   /*if (options.isVR){
	   window.thepano= this;
   //	this.setupGamepad();
   }*/
   
};

Pano.prototype.setToolbar= function(){

	// var panoname = document.getElementById(this.panoname+"1");
	// var panoname = document.getElementById('htmlContainer');
	// var element = document.getElementsByClassName('ol-overlaycontainer-stopevent')[0];

	// panoname.appendChild(element);

	// _this.overlayContainerStopEvent_ = document.createElement('div');
	// element.style.position = 'relative';
	// element.style.right = '0px';
	// element.style.left = '0px';
	// element.style.zIndex = '0';
	// element.style.width = '100%';
	// element.style.height = '100%';

	var hello = new Button (
		{	html: '<i class="fa fa-bookmark"></i>',
			className: "hello",
			title: "Toggle Bookmarks",
			handleClick: function()
				{	
				global.myEmitter.emit('boutton', "togglebookmarks");
				}
		});

		
	// console.log(hello)
	this.omap.addControl(hello);

	var fscreen = new FullScreen()
	this.omap.addControl(fscreen);
}

Pano.prototype.setPano = function(options){


    if (window.isNode){
		//let pano = options.pano;
		
        this.panodata = options.panodata.panodata;
     /*   this.panoPath = options.panodata.panoPath;
        this.panodata.theme = options.panodata.theme*/
		this.panoname = this.panodata.panoname;
		this.id = this.panodata.id;
        this.panoPath = this.panodata.url;
        this.panodata.uiGroups = [];
		this.panodata.popups = [];
			
		this.panoindex = options.panoindex;
		this.positionini = options.positionini;
    } else {
        this.panoname = options.panoname;
	this.panoPath = options.panoPath;

	

    
    this.setPanodata();
    
    }

    this.imgWidth = this.panodata.width;
	this.imgHeight = this.panodata.height;
	this.tilesize = this.panodata.tilesize;
    this.theme = this.panodata.theme;
	this.titre = this.panodata.titre;
	this.info = this.panodata.info;

	this.minzoom = 2;
	this.enableRotation = false;

	if (this.panodata.data){
		if (this.panodata.data.minzoom) this.minzoom = this.panodata.data.minzoom;
		if (this.panodata.data.enableRotation) this.enableRotation = this.panodata.data.enableRotation;
	}

	

	

//	window.consoleM(info(),window.name,'setPano panoname',options.panoname);
//	window.consoleM(info(),window.name,'setPano panoname',options.window.panoPath);

	this.ScaleLine = options.ScaleLine;
	this.ZoomSlider = options.ZoomSlider;
	this.fscreen = options.fscreen;
	this.OverviewMap = options.OverviewMap;
	this.OverviewMap = false;
	

	this.maps = [];
	this.layers = [];
	this.mapstolink = [];

	
	
	this.tilePixelRatio = 1;
	if (this.tilesize == 512) this.tilePixelRatio = 2;
	

	if (this.panodata.zoomini) this.zoomini = this.panodata.zoomini;
	else this.zoomnin = window.panosettings.zoominidefault;

	this.tourIndex = -1;		

	// window.consoleM(info(),window.name,'setPano initMaps',options.initMaps);

	this.ui = new Ui(this,this.panodata);

	if (options.initMaps) this.initMaps();

//	window.getPanoramasRequest();
	
};

Pano.prototype.sync = function(){
	// this.mapstolink = [];

		if (this.panoindex == 1){
			// this.mapstolink.push({map: window.thepano2.omap,layer: this.layer, zoomfactor: 0,minZoom:0})
			
			this.omap.addInteraction( new Synchronize({ map: this.omap,maps: [window.thepano2.omap]}) );
			console.log("11111111111")
		} else {

			// this.mapstolink.push({map: window.thepano.omap,layer: this.layer, zoomfactor: 0,minZoom:0})
			
			this.omap.addInteraction( new Synchronize({ map: this.omap,maps: [window.thepano.omap]}) );
			console.log("222222222222")
		}

		// this.omap.addInteraction( new SynchronizeCustomZoom({ mapmaster: this.omap,maps: this.mapstolink }) );
}

Pano.prototype.setMaps = function(){

    this.setPopupsSize();
      
	  this.zoomifyUrl = this.panoPath +this.panoname + '/Images/';
	  
	  console.log("zoomifyUrl",this.zoomifyUrl)
 
      this.source = new Zoomify({
        url: this.zoomifyUrl,
        size: [this.imgWidth, this.imgHeight],
     //   crossOrigin: 'anonymous',
     //   tileSize: this.witilesize,
        tileSize: this.tilesize,
        tilePixelRatio:this.tilePixelRatio
     //   tilePixelRatio: 4
      });
  
     this.layer = new OlLayer({
        renderMode: 'image',
		
     //    preload: 2,
		source: this.source,
		tileOptions: {crossOriginKeyword: 'anonymous'},
      });
 
      let zoomini2 = 2;	
    
    try{ 
        zoomini2 = window.getval(this.pano,'zoomini');;
    } catch(e) {}	
	  
	
	// if (this.panoindex == 1) {
	// 	window.theview= new View({
	// 		zoom:zoomini2,
	// 		// zoom:8,
	// 		resolutions: this.source.getTileGrid().getResolutions(),
	// 		extent: extent,
	// 		enableRotation: this.enableRotation
	// 	});
	// } else {
	// 	window.theview= window.thepano.theview;
	// }



	this.theview= new View({
		// interactions: dragDragRotateInteraction,
		zoom:this.minzoom,
		minZoom: this.minzoom,
		
		// zoom:8,
		resolutions: this.source.getTileGrid().getResolutions(),
		extent: extent,
		enableRotation: this.enableRotation
	});
     
 
    var extent = [0, -this.me.imgHeight, this.me.imgWidth, 0];
    
    this.vectorsource = new VectorSource({wrapX: false});

	var vector = new VectorLayer({
		source: this.vectorsource
	});
 
 //    window.consoleM(info(),window.name,'getResolutions',layer.getSource().getTileGrid().getResolutions());
        
    
 
    window.loadstart = Date.now();
 
    if (window.panosettings.hasscreenshots){
 
     this.views = new View({
         zoom:zoomini2,
         // zoom:8,
       //  resolutions: this.layer.getSource().getTileGrid().getResolutions(),
         resolutions: this.source.getTileGrid().getResolutions(),
         extent: extent,
         enableRotation: this.enableRotation
     });
 
     if (!window.isAndroid){
         window.emptyDiv('targetscreenshot');
         window.addScreenShotDiv('targetscreenshot',this.panoname + 's');
 
         // document.getElementById('targetscreenshot').style.height= window.panosettings.ui.thumbsize;
         // document.getElementById('targetscreenshot').style.width= window.panosettings.ui.thumbsize;
 
         this.omaps = new Map({
			// interactions: defaultInteractions().extend([new DragRotateAndZoom()]),
             layers: [this.layer],
             target: this.panoname+'s',
             view: this.views,
         //	logo: window.logoElement,
             controls: []
         });
		 this.omaps.getView().fit(extent);
		 
		 console.log("Extent::::",extent);
 
         this.omaps.on('postcompose', function(event) {
 
             window.thepano.thumbCanvas = event.context.canvas;
 
         });
 
         this.omaps.on('rendercomplete', function() {
 
             if (window.thepano.tempuiItem){
                 switch(window.panostates.thumbstep){
                     case 0:
                         window.panostates.thumbstep++;
                         window.thepano.getNextUiThumb();
                     break;
                     case 1:
                         window.panostates.thumbstep++;
                         window.thepano.getNextUiThumb();
                     break;
                 }	
             }
 
 
             
             // window.consoleM(info(),window.name,'rendercomplete','omaps rendercomplete');
 
             // if (window.panostates.takingscreenshot){
             // 	window.panostates.takingscreenshot = false;
 
             // }
                 
         });
     }
 
     
       
     //   this.ui
      
     //  window.thepano.takeScreenShot(1);
 }
 
 
    let index = 1;
   if (window.isVR) window.emptyDiv('htmlTarget');
    
 //    document.getElementById('htmlTarget').style.height= window.panosettings.mapheight;
 
 //    addDivForMap(1);
 
 // <!-- ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// -->
 
 //var i = 1;
     for (var i = 1; i <= window.panosettings.nbmaps; i++) {
 
     /*	let layer = new OlLayer({
         //	renderMode: 'image',
          //    preload: 2,
             source: this.source
         });*/
 
         
 
         let targetdivname = "target" + i;
 
         // var htmlTarget = document.getElementById(targetdivname);
         // window.consoleM(info(),window.name,'htmlTarget',value);
 
         
 
       //  window.addDiv('htmlTarget',targetdivname);
       //  window.addDiv(targetdivname,this.panoname+i);
 
         let angleini = 12;
 
         
         // let size = window.panosettings.mapcontainersize;
         // let size = Math.pow(window.panosettings.mapcontainersize, i+1);
 
         let size = window.panosettings.mapcontainersize * Math.pow(2, i-1);
 
         let angle = size * -angleini / 4; 
         let rotation = 180 - angle /2;
 
         // if (i == 1) size = window.panosettings.mapcontainersize * 3
         // if (i == 1) size = size
         // if (i == 2) size = size * 2
         // if (i == 3) size = size * 4
 
         // window.addCurvedMapElement('#' + targetdivname,size,-0.01*i,angle,rotation);
         if (window.isVR) window.addMapElement('#' + targetdivname,size,-0.01*i);
   
     /*    this.olmap = new OlMap({
             target: null,
             layers: [
               new OlLayer({
                 source: this.source
               })
             ],
             view: theview
           });*/
 
		let target = 'htmlTarget';

		if (this.panoindex == 2) target = 'htmlTarget2';
		if (this.panoindex == -1) target = 'htmlTargetThumb';
 
         let mapa = new OlMap({
             renderer: 'canvas',
          //   layers: this.layer,
          layers: [
            /*   new OlLayerTile({
                 source: new OlSourceOSM()
               })*/
               new OlLayerTile({
                   source: new Zoomify({
       /*/                url: 'http://37.187.22.210/pano/noisiel/Images/',
                       size: [121959, 144000],
                       crossOrigin: 'anonymous'*/
                       url: this.zoomifyUrl,
                       size: [this.imgWidth, this.imgHeight],
                       crossOrigin: 'anonymous',
                    //   tileSize: this.witilesize,
                       tileSize: this.tilesize,
                       tilePixelRatio:this.tilePixelRatio
                     })
				 }),
				 new VectorLayer({
					source: this.vectorsource
				})
				 
               
			 ],
			 target: target,
		//	 target: 'target1',
         //    target: this.panoname+i,
         //    target: 'arus1',
         //	target: null,
             view: this.theview,
             logo: window.logoElement,
             controls: []
         });
         
     //    mapa.setTarget(this.panoname+i)
 
		 mapa.getView().fit(extent);
		 
		 if (this.positionini) mapa.getView().setZoom(this.positionini.zoom);
 
         if (i ===1) {
             this.omap = mapa;	
             this.view = this.theview;		
         } else {
             this.mapstolink.push({map: mapa,layer: this.layer, zoomfactor: i-1,minZoom:0})
         }
         this.maps.push(mapa);
         
         
       }
       
       if (window.panosettings.nbmaps > 1){
     //	this.omap.addInteraction( new SynchronizeCustomZoom({ mapmaster: this.omap,maps: this.mapstolink }) );
	   }
	   
	//    if (this.panoindex == 2){
	// 		this.omap.addInteraction( new Synchronize({ maps: [window.thepano.omap]}) );
	// 	}
 
	   this.setZoomInit();
	   
	   this.initFeatures();
       
    
 
 
     // <!-- ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// -->
 
 
 
     
     // <!-- ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// -->
 
 
  /*  	if (window.panosettings.nbmaps > 1){
 
         this.layer2 = new ol.layer.Tile({
             renderMode: 'image',
          //    preload: 2,
             source: this.source,
           });
 
         this.view2 = new ol.View({
             zoom:zoomini2,
             // zoom:8,
             resolutions: this.layer2.getSource().getTileGrid().getResolutions(),
             extent: extent,
             enableRotation: this.enableRotation
         });
 
         document.getElementById('target2').style.height= window.panosettings.mapheight;
         document.getElementById('target2').style.width= window.panosettings.mapwidth;
 
         window.emptyDiv('target2');
         window.addDiv('target2',this.panoname + '2');
 
         this.omap2 = new ol.Map({
             layers: [this.layer],
             target: this.panoname+'2',
             view: this.view2,
             logo: window.logoElement
          });
          this.omap2.getView().fit(extent);
 
         var maps = [{map: this.omap2, zoomfactor: 1}];	
        }
 
     //    <!-- ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// -->
 
    if (window.panosettings.nbmaps > 2){
     this.layer3 = new ol.layer.Tile({
         renderMode: 'image',
      //    preload: 2,
         source: this.source,
       });
 
     this.view3 = new ol.View({
         zoom:zoomini2,
         // zoom:8,
         resolutions: this.layer3.getSource().getTileGrid().getResolutions(),
         extent: extent,
         enableRotation: this.enableRotation
     });
 
     document.getElementById('target3').style.height= window.panosettings.mapheight;
     document.getElementById('target3').style.width= window.panosettings.mapwidth;
 
     window.emptyDiv('target3');
     window.addDiv('target3',this.panoname + '3');
 
     this.omap3 = new ol.Map({
         layers: [this.layer],
         target: this.panoname+'3',
         view: this.view3,
         logo: window.logoElement
     });
     this.omap3.getView().fit(extent);
 
     maps = [{map: this.omap2, layer: this.layer2,zoomfactor: 1, minZoom:2},{map: this.omap3, layer: this.layer3,zoomfactor: 2, minZoom:0}];
    }
 
    if (maps) this.omap.addInteraction( new ol.interaction.SynchronizeCustomZoom({ mapmaster: this.omap,maps: maps }) );*/
	
	//    this.omap.getView().setZoom(7);
 //    window.thepano.flyToIndex(4);
 
    //    this.omap2.getView().setZoom(5);
 //    this.omap3.getView().setZoom(4);
 
 	this.notification = new Notification({
	});
	this.omap.addControl(this.notification);
 
    var exportOptions = {
		filter: function(element) {
			if(!element) return false;
			if(!element.className) return false;
		  return element.className.indexOf('ol-control') === -1;
		}
	  };

	  let that = this;
 
    this.omap.on('rendercomplete', function() {
 
		/* window.consoleM('rendercomplete !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!') */
		//  window.thepano.PostRendered();
		 that.PostRendered();
	// console.log('rendercomplete')

		 
 
   });

   this.omap.on('postcompose', function(event) {
	/* window.consoleM('postcompose !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!') */

	// switch(this.panoindex){
	// 	case 1:
	// 		window.thepano.PanoCanvas = event.context.canvas;
	// 		break;
	// 	case -1:
	// 		window.thepano.PanoCanvas = event.context.canvas;
	// 		break;
	// }

		that.PanoCanvas = event.context.canvas;

	//  console.log('postcompose')
	 
	//  var map = window.thepano.omap;
	// 	var extent = map.getView().calculateExtent(map.getSize());
	// 	window.consoleM('moveend !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!')
	// 	var bottomLeft = getBottomLeft(extent);
	// 	// var bottomLeft = toLonLat(getBottomLeft(extent));
	// 	var topRight = toLonLat(getTopRight(extent));
	// 	// window.consoleM('bottomleft',bottomLeft);
	// 	// global.myEmitter.emit('ping', 'My first Node.js event has been triggered.');
	// 	window.consoleM('getCenter',map.getView().getCenter());
	// 	global.myEmitter.emit('browsed', {extent: extent,zoom: map.getView().getZoom()});

	});

	this.omap.on('moveend', onMoveEnd);
	function onMoveEnd(evt) {
		// console.log('moveendmoveendmoveend0000',Date.now()-global.lastmoveend)
		var map = evt.map;
		// console.log('moveendmoveendmoveend0000',map.getView().getCenter())
		global.lastmoveend = Date.now();

		if (window.thepano.doStartAutoMove){
			
			window.thepano.startAutoMove();
		} else {
			
		var extent = map.getView().calculateExtent(map.getSize());
		// window.consoleM('moveend !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!')
		var bottomLeft = getBottomLeft(extent);
		// var bottomLeft = toLonLat(getBottomLeft(extent));
		var topRight = toLonLat(getTopRight(extent));
		// window.consoleM('bottomleft',bottomLeft);
		// global.myEmitter.emit('ping', 'My first Node.js event has been triggered.');
		window.consoleM('getCenter',map.getView().getCenter());
	
		global.myEmitter.emit('browsed', {extent: extent,zoom: map.getView().getZoom(),center: map.getView().getCenter()});
		}
		
	}

	this.omap.once('moveend', function(event) {
		//   console.log('moveendmoveendmoveend')
		window.consoleM('moveend !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! moveend')
		
	});
	
	this.omap.once('postcompose', function(event) {
		//window.thepano.PanoCanvas = event.context.canvas;
	});


	this.omap.once('rendercomplete', function() {
		exportOptions.width = 800;
		exportOptions.height = 800;
		toJpeg(window.thepano.omap.getViewport(), exportOptions).then(function(dataUrl) {
			var test = dataUrl
		/*  var pdf = new jsPDF('landscape', undefined, format);
		  pdf.addImage(dataUrl, 'JPEG', 0, 0, dim[0], dim[1]);
		  pdf.save('map.pdf');
		  // Reset original map size
		  map.setSize(size);
		  map.getView().setResolution(viewResolution);
		  exportButton.disabled = false;
		  document.body.style.cursor = 'auto';*/
		});
	  });
 
 //    window.thepano.setZoom(zoomini2);
 
      
    // if (!window.sceneEl & !window.isAndroid){
        if (this.ScaleLine){
         var scale = new ScaleLine();
         this.omap.addControl(scale);
         }
         if (this.ZoomSlider){
             // zoom slider
             var zslider = new ZoomSlider();
             this.omap.addControl(zslider);
         }
         
         if (this.fscreen){
            //  var fscreen = new FullScreen({	label: window.iconFullScreen,source: 'fullscreen'})
            //  this.omap.addControl(fscreen);
             
         }
         
         if (this.OverviewMap){
             this.omap.addControl(new OverviewMap({collapsed : true}));
         }
         
         try{ 
            //  this.omap.addControl(window.bm);
         } catch(e) {}
 
         //this.popup = new Popup();
        //  this.omap.addOverlay(this.popup);
         
        //  this.setControlBar();
    // }
      
     
        
    
    
  
    this.setPopups();	
 
	this.mapIsLoaded();
	
	// this.setToolbar();
 
 //    this.UpdateItems();
 
     
 
    
    
 //	this.ompap.onclick = 
 
 //	var data = {popup:this.popup, sport: "hockey"};
 
    // this.omap.on('singleclick',{ me: this.me}, function(evt) {
 
    // 	window.consoleM(info(),window.name,data.me.pano);
 
    // });
 
    this.omap.on('click', function(evt) {

		// console.log('evt.coordinate',evt.coordinate)

		window.thepano.processClick(window.thepano.parent,evt)

		if (!window.isaddingFeature){
			var map = window.thepano.omap;
			// window.consoleD('evt',evt);
			var coordinates = evt.coordinate;
			// console.window.info(evt.pixel);
			// console.window.info(evt.coordinate);
			window.consoleM(info(),window.name,'evt.pixel',evt.pixel);
			window.consoleM(info(),window.name,'evt.coordinate',evt.coordinate);
	 
			var coordinate =  map.getCoordinateFromPixel(evt.pixel);
	 
			window.consoleM(info(),window.name,'coordinate', coordinate);
	 
			// console.log(window.thepano.imgWidth)
			// console.log(window.thepano.imgHeight)
	 
			// var pixel1 = map.getPixelFromCoordinate(evt.coordinate);
			// window.consoleM(info(),window.name,'pixel1',pixel1);
			
			// console.window.info(map.getPixelFromCoordinate(evt.coordinate));
			// console.window.info(ol.proj.toLonLat(evt.coordinate));
			// var coords = ol.proj.toLonLat(evt.coordinate);
			// var lat = coords[1];
			// var lon = coords[0];
			// var locTxt = "Latitude: " + lat + " Longitude: " + lon;
			// window.consoleM(info(),window.name,'locTxt',locTxt);
	 
			// // var lonlat = map.getLonLatFromPixel( evt.pixel ).transform(proj.google, proj.latlng);
	 
	 
	 
			// var test2 = document.getElementById('fullscreen').clientHeight;
			// window.consoleM(info(),window.name,'clientHeight',test2);
	 
			// test2 = document.getElementById('fullscreen').clientWidth;
			// window.consoleM(info(),window.name,'clientWidth',test2);
	 
	 // 		evt.pixel ::: 1086,1269.4667472839355
	 // gigapixel_test.js:695 evt.coordinate ::: 49258.666666666664,-70030.93591308594
	 // gigapixel_test.js:695 extent ::: 14518,-62613.5,42518,-29397.5
	 
	 // 		var x = evt.pixel.x;
		}

 
 
        });
 
    // window.$("#" + this.pano).bind('click',{ me: this.me}, function(e) {
    // 	var data = e.data;
    // 	window.consoleD('data',data);
    // 	window.consoleM(info(),window.name,data.me.pano);
    // 	data.me.OnClick(e)
    // });
    
    //this.omap.on('singleclick', this.OnClick(evt) );
    
    // this.me.simulateEvent('click',27000,27000,false);
 
 };

 /* Pano.prototype.setAutoMove = function(data){

	this.flyToDuration([data.pathpoints[0].posx,data.pathpoints[0].posy],data.pathpoints[0].zoom,1000);
	this.doStartAutoMove = true;
	this.isautoMove = true;
	// console.log(data)

	let inPoints = [];
	let nbsegments = data.pathpoints.length-1;
	
	global.duration = 1000/60;
	// let nbpoints = Math.round((data.pathpoints[1].time - data.pathpoints[0].time)*1000/global.duration);
	// global.duration = (data.pathpoints[1].time - data.pathpoints[0].time)*1000/nbpoints;

	// nbpoints = 10;

	data.pathpoints.forEach(element => {
		inPoints.push(element.posx);
		inPoints.push(element.posy);
	});
	

	global.pathPoints = [];

	var j;
	for (j = 0; j < nbsegments; j++) {
		let nbpoints = Math.round((data.pathpoints[j+1].time - data.pathpoints[j].time)*1000/global.duration);
		// nbpoints = 3;

		console.log("nbpoints",nbpoints)

		let curvedpoints = getCurvePoints(inPoints,0.5, nbpoints);
		
		var i;
		let zoomini = data.pathpoints[j].zoom
		let zoomfinal = data.pathpoints[j+1].zoom

		let zoominc = (zoomfinal - zoomini)/nbpoints

		let timeini = data.pathpoints[j].time
		let timefinal = data.pathpoints[j+1].time
		let timeinc = (timefinal - timeini)/nbpoints

		for (i = 0; i < nbpoints; i++) {
			let decalage = j*nbpoints * 2;
			let xx = i*2+decalage;
			let yy = i*2 + 1 +decalage;
			// console.log(xx,yy)
			global.pathPoints.push({x:curvedpoints[xx],y:curvedpoints[yy],zoom:zoomini + zoominc * i,duration:global.duration,time:timeini+timeinc*i});
		}
	}

	let lastone = data.pathpoints[data.pathpoints.length-1];
	global.pathPoints.push({x:lastone.posx,y:lastone.posy,zoom:lastone.zoom,duration:global.duration,time:lastone.time});

	// let nbpoints = 3;
	
	// let curvedpoints = getCurvePoints(inPoints,0.5, nbpoints);

	// console.log("nbpoints",nbpoints);
	// console.log("curvedpoints",curvedpoints)

	// var i;
	// for (i = 0; i < curvedpoints.length/2; i++) {
	// 	global.pathPoints.push({x:curvedpoints[i*2],y:curvedpoints[i*2+1],zoom:data.pathpoints[0].zoom-1,duration:global.duration});
	// }

	
	// console.log("duration",global.duration)

	// console.log("data.pathpoints",data.pathpoints);
	
	console.log(global.pathPoints.length)
	// this.AutoMoveNext();

	// global.duration = (lastone.time - data.pathpoints[0].time) *1000/ global.pathPoints.length;

	// console.log("global.duration",global.duration)

	// global.autoMove = setInterval(this.AutoMoveNext, global.duration);
	requestAnimationFrame(this.AutoMoveNext.bind(this));

}



Pano.prototype.pauseAutoMove = function(){
	if (global.autoMove) clearInterval(global.autoMove);
}

Pano.prototype.startAutoMove = function(){
	if (this.isautoMove) {
		this.doStartAutoMove = false;
		global.autoMove = setInterval(this.AutoMoveNext, global.duration);
	}
}

Pano.prototype.AutoMoveNext = function(){
	// if (!global.isplaying) clearInterval(global.autoMove);

	var self = this;

	if (global.pathPoints.length > 0){
		let point = global.pathPoints.shift()
		let point = global.pathPoints.find(pathPoint => pathPoint.time > position);
		// this.flyToDuration([point.x,point.y],point.zoom,point.duration);
		window.thepano.setCenter([point.x,point.y]);
		window.thepano.setZoom(point.zoom);

		if (global.isplaying) requestAnimationFrame(window.thepano.AutoMoveNext.bind(self));
		// this.flyToDuration([point.x,point.y],point.zoom,100);
	} else {
		window.thepano.isautoMove = false;
		clearInterval(global.autoMove);
	}
} */

function info() {

	try {
		const e = new Error();
	const regex = /\((.*):(\d+):(\d+)\)window.$/
	const match = regex.exec(e.stack.split("\n")[2]);

	let name = match[1];
	let namePosition = name.lastIndexOf('/') + 1;
	if (namePosition != 0) name = name.substr(namePosition);
	name = name.replace('.js','');
		
	// return
	return name + " " +  match[2];
	
	  } catch (e) {
		return "";
	  }
	
  };

/*Pano.prototype.setPano = function(options){




	this.panoname = options.panoname;
    this.panoPath = options.panoPath;
    
   

//	window.consoleM(info(),window.name,'setPano panoname',options.panoname);
//	window.consoleM(info(),window.name,'setPano panoname',options.window.panoPath);

	this.ScaleLine = options.ScaleLine;
	this.ZoomSlider = options.ZoomSlider;
	this.fscreen = options.fscreen;
	this.OverviewMap = options.OverviewMap;
	this.enableRotation = options.enableRotation;

	this.maps = [];
	this.layers = [];
	this.mapstolink = [];

	this.setPanodata();

	this.imgWidth = this.panodata.width;
	this.imgHeight = this.panodata.height;
	this.witilesize = this.panodata.witilesize;
	this.tilePixelRatio = 1;
	if (this.witilesize == 512) this.tilePixelRatio = 2;
	this.theme = this.panodata.theme;
	this.titre = this.panodata.titre;
	this.info = this.panodata.info;

	if (this.panodata.zoomini) this.zoomini = this.panodata.zoomini;
	else this.zoomnin = window.panosettings.zoominidefault;

	this.tourIndex = -1;		

	// window.consoleM(info(),window.name,'setPano initMaps',options.initMaps);

	this.ui = new Ui(this,this.panodata);

	if (options.initMaps) this.initMaps();

	window.getPanoramasRequest();
	
};*/
Pano.prototype.showNotification= function(data){
	this.notification.show("hello");
}

Pano.prototype.setClickCallback= function(parent,processclick){
	this.processClick = processclick;
	this.parent = parent;
}

Pano.prototype.initFeatures= function(){
	window.typeSelect = document.getElementById('type');
	
	//var draw; // global so we can remove it later

}

Pano.prototype.addBookmarckPoint = function(){

}

Pano.prototype.addInteraction = function(annotation) {

	this.getMap().addInteraction(annotation.draw);

	annotation.draw.on('drawend', function(e){
		console.log('drawn')
	  });


	// var value = window.typeSelect.value;
	// if (value !== 'None') {
	// 	var geometryFunction;
	// 	if (value === 'Square') {
	// 	value = 'Circle';
	// 	geometryFunction = createRegularPolygon(4);
	// 	} else if (value === 'Box') {
	// 	value = 'Circle';
	// 	geometryFunction = createBox();
	// 	} else if (value === 'Star') {
	// 	value = 'Circle';
	// 	geometryFunction = function(coordinates, geometry) {
	// 		var center = coordinates[0];
	// 		var last = coordinates[1];
	// 		var dx = center[0] - last[0];
	// 		var dy = center[1] - last[1];
	// 		var radius = Math.sqrt(dx * dx + dy * dy);
	// 		var rotation = Math.atan2(dy, dx);
	// 		var newCoordinates = [];
	// 		var numPoints = 12;
	// 		for (var i = 0; i < numPoints; ++i) {
	// 		var angle = rotation + i * 2 * Math.PI / numPoints;
	// 		var fraction = i % 2 === 0 ? 1 : 0.5;
	// 		var offsetX = radius * fraction * Math.cos(angle);
	// 		var offsetY = radius * fraction * Math.sin(angle);
	// 		newCoordinates.push([center[0] + offsetX, center[1] + offsetY]);
	// 		}
	// 		newCoordinates.push(newCoordinates[0].slice());
	// 		if (!geometry) {
	// 		geometry = new Polygon([newCoordinates]);
	// 		} else {
	// 		geometry.setCoordinates([newCoordinates]);
	// 		}
	// 		return geometry;
	// 	};
	// 	}
	// 	window.draw = new Draw({
	// 	source: this.vectorsource,
	// 	type: value,
	// 	geometryFunction: geometryFunction
	// 	});
	// this.getMap().addInteraction(window.draw);
	// }
}

Pano.prototype.removeInteraction = function() {
	this.getMap().removeInteraction(window.draw);
}

Pano.prototype.getExtent = function(){
	return this.getView().calculateExtent();
}

Pano.prototype.getWidth = function(){
	return this.getExtent()[2] - this.getExtent()[0];
}

Pano.prototype.getWidthforZoom = function(zoom){
	let curwidth = this.getWidth();
	let difzoom = this.getZoom() - zoom;
	let factor = Math.pow(2, difzoom);
	// let factor = difzoom * 2;
	return curwidth * factor;
}

Pano.prototype.getHeightforZoom = function(zoom){
	let curwidth = this.getHeight();
	let difzoom = this.getZoom() - zoom;
	let factor = Math.pow(2, difzoom);
	// let factor = difzoom * 2;
	return curwidth * factor;
}

Pano.prototype.getHeight = function(){
	return this.getExtent()[3] - this.getExtent()[1];
}
Pano.prototype.getPanoHeight = function(){
	return this.imgHeight;
}

Pano.prototype.getPanoWidth = function(){
	return this.imgWidth;
}
Pano.prototype.getCenterPercent = function(){
	let center = this.getView().getCenter();
	let center2 = [];
	center2[0] = center[0] / this.getPanoHeight();
	center2[1] = center[1] / this.getPanoWidth();
	return center2;
}

Pano.prototype.hasSameCenterPercent = function(othercenter){
	return ((Math.abs(othercenter[0] - this.getCenterPercent()[0])) < 0.01) && ((Math.abs(othercenter[1] - this.getCenterPercent()[1])) < 0.01);
}

Pano.prototype.setCenterPercent = function(pos){
	// let center = this.getView().getCenter();
	let center2 = [];
	center2[0] = pos[0] * this.getPanoHeight();
	center2[1] = pos[1] * this.getPanoWidth();
	return this.getView().setCenter(center2);
}


Pano.prototype.getCenter = function(){
	return this.getView().getCenter();
}

Pano.prototype.getCenterRelative = function(position,xoffset,yoffset){
	let center = position;
	center.x = position.x - this.getWidthforZoom(position.zoom) / 2 * xoffset / 100
	center.y = position.y - this.getHeightforZoom(position.zoom) / 2 * yoffset / 100
	// center.x = position.x - this.getWidth() / 2 * xoffset / 100
	// center.y = position.y - this.getHeight() / 2 * yoffset / 100
	return center;
}

Pano.prototype.getPosition = function(){
	return new Position(this.getCenter(),this.getZoom());
}

Pano.prototype.getResolution = function(){
	return this.getView().getResolution();
}

Pano.prototype.getRelativeResolution = function(){
	return this.getView().getResolution() / this.imgWidth;
}

Pano.prototype.setCenter = function(pos){
	return this.getView().setCenter(pos);
}

Pano.prototype.setPosition = function(pos){
	this.setCenter(pos.center());
	this.setZoom(pos.zoom);
}

Pano.prototype.centerMap = function(){
	this.setCenter([0,0]);
}

Pano.prototype.movePercent = function(x,y,zoomdif,duration){
	if (!zoomdif) zoomdif = 0;
	if (!duration) duration = 1000;
	let pos = this.getPosition();
	pos.move(this.getWidth()/2*x/100,this.getHeight()/2*y/100,zoomdif);

	this.flyToDuration(pos,1000);
}


Pano.prototype.getZoom = function(){
	return this.getView().getZoom();
}

Pano.prototype.setZoom = function(zoom){
	if (window.detectMob()) zoom--;
	return this.getView().setZoom(zoom);
}

Pano.prototype.getView = function(){
	return this.getMap().getView();
}

Pano.prototype.getMap = function(){
	return this.omap;
}

Pano.prototype.getViewInfo = function(){
	console.log('ViewInfo:'+this.getZoom() + ";"+this.getCenter())
}

Pano.prototype.setZoomInit = function(){

	if (this.positionini){
		this.setPosition(this.positionini);
		// this.setZoom(this.positionini.zoom);
	}
	else if (window.UiItemtobeShown){		
		this.flyToUiItem(window.UiItemtobeShown);
		window.UiItemtobeShown = null;
	} else {
		if (window.panosettings.overrideZoomini > 0) this.omap.getView().setZoom(window.panosettings.overrideZoomini);
		else this.omap.getView().setZoom(this.minzoom);
	}	
}

Pano.prototype.resetMap = function(){
	window.setElementPositionZ(window.getThePanoElement(),window.panosettings.mapzini);
	this.setZoomInit();
	// this.centerMap();
	window.resetZoom();

}

Pano.prototype.getThumbPath = function(){
	return this.getPanoFullPath() + "/thumb.jpg";
};

Pano.prototype.getThumbHeight = function(){
	if ((window.panosettings.panothumbs.height * this.imgWidth / this.imgHeight)>window.panosettings.panothumbs.minwidth) return window.panosettings.panothumbs.height;
	else return window.panosettings.panothumbs.minwidth * this.imgHeight / this.imgWidth;
}

Pano.prototype.getThumbWidth = function(){
	if ((window.panosettings.panothumbs.height * this.imgWidth / this.imgHeight)>window.panosettings.panothumbs.minwidth) return window.panosettings.panothumbs.height * this.imgWidth / this.imgHeight;
	else return window.panosettings.panothumbs.minwidth;
}

Pano.prototype.getThumbTextWidth = function(){
	if (this.getThumbWidth()>window.panosettings.panothumbs.maxtextwidth) return window.panosettings.panothumbs.maxtextwidth;
	else return this.getThumbWidth();
}

Pano.prototype.getPanoFullPath = function(){
	return this.panoPath + this.panoname;
};

Pano.prototype.initMaps = function(){
	this.popups = this.panodata.popups;
		

	// window.consoleM(info(),window.name,'this.ui',this.ui);

	// this.panobookmarks = window.getval(this.panoname,'window.bm');
	//this.initPanoNew(pano);
	this.me = this;

	// window.thepano= this;

	this.setupGamepad();
	
	if (!window.thepano){
		// window.thepano= this;
		// this.setupGamepad();
	}
	// window.consoleM(info(),window.name,'window.thepano.bookmarks000',window.thepano.panobookmarks);
	
	   this.setMaps();
	   
	   if (window.panostates.sceneloaded & !window.panostates.sceneisreadydone) this.sceneIsReady();
}

Pano.prototype.setPanodata = function(){

	switch(this.panoname){
		case 'chapelles':
			this.panodata = { 
				panoname : 'chapelles',
				theme: 'vitraux',
				titre: 'Vitraux des chapelles de Notre Dame',
				info: '',
				zoomini: 5,
				width: 300000,
				height: 80000,
				numtiles: 489856,
				numimages: 1,
				witilesize: 256,
				// popups : popups,
				uiGroups: [
					{
					  "id": 0,
					  "label": "Vitraux",
					  "type": "Thumbs",
					  "uiItems": [
					 /* {
						  "label": "Vitraux 1",
						  "pos": [
							29183,
							-39381
						  ],
						  "zoom": 5,
						  "type": "thumb"
						},
						{
						  "label": "Vitraux 2",
						  "pos": [
							75519,
							-38869
						  ],
						  "zoom": 5,
						  "type": "thumb"
						},
						{
						  "label": "Vitraux 3",
						  "pos": [
							132949,
							-40106
						  ],
						  "zoom": 5,
						  "type": "thumb"
						},
						{
						  "label": "Vitraux 4",
						  "pos": [
							171989,
							-39338
						  ],
						  "zoom": 5,
						  "type": "thumb"
						},
						{
						  "label": "Vitraux 5",
						  "pos": [
							215253,
							-40960
						  ],
						  "zoom": 5,
						  "type": "thumb"
						},
						{
						  "label": "Vitraux 6",
						  "pos": [
							272682,
							-39680
						  ],
						  "zoom": 5,
						  "type": "thumb"
						},*/
						{
						  "label": "Detail 1",
						  "pos": [
							272339,
							-21106
						  ],
						  "zoom": 10,
						  "type": "thumb"
						},
						{
						  "label": "Detail 2",
						  "pos": [
							262809,
							-62910
						  ],
						  "zoom": 11,
						  "type": "thumb"
						},
						{
						  "label": "Detail 3",
						  "pos": [
							218265,
							-66325
						  ],
						  "zoom": 11,
						  "type": "thumb"
						},
						{
						  "label": "Detail 4",
						  "pos": [
							28108,
							-19710
						  ],
						  "zoom": 8,
						  "type": "thumb"
						},
						{
						  "label": "Detail 5",
						  "pos": [
							274655,
							-20622
						  ],
						  "zoom": 8,
						  "type": "thumb"
						},
						{
						  "label": "Detail 6",
						  "pos": [
							138532,
							-20122
						  ],
						  "zoom": 8,
						  "type": "thumb"
						},
						{
						  "label": "Detail 7",
						  "pos": [
							82789,
							-19400
						  ],
						  "zoom": 9,
						  "type": "thumb"
						},
						{
						  "label": "Detail 8",
						  "pos": [
							170290,
							-20501
						  ],
						  "zoom": 8,
						  "type": "thumb"
						},
						{
						  "label": "Detail 9",
						  "pos": [
							140922,
							-66222
						  ],
						  "zoom": 10,
						  "type": "thumb"
						},
						{
						  "label": "Detail 10",
						  "pos": [
							88821,
							-70154
						  ],
						  "zoom": 10,
						  "type": "thumb"
						},
						{
						  "label": "Detail 11",
						  "pos": [
							28042,
							-19843
						  ],
						  "zoom": 8,
						  "type": "thumb"
						},
						
					]
				}			  
					
				]				
			};

		break;
		default:
		window.panodatas.forEach(panodata => {
			if (panodata.panoname === this.panoname) {
				window.consoleM(info(),'panodatazoomini','panodata',panodata.zoomini);
				this.panodata = panodata;
			}
		});
		break;
	}
	


};

Pano.prototype.updatePano = function(options){
	this.setPano(options);
	// this.layer.getSource().changed();
	// this.layer.getSource().updateParams({CQL_FILTER:"1=1"});
	// this.omap.render();
	// this.panoname = options.panoname;
	// this.imgWidth = this.panodata.width;
	// this.imgHeight = this.panodata.height;
	// this.setPanodata();

	this.zoomifyUrl = window.panoPath +this.panoname + '/Images/';
	this.layer.getSource().setUrl(this.zoomifyUrl);

	// this.omap.get

}

Pano.prototype.saveBookmark = function(){
	
}



// ol.Overlay.Popup = function (options) {
// 	var self = this;
// 	options = options || {};


// }

Pano.prototype.mapIsLoaded = function(){
	// window.consoleM(info(),window.name,'mapIsLoaded','mapIsLoaded !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!')
	// this.window.AframeAction('showThePano','show');
};

Pano.prototype.sceneIsReady = function(){
	
	window.panostates.sceneisreadydone = true;

	this.ui.IsReady(false);

	// if (!this.ui.AreThumbReady()) this.setUiItems();
	// else {		
	// 	this.ui.IsReady(false);
	// }
};

Pano.prototype.test = function(){
	window.consoleM(info(),window.name,'test','test')
};

Pano.prototype.getControllerInput = function(hand,axis){

   // window.consoleM(info(),window.name,'getControllerInput',axis);
   // window.consoleM(info(),window.name,'getControllerInput',hand);

   if (hand == 'right'){
	   window.input.stick1.x = axis[0];
	   window.input.stick1.y = axis[1];
   } else {

	   window.input.stick2.x = axis[0];
	   window.input.stick2.y = axis[1];
   }
};

Pano.prototype.checkGamepad = function(param0){
	
	
	window.panostates.hasmoved = false;
	window.panostates.haszoomed = false;
   
		var deltaxy = {x: 0, y: 0};
		var zoomdelta = 0;
		   
   
   
	   if (Math.abs(window.input.stick1.x) > window.controlsettings.right.stickminval) {     	
		window.panostates.hasmoved = true;
			deltaxy.x = window.input.stick1.x *Math.abs(window.input.stick1.x)* window.panosettings.scrollspeed;
	   }
	   if (Math.abs(window.input.stick1.y) > window.controlsettings.right.stickminval){
		window.panostates.hasmoved = true;
			deltaxy.y = -window.input.stick1.y *Math.abs(window.input.stick1.y)* window.panosettings.scrollspeed;
	   }
   
	   if (window.panostates.hasmoved) param0.moveMap(deltaxy);
   
	   if (Math.abs(window.input.stick2.y) >window.controlsettings.left.stickminval){
		window.panostates.haszoomed = true;
			zoomdelta = -window.input.stick2.y
	   }
   
	   if (window.panostates.haszoomed) param0.zoomMap(zoomdelta);

   
};

Pano.prototype.setupGamepad = function(){

//    window.consoleM(info(),window.name,'setupGamepad','setupGamepad');	

   window.setInterval(this.checkGamepad,90,this.me);

	
   // this.hasGP = false;
   var hasGP = false;
   var repGP;
   

   function canGame() {
	   return "getGamepads" in navigator;
   }

   function reportOnGamepad() {
	   var gp = navigator.getGamepads()[0];
	   if (gp){
		   var html = "";
	   //    html += "id: "+gp.id+"<br/>";

	   for(var i=0;i<gp.buttons.length;i++) {
	  //     html+= "Button "+(i+1)+": ";
		   if(gp.buttons[i].pressed) {
			   html+= "Button "+(i+1)+":  pressed";
		 //  html+= "<br/>";
		   // window.consoleM(info(),window.name,'html',html);
		   }
	   }
	   


	   window.input.stick1.x = gp.axes[0];
	   window.input.stick1.y = gp.axes[1];
	   
	   window.input.stick2.y = gp.axes[3];

	 /*  for(var i=0;i<gp.axes.length; i+=2) {
		   html= "Stick "+(Math.ceil(i/2)+1)+": "+gp.axes[i]+","+gp.axes[i+1]+"<br/>";

		   window.input.stick1.x = gp.axes[i];
		   window.input.stick1.y = gp.axes[i+1];
		   window.input.stick2.y = gp.axes[i+1];
		/*	if (gp.axes[i] > 0.2) {
				window.input.stick1.x = gp.axes[i];
				//window.consoleM(info(),window.name,'axe',html);
				
			}
	   }*/



	   window.$("#gamepadDisplay").html(html);
	   }
	   
   }

   window.$(document).ready(function() {

	   if(canGame()) {

		   var prompt = "To begin using your gamepad, connect it and press any button!";
		   window.$("#gamepadPrompt").text(prompt);

		   window.$(window).on("gamepadconnected", function() {
			   hasGP = true;
			   window.$("#gamepadPrompt").html("Gamepad connected!");
			   console.log("connection event");
		   //    window.consoleD("checkpano",this.me);
			   repGP = window.setInterval(reportOnGamepad,90);

		   //    repGP = window.setInterval(this.checkGamepad,100);
		   });

		   window.$(window).on("gamepaddisconnected", function() {
			   console.log("disconnection event");
			   window.$("#gamepadPrompt").text(prompt);
			   window.clearInterval(repGP);
		   });

		   //setup an interval for Chrome
		   var checkGP = window.setInterval(function() {
			//   console.log('checkGP');
			   if(navigator.getGamepads()[0]) {
				   if(!hasGP) window.$(window).trigger("gamepadconnected");
				   window.clearInterval(checkGP);
			   }
		   }, 500);

		   
	   }

   });
};

Pano.prototype.moveMap= function(deltaxy){

   // window.consoleM(info(),window.name,'moveMap deltaxy.x',deltaxy.x);
   var center = this.omap.getView().getCenter();
   var resolution = this.omap.getView().getResolution();
   this.omap.getView().setCenter([center[0] + 10*resolution*deltaxy.x, center[1] + 10*resolution*deltaxy.y]);
};

Pano.prototype.zoomMap= function(zoomdelta){

   //window.consoleM(info(),window.name,'zoomMap deltaxy.x',deltaxy.x);
   var zoom = this.omap.getView().getZoom();	
   this.omap.getView().setZoom(zoom + zoomdelta / 10);
};


Pano.prototype.onGamepad= function(options){

};
  Pano.prototype.Ongamepadconnected = function(options){
	
};

Pano.prototype.getUiItemThumb = function(uiItem){
	

	window.consoleM(info(),window.name,'window.getUiItemThumb uiItem !!!!!!!!!!!!!!!!!!!!!!!',uiItem.Id());
	window.panostates.itemToScreenshot.unshift(uiItem)
	if (window.panostates.itemToScreenshot.length === 1 && !window.thepano.tempuiItem) this.getNextUiItemThumb();

}

Pano.prototype.getNextUiItemThumb = function(){

	window.consoleM(info(),window.name,'getNextUiItemThumb',window.panostates.itemToScreenshot.length);

	if (window.panostates.itemToScreenshot.length > 0){
		window.thepano.tempuiItem = window.panostates.itemToScreenshot.pop();
		window.panostates.thumbstep = 0;
		this.getNextUiThumb();
	} else {
		window.thepano.tempuiItem = null;
	}
}


// Pano.prototype.setUiItems = function(){
	

// 	this.tempuiItem = this.ui.getFirstUiItemwithoutThumb();
// 	window.consoleM(info(),'setUiItems','this.tempuiItem',this.tempuiItem);
// 	if(this.tempuiItem){
// 		this.thumbstep = 0;
// 		this.getNextUiThumb();
// 	} else {
// 		// window.consoleM(info(),window.name,'updateUiItem ui uiItems',this.ui.uiItems);
// 		// this.window.AframeAction('UiReady', {uiItems : this.ui.uiItems});
// 		// window.consoleM(info(),window.name,'updateUiItem ui uiItems');
		
// // 		this.window.AframeAction('UiReady',{uiItems : this.ui.uiGroups});
// 		this.ui.IsReady(true);
// 		// window.sceneEl.emit('olmessage', false);
		
// 	}
// }

Pano.prototype.getNextUiThumb = function () {
	 window.consoleM(info(),'getNextUiThumb','this.thumbstep',window.panostates.thumbstep);
	 window.consoleM(info(),'getNextUiThumb','this.tempuiItem.pos',window.thepano.tempuiItem.label);
	switch(window.panostates.thumbstep){
		case 0:
			this.thumbready = false;
			
			this.omaps.getView().setCenter(window.thepano.tempuiItem.pos);
		break;
		case 1:
			if (this.omaps.getView().getZoom() !== window.thepano.tempuiItem.zoom - window.panosettings.ui.thumbsizezoom){
				this.thumbready = false;
				this.omaps.getView().setZoom(window.thepano.tempuiItem.zoom - window.panosettings.ui.thumbsizezoom);				
			} else {
				this.thumbready = true;
			}
			
		break;
		case 2:
			this.thumbready = true;
			
		break;
	}

	if (window.thepano.tempuiItem && this.thumbready){
		window.thepano.tempuiItem.thumb = window.convertCanvasToImageString(this.thumbCanvas);
		// window.consoleM(info(),window.name,'thumb',this.tempuiItem.thumb);
		this.ui.updateUiItem(window.thepano.tempuiItem);
		this.getNextUiItemThumb();		
	}

	// window.panostates.takingscreenshot = true;	 
 
 }
	

Pano.prototype.UpdateItems = function(){
	var els = window.sceneEl.querySelectorAll('.link');
	// console.log(els);
	window.consoleM(info(),window.name,'UpdateItems els',els);
        for (var i = 0; i < els.length; i++) {
		//   console.log(els[i]);
		  window.EmittoAframe('updateItem', {},false,els[i]);
        //   els[i].updateItem();
        }
};

Pano.prototype.PostRendered = function(){

	// window.consoleM(info(),window.name,'PostRendered states.ismoving' ,window.panostates.ismoving);


	this.Benchmark('tick');
	window.panostates.refreshonce = true;

	if (!window.panostates.ismoving){
		// htmlmap.emit('setfps', {fps: window.panostates.htmlmapfpsmin}, false);
		
		window.EmittoAframe('setfps', {fps: window.panosettings.htmlmapfpsmin}, false);
	}
		// window.loadstart = Date.now();
		// window.sceneEl.emit('setfps', {fps: 0}, false);
 
		// if (currentindex == 0){
		// 	currentindex = 1;
		// 	window.thepano.setZoom(10);
		// } else {
		// 	currentindex = 0;
		// 	window.thepano.setZoom(5);
		// }
		
		// window.thepano.flyToIndex(0);
	// }
	// setTimeout(window.thepano.PostRendered,200);
  };



Pano.prototype.EmittoAframe = function(message,messagedata,bool,target=window.sceneEl){
	// var window.name = 'hide';
	// window.consoleM(info(),window.name,'window.EmittoAframe message',message);
	// window.consoleM(info(),window.name,'window.EmittoAframe messagedata',messagedata);
	// if (target) target.emit(message, messagedata, bool);
	window.EmittoAframe(message,messagedata,bool,target=window.sceneEl)
}

Pano.prototype.AframeAction = function(action,data){
	var messagedata = {action:action,messagedata:data};
	window.EmittoAframe('olmessage', messagedata,false);
}

Pano.prototype.FakeControllerAction = function(action,data){
	var messagedata = {action:action,messagedata:data};
	this.window.AframeAction('sendfakeaction', {action:action,data:messagedata});
}



Pano.prototype.Benchmark = function(action,value){
	
	var name = 'hide';
	switch (action){
		case 'start':
			window.benchmarkdata.vals = [];
		break;
		case 'tick':

			var millis = Date.now() - window.loadstart;

			window.consoleM(info(),name,'millis',millis);
			
			window.benchmarkdata.vals.push(millis);

			if (window.benchmarkdata.vals.length > window.benchmarkdata.valssize){
				window.benchmarkdata.vals = window.benchmarkdata.vals.slice(1,-1);
			}

			window.consoleM(info(),name,'window.benchmarkdata.vals',window.benchmarkdata.vals);

			var sum = 0;
			for (var i = 0; i < window.benchmarkdata.vals.length; i++) {
				sum = sum + window.benchmarkdata.vals[i];
			}
			var value = sum / window.benchmarkdata.vals.length / 1000;

			value = 1/value;

			value = value.toFixed(1);

			// this.window.AframeAction('udpateolbenchmark', {value: value});

			window.loadstart = Date.now();			
		break;
	}
};

Pano.prototype.Delayed = function(){
    // if (window.thepano) {
        console.log('delayed');
        window.loadstart = Date.now();
        window.sceneEl.emit('setfps', {fps: 0}, false);
 
     //    if (currentindex == 0){
     // 	   currentindex = 1;
     // 	   window.thepano.setZoom(10);
     //    } else {
     // 	   currentindex = 0;
     // 	   window.thepano.setZoom(5);
     //    }
        
        // window.thepano.flyToIndex(0);
    // }
    setTimeout(window.thepano.Delayed,20000);
  };
 
 Pano.prototype.OnClick = function(xy){
                
        window.x1 = xy[0];
        window.y1 = xy[1];
        
        // window.consoleM(info(),window.name,'OnClick1',window.x1);
        // window.consoleM(info(),window.name,'OnClick2',window.y1);
        
        var resulttitle = "";
        var dist = -1;
        var rayon = 1300;
        
        
        for (var b in this.popups) {
        
            // console.log(b);
            // console.log(this.popups[b].pos);
            
            window.x2 = this.popups[b].pos[0]
            window.y2 = this.popups[b].pos[1]
            
            var a = window.x1 - window.x2
            var bb = window.y1 - window.y2
 
            var c = Math.sqrt( a*a + bb*bb );
            
        //	console.log(c);
            
            if (dist == -1 || c < dist) {
                dist = c
                resulttitle = b
                if (this.popups[b].rayon) {
                    rayon = this.popups[b].rayon;
                } else {
                    rayon = window.panosettings.popuprayon;
                }
            }
    
        }
        
 
        // window.consoleM(info(),window.name,'resulttitle',resulttitle);
        // window.consoleM(info(),window.name,'dist',dist);
        
        
        
        if (dist < rayon){
            // this.popup.show(evt.coordinate, '<div><p>' + resulttitle + '</p></div>');
            this.popup.show(xy, '<div><p>' + resulttitle + '</p></div>');
        }
        
    //var prettyCoord = ol.coordinate.toStringHDMS(ol.proj.transform(evt.coordinate, 'EPSG:3857', 'EPSG:4326'), 2);
    //this.popup.show(evt.coordinate, '<div><h2>Coordinates</h2><p>' + prettyCoord + '</p></div>');
 }
 
 Pano.prototype.setPopups = function(){
     
    
 }
 
 
 Pano.prototype.resetControlBar = function(){
    this.omap.removeControl(this.mainbar);
    this.omap.removeControl(this.play);
    this.omap.removeControl(this.pause);
    
    this.setControlBar()	
 }
 
 // Pano.prototype.setControlBar = function(){
    
 // //	console.log("testvar setControlBar: "+this.pano);
    
 // //	console.log("popups setControlBar: "+this.popups);
    
 // 	// Main control bar
 // 	   this.mainbar = new Bar();
 // 	   this.omap.addControl(this.mainbar);
 // 	   /* Nested toobar with one control activated at once */
 //    /*	var nested = new ol.control.Bar ({ toggleOne: true, group:true });
 // 	   this.mainbar.addControl (nested);*/
      
        
        
 // 	   window.popupsg = this.popups;
 // 	   window.popupg = this.popup;
        
        
 // //		console.log("window.popupsg setControlBar: "+window.popupsg);
    
 // //	window.consoleD("this.me", this.me);
 //    /*window.consoleD("this.pano", this.pano);
 //    window.consoleD("this", this);*/
    
 //    var aparent = this;
    
 //    var tagindex = 0;
    
 //    //console.log("thename: "+ thename);
    
 //    // Add a toggle control with a select interaction
    
 //    if (this.isplayingtour){
 
 // 	   this.pause = new Toggle(
 // 			   {	html: '<i class="fa fa-pause"></i>',
 // 				   className: "pause",
 // 				   title: "Pause",
 // 			   //	interaction: new ol.interaction.Select (),
 // 				   active:true,
 // 				   onToggle: function(active)
 // 				   {	
 // 					   //map.removeControl(hello);
 // 					   this.isplayingtour = false ;
 // 					   this.resetControlBar();
                        
 // 				   }
 // 			   });
 // 	   this.mainbar.addControl(this.pause);
 //    } else {
 // 	   this.previous = new Button (
 // 			   {	html: '<i class="fa fa-chevron-left"></i>',
 // 				   className: "previous",
 // 				   title: "Previous",
 // 				   handleClick: function()
 // 					   {	
 // 					   window.updateTourIndex(-1);
 // 					   var tag = window.getTagInfoForIndex(this.tourIndex);
                        
 // 					   window.flyTo(this.popups[tag],function(){
 // 					   this.pendingTour = false;
 // 						   this.popup.show(this.popups[tag].pos, '<div><p>' + tag + '</p></div>');
 // 						   });
 // 					   }						
 // 			   });
 // 	   this.mainbar.addControl(this.previous);
        
 // 	   this.play = new Toggle(
 // 			   {	html: '<i class="fa fa-play"></i>',
 // 				   className: "play",
 // 				   title: "Play",
 // 			   //	interaction: new ol.interaction.Select (),
 // 				   active:false,
 // 				   onToggle: function(active)
 // 				   {	
 // 					   //map.removeControl(hello);
 // 					   this.isplayingtour = true;
 // 					   this.resetControlBar();
 // 					   if (!this.pendingTour) tour();
 // 				   }
 // 			   });
 // 	   this.mainbar.addControl(this.play);
        
        
        
 // 	   this.next = new Button (
 // 			   {	html: '<i class="fa fa-chevron-right"></i>',
 // 				   className: "next",
 // 				   id: "next",
 // 				   title: "Next",
 // 		   /*/		popups: this.popups,
 // 				   popup: this.popup,
 // 				   window.updateTourIndex: this.window.updateTourIndex,
 // 				   window.getTagInfoForIndex: this.window.getTagInfoForIndex,
 // 				   tourIndex:this.tourIndex,*/
 // 				   thename: this.panoname,
 // 				   me: this.me,
 // 				   handleClick: function(thename,me)
 // 					   {	
                        
 // 				   //	console.log("testme " + this.pano); 
 // 					   /*console.log("thename2: "+ thename);
 // 					   window.consoleD("thename", thename);*/
 // 				   //	window.consoleD("this.popupsss", this.popups);
                        
                    
 // 					   window.consoleM(info(),window.name,"this.tourIndex", this.tourIndex);
 // 					   this.window.updateTourIndex(1);
 // 					   var tag = this.window.getTagInfoForIndex(this.tourIndex);
                        
                        
                        
 // 					   this.window.flyTo(this.popups[tag],function(me){
 // 						   me.pendingTour = false;
 // 						   me.popup.show(me.popups[tag].pos, '<div><p>' + tag + '</p></div>');							
 // 					   });
 // 					   }
 // 			   });
                
 // 		   //	console.log("this.next " + this.next.id);
                
 //    /*	window.$("#" + this.pano).bind("click",this, function(e) {
 // 	 console.log(e.data.pano + " " + e.data.pano);
 // //	  this.OnClick(evt)
 //    });*/
 // 	   this.mainbar.addControl(this.next);
        
        
 //    }
        
 // }
 
 Pano.prototype.takeScreenShot = function (index) {
 
    
     var tag = window.thepano.window.getTagInfoForIndex(index);
     
     window.thepano.omaps.getView().setCenter(window.thepano.popups[tag].pos);
     window.thepano.omaps.getView().setZoom(window.thepano.popups[tag].zoom - 2);
 
     window.panostates.takingscreenshot = true;
          
  
  }
 
 Pano.prototype.flyToIndex = function (index) {
 
    
    var tag = window.thepano.window.getTagInfoForIndex(index);												
 
    window.thepano.window.flyTo(window.thepano.popups[tag].pos,window.thepano.popups[tag].zoom,function(){
        window.thepano.pendingTour = false;
        // window.thepano.popup.show(window.thepano.popups[tag].pos, '<div><p>' + tag + '</p></div>');							
    });
     
 
 }
 
 Pano.prototype.setZoom = function (index) {
    
        window.thepano.omap.getView().setZoom(index);	
 
 }
 
 Pano.prototype.getZoom = function () {
    
     return window.thepano.omap.getView().getZoom();	
 
 }
 
 Pano.prototype.incZoom = function () {
     window.consoleM(info(),window.name,'incZoom','incZoom');
     this.setZoom(this.getZoom()+1);	
 
 }
 
 Pano.prototype.decZoom = function () {
    window.consoleM(info(),window.name,'decZoom','decZoom');
     this.setZoom(this.getZoom()-1);
 
 }
 
 Pano.prototype.move = function () {
    
     let resolution = this.getView().getResolution;
     window.consoleM(info(),window.name,'resolution',resolution);
 
 }
 
 /**
   * Simulates a browser event on the map viewport.  The client x/y location
   * will be adjusted as if the map were centered at 0,0.
   * @param {string} type Event type.
   * @param {number} x Horizontal offset from map center.
   * @param {number} y Vertical offset from map center.
   * @param {boolean=} opt_shiftKey Shift key is pressed.
   */
  Pano.prototype.simulateEvent = function (type, x, y, opt_shiftKey) {
    // var viewport = this.me.omap.getViewport();
    // // calculated in case body has top < 0 (test runner with small window)
    // var position = viewport.getBoundingClientRect();
    // window.consoleM(info(),window.name,'simulateEvent position',position);
    // // var shiftKey = opt_shiftKey !== undefined ? opt_shiftKey : false;
    // var event = new ol.pointer.PointerEvent(type, {
    // //   clientX: position.left + x + width / 2,
    // //   clientY: position.top + y + height / 2,
    //   clientX: position.left + x + width / 2,
    //   clientY: position.top + y + height / 2,
    //   shiftKey: false
    // });
    // this.me.omap.handleMapBrowserEvent(new ol.MapBrowserPointerEvent(type, this.me.omap, event));
  }
 
  
  Pano.prototype.OnClickXY = function(xy){
 
 
    var coordinates =  window.thepano.xyToCoordinates(xy);
 
    window.thepano.OnClick(coordinates);
 
 }
 
 Pano.prototype.xyToCoordinates = function(xy,mapindex){
 //    var clientHeight = document.getElementById(window.thepano.panoname).clientHeight;
 //    var	clientWidth = document.getElementById(window.thepano.panoname).clientWidth;
 //***** */
 //    var clientHeight = getMapHeight();
 //    var	clientWidth =  getMapWidth();
 
 // window.consoleM(info(),'xyToCoordinates','xy[0]',xy[0]);
 // window.consoleM(info(),'xyToCoordinates','xy[1]',xy[1]);
 
     if (!mapindex) mapindex = 1;
 //    var clientHeight = document.getElementById(window.thepano.panoname+1).clientHeight / Math.pow(2, mapindex-1) ;
 //    var	clientWidth = document.getElementById(window.thepano.panoname+1).clientWidth / Math.pow(2, mapindex-1);
 
    var clientHeight = document.getElementById(window.thepano.panoname+1).clientHeight ;
    var	clientWidth = document.getElementById(window.thepano.panoname+1).clientWidth ;
 
    var newx = clientWidth * xy[0];
 
    // window.consoleM(info(),window.name,'newx',newx);
 
    var newy = clientHeight * xy[1];
 
    // window.consoleM(info(),window.name,'newy',newy);
    var coordinates =  window.thepano.maps[mapindex-1].getCoordinateFromPixel([newx,newy]);
 //    var coordinates =  window.thepano.omap.getCoordinateFromPixel([newx,newy]);
 
    return coordinates;
 }
 
 
 Pano.prototype.flyToXY01 = function(xy){
 
 
 
    // window.thepano.window.flyTo(xy,window.thepano.popups[tag].zoom,function(){
    // 	window.thepano.pendingTour = false;
    // 	// window.thepano.popup.show(window.thepano.popups[tag].pos, '<div><p>' + tag + '</p></div>');							
    // });
 
 }
 
 Pano.prototype.flyToUiItem = function(uiItem){
 
  
     window.thepano.omap.getView().setCenter(uiItem.pos);
     window.thepano.omap.getView().setZoom(uiItem.zoom);
 
     // window.consoleM(info(),'thezoom','getZoom','-------------------------------------------------------------');
     // window.consoleM(info(),'thezoom','getZoom',window.thepano.omap.getView().getZoom());
     // window.thepano.window.flyTo(uiItem.pos,uiItem.zoom,function(){
     // 	// window.thepano.pendingTour = false;
     // 	// window.thepano.popup.show(window.thepano.popups[tag].pos, '<div><p>' + tag + '</p></div>');							
     // });
  
  }
 
  Pano.prototype.flyToXY = function(xy,mapindex){
 
    
    var zoom = window.thepano.view.getZoom();
 
    var coordinates =  window.thepano.xyToCoordinates(xy,mapindex);
 
    window.thepano.window.flyTo(coordinates,zoom,function(){
        window.thepano.pendingTour = false;
        // window.thepano.popup.show(window.thepano.popups[tag].pos, '<div><p>' + tag + '</p></div>');							
    });
 
 }
 
 Pano.prototype.flyZoomToXY = function(xy,zoominc){
 
     if (!zoominc) zoominc = 0;
    
    var zoom = window.thepano.view.getZoom() + 1;
 
    var coordinates =  window.thepano.xyToCoordinates(xy);
 
    window.thepano.window.flyTo(coordinates,zoom,function(){
        window.thepano.pendingTour = false;
        // window.thepano.popup.show(window.thepano.popups[tag].pos, '<div><p>' + tag + '</p></div>');							
    });
 
 }
 
 // Pano.prototype.flyTo = function (tag, done) {
 // 	window.thepano.window.flyTo(tag.pos,tag.zoom,done);
 // }
 
 Pano.prototype.flyTo = function (pos, zoom2, done) {

	this.setToolbar();
    
    window.consoleD("window.flyTo pos", pos);
 //	window.consoleD("window.flyTo", this.me);
         if (window.panosettings.flytoanimated){
             var location = pos;
             var duration = 2000;
             var zoom = this.getZoom();
             var parts = 2;
             var called = false;
             var test = this.me;
             function callback(complete) {
               --parts;
               if (called) {
                 return;
               }
               if (parts === 0 || !complete) {
                 called = true;
              //   window.consoleM(info(),window.name,"window.flyTo test", test);
                 if (done) done();
               }
             }
             this.getView().animate({
               center: location,
               duration: duration
             }, callback);
         //     this.view.animate({
         //   //  zoom: zoom - 1,
         //       zoom: zoom,
         //       duration: duration / 2
         //     }, {
         //       zoom: zoom2,
         //       duration: duration / 2
         // 	}, callback);
             this.getView().animate({
                     zoom: zoom2,
                     duration: duration
                   }, callback);
         } else {
             this.setZoom(zoom2);
             this.setCenter(pos);
         }   
        
 }

 Pano.prototype.flyTo2 = function (location, zoom2, done) {
    
	window.consoleD("window.flyTo pos", location);
	
	if (!zoom2) zoom2 = this.getZoom();
 //	window.consoleD("window.flyTo", this.me);
         if (window.panosettings.flytoanimated){
			var duration = 2000;
				var zoom = this.getZoom();
				var parts = 2;
				var called = false;
				function callback(complete) {
				  --parts;
				  if (called) {
					return;
				  }
				  if (parts === 0 || !complete) {
					called = true;
					if (done) done(complete);
				  }
				}
				this.getView().animate({
					center: location,
					zoom: zoom2 ,
					duration: duration
				  }, callback);


         } else {
             this.setZoom(zoom2);
             this.setCenter(location);
         }   
        
 }

 Pano.prototype.flyToRelative = function (position, xoffset,yoffset) {
	 this.flyToDuration(this.getCenterRelative(position,xoffset,yoffset),1500)
 }

 Pano.prototype.flyToDuration = function (location, zoom2, duration) {
    
	window.consoleD("window.flyTo pos", location);

	let pos;

	

	if (Array.isArray(location)){
		if (!zoom2) zoom2 = this.getZoom();
		pos = new Position(location,zoom2)
	} else {
		pos = location;
		duration = zoom2;
	}
		

	if (window.panosettings.flytoanimated){
	// var duration = 2000;
		// var zoom = this.getZoom();
		var parts = 2;
		var called = false;
		function callback(complete) {
			--parts;
			if (called) {
			return;
			}
			if (parts === 0 || !complete) {
			called = true;
			// if (done) done(complete);
			}
		}
		this.getView().animate({
			center: pos.center(),
			zoom: pos.zoom ,
			duration: duration
			}, callback);

	} else {
		this.setZoom(zoom2);
		this.setCenter(location);
	}   
        
 }

 

 Pano.prototype.setPopupsSize = function (){
   
	//    console.log("testvar2: "+this.pano);
	
	   var i = 0;
	   for (var b in this.popups) {	
		   
			   i++;
	   
	   }
	   
	   window.PopupsSize = i;
	   
	}
	

Pano.prototype.getTagInfoForIndex = function(ind){
   var result;
   
   var i = 0;
   for (var b in this.popups) {
	   
	   //	if (i == ind) result = popups[b]
		   if (i == ind) result = b
		   
		   i++;
   
	   }
	   return result;
}


	 
Pano.prototype.showPopup = function(evt) {
	   
	   
	   window.x1 = evt.coordinate[0]
	   window.y1 = evt.coordinate[1]
	   
	   console.log(window.x1);
	   console.log(window.y1);
	   
	   var resulttitle = "";
	   var dist = -1;
	   var rayon = 1300;
	   
	   
	   for (var b in this.popups) {
	   
		   //console.log(b);
		   //console.log(popups[b].pos);
		   
		   window.x2 = this.popups[b].pos[0]
		   window.y2 = this.popups[b].pos[1]
		   
		   var a = window.x1 - window.x2
		   var bb = window.y1 - window.y2

		   var c = Math.sqrt( a*a + bb*bb );
		   
	   //	console.log(c);
		   
		   if (dist == -1 || c < dist) {
			   dist = c
			   resulttitle = b
			   if (this.popups[b].rayon) {
				   rayon = this.popups[b].rayon;
			   } else {
				   rayon = 1300;
			   }
		   }
   
	   }
	   
	   console.log(resulttitle);
	   console.log(dist);
	   
	   
	   
	   if (dist < rayon){
		   this.popup.show(evt.coordinate, '<div><p>' + resulttitle + '</p></div>');
	   }
	   
   //var prettyCoord = ol.coordinate.toStringHDMS(ol.proj.transform(evt.coordinate, 'EPSG:3857', 'EPSG:4326'), 2);
   //popup.show(evt.coordinate, '<div><h2>Coordinates</h2><p>' + prettyCoord + '</p></div>');
}

Pano.prototype.updateTourIndex = function(val){
   this.tourIndex = this.tourIndex + val;
   if (this.tourIndex >= this.window.PopupsSize) this.tourIndex = 0;
   if (this.tourIndex < 0) this.tourIndex = this.window.PopupsSize - 1;
}

function tour() {
   //    var locations = [london, bern, rome, moscow, istanbul];
	   
	   function next(more) {
		 if (this.isplayingtour && more) {
		   window.updateTourIndex(1);
		   
		   var tag = window.getTagInfoForIndex(this.tourIndex);
		   
		   console.log(tag);
		   
		   if (tag) {
	   //    if (index < popups.length) {
				   this.pendingTour = true;
			 var delay = this.tourIndex === 0 ? 0 : 2000;
			 setTimeout(function() {
			   
			   if (this.isplayingtour){
				   window.flyTo(this.popups[tag],function(){
				   this.pendingTour = false;
					   this.popup.show(this.popups[tag].pos, '<div><p>' + tag + '</p></div>');
					   next(true);
					   });
			   }                					
					   
			 }, delay);
		   } else {
			   this.tourIndex = -1
			 //alert('Tour complete');
		   }
		 } else {
		 
		   //alert('Tour cancelled');
		 }
	   }
	   next(true);
	 }

Pano.prototype.setPanoSize = function(result){
   
   
			   var n = result.indexOf("WIDTH='") ;
			   var n2 = result.indexOf("' HEIGHT='");
			   this.me.imgWidth = parseInt(result.substring(n+ 7,n2));
			   
			   var n3 = result.indexOf("' NUMTILES");
			   
			   //console.log("substring: "+result.substring(n2+10,n3));
			   
			   this.me.imgHeight = parseInt(result.substring(n2+10,n3));
			   
			   //console.log(widths);	
}