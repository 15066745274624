import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import firebase from '../Firebase';
import { Link } from 'react-router-dom';

import nodeUtils from '../js/nodeUtils.js'

class Edit extends Component {

  constructor() {
    super();
    this.ref = firebase.firestore().collection('gigaphotos');
    this.state = {
      titre: '',
      panoname: '',
      theme: '',
      info: '',
      width: 0,
      height: 0,
      numtiles: 0,
      witilesize: 0,
      url:'http://37.187.22.210/pano/',
      autheur: '',
      data:'{"position":{"position":[0,0],"zoom":1}}',
      users: ''
    };
 //  this.new = (this.props) ? true : false;
  }

  componentDidMount() {
    //  if (!this.new){
        const ref = firebase.firestore().collection('gigaphotos').doc(this.props.match.params.id);
        ref.get().then((doc) => {
          if (doc.exists) {
            const gigaphoto = doc.data();

           /*  console.log(gigaphoto.uiGroups[0].uiItems[0]);

            let uiItem = gigaphoto.uiGroups[0].uiItems[0];

            let index = 1; */

            // nodeUtils.addBookmarkFull({label: uiItem.label,posx: uiItem.pos[0],posy: uiItem.pos[1],zoom: uiItem.zoom,index: index, panoname: gigaphoto.panoname,thumb:'',author:"Cyril PREISS"})

            // window.bref.add({label: params.label,posx: params.posx,posy: params.posy,zoom: params.zoom,index: params.index, panoname: params.panoname,thumburl:params.thumb,author:params.author})

/*             gigaphoto.uiGroups[0].uiItems.forEach(uiItem => {
              console.log(uiItem);
              // nodeUtils.addBookmarkFull({label: uiItem.label,posx: uiItem.pos[0],posy: uiItem.pos[1],zoom: uiItem.zoom,index: index, panoname: gigaphoto.panoname,thumb:'',author:"Cyril PREISS"})
              index = index + 1;
            }); */

            if(!gigaphoto.autheur) gigaphoto.autheur = '';

            if(!gigaphoto.data) gigaphoto.data = '{"position":{"position":[0,0],"zoom":1}}';

            this.setState({
              key: doc.id,
              titre: gigaphoto.titre,
              panoname: gigaphoto.panoname,
              theme: gigaphoto.theme,
              info: gigaphoto.info,
              width: gigaphoto.width,
              height: gigaphoto.height,
              numtiles: gigaphoto.numtiles,          
              witilesize: gigaphoto.witilesize,
              url: gigaphoto.url,
              autheur:gigaphoto.autheur,
              data:gigaphoto.data,
              users:gigaphoto.users
            });
          } else {
            console.log("No such document!");
          }
        });
     // } 
    
  }

  deleteGigaphoto(){
    console.log(this.state.key);
    firebase.firestore().collection('gigaphotos').doc(this.state.key).delete().then((docRef) => {
          this.props.history.push("/")
      });
  }
  onChange = (e) => {
    const state = this.state
    state[e.target.name] = e.target.value;
    this.setState(state);
  }

  onSubmit = (e) => {
    e.preventDefault();

    const { titre,panoname,theme,info,width,height,numtiles,witilesize,url,autheur,data,users } = this.state;

     
        const updateRef = firebase.firestore().collection('gigaphotos').doc(this.state.key);
        updateRef.set({
            titre,panoname,theme,info,width,height,numtiles,witilesize,url,autheur,data,users
        }).then((docRef) => {
        /*  this.setState({
            key: '',
            title: '',
            description: '',
            author: ''
            });*/
            this.props.history.push("/")
        //  this.props.history.push("/show/"+this.props.match.params.id)
        })
        .catch((error) => {
            console.error("Error adding document: ", error);
        });
          
   // }



    
  }

  render() {
    const { titre,
        panoname,
        theme,
        info,
        width,
        height,
        numtiles,
        witilesize,url,autheur,data,users } = this.state;
    return (
      <div class="container">
        <div className="panel panel-default">
          <div className="panel-heading">
            <h3 className="panel-title">
              ADD BOARD
            </h3>
          </div>
          <div class="panel-body">
            <h4><Link to="/" className="btn btn-primary">Book List</Link></h4>
            <form onSubmit={this.onSubmit}>
              <div className="form-group">
                <label htmlFor="panoname">Panoname:</label>
                <input type="text" className="form-control" name="panoname" value={panoname} onChange={this.onChange} placeholder="Panoname" />
              </div>
              <div className="form-group">
                <label htmlFor="titre">Title:</label>
                <input type="text" className="form-control" name="titre" value={titre} onChange={this.onChange} placeholder="Titre" />
              </div>
              <div className="form-group">
                <label htmlFor="theme">Theme:</label>
                <input type="text" className="form-control" name="theme" value={theme} onChange={this.onChange} placeholder="Theme" />
              </div>
              <div className="form-group">
                <label htmlFor="info">Info:</label>
                <textArea className="form-control" name="info" onChange={this.onChange} placeholder="info" cols="80" rows="3">{info}</textArea>
              </div>
              <div className="form-group">
                <label htmlFor="width">width:</label>
                <input type="number" className="form-control" name="width" value={width} onChange={this.onChange} placeholder="width" />
              </div>
              <div className="form-group">
                <label htmlFor="height">height:</label>
                <input type="number" className="form-control" name="height" value={height} onChange={this.onChange} placeholder="height" />
              </div>
              <div className="form-group">
                <label htmlFor="numtiles">numtiles:</label>
                <input type="number" className="form-control" name="numtiles" value={numtiles} onChange={this.onChange} placeholder="numtiles" />
              </div>
              <div className="form-group">
                <label htmlFor="witilesize">witilesize:</label>
                <input type="number" className="form-control" name="witilesize" value={witilesize} onChange={this.onChange} placeholder="witilesize" />
              </div>
              <div className="form-group">
                <label htmlFor="url">Url:</label>
                <input type="text" className="form-control" name="url" value={url} onChange={this.onChange} placeholder="Url" />
              </div>
              <div className="form-group">
                <label htmlFor="autheur">Autheur:</label>
                <input type="text" className="form-control" name="autheur" value={autheur} onChange={this.onChange} placeholder="autheur" />
              </div>
              <div className="form-group">
                <label htmlFor="data">Autheur:</label>
                <input type="text" className="form-control" name="data" value={data} onChange={this.onChange} placeholder="data" />
              </div>
              <div className="form-group">
                <label htmlFor="data">Users:</label>
                <input type="text" className="form-control" name="users" value={users} onChange={this.onChange} placeholder="users" />
              </div>
              
              <button type="submit" className="btn btn-success">Submit</button>
            </form>
            <button  onClick={() => this.deleteGigaphoto()} >
                        Delete
                        </button>
          </div>
        </div>
      </div>
    );
  }
}

export default Edit;