import { AudioTimeLine } from '../js/AudioTimeLine.js';
import {Howl, Howler} from 'howler';
import { AudioEvent } from './AudioEvent.js';
import nodeUtils from '../js/nodeUtils.js'

export class AudioObject{
    audioname = "";

    constructor(parent,bookmark){     
        this.parent = parent;   
        this.update(bookmark)
    } 

    update(bookmark){

      this.timer = 0;
      this.startplaytime = 0;
      this.audioevents = [];
      this.isplayingaudio = false;

      this.audioname = bookmark.audioname;
      this.audiofile = window.thepano.getPanoFullPath() + "/Audio/" + this.audioname + '.mp3';

      console.log("audioevent",bookmark.audioevents)
      if (bookmark.audioevents  && bookmark.audioevents!="none" && bookmark.audioevents!="no"){            
          this.audioeventstab = JSON.parse(bookmark.audioevents);      
          this.audioeventstab.forEach(audioevent => {
            this.audioevents.push(new AudioEvent(this,audioevent));
          });  
      } 
      
      this.audioTimeLine = new AudioTimeLine(this.audioevents)

      if (this.sound) {
          this.sound.stop();
      }

      var self = this;

      self.soundended = false;

      this.sound = new Howl({            
          src: [this.audiofile],
          autoplay: false,
          // autoplay: global.isplaying,
          html5: true, // Force to HTML5 so that the audio can stream in (best for large files).
          onplay: function() {
            // self.setAudioProgress()
            // this.state.seek = 0;
            requestAnimationFrame(self.step.bind(self));
          },
          onload : function() {
              self.parent.state.seek = 0;
              self.parent.state.length = self.duration();
              self.parent.setState(self.parent.state);
            console.log(self.parent.state);
            self.parent.setProgressBar();
          },
          onend: function() {
            self.pause();
            console.log('Finished!');
          },
          onloaderror : function(id,error) {
            console.log('onloaderror!',error);
            if (nodeUtils.isMe()) alert(error)
          },
      /*     onplayerror : function(id,error) {
            console.log('onloaderror!',error);
            if (nodeUtils.isMe()) alert('onplayerror!',error)
          } */
        });

    }

    getState(position){
        return this.audioTimeLine.getState(position);
    }

    play(doplay){
        if (doplay) this.sound.play();
        else this.sound.pause();
    }

    pauseAudio(){
      if (this.isMovingProgress) return
      if (!this.isplayingaudio) return
      this.isplayingaudio = false;
      this.sound.pause();
    }

    playAudio(){
      if (this.isMovingProgress) return
      if (this.isplayingaudio) return
      this.isplayingaudio = true;
      this.sound.play();
    }

    pause(){
      global.isplaying = false;
      this.parent.state.isplaying = global.isplaying;
      this.parent.setState(this.parent.state)
      this.pauseAudio();
      this.timer = this.timer + (Date.now() - this.startplaytime)/1000
    }

    play(){
      if (this.getTime()>= this.duration()) this.timer = 0;
      this.startplaytime = Date.now(); 
      global.isplaying = true;
      if (this.shouldPlayAudio()) this.playAudio();
      this.parent.state.isplaying = global.isplaying;
      this.parent.setState(this.parent.state)

      this.playEvents();
    }

    playEvents(){
      this.audioevents.forEach(audioevent => {
        audioevent.play();
      });
    }

    shouldPlayAudio(){
      return this.getState(this.getTime()) == 'play'
    }

    setProgressValue(value){
      console.log("setProgressValue",value)
      this.seek((value == 0) ? 0 : this.duration()*value/100);
      
    }

    seek(position){
        console.log("position",position)
        if (position == null) return this.getTime() 
        
        this.timer = position;
        this.startplaytime = Date.now(); 
        this.updateParentTime();
        // return this.sound.seek(position)
    }    



    duration(){
        return this.sound.duration() + this.audioTimeLine.pauseduration;
    }

    getTime(){
      return (global.isplaying) ? this.timer + (Date.now() - this.startplaytime)/1000 : this.timer;
    }

    updateParentTime(){
      this.parent.state.seek = Math.round(this.getTime()*10)/10 || 0;
        this.parent.setState(
          this.parent.state
        )
        this.parent.setProgressBar()
    }

    startMovingProgress(){
      this.isMovingProgress = true;
      if (this.isplayingaudio) this.sound.pause();
    }

    endMovingProgress(){
      this.isMovingProgress = false;
      
      let soundpos =  this.audioTimeLine.getSoundPosition(this.getTime());
      console.log("soundpos",soundpos)
      this.sound.seek(soundpos);

      if (this.isplayingaudio && this.shouldPlayAudio()) {
        this.sound.play();
      }
      this.updateMapPos()
      requestAnimationFrame(this.step.bind(this));
    }

    updateEvents(){
      this.audioevents.forEach(audioevent => {
        audioevent.autoupdate();
      });
    }

    updateMapPos(){
      let lastpositionEvent = this.getLastPositionEvent();
      if (lastpositionEvent && !lastpositionEvent.active){
        window.thepano.setPosition(lastpositionEvent.endPos());
      }
    }

    getLastPositionEvent(){
      let reversed = Array.from(this.audioevents).reverse();
      // let test = reversed[0].isPositionEvent();
      // let test2 = reversed[0].time < this.getTime()
      return  reversed.find(audioevent => audioevent.isPositionEvent() && audioevent.time() < this.getTime());
    }

    step() {

      if (this.isMovingProgress) return;
      
        var self = this;
    
        // Get the Howl we want to manipulate.
        // var sound = self.playlist[self.index].howl;
    
        // Determine our current seek position.
        this.updateParentTime();
    
        

        this.checkIfEvent();
    
        // this.parent.setProgressBar();
        
    
        // if (global.isplaying) {
          requestAnimationFrame(self.step.bind(self));
        // }
      }

      checkIfEvent(){
        this.audioevents.forEach(audioevent => {
          audioevent.update(this.getTime())
        });  
        /* this.audioevents.forEach(element => {
          if (element.type == 'pause'){
            let delta = (element.time - this.getTime())
            if (this.currentmoveelement != element && delta < 1 && delta > 0) {
              this.currentmoveelement = element;
              window.thepano.flyTo2([element.posx,element.posy],element.zoom)
            }
            
          } else 
          if (element.type == 'move'){
            let delta = (element.time - this.getTime())
            if (this.currentmoveelement != element && delta < 1 && delta > 0) {
              this.currentmoveelement = element;
              window.thepano.flyTo2([element.posx,element.posy],element.zoom)
            }
            
          } else if (element.type == 'photo'){
            let delta = (element.time - this.getTime())
            if (this.currentphotoelement != element && delta < 1 && delta > 0) {
              this.currentphotoelement = element;
              console.log('showLightBox currentphotoelement',this.currentphotoelement)
              this.props.askshowLightBox(true,element.duration,element.url,element.titre)
              
            } else
            if (this.currentphotoelement == element && -delta > element.duration) {
              console.log('showLightBox currentphotoelement2',this.currentphotoelement)
              
              this.props.askshowLightBox(false)
              this.currentphotoelement = null;
            }
          } else if (element.type == 'path'){
            let delta = (element.time - this.getTime())
            if (this.currentpathelement != element && delta < 1 && delta > 0) {
              this.currentpathelement = element;
              window.thepano.setAutoMove({pathpoints: element.path})
            }
          }
          
        });  */
      } 

}