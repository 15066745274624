import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Container from 'react-bootstrap/Container'

import Carousel from "react-multi-carousel";

import Slider from "react-slick";



import "react-multi-carousel/lib/styles.css";

import firebase from '../Firebase';
import withFirebaseAuth from 'react-with-firebase-auth'
import 'firebase/auth';
import logo from './icons/pencil-edit-button.svg'
import { Bookmark } from '../js/Bookmark';

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 7,
    partialVisibilityGutter: 40
  },
  desktop: {
    breakpoint: { max: 3000, min: 1980 },
    items: 6,
    partialVisibilityGutter: 40
  },
  desktopsmall: {
    breakpoint: { max: 1979, min: 1024 },
    items: 5,
    partialVisibilityGutter: 40
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 4,
    partialVisibilityGutter: 30
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2,
    partialVisibilityGutter: 30
  },
};

class BookmarksComp extends Component {
  constructor(props) {
    super(props);
    this.ref =window.bref;
    this.panoname = props.panoname;
    this.unsubscribe = null;
    this.state = {
      bookmarks: []
    };
  }

  testpanoname(element) {
    return element.panoname === this.panoname;
  }

  // onCollectionUpdate = (querySnapshots) => {
  //   console.log('onCollectionUpdateonCollectionUpdate')
  //   const bookmarks = [];
    
  //   querySnapshots.forEach((doc) => {
  //     const { label,text,posx,posy,zoom,panoname,index,thumburl } = doc.data();
  //     if (doc.data().panoname == this.panoname) {
  //         bookmarks.push(
  //           {key: doc.id,
  //             bookmark: new Bookmark(doc.data())
  //           }  
  //         );
  //     }
      
  //   });
  //   // bookmarks = bookmarks.filter(function(querySnapshot) {
  //   //   return querySnapshot.doc().panoname == this.panoname;
  //   // });
  //   bookmarks.sort(function (a, b) {
  //     return a.index - b.index;
  //   });
  //   this.setState({
  //     bookmarks
  //  });
  // }

  UNSAFE_componentWillUpdate(nextProps, nextState) {

    // if (this.state.bookmarks != nextProps.bookmarks){
    //   this.setState({bookmarks: nextProps.bookmarks});
    // }

    if (this.state.bookmarks != nextProps.bookmarks){

      let bks = [];
      nextProps.bookmarks.forEach(element => {
        if(element.show) bks.push(element);
      });

      this.setState({bookmarks: nextProps.bookmarks});
    }
    
  }

  componentDidMount() {
   // this.unsubscribe = this.ref.onSnapshot(this.onCollectionUpdate);
  }

  render() {

    let bookmarck_clicked = this.props.onBookmarkClicked;
    let bookmarck_clicked_edit = this.props.bookmarck_clicked_edit;

    return (
      <Container id="Bookmarks"  className="whiteBackground clickeable bookmarks" fluid >


        
        <Carousel class= "slider-all" responsive={responsive} ssr={true} autoPlay={this.props.deviceType !== "mobile" ? true : false} swipeable={true}
                draggable={true} partialVisible={true}
                showDots={false}
                autoPlay={false} keyBoardControl={true} containerClass="carousel-container" removeArrowOnDeviceType={["tablet", "mobile"]} itemClass="carousel-item-padding-40-px">
                {this.state.bookmarks.map(bookmark =>

                  <div key={bookmark.index} className= "slider-elem">
                    
                    <img className= "slider-image" src={bookmark.getThumbUrl()} style={{ width: "auto", height: "15vh"}} onClick={() => bookmarck_clicked(bookmark)}/>

                    <p className= "slider-image-label" onClick={() => bookmarck_clicked_edit(bookmark)}>{bookmark.label}</p>
                   </div>
                )}

        </Carousel>            

      </Container>
    );
  }
}

export default BookmarksComp;
