import app from 'firebase/compat/app';
import 'firebase/auth';
import 'firebase/database';
import { getFirestore } from "firebase/firestore";

// var FCM = require('fcm-node');

const config = {
apiKey: "AIzaSyAQDd5T2sWBzV_CQsSmlil_YO4PBPmp968",
    authDomain: "newgigascopejs.firebaseapp.com",
    databaseURL: "https://newgigascopejs.firebaseio.com",
    projectId: "newgigascopejs",
    storageBucket: "newgigascopejs.appspot.com",
    messagingSenderId: "229995449109",
    appId: "1:229995449109:web:893b34b67545f69c6f371c",
    measurementId: "G-8154K80V21"
};

class Firebase {
  constructor() {
    app.initializeApp(config);

    this.auth = app.auth();
    this.db = app.database();
    this.provider = new this.auth.GoogleAuthProvider();
    console.log('!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!')
  }
  

  // *** Auth API ***

  

doSignInWithGoogle ()  { 
   this.auth.signInWithPopup(this.provider).then(function(result) {
      var token = result.credential.accessToken;
      var user = result.user;
		
      console.log(token)
      console.log(user)
   }).catch(function(error) {
      var errorCode = error.code;
      var errorMessage = error.message;
		
      console.log(error.code)
      console.log(error.message)
   });
  }
  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignOut = () => this.auth.signOut();

  doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

  doPasswordUpdate = password =>
    this.auth.currentUser.updatePassword(password);

  // *** User API ***

  user = uid => this.db.ref(`users/${uid}`);

  users = () => this.db.ref('users');





  buildCommonMessage = () => {
    return {
      'message': {
        'topic': 'message',
        'notification': {
          'title': 'FCM Notification',
          'body': 'Notification from FCM'
        }
      }
    };
  }
}

export default Firebase;


