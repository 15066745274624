import React, { Component } from 'react';
import { Link } from 'react-router-dom';
// import './App.css';
import firebase from '../Firebase';
import withFirebaseAuth from 'react-with-firebase-auth'
import 'firebase/auth';
import HeaderComp from './HeaderComp';
/* import { browserHistory } from 'react-router'; */

import { doc, onSnapshot,query } from "firebase/firestore";

import nodeUtils from '../js/nodeUtils.js'

// import nodeUtils from '../js/nodeUtils.js'



// const dbRef = firebase.database().ref("internalmessages")
// console.log(dbRef);
// dbRef.on("value", snap => {
//   console.log(snap); // this key will output users
//   console.log(snap.key); // this method will return full user
// });

class PhotosClient extends Component {
  constructor(props) {
    super(props);
    this.ref = window.ref;
    this.unsubscribe = null;
    this.state = {
      gigaphotos: []
    };

    this.client = this.props.match.params.client;
    
  }

  onCollectionUpdate = (querySnapshot) => {
    const gigaphotos = [];
    querySnapshot.forEach((doc) => {
      const { titre,
        panoname,
        client,
        theme,
        info,
        width,
        height,
        numtiles,
        witilesize,
        url,
        uiGroups } = doc.data();
      if (client == this.client) {
        gigaphotos.push({
          key: doc.id,
          doc, // DocumentSnapshot
          titre,
          panoname,
          theme,
          info,
          width,
          height,
          numtiles,
          witilesize,
          url,
          uiGroups
        });
      }
      
    });
    this.setState({
      gigaphotos
   });

   
  }

  componentDidMount() {

    const q = query(global.ref);
  const unsubscribe = onSnapshot(q, (querySnapshot) => {
  const cities = [];
  this.onCollectionUpdate(querySnapshot);});

  }




  render() {
    return (
      <div className="container">
        {/* <HeaderComp></HeaderComp> */}
        <div className="panel panel-default">
          <div className="panel-heading">
            <h3 className="panel-title">
              Gigaphotos
            </h3>
          </div>
          <div className="panel-body">
            
            <table className="table table-stripe">
              <thead>
                <tr>
                  {/* <th>Panoname</th> */}
                  <th>Titre</th>
                  {/* <th>Theme</th>
                  <th>Thumb</th> */}
                </tr>
              </thead>
              <tbody>
                {this.state.gigaphotos.map(gigaphoto =>
                  <tr key={gigaphoto.key}>
                    {/* <td><Link to={`/edit/${gigaphoto.key}`}>{gigaphoto.panoname}</Link></td> */}
                    <td><Link to={`/photo/${gigaphoto.panoname}`}>{gigaphoto.titre}</Link></td>
                    <td><img src={gigaphoto.url + gigaphoto.panoname + "/thumb.jpg"} style={{  height: "128px" }} /> </td>
                    {/* <td>{gigaphoto.theme}</td> */}
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

export default PhotosClient;
