import React from 'react';
import { Link } from 'react-router-dom';

import SignOutButton from '../SignOut';
import * as ROUTES from '../../constants/routes';

import { AuthUserContext } from '../Session';
import firebase from '../Firebase';
import app from 'firebase/compat/app';

class Navigation extends React.Component {
  constructor (props) {
      super(props);
      this.auth = app.auth();
      
  }

  // signout() {
  //   console.log('sign')
  // }

  signout() {
    console.log('sign');
    app.auth().signOut();
    // firebase.doSignOut()
  }

  render () {
      return (
        <div>
        {/* <AuthUserContext.Consumer>
          {authUser =>
            authUser ? <NavigationAuth /> : <NavigationNonAuth />
          }
        </AuthUserContext.Consumer> */}
        {/* {global.islogged ? <NavigationNonAuth /> : <NavigationAuth />} */}
        {/* {global.islogged ? <NavigationAuth /> : <NavigationNonAuth />} */}
        {!global.islogged ? (
        <ul>
          <li>
            <Link to={ROUTES.LANDING}>Landing</Link>
          </li>
          <li>
            <Link to={ROUTES.SIGN_IN}>Sign In</Link>
          </li>
        </ul>
        ) : (
          <ul>
            <li>
              <Link to={ROUTES.LANDING}>Landing</Link>
            </li>
            <li>
              <Link to={ROUTES.HOME}>Home</Link>
            </li>
            <li>
              <Link to={ROUTES.ACCOUNT}>Account</Link>
            </li>
            <li>
              <Link to={ROUTES.ADMIN}>Admin</Link>
            </li>
            <li>
              {/* <SignOutButton /> */}
              <button type="button" onClick={this.signout()}>
                Sign Out
              </button>
            </li>
          </ul>
        )}
      </div>
      );
  }
  }



// const Navigation = () => (
  
// );

const NavigationAuth = () => (
  <ul>
    <li>
      <Link to={ROUTES.LANDING}>Landing</Link>
    </li>
    <li>
      <Link to={ROUTES.HOME}>Home</Link>
    </li>
    <li>
      <Link to={ROUTES.ACCOUNT}>Account</Link>
    </li>
    <li>
      <Link to={ROUTES.ADMIN}>Admin</Link>
    </li>
    <li>
      {/* <SignOutButton /> */}
      <button type="button" onClick={this.signout}>
        Sign Out
      </button>
    </li>
  </ul>
);



const NavigationNonAuth = () => (
  <ul>
    <li>
      <Link to={ROUTES.LANDING}>Landing</Link>
    </li>
    <li>
      <Link to={ROUTES.SIGN_IN}>Sign In</Link>
    </li>
  </ul>
);

export default Navigation;
