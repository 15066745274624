import React, { Component } from 'react';
import Container from 'react-bootstrap/Container'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import parse from 'html-react-parser';
import Player from './PlayerComp';

import PinchZoomPan from "react-responsive-pinch-zoom-pan";
import nodeUtils from '../js/nodeUtils.js'
// import nodeUtils from '../js/nodeUtils.js'

// var Jimp = require('jimp');

const screenchotsize = 256;

class GetThumbComp extends Component {
  constructor(props) {
    super(props);
        
     this.state = {
       shown:true,
       lightboxtitre: props.lightboxtitre,
       lightboxurl: props.lightboxurl
    }; 
  }
  
  componentDidMount() {
  // if (nodeUtils.ReadString("InfoBig") == "1") this.toggleBig();

  // nodeUtils.setOpacity('lightbox',false)
    window.loadPanoThumb( { panodata :this.props.annotationtoedit.pano.panodata},this.props.annotationtoedit.getPosition());
    window.thepanoThumb.setPosition(this.props.annotationtoedit.getPosition())
    // if (this.props.getThumbCenter)
  }

  componentWillUpdate(nextProps) {


  }

  componentDidUpdate(){
        
  }

  getHtml(text) {
    return {__html: text};
  }

  thumbisSet(){
    this.props.AnnotationThumbHasBeenSet();
    // var panoname = window.panoname;

    // var screenshot = window.getThumbScreenshot();
    // Jimp.read(screenshot)
    // .then(image => {
    //     image.cover( screenchotsize, screenchotsize ); 

    //     image.quality(60);

    //     image.getBase64(Jimp.MIME_JPEG,function(err,data){
    //       this.props.ThumbHasBeenSet(data);                        
    //     });

    // })
    // .catch(err => {
    //     console.error('oops, something went wrong!', err);
    //     // Handle an exception.
    // }); 
  }


  render() {

    const {lightboxtitre,lightboxurl,isplaying} = this.state;
    // console.log(lightboxtitre)

    return (
      
      
      // (lightboxurl != "") &&

      <div id='TargetThumb' className="infront full d-flex flex-column align-items-center ">
          < h1 className="targetThumbTitre">
            Ajuster la miniature
          </h1>
        
        <div className="targetThumb" >
          <div id="htmlTargetThumb"  className="map" style={{ width: "100%", height: "100%" }}></div>
        </div>
              
        <button className="targetThumbButton"  onClick={() => this.thumbisSet()} >
                Sélectionner
        </button>
            
      </div>
      
    );
  }
}

export default GetThumbComp;
