import React, { Component } from 'react';

import nodeUtils from '../js/nodeUtils.js';

// import Button from '@material-ui/core/Button';
// import TextField from '@material-ui/core/TextField';
// import Dialog from '@material-ui/core/Dialog';
// import DialogActions from '@material-ui/core/DialogActions';
// import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
// import DialogTitle from '@material-ui/core/DialogTitle';

class AddPopupDialog extends Component {
    constructor(props) {
        super(props);
        // this.text = "";
        // this.name = "";

        if (this.props.popuptoedit && !this.props.popuptoedit.newTexttoAdd){
            this.state = { 
                isdialogok: true,
                name:this.props.popuptoedit.getName(),
                text: this.props.popuptoedit.getText(),
                showdelete:true     
            }
        } else {
            this.state = { 
                isdialogok: false,
                name:nodeUtils.getLastPopupAuthor(),
                text: "",
                showdelete:false        
            }
        }
        
        
    }; 
    

  cancel = () => {
    this.props.showAddPopupMessage(false);
  }

  confirm = () => {
    // if (this.text == "" || this.name == "")
    this.props.PopupMessageResult(this.state.name,this.state.text,this.props.popuptoedit);
  }

  delete = () => {
    // if (this.text == "" || this.name == "")
    this.props.PopupMessageResult("delete","",this.props.popuptoedit);
  }

  setTextValue = (event) => {
    console.log(event.target.id,event.target.value)
    switch(event.target.id){
        case 'text':
            this.state.text = event.target.value;
            break;
        case 'name':
            this.state.name = event.target.value;
            break;
    }
    this.state.isdialogok = this.state.text != "" && this.state.name != "";
    this.setState(this.state);
  }

  render() {

    let showAddPopupMessage = this.props.showAddPopupMessage
    const {name,text,showdelete,isdialogok} = this.state;

    return (
        <div>
    
          {/* <Dialog open={true} onClose={this.cancel} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Ajout d'un popup</DialogTitle>
            <DialogContent>

              <TextField
                onChange={this.setTextValue}
                autoFocus
                margin="dense"
                id="text"
                label="Texte"
                type="text"
                fullWidth
                multiline
                value={text}
              />
            <TextField
                onChange={this.setTextValue}
                margin="dense"
                id="name"
                label="Votre nom"
                type="text"
                fullWidth
                value={name}
              />
            </DialogContent>

            <DialogActions>
              <Button onClick={this.cancel} color="primary">
                Annuler
              </Button>
              {showdelete && 
                <Button onClick={this.delete} color="primary">
                    Supprimer
                </Button>
              }
              {showdelete && 
                <Button disabled={!isdialogok} onClick={this.confirm} color="primary">
                    Modifier
                </Button>
              }
              {!showdelete && 
                <Button disabled={!isdialogok} onClick={this.confirm} color="primary">
                    Ajouter
                </Button>
              }

            </DialogActions>
          </Dialog>*/}
        </div> 
      );
  }
}

export default AddPopupDialog;

