import * as THREE from 'three'
import { memo, useRef, forwardRef } from 'react'
import { Canvas, useThree,useFrame } from '@react-three/fiber'
import { Backdrop,OrbitControls,Stage,Clone,Grid, Center, AccumulativeShadows, RandomizedLight, Environment, useGLTF, CameraControls } from '@react-three/drei'
//import { useControls, button, buttonGroup, folder } from 'leva'
import {Loader3DTilesR3FAsset}from './Loader3DTilesR3FAsset.tsx';
import {	Group,Euler,DoubleSide} from 'three';

import { OGC3DTile } from "@jdultra/threedtiles/src/tileset/OGC3DTile";
import { TileLoader } from "@jdultra/threedtiles/src/tileset/TileLoader";

import {
	DebugTilesRenderer,
	NONE,
	SCREEN_ERROR,
	GEOMETRIC_ERROR,
	DISTANCE,
	DEPTH,
	RELATIVE_DEPTH,
	IS_LEAF,
	RANDOM_COLOR,
} from '3d-tiles-renderer';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js';

const { DEG2RAD } = THREE.MathUtils

const deg2rad = degrees => degrees * (Math.PI / 180);


export function EmericTile() {

  const threeState = useThree();

  const meshRef = useRef()
  const cameraControlsRef = useRef()



  const tileLoader = new TileLoader( 100, mesh => {
    //// Insert code to be called on every newly decoded mesh:
    mesh.material.wireframe = false;
    mesh.material.side = THREE.DoubleSide;
    mesh.material.metalness = 0.0
}, points=>{
    points.material.size = Math.min(1.0,0.5*Math.sqrt(points.geometricError));
    points.material.sizeAttenuation = true;
});

const ogc3DTile = new OGC3DTile({
  url: "https://data2.gigascope.net/3d/leo/tileset.json",
  renderer: threeState.gl, // the Threejs renderer
  geometricErrorMultiplier: 1, // a detail multiplier [0 - infinity] (optional)
  loadOutsideView: false, // load low detail meshes outside the view for better shadows and basic data present when the camera pans (optional)
  tileLoader: tileLoader, // the loader that caches tiles (optional)
  static: true, // set this to true if you'll be moving the model often, for example in a loop (optional)
  centerModel:true, // center the model on the origin (optional)
   
});  

// useFrame(({ gl, camera }, dt) => {
//   runtime.update(dt, gl, camera);
// });

  // useThree(({camera}) => {
  //   camera.position.y = 0.2;
  //   // camera.rotation.set(deg2rad(60), 0, 0);
  //   // camera.rotation = ;
  //   // camera.lookAt(0, 100, 0);
  // });

  

  return (
    // <group {...props} dispose={runtime.dispose}>
      <primitive object={ogc3DTile} 
      position = {[0,0.343,0]}
      // scale={window.modelresize }
      // rotation={window.modelrotation}
      />
    // </group>
  )

}
