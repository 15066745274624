import React from 'react';
import ReactDOM from 'react-dom/client';
import { render } from 'react-dom';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
// import '../node_modules/react-reflex/styles.css'
import './index.css';
import * as serviceWorker from './serviceWorker';
import Home from './components/HomeComp';
import Test from './pages/dtwin/test';
import Gigaphoto from './components/gigaphoto';
import GigascopephotoSplitted from './components/gigascopephotosplitted';
// import GigascopephotoSplittedOrleans from './components/gigascopephotosplittedorleans';
// import GigascopephotoSplittedOrleansTriple from './components/gigascopephotosplittedorleanstriple';
import PublicMap from "./components/PublicMap";
import Edit from './components/Edit';
import create from './components/Create';
import Show from './components/Show';
import Photos from './components/Photos';
// import Tiled from './components/TiledFolder/src/Tiled';
import PhotosPublic from './components/PhotosPublic';
import PhotosClient from './components/PhotosClient';
import PhotosEvent from './components/PhotosEvent';
import TestTiles from './components/TestTiles';
import TestTiles2 from './components/TestTiles2';
import TestTilesVR from './components/TestTilesVR';

import Tiles from './components/Tiles';

import firebase from './Firebase';
import RtcComp from './components/RtcComp';

import BackOffice from './components/BackOfficeComp';
import Vr from './components/VrComp';
import Vr2 from './components/Vr2Comp';
import Arskan from './components/ArskanComp';
import Arskan2 from './components/Arskan2comp';
import VideoComp from './components/VideoComp';
// import 'bootstrap/dist/css/bootstrap.min.css';
import NiraComp from './components/NiraComp';
import Navigation from './components/Navigation';

import MySplatComp from './components/MySplat';

import MessageListener from './components/MessageListener';

import * as ROUTES from './constants/routes';

import { getDatabase, ref as sRef , onValue } from "firebase/database";
import { collection, addDoc } from "firebase/firestore";

import nodeUtils from './js/nodeUtils.js'

// import LandingPage from './components/Landing';
import SignUpPage from './components/SignUp';
import SignInPage from './components/SignIn';
import PasswordForgetPage from './components/PasswordForget';

import { BrowseEvent } from './js/browsinghistory.js';

import { library } from '@fortawesome/fontawesome-svg-core'
import { faHome,faStepBackward,faCommentSlash,faComments,faCommentMedical,
        faUndoAlt,faPause,faPlay,faVolumeUp,faInfo,faTimes,faSearchMinus,faSearchPlus,
        faWindowClose,faChevronLeft,faChevronRight,faList,faCompress,faExpand, faCoffee ,faUserLock,faLockOpen} from '@fortawesome/free-solid-svg-icons'

import SignInScreen from './components/SignInScreen';
// import AuthenticationPage from './components/AuthenticationPage';

import { getAuth, connectAuthEmulator } from 'firebase/auth';
import { doc, getFirestore } from 'firebase/firestore';
import {
  FirebaseAppProvider,
  FirestoreProvider,
  useFirestoreDocData,
  useFirestore,
  useFirebaseApp,
  useSigninCheck,
  AuthProvider
} from 'reactfire';

library.add(faUserLock,faLockOpen,faHome,faComments,faCommentMedical,faCommentSlash,faStepBackward,faUndoAlt,faPause,faPlay,faVolumeUp,faInfo,faTimes,faSearchMinus,faSearchPlus,faWindowClose,faChevronLeft,faChevronRight,faList,faCompress,faExpand, faCoffee)



console.log("*****************************************************************************************************************************************")

const cors=require("cors");
const corsOptions ={
   origin:'*', 
   credentials:true,            //access-control-allow-credentials:true
   optionSuccessStatus:200,
}



const events = require('events');

global.ol = require("ol");
global.ol.control = require("ol/control");
global.ol.layer = require("ol/layer");
global.ol.layer.Base = require("ol/layer/Base");
global.ol.interaction = require("ol/interaction");
global.ol.source = require("ol/source");
global.ol.style = require("ol/style");
global.ol.coordinate = require("ol/coordinate");
global.ol.geom = require("ol/geom");
global.ol.sphere = require("ol/sphere");
global.ol.geom.GeometryCollection = require("ol/geom/GeometryCollection");


require('./js/initgiga.js');
require('./js/giga_imports.js');
require('./js/utils.js');
require('./js/gigascope.js');


var ipapi = require('ipapi.co');


var url = require('url');

function fullUrl(req) {
  return url.format({
    protocol: req.protocol,
    host: req.get('host'),
    pathname: req.originalUrl
  });
}

global.myEmitter = new events.EventEmitter();

window.isNode = true

nodeUtils.setLocalId();

const root = ReactDOM.createRoot(document.getElementById("root"));

console.log(window.location.href); 

global.testurl =window.location.href;

const firebaseConfig = {
  apiKey: "AIzaSyAQDd5T2sWBzV_CQsSmlil_YO4PBPmp968",
  authDomain: "newgigascopejs.firebaseapp.com",
  databaseURL: "https://newgigascopejs.firebaseio.com",
  projectId: "newgigascopejs",
  storageBucket: "newgigascopejs.appspot.com",
  messagingSenderId: "229995449109",
  appId: "1:229995449109:web:893b34b67545f69c6f371c",
  measurementId: "G-8154K80V21"
};

function AppAuthOk() {
  
  return(
    <div>
       <Router>
          <div style={{ width: "100%", height: "100%" }}>
              <MessageListener />
              {/* <Navigation /> */}
              {/* <hr /> */}
              <Route exact path={ROUTES.LANDING} component={() => {
    window.location.href = 'http://dtwin.io';
    return null;
}} />
              <Route path={ROUTES.SIGN_UP} component={SignUpPage} />
              <Route path={ROUTES.SIGN_IN} component={SignInPage} />
              <Route path={ROUTES.PASSWORD_FORGET} component={PasswordForgetPage} />
              {/* <Route path={ROUTES.HOME} component={HomePage} /> */}
              {/* <Route path={ROUTES.ACCOUNT} component={AccountPage} />
              <Route path={ROUTES.ADMIN} component={AdminPage} /> */}
            <Route path='/ho' component={Home} />  
            <Route path='/test' component={Test} />  
            <Route path='/splat/:splat' component={MySplatComp} />  
              {/* <Route exact path='/' component={Photos} />  */}
              <Route path='/photos' component={Photos} />   
              {/* <Route exact path='/:opt' component={Photos} />    */}
              {/* <Route exact path='/' component={Bookmarks} />           */}
              <Route path='/create' component={create} />
              <Route path='/edit/:id' component={Edit} />
              <Route path='/show/:id' component={Show} />
              {/* <Route path='/editbookmark/:id' component={Edit} /> */}
              {/* <Route path='/photo/:panoname/:mode' component={Gigascopephoto} /> */}
              <Route path='/photo/:panoname' component={Gigaphoto} />
              <Route path='/jeu/:panoname' component={GigascopephotoSplitted} />
              <Route path='/split/:panoname' component={GigascopephotoSplitted} />
              {/* <Route path='/orleans/:version' component={GigascopephotoSplittedOrleans} /> */}
              <Route path='/us/all' component={PhotosPublic} />
              <Route path='/us/client/:client' component={PhotosClient} />
              <Route path='/collection/:event' component={PhotosEvent} />
              <Route path='/us/event/:event' component={PhotosEvent} />
              <Route path='/demo/:panoname' component={Gigaphoto} />
              <Route path='/client/:panoname' component={Gigaphoto} />
              <Route path='/master/:panoname' component={Gigaphoto} />
              <Route path='/TestTiles/:name' component={TestTiles} />
              <Route path='/3d2/:name' component={TestTiles} />
              <Route path='/3dloc/:name' component={TestTiles} />
              <Route path='/modele/:name' component={TestTiles} />
              {/* <Route path='/modele/:name/:mode' component={TestTiles} /> */}
              <Route path='/modeleloc/:name' component={TestTiles} />
              {/* <Route path='/modeleloc/:name/:mode' component={TestTiles} /> */}
              <Route path='/3dmaster/:name' component={TestTiles} />
              <Route path='/3dclient/:name' component={TestTiles} />
              <Route path='/3dmasterloc/:name' component={TestTiles} />
              <Route path='/3dclientloc/:name' component={TestTiles} />
              <Route path='/3dstereo/:name' component={TestTiles} />
              {/* <Route path='/3dstereoclient/:name' component={TestTiles} /> */}
              <Route path='/TestTiles2/:name' component={TestTiles2} />
              <Route path='/rtc' component={RtcComp} />
              <Route path='/control' component={BackOffice} />
              <Route path='/vr3' component={Vr} />
              <Route path='/vr2' component={Vr2} />
              <Route path='/vr/:name' component={TestTilesVR} />
              <Route path='/arksan/:modelname' component={Arskan} />
              <Route path='/3d/:modelname' component={Arskan2} />
              <Route path='/video/:videoname' component={VideoComp} />
              <Route path='/tiles/:name' component={Tiles} />
              <Route path='/tilestest/:name/:error' component={Tiles} />
              <Route path='/model/:modelname' component={NiraComp} />
   {/*   <Route path='/signin' component={SignInPage} />*/}
    
      {/* <Route path='/tiled' component={Tiled} /> */}
      
          </div>
        </Router>
    </div>
  );
}

function AppAuthNotOk() {
  return(
    <div>
      not ok
      {/* <AuthenticationPage /> */}
    </div>
  );
}

function AppAll(){
  const { status, data: signInCheckResult } = useSigninCheck();

  console.log("appall");

  if (status === 'loading') {
    return <span>loading...</span>;
  }
return <AppAuthOk />;
  // if (signInCheckResult.signedIn === true || true) {
  if (signInCheckResult.signedIn === true ) {
    
  } else {
    return <AppAuthNotOk />;
  }
}

function App() {

  const app = useFirebaseApp();
  const auth = getAuth(app);

  const firestoreInstance = getFirestore(app);

 
  return (
    <AuthProvider sdk={auth}>
      <FirestoreProvider sdk={firestoreInstance}>
        <AppAll />       
      </FirestoreProvider>
    </AuthProvider>
  );
}



render(  
  <FirebaseAppProvider firebaseConfig={firebaseConfig}>    
    <App />    
  </FirebaseAppProvider>,
  document.getElementById('root')
);


