import firebase from 'firebase/compat/app';
import { initializeApp } from 'firebase/app';
//import auth from 'firebase/auth';
import 'firebase/compat/firestore';
import 'firebase/auth';
import { getFirestore } from "firebase/firestore";
import { collection, addDoc } from "firebase/firestore"; 
import { getAuth, onAuthStateChanged } from "firebase/auth";

import firestore from 'firebase/firestore';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';

const settings = {timestampsInSnapshots: true};

const firebaseConfig = {
    apiKey: "AIzaSyAQDd5T2sWBzV_CQsSmlil_YO4PBPmp968",
    authDomain: "newgigascopejs.firebaseapp.com",
    databaseURL: "https://newgigascopejs.firebaseio.com",
    projectId: "newgigascopejs",
    storageBucket: "newgigascopejs.appspot.com",
    messagingSenderId: "229995449109",
    appId: "1:229995449109:web:893b34b67545f69c6f371c",
    measurementId: "G-8154K80V21"
  };
  
  
  const firebaseApp = initializeApp(firebaseConfig);

console.log("***************************************");
console.log("*************ppppppppppppppppppppppppppppppppppppppppppppppppppppppppppp*********************");

// firebase.firestore().settings(settings);

global.db = getFirestore(firebaseApp);

window.ref = collection(global.db,"gigaphotos");
window.bref =collection(global.db,"bookmarks");
window.bhref =collection(global.db,"browsinghistory");
global.imref =collection(global.db,"internalmessages");
window.userHist =collection(global.db,"usersHistory");
window.popupsref =collection(global.db,"popups");
window.annotationsref =collection(global.db,"annotations");

window.aliases =collection(global.db,"aliases");
window.events =collection(global.db,"event");

window.messagingref =collection(global.db,"messaging");

window.mail =collection(global.db,"mail");

const auth = getAuth();
onAuthStateChanged(auth, (user) => {
  if (user) {
    // User is signed in, see docs for a list of available properties
    // https://firebase.google.com/docs/reference/js/auth.user
    const uid = user.uid;
    global.user = user;
    global.islogged = true;
    // ...
  } else {
    // User is signed out
    // ...
    global.user = null;
    global.islogged = false;
  }
});



global.user = auth.currentUser;

export default firebase;