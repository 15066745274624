import React, { Component } from 'react';
import Container from 'react-bootstrap/Container'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import parse from 'html-react-parser';

import nodeUtils from '../js/nodeUtils.js'

// import nodeUtils from '../js/nodeUtils.js'

class GigaphotoInfo extends Component {
  constructor(props) {
    super(props);
    this.panorama = props.panorama;

    this.size = "1em";
    
    
     this.state = {
       shown:true,
       panorama: props.panorama,       
      big:false
    }; 
  }
  
  componentDidMount() {
  // if (nodeUtils.ReadString("InfoBig") == "1") this.toggleBig();
  }

  componentWillUpdate(nextProps, nextState) {
     if (nextProps.showPanoInfo != this.state.shown || nextProps.panorama != this.state.panorama ){

      
    
      this.state.panorama =nextProps.panorama;
      this.state.shown = nextProps.showPanoInfo
      this.setState(
        this.state
      )

      // this.setHtmlText();
      

    }
   /*  if (nextProps.bookmark) nodeUtils.showBookmarkInfo();  */

  }

  componentDidUpdate(){
    if (this.state.shown  ){
      this.setHtmlText();
    }
    // this.setHtmlText();
    // if (nodeUtils.ReadString("InfoBig") == "1") this.setBig();
    // else this.setSmall();
    
  }

  getHtml(text) {
    return {__html: text};
  }

  close(){
    // nodeUtils.toggleVisibleBookmarkInfo();
/*     this.state.bookmark = "null";
    this.state.shown = false;
    
    this.setState(
      this.state
    ) */
  }

  setHtmlText(){
    if(this.state.panorama){
      if (nodeUtils.ReadString("InfoBig") == "1") this.setBig();
    else this.setSmall();
    nodeUtils.setContent("gigaphotoinfotext",nodeUtils.getHtmlText(this.state.panorama.info,"1.25em")) 
    }
    
  }

  setBig(){
    if (this.state.shown && this.state.panorama){
         
   
    nodeUtils.setVisible("gigaphotosinfoplus",false);
    nodeUtils.setVisible("gigaphotoinfomoins",true);
   /*  let testtext = "<div style={{ fontSize: \"2em\" }} >"+this.state.bookmark.text + "</ div>" ;*/
    nodeUtils.setContent("gigaphotoinfotext",nodeUtils.getHtmlText(this.state.panorama.info,"1.25em")) 
    
    }
  }
  setSmall(){
    if (this.state.shown && this.state.panorama){
    
   
    nodeUtils.setVisible("gigaphotosinfoplus",true);
    nodeUtils.setVisible("gigaphotoinfomoins",false);
    nodeUtils.setContent("gigaphotoinfotext",nodeUtils.getHtmlText(this.state.panorama.info,"1.25em")) 
    }

  }
  toggleBig(){
   
    nodeUtils.SaveString("InfoBig","1");
    this.setBig();
    
    
  }
  toggleSmall(){

    nodeUtils.SaveString("InfoBig","0");
    this.setSmall();
    
  }

  
  getSize(){
    return "3em"
  }

  commanderposter(){
    alert("Pour commander un poster (de l'ensemble ou du cadrage que vous choisissez) merci de me contacter sur cyrilpreiss@gigascope.net")
}

  render() {

    

  let setShowPanoInfo = this.props.setShowPanoInfo

  const {panorama} = this.state;

  // console.log(panorama);
    return (
      
      (this.state.shown && this.state.panorama)?
      <div  id="gigascopephotologin" className="infront full d-flex justify-content-center align-items-center">
            <div className="panoinfomessagebox darkmessageBox">

            <div className="d-flex justify-content-between">

                < div className="d-flex">
                <div id="gigaphotosinfoplus" > <FontAwesomeIcon icon="search-plus"  onClick={() => this.toggleBig()}/></div>
                <div id="gigaphotoinfomoins" ><FontAwesomeIcon icon="search-minus"  onClick={() => this.toggleSmall()}/></div>

                </div>

                <div>
                <div className="d-flex">

                <div ><FontAwesomeIcon className="closebicon2" icon="times"  onClick={() => setShowPanoInfo(false)}/></div>
                </div>
                    <div ></div>
                </div>

            </div>
            <div className="d-flex flex-column align-items-center ">



                <h4 className="panoinfomessagetitre">{this.state.panorama.titre}</h4>

                <div >
                     <img  className="panoinfomessageimg" src={this.state.panorama.url + this.state.panorama.panoname + "/thumb.jpg"} />
                </div>
               
                <button onClick={() => setShowPanoInfo(false)}>Commencer la visite</button>

                
                {/* {panorama.panoname == "montblanc2" && <button onClick={() => this.commanderposter()}>Commander un poster</button>} */}

                <div id="gigaphotoinfotext" className="panoinfomessagetext">

                
                </div>


                </div>
            </div>
            
        </div>:
        <div></div>
/*       <div className="bookmarkInfoTitre">{this.state.bookmark.label}</div>:           
      <div className="bookmarkInfoTitre">fail</div> */
      
    );
  }
}

export default GigaphotoInfo;
