import React, { Component }  from 'react';
import {Helmet} from "react-helmet";

class HeaderComp extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return(
            <div>
                <Helmet>
                    <script defer src="https://code.getmdl.io/1.1.3/material.min.js"></script>
                </Helmet>
                 <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:regular,bold,italic,thin,light,bolditalic,black,medium&amp;lang=en"/>
  <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons"/>
  <link rel="stylesheet" href="https://code.getmdl.io/1.1.3/material.orange-indigo.min.css"/>
  

  


  
    <div className="mdl-cell mdl-cell--12-col mdl-cell--12-col-tablet mdl-grid">
      <div className="mdl-layout__header-row mdl-cell mdl-cell--12-col mdl-cell--12-col-tablet mdl-cell--12-col-desktop">
        <h3><i className="material-icons">chat_bubble_outline</i> Friendly Chat</h3>
      </div>
      <div id="user-container">
          
        <div hidden id="user-pic"></div>
        <div hidden id="user-name"></div>
        <button hidden id="sign-out" className="mdl-button mdl-js-button mdl-js-ripple-effect mdl-color-text--white">
          Sign-out
        </button>
        <button hidden id="sign-in" className="mdl-button mdl-js-button mdl-js-ripple-effect mdl-color-text--white">
          <i className="material-icons">account_circle</i>Sign-in with Google
        </button>
      </div>
    </div>
    
    
            </div>
            
              
  
           
          );
    }
}

export default HeaderComp;