import React, { Component } from 'react';
import Container from 'react-bootstrap/Container'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FullScreen } from 'ol/control';
import Rounded from "react-rounded-image";
import app from 'firebase/compat/app';
import AskIdMessage from './AskIdMessageComp';

/* import { faCoffee } from '@fortawesome/free-solid-svg-icons'
 */
import nodeUtils from '../js/nodeUtils.js'
// import nodeUtils from '../js/nodeUtils.js'



class PanotopComp extends Component {
  constructor(props) {
    super(props);
    this.panorama = props.panorama;

    this.toggleFullscreen = this.toggleFullscreen.bind(this);

    this.clicks = 0;
    
     this.state = {
      panorama: props.panorama,
      panoname: '',
      titre:'',
      showlogin:false,
      fullScreen: false,
      showbookmarksbutton: true,
      showinfobutton: true,
      issplit: false,
      showfullscreeniffullscreen: true,
      showfullscreenicon : true,
      titlebackgroundsmall : false
    }; 

    
  }
  
  componentDidMount() {
   
  }

  componentWillUpdate(nextProps, nextState) {
     if (nextProps.panorama != this.state.panorama){
    
      this.state.panorama =nextProps.panorama;
      this.state.panoname =nextProps.panorama.panoname;
      this.state.titre =nextProps.panorama.titre;
      if (nextProps.customtitle != null)  this.state.titre= nextProps.customtitle

      if (nextProps.showbookmarksbutton != null)  this.state.showbookmarksbutton= nextProps.showbookmarksbutton
      if (nextProps.showinfobutton != null)  this.state.showinfobutton= nextProps.showinfobutton
      if (nextProps.issplit != null)  this.state.issplit= nextProps.issplit
      if (nextProps.showfullscreeniffullscreen != null)  this.state.showfullscreeniffullscreen= nextProps.showfullscreeniffullscreen
      if (nextProps.showfullscreenicon != null)  this.state.showfullscreenicon= nextProps.showfullscreenicon
     if (nextProps.titlebackgroundsmall != null)  this.state.titlebackgroundsmall= nextProps.titlebackgroundsmall

      // console.log("props.panorama",nextProps.showbookmarksbutton);

      this.setState(
        this.state
      )
    }
 
  }

  toggleFullscreen(){
    if (this.state.issplit){
      nodeUtils.toggleSplitFullScreen();
    } else {
      console.log("fullScreen1",this.state.fullScreen)
      nodeUtils.togglePanoFullScreen();
      this.state.fullScreen=  !this.state.fullScreen;
      this.setState(this.state)
      console.log("fullScreen2",this.state.fullScreen)
    }
    
  }


  fullscreenIcon() {
    if (!document.fullscreen) {
      return <FontAwesomeIcon icon="expand"  onClick={() => this.toggleFullscreen}/>;
    }
    
    return <FontAwesomeIcon icon="compress"  onClick={() => this.toggleFullscreen}/>;
  }

  toggleBookmarks(){
    window.bookmarksVisible = !window.bookmarksVisible;
    nodeUtils.toggleVisibleBookmarks()
  }

  signout() {
    
 /*    nodeUtils.uploadUserHistory() */

  if (nodeUtils.isMe())  app.auth().signOut();
    // firebase.doSignOut()
  }

  getAccuntIcon(){
    return (nodeUtils.isAuthenticated()) ? 
    <div className="photoaccount" onClick={() => this.signout()}>
              {/* <Rounded  image={global.user.photoURL} imageWidth="24"
                        imageHeight="24"
                        roundedSize="1" /> */}
            </div>
            : <div></div>
  }

  titleclicked(){
    this.clicks++;
    if (this.clicks > 5) this.doshowLogin(true)
  }

  doshowLogin(show){
    this.state.showlogin = show;
    this.setState(this.state);
  }

  render() {

    let setShowPanoInfo = this.props.setShowPanoInfo
    let popupsshown = this.props.popupsshown
    let showpopupiconbar = this.props.showpopupiconbar
    let showPopups = this.props.showPopups;
    let addOnePopup = this.props.addOnePopup;
    
    const {titlebackgroundsmall,fullScreen,showlogin,showbookmarksbutton,showinfobutton,panoname,titre,showfullscreeniffullscreen,showfullscreenicon } = this.state;

    let titreclassName = 'panotop clickeable';
    if (fullScreen || titlebackgroundsmall) {
      titreclassName += ' panotopbackhidden';
    }

    let titretexteclassName = 'panoInfoTitre';
    if (titlebackgroundsmall) {
      titretexteclassName += ' blackBackground padding075em';
    }
    // if (panoname.includes('mbao_velasquez') ) {
    //   titreclassName += ' titretextembaovelasquez';
    // }

    let barclassName = 'd-flex align-items-center whiteBackground clickeable';
    if (titlebackgroundsmall) barclassName = 'd-flex align-items-center transparentBackground clickeable';
    if (fullScreen) {
      barclassName += ' padding075em';
    }

    let fullscreenbarclassName  = barclassName;
    if (!showfullscreeniffullscreen) {
      fullscreenbarclassName = 'd-flex align-items-center'
    }

    console.log("showbookmarksbutton",showbookmarksbutton)

    return (
      (this.state.panorama)?
      <div className={titreclassName}  onClick={() => this.titleclicked()}>
        {/* {showlogin && <AskIdMessage />} */}
        <div className="d-flex justify-content-between align-items-center">
          <div className={fullscreenbarclassName}>
            
          
          {/* {showbookmarksbutton && <div ><FontAwesomeIcon icon="list"  onClick={() => this.toggleBookmarks()}/></div>}
          {showinfobutton && <div className="infopano" ><FontAwesomeIcon icon="info"  onClick={() => setShowPanoInfo(true)}/></div>} */}
          </div> 
          {showpopupiconbar && 
            <div id='adminpanel' className="clickeable">
              {popupsshown && <FontAwesomeIcon className="maptopicon" icon="comment-slash"  onClick={() => showPopups(false)}/>}
              {!popupsshown && <FontAwesomeIcon className="maptopicon" icon="comments"  onClick={() => showPopups(true)}/>}
              <FontAwesomeIcon className="maptopicon" icon="comment-medical"  onClick={() => addOnePopup()}/>
            </div>
          }
        
        {!fullScreen &&  <div className={titretexteclassName}>
            {titre}
          </div>}

          <div className={fullscreenbarclassName}>
            {(!fullScreen && showfullscreenicon) &&  <FontAwesomeIcon icon="expand"  onClick={() => this.toggleFullscreen()}/>}
            {(fullScreen && showfullscreeniffullscreen) &&  <FontAwesomeIcon icon="compress"  onClick={() => this.toggleFullscreen()}/>}
          </div>
         
          
          {/* <div >
            <this.fullscreenIcon />          
           </div> */}
        </div>

      </div>:
        <div></div>
/*       <div className="bookmarkInfoTitre">{this.state.bookmark.label}</div>:           
      <div className="bookmarkInfoTitre">fail</div> */
      
    );
  }
}

export default PanotopComp;
