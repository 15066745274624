import jQuery from "jquery";

// console.log("test")

export function test(){
  alert('Function from index.html');
}

export class H{
  constructor() {
    this.panonameini = 'arus';
  }  
}

window.$ = jQuery;
    window.sceneEl= null;
    window.thepano = null;

    // window.sceneloaded = false;

        // window.panoname = 'astro2';
    // window.panoname = 'lasaintechapelle';
        // window.panoname = 'napoleon';
      // window.panoname = 'chapelles';
      // window.panoname = 'garnierjour1';

      // window.panoname = 'horlogeastronomique';

      // window.panoPath = "http://www.preiss.fr/pano/";

     // window.panonameini = 'renouard512';
    //  window.panonameini = 'arus512';

    window.panonameini = 'arus';
      window.panonameini = 'arus';
      window.pathini = 'http://37.187.22.210/pano/';

      window.highdpi = true;

    window.panoPath = "/pano/";
    window.jsPath= "http://www.preiss.fr/pano/";
    window.jsPath = "http://www.preiss.fr/pano/";

    window.loadpanodatalist = [
      
    { path: "http://37.187.22.210/pano/", panoname :'horlogeastronomique'},
    { path: "http://37.187.22.210/pano/", panoname :'newsaintechapelle'},
  //  { path: "/pano/", panoname :'lasaintechapelle'},
      { path: "http://www.preiss.fr/pano/", panoname :'garnierfoyer'},
      { path: "http://www.preiss.fr/pano/", panoname :'napoleon'},
      { path: "http://www.preiss.fr/pano/", panoname :'nocesdecana'},
      { path: "http://www.preiss.fr/pano/", panoname :'basquiat'},
      { path: "http://37.187.22.210/pano/", panoname :'basquiat2'},
      { path: "http://www.preiss.fr/pano/", panoname :'garnierjour1'},  
      { path: "http://37.187.22.210/pano/", panoname :'eiffelx2'},          
      { path: "http://37.187.22.210/pano/", panoname :'invalides2'},
      { path: "http://37.187.22.210/pano/", panoname :'chapelles'},
      { path: "http://www.preiss.fr/pano/", panoname :'bvsrosace1'},      
      
    ]
    window.panolist = [];
    window.UiItemslist = [];

    window.mapelements = [];

    window.mapelements = [];

    window.panodatas = [];

    window.UiItemtobeShown = null;

    window.isVR = false;

    window.isAndroid = true;

    window.isaddingFeature = false;
     

    window.controlsettings = {
      sticktreshold: 0.15,
      left : {
        stickmode : "zoom",
        gripdown : "toggleui",
        speed : 40,
        clickenabled : true,
        stickminval : 0.10
      },
      right : {
        stickmode : "pan",
        clickenabled : true,
        stickminval : 0.08
      },
      
    };

    window.panostates = {
      status : 'home',
      ismoving : false,
      refreshonce : false,
      takingscreenshot:false,
      hasmoved : false,
      haszoomed : false,
      sceneisreadydone: false,
      sceneloaded: false,
      panoindex :0,
      panothumbsloaded : false,
      controlleruishown :false,
      homerotation:0,
      itemToScreenshot : [],
      thumbstep:0,
      currentUIGroup:0

    };

    window.mapsize = '2048px';
    window.canvassizefactor = 3;
    window.canvassize = 1024*window.canvassizefactor;

    window.panosettings = {
      panothumbs : {
        height : 4,
        minwidth : 4,
        maxtextwidth : 6,
        textheight : 1.2,
        radius: 20,
        z : -5,
        y : 2
      },
      panothumbDetailed : {
        height : 2,
        minwidth : 2,
        maxtextwidth : 2,
        textheight : 0.5,
        radius: 20,
        thumbsheight: 1,
        z : -5,
        y : 2
      },
      zoominidefault: 4,
      autoOpenPano : true,
      hideui: true,
      overrideZoomini:2,
      canvash : window.canvassize,
      canvasw : window.canvassize,
      nbmaps : 1,
      htmlmapfpsmin : 1,
      htmlmapfps : 10,
      mapwidth :window.mapsize,
      mapheight :window.mapsize,
      hasscreenshots: true,   
      showAframeLog : true,
      aframedetailed : false, 
      popuprayon : 5000,    
      scrollspeed : 10,  
      homerotationspeed: 3,
      saveBrowseEvents: false,
      useFaceApi:false,
      useArtyom: false,
      ui : {
        thumb :{
          off : {
            width: 0.07,
            height: 0.07
          },
          on : {
            width: 0.14,
            height: 0.17,
            thumbimg: 90,
            thumblabel: 20,
            thumbz:0.03
          },
          imgmargin: 10,

        },
        button :{
          off : {
            width: 0.07,
            height: 0.07
          },
          on : {
            width: 0.10,
            height: 0.14,
            thumbimg: 90,
            thumblabel: 20,
            thumbz:0.03
          }
        },
        uiItemsMargin : '0.08',   
        uiItemsSize : '0.07',   
        // thumb : '0.07',       
        thumbsize : '256px',   
        thumbsizezoom : 2,     
      },          
      flytoanimated : true
    };

    window.benchmarkdata = {
      started : false,
      vals : [],
      fps : 0,
      valssize: 30
    };
    
    
    window.input = {
      stick1 : {
      x: 0,
      y: 0},
      stick2 : {
      x: 0,
      y: 0}
    };
            
  
   /* try{ myMap.size; } catch(e) {
      window.myMap = new Map();
    }	*/
    
  //  document.write('<script src="js/utils.js"></script>');

// document.write('<script type="text/javascript" src="js/giga_imports.js"></script>');


    