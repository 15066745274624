

// var url = "http://www.preiss.fr/pano/"+panoname+"/thepanosettings.js";
// var url = panoPath + panoname+"/thepanosettings.js";


// console.log('$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$')
// console.log("jsPath :" + window.jsPath)

// document.write("<link rel='stylesheet' href='https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css'>");

/*document.write("<link rel='stylesheet' href='" + window.jsPath + "control/ol.css' type='text/css'>");
document.write("<link rel='stylesheet' href='" + window.jsPath + "control/ol-popup.css' type='text/css'>");
document.write("<link rel='stylesheet' href='" + window.jsPath + "control/popup.css' type='text/css'>");*/

// document.write("<script src='" + window.jsPath + "control/polyfill.min.js?features=requestAnimationFrame,Element.prototype.classList,URL'></script>");

document.write("<script src='https://cdn.jsdelivr.net/gh/openlayers/openlayers.github.io@master/en/v6.1.1/build/ol.js'></script>");

/*

//document.write("<script src='" + jsPath + "control/ol.js'></script>");

// document.write("<script type='text/javascript' src='js/ol.js'></script>");

// document.write("<link rel='stylesheet' href='http://www.preiss.fr/pano/control/ol3-ext.css' type='text/css'>");
// document.write("<script type='text/javascript' src='http://www.preiss.fr/pano/control/ol3-ext.js'></script>");
document.write("<link rel='stylesheet' href='css/ol-ext.css' type='text/css'>");

// document.write("<script type='text/javascript' src='js/ol-ext.js'></script>");
// document.write("<script type='text/javascript' src='js/myol-ext.js'></script>");

document.write("<script type='text/javascript' src='js/ui.js'></script>");

document.write("<script type='text/javascript' src='" + window.jsPath + "control/ol-popup.js'></script>");

document.write("<link rel='stylesheet' href='" + window.jsPath + "control/geobookmarkcontrol.css' type='text/css'>");
document.write("<script type='text/javascript' src='js/gigascope.js'></script>");
document.write("<script type='text/javascript' src='" + window.jsPath + "control/panocommon.js'></script>");

// document.write("<script type='text/javascript' src='"+ url + "'><\/scr" + "ipt>");

//document.write("<script type='text/javascript' src='js/thepanosettings.js'></script>");

document.write("<link rel='stylesheet' href='" + window.jsPath + "control/gigapixel.css' />");*/


