import { Position } from './Position';
// import Popup from 'ol-popup'
import Popup from '../js/ol-popup/dist/ol-popup'

export class MyPopup{

    constructor(){
        this.data = {};
        this.textindex = 0;
        this.small = true;
        this.newTexttoAdd = false;
    }

    setExisting(mypopup){
        this.mypopup = mypopup;
        this.id = mypopup.id;
        this.data = JSON.parse(mypopup.data);
        this.index = mypopup.index;
        this.data.position = new Position(this.data.position);
    }

    setNew(panoname,coord){
        this.panoname = panoname;
        // this.data.thumb = thumb;
        let ext = window.thepano.getExtent();
        this.data.position = new Position(coord[0], coord[1], window.thepano.getZoom());
        this.data.texts = [];       
    }

    setPopups(){
        this.popups = new Popup({mypopup : this,showClose: true,issmall: true});
        this.popup = new Popup({mypopup : this,showClose: true,issmall: false});
        
    }

    setSmall(small){
        this.small = small;
    }

    toggleSmall(){
        this.small = !this.small;
    }

    show(show,small){
        if (small != null) this.small = small;
        if (show){
            if (this.small){
                this.popups.show(this.data.position.center(), this.getHtml());
                this.popup.hide();
            } else {
                this.popup.show(this.data.position.center(), this.getHtml());
                this.popups.hide();
            }
            
        } else {
            this.popups.hide();
            this.popup.hide();  
        }
        
              
    }
    getPopup(){
        if (this.popup == null) {
            this.setPopups();
        }
        return this.popup;
        
    }

    getPopupSmall(){ 
        if (this.popup == null) {
            this.setPopups();
        }
        return this.popups;        
    }

    // getText(){
    //     return this.data.texts;
    // }

    getNbTexts(){
        return this.data.texts.length;
    }

    hasText(){
        return this.getNbTexts() > 0;
    }

    getTextNum(){
        return this.textindex + 1;
    }

    addText(name,text){
        this.data.texts.push({name: name,text: text})
    }

    removeText(index){
        this.data.texts.splice(index,1)
        if (this.textindex >= index) this.textindex--;
    }

    setText(name,text,index){
        this.data.texts[index] = {name: name,text: text};
    }

    getCurrenttext(){
        return this.data.texts[this.textindex];
    }

    setNextText(){
        this.textindex++;
        if (this.textindex >= this.getNbTexts())  this.textindex = 0;        
    }

    setPrevText(){
        this.textindex--;
        if (this.textindex < 0)  this.textindex = this.getNbTexts() - 1;        
    }

    getName(){
        return this.getCurrenttext().name;
    }

    getText(){
        return this.getCurrenttext().text;
    }

    getHtml(){
        let html ='<div><p>' + this.getText() + '</p><h4>' + this.getName() + '</h4><h4>' + this.getTextNum() + "/" + this.getNbTexts() +'</h4></div>';
        // html +='<div><p>' + this.getCurrenttext().name + '</p></div>';
        return html;
    }

}