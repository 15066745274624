import * as THREE from 'three'
import { memo, useRef, forwardRef } from 'react'
import { Canvas, useThree } from '@react-three/fiber'
import { Backdrop,OrbitControls,Stage,Clone,Grid, Center, AccumulativeShadows, RandomizedLight, Environment, useGLTF, CameraControls } from '@react-three/drei'
import { useControls, button, buttonGroup, folder } from 'leva'
import {Loader3DTilesR3FAsset}from './Loader3DTilesR3FAsset.tsx';
import {	Group,Euler,DoubleSide} from 'three';

import {EmericTile}from './EmericTile.js';

import {
	DebugTilesRenderer,
	NONE,
	SCREEN_ERROR,
	GEOMETRIC_ERROR,
	DISTANCE,
	DEPTH,
	RELATIVE_DEPTH,
	IS_LEAF,
	RANDOM_COLOR,
} from '3d-tiles-renderer';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js';

const { DEG2RAD } = THREE.MathUtils

const deg2rad = degrees => degrees * (Math.PI / 180);

export default function App() {
  return (
    <Canvas shadows camera={{ position: [0, 0, 5], fov: 60 }}>
      <MyScene />
    </Canvas>
  )
}

export function OtherScene() {

  const meshRef = useRef()
  const cameraControlsRef = useRef()

  var cameraypos = 0;
  var maxangle = Math.PI;
  var minangle = 0;

  if (window.modelename == "leo"){
    cameraypos = 0.1;    
    maxangle = 2.2;
    minangle = 0.7;
  }
  if (window.modelename == "watch"){
    cameraypos = 0.2;    
    maxangle = Math.PI/2*1.01;
    minangle = 0;
  }

  
  useThree(({camera}) => {
      camera.position.y = cameraypos;
      // camera.rotation.set(deg2rad(60), 0, 0);
      // camera.rotation = ;
      // camera.lookAt(0, 100, 0);
    });
  

  // const { gridSize, ...gridConfig } = useControls({
  //   gridSize: [10.5, 10.5],
  //   cellSize: { value: 0.6, min: 0, max: 10, step: 0.1 },
  //   cellThickness: { value: 1, min: 0, max: 5, step: 0.1 },
  //   cellColor: '#6f6f6f',
  //   sectionSize: { value: 3.3, min: 0, max: 10, step: 0.1 },
  //   sectionThickness: { value: 1.5, min: 0, max: 5, step: 0.1 },
  //   sectionColor: '#9d4b4b',
  //   fadeDistance: { value: 25, min: 0, max: 100, step: 1 },
  //   fadeStrength: { value: 1, min: 0, max: 1, step: 0.1 },
  //   followCamera: false,
  //   infiniteGrid: true
  // })

  return (
    <>
     <ambientLight intensity={0.5}/>
    {/* <pointLight position={[10, 10, 10]} /> */}
    <directionalLight  color="white" position={[3, 5, 3]} />

          {/* <Backdrop receiveShadow scale={[20, 5, 5]} floor={1.5} position={[0, -0.5, -2]}>
        <meshPhysicalMaterial roughness={1} color="#efefef" />
      </Backdrop> */}

    <group position-y={-0.32}>

   
      {/* <Ground /> */}

{/* 
      <Stage
          intensity={0.5}
          preset="rembrandt"
          shadows={{ type: 'accumulative', color: 'skyblue', colorBlend: 2, opacity: 2 }}
          adjustCamera={1.75}
          environment="city"> */}
            
     
      {/* <Model scale={[0.3, 0.3, 0.3]} position = {[.5,0,0]} /> */}

      {/* <Center top> */}
      {/* <Suzi ref={meshRef} rotation={[-0.63, 0, 0]}/> */}
       <Loader3DTilesR3FAsset castShadow receiveShadow/> 
       {/* <EmericTile castShadow receiveShadow/>  */}
      {/* </Center> */}
      {/* </Stage> */}
      {/* <GroundPlane /> */}
      {/* <TheShadows /> */}
        {/* <OrbitControls makeDefault /> */}
      <CameraControls makeDefault
          
          ref={cameraControlsRef}
          minPolarAngle = {minangle}
          maxPolarAngle = {maxangle}
          // minDistance={minDistance}
          // enabled={enabled}
          // verticalDragToForward={verticalDragToForward}
          dollyToCursor={true}
          // infinityDolly={infinityDolly}
        />
    </group>
    </>
  )

}


const TheShadows = memo(() => (
  <AccumulativeShadows temporal frames={100} color="#9d4b4b" colorBlend={0.5} alphaTest={0.9} scale={20}>
    <RandomizedLight amount={8} radius={4} position={[5, 5, -10]} />
  </AccumulativeShadows>
))

function GroundPlane() {
  return (
    // The mesh is at the origin
    // Since it is inside a group, it is at the origin
    // of that group
    // It's rotated by 90 degrees along the X-axis
    // This is because, by default, planes are rendered
    // in the X-Y plane, where Y is the up direction
    <mesh position={[0, 0, 0]} rotation={[Math.PI / 2, 0, 0]} scale={[3,  3, 1]}>
      {/*
        The thing that gives the mesh its shape
        In this case the shape is a flat plane
      */}
      <planeBufferGeometry />
      {/*
        The material gives a mesh its texture or look.
        In this case, it is just a uniform green
      */}
      <meshBasicMaterial color="green" side={DoubleSide} />
    </mesh>
  );
}

export function OtherScene2() {

  const meshRef = useRef()
  const cameraControlsRef = useRef()

  const { gridSize, ...gridConfig } = useControls({
    gridSize: [10.5, 10.5],
    cellSize: { value: 0.6, min: 0, max: 10, step: 0.1 },
    cellThickness: { value: 1, min: 0, max: 5, step: 0.1 },
    cellColor: '#6f6f6f',
    sectionSize: { value: 3.3, min: 0, max: 10, step: 0.1 },
    sectionThickness: { value: 1.5, min: 0, max: 5, step: 0.1 },
    sectionColor: '#9d4b4b',
    fadeDistance: { value: 25, min: 0, max: 100, step: 1 },
    fadeStrength: { value: 1, min: 0, max: 1, step: 0.1 },
    followCamera: false,
    infiniteGrid: true
  })

  return (
    <>
      <Stage
        intensity={0.5}
        preset="rembrandt"
        shadows={{ type: 'accumulative', color: 'skyblue', colorBlend: 2, opacity: 2 }}
        adjustCamera={1.75}
        environment="city">
        {/* <Othertiles /> */}
        {/* <Loader3DTilesR3FAsset /> */}
        
      </Stage>
      <OrbitControls makeDefault />
      <Grid position={[0, -0.01, 0]} args={gridSize} {...gridConfig}/>
      
    </>
  )

}

function Othertiles(props) {

    let url = 'https://nasa-ammos.github.io/3DTilesRendererJS/example/data/tileset.json';

    let tiles = new DebugTilesRenderer( url );
     
    // Note the DRACO compression files need to be supplied via an explicit source.
    // We use unpkg here but in practice should be provided by the application.
    const dracoLoader = new DRACOLoader();
    dracoLoader.setDecoderPath( 'https://unpkg.com/three@0.123.0/examples/js/libs/draco/gltf/' );
  
    const loader = new GLTFLoader( tiles.manager );
    loader.setDRACOLoader( dracoLoader );
  
    tiles.fetchOptions.mode = 'cors';
    tiles.manager.addHandler( /\.gltf$/, loader );
    // this.offsetParent.add( this.tiles.group );

    let offsetParent = new Group();
    offsetParent.add( tiles.group ); 
    
  
  // const { scene } = useGLTF(
  //   'https://vazxmixjsiawhamofees.supabase.co/storage/v1/object/public/models/target-stand/model.gltf'
  // )
  return <Clone object={offsetParent} castShadow receiveShadow {...props} />
}

function Model(props) {
  const { scene } = useGLTF(
    'https://vazxmixjsiawhamofees.supabase.co/storage/v1/object/public/models/target-stand/model.gltf'
  )
  return <Clone object={scene} castShadow receiveShadow {...props} />

  function Model3(props) {
    const { scene } = useGLTF(
      'https://vazxmixjsiawhamofees.supabase.co/storage/v1/object/public/models/target-stand/model.gltf'
    )
    return <Clone object={scene} castShadow receiveShadow {...props} />
  }
}

export function MyScene() {
  const meshRef = useRef()
  const cameraControlsRef = useRef()

  const { camera } = useThree()

  // All same options as the original "basic" example: https://yomotsu.github.io/camera-controls/examples/basic.html
  const { minDistance, enabled, verticalDragToForward, dollyToCursor, infinityDolly } = useControls({
    thetaGrp: buttonGroup({
      label: 'rotate theta',
      opts: {
        '+45º': () => cameraControlsRef.current?.rotate(45 * DEG2RAD, 0, true),
        '-90º': () => cameraControlsRef.current?.rotate(-90 * DEG2RAD, 0, true),
        '+360º': () => cameraControlsRef.current?.rotate(360 * DEG2RAD, 0, true)
      }
    }),
    phiGrp: buttonGroup({
      label: 'rotate phi',
      opts: {
        '+20º': () => cameraControlsRef.current?.rotate(0, 20 * DEG2RAD, true),
        '-40º': () => cameraControlsRef.current?.rotate(0, -40 * DEG2RAD, true)
      }
    }),
    truckGrp: buttonGroup({
      label: 'truck',
      opts: {
        '(1,0)': () => cameraControlsRef.current?.truck(1, 0, true),
        '(0,1)': () => cameraControlsRef.current?.truck(0, 1, true),
        '(-1,-1)': () => cameraControlsRef.current?.truck(-1, -1, true)
      }
    }),
    dollyGrp: buttonGroup({
      label: 'dolly',
      opts: {
        '1': () => cameraControlsRef.current?.dolly(1, true),
        '-1': () => cameraControlsRef.current?.dolly(-1, true)
      }
    }),
    zoomGrp: buttonGroup({
      label: 'zoom',
      opts: {
        '/2': () => cameraControlsRef.current?.zoom(camera.zoom / 2, true),
        '/-2': () => cameraControlsRef.current?.zoom(-camera.zoom / 2, true)
      }
    }),
    minDistance: { value: 0 },
    moveTo: folder(
      {
        vec1: { value: [3, 5, 2], label: 'vec' },
        'moveTo(…vec)': button((get) => cameraControlsRef.current?.moveTo(...get('moveTo.vec1'), true))
      },
      { collapsed: true }
    ),
    'fitToBox(mesh)': button(() => cameraControlsRef.current?.fitToBox(meshRef.current, true)),
    setPosition: folder(
      {
        vec2: { value: [-5, 2, 1], label: 'vec' },
        'setPosition(…vec)': button((get) => cameraControlsRef.current?.setPosition(...get('setPosition.vec2'), true))
      },
      { collapsed: true }
    ),
    setTarget: folder(
      {
        vec3: { value: [3, 0, -3], label: 'vec' },
        'setTarget(…vec)': button((get) => cameraControlsRef.current?.setTarget(...get('setTarget.vec3'), true))
      },
      { collapsed: true }
    ),
    setLookAt: folder(
      {
        vec4: { value: [1, 2, 3], label: 'position' },
        vec5: { value: [1, 1, 0], label: 'target' },
        'setLookAt(…position, …target)': button((get) => cameraControlsRef.current?.setLookAt(...get('setLookAt.vec4'), ...get('setLookAt.vec5'), true))
      },
      { collapsed: true }
    ),
    lerpLookAt: folder(
      {
        vec6: { value: [-2, 0, 0], label: 'posA' },
        vec7: { value: [1, 1, 0], label: 'tgtA' },
        vec8: { value: [0, 2, 5], label: 'posB' },
        vec9: { value: [-1, 0, 0], label: 'tgtB' },
        t: { value: Math.random(), label: 't', min: 0, max: 1 },
        'f(…posA,…tgtA,…posB,…tgtB,t)': button((get) => {
          return cameraControlsRef.current?.lerpLookAt(
            ...get('lerpLookAt.vec6'),
            ...get('lerpLookAt.vec7'),
            ...get('lerpLookAt.vec8'),
            ...get('lerpLookAt.vec9'),
            get('lerpLookAt.t'),
            true
          )
        })
      },
      { collapsed: true }
    ),
    saveState: button(() => cameraControlsRef.current?.saveState()),
    reset: button(() => cameraControlsRef.current?.reset(true)),
    enabled: { value: true, label: 'controls on' },
    verticalDragToForward: { value: false, label: 'vert. drag to move forward' },
    dollyToCursor: { value: false, label: 'dolly to cursor' },
    infinityDolly: { value: false, label: 'infinity dolly' }
  })

  return (
    <>
      <group position-y={-0.5}>
        <Center top>
          <getTiles />
          {/* <Suzi ref={meshRef} rotation={[-0.63, 0, 0]} /> */}
        </Center>
        <Ground />
        <Shadows />
        <CameraControls
          // ref={cameraControlsRef}
          // minDistance={minDistance}
          // enabled={enabled}
          // verticalDragToForward={verticalDragToForward}
          // dollyToCursor={dollyToCursor}
          // infinityDolly={infinityDolly}
        />
        <Environment preset="city" />
      </group>
    </>
  )
}

function Ground() {
  const gridConfig = {
    cellSize: 0.5,
    cellThickness: 0.5,
    cellColor: '#6f6f6f',
    sectionSize: 3,
    sectionThickness: 1,
    sectionColor: '#9d4b4b',
    fadeDistance: 30,
    fadeStrength: 1,
    followCamera: false,
    infiniteGrid: true
  }
  return <Grid position={[0, -0.01, 0]} args={[10.5, 10.5]} {...gridConfig} />
}

function getTiles(){
  window.modelrotation = new Euler(Math.PI, Math.PI ,0);
  window.url = "https://data2.gigascope.net/3d/montre/tileset.json";	

  if (this.modelename == "montre") window.url = "https://data2.gigascope.net/3d/montre/tileset.json";
  if (this.modelename == "leo") window.url = "https://data2.gigascope.net/3d/leo/tileset.json";
  if (this.modelename == "guernesey_salleamanger") {
    window.url = "https://data2.gigascope.net/3d/guernesey_salleamanger/tileset.json";
    window.modelrotation = new Euler( -Math.PI/6,0, 0);
    this.cameraposition  = [0,0,5];
  }
  // window.url = "https://data2.gigascope.net/3d/coeurchartres/tileset.json";
  // window.url = "https://data2.gigascope.net/3d/notredame/tileset.json";	
  // window.url = "https://data2.gigascope.net/3d/gerberoy/tileset.json";	
    // window.url = "../montre/tileset.json";
  // window.url = "http://localhost/3dmodeles/dome/tileset.json";
  // window.url = "https://nasa-ammos.github.io/3DTilesRendererJS/example/data/tileset.json";
  // window.url = "https://storage.googleapis.com/ogc-3d-tiles/baltimore/tileset.json";
      return (
    <Loader3DTilesR3FAsset/>
      )
  } 

const Shadows = memo(() => (
  <AccumulativeShadows temporal frames={100} color="#9d4b4b" colorBlend={0.5} alphaTest={0.9} scale={20}>
    <RandomizedLight amount={8} radius={4} position={[5, 5, -10]} />
  </AccumulativeShadows>
))

const Suzi = forwardRef((props, ref) => {
  const { nodes } = useGLTF('https://market-assets.fra1.cdn.digitaloceanspaces.com/market-assets/models/suzanne-high-poly/model.gltf')
  return (
    <>
      <mesh ref={ref} castShadow receiveShadow geometry={nodes.Suzanne.geometry} {...props}>
        <meshStandardMaterial color="#9d4b4b" />
      </mesh>
    </>
  )
})


