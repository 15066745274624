import React, { Component } from 'react';

 
// if (!process.env.REACT_APP_CLERK_PUBLISHABLE_KEY) {
//   throw "Missing Publishable Key"
// }
 
const clerkPubKey = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;



class testcomp extends Component {
  constructor(props) {
    super(props);

     this.state = {
    }; 

    
  }
  
  componentDidMount() {
   
  }

  componentWillUpdate(nextProps, nextState) {

    }
 
  
  render() {
  return (
    <div></div>
    // <ClerkProvider publishableKey={clerkPubKey}>
    //   <SignedIn>
    //     <Welcome />
    //   </SignedIn>
    //   <SignedOut>
    //     <RedirectToSignIn />
    //   </SignedOut>
    // </ClerkProvider>
  );
}
}

export default testcomp;