
import React, { Component } from 'react';
import firebase from '../Firebase';
import { doc,collection, query, where, getDocs } from "firebase/firestore";

import Bookmarks from './BookmarksComp';
import BookmarksInfo from './BookmarkInfoComp';
import PlaceInfo from './PlaceInfoComp';

import GigaphotoInfo from './GigaphotoInfoComp';
import EditBookmark from './EditBookmark';
import PanotopSplit from './PanotopComp';

import LightBox from './LightBoxComp';
import ChoixAudio from './ChoixAudioComp';
import Player from './PlayerComp';

import Photo from './PhotoComp';

import PanelGroup from "react-panelgroup/lib/PanelGroup";

import AskIdMessage from './AskIdMessageComp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Bookmark } from '../js/Bookmark.js';

// import * as handTrack from 'handtrackjs';

// import Lightbox from 'react-image-lightbox';
// import 'react-image-lightbox/style.css';

import Jumbotron from 'react-bootstrap/Jumbotron'

// import Artyom from "artyom.js"

// import * as canvas from 'canvas';

// import * as faceapi from 'face-api.js';

import nodeUtils from '../js/nodeUtils.js'

// import Synchronize from "../js/ol-ext-node.js"

// import Handsfree from '../../node_modules/handsfree/src/handsfree.js'

// const Handsfree = require('../../node_modules/handsfree/src/handsfree')
// import Handsfree from 'handsfree'



// import Handsfree from '../js/handsfree/handsfree'

// const Popo = require('../js/handsfree2.js')

import { Handsfree } from '../js/handsfree/handsfree.js'

// const testFolder = '../assets/';
// const fs = require('fs');

// const { Canvas, Image, ImageData } = canvas
// faceapi.env.monkeyPatch({ Canvas, Image, ImageData })

/*
if(window.panosettings.useFaceApi){
    faceapi.env.monkeyPatch({
    Canvas: HTMLCanvasElement,
    Image: HTMLImageElement,
    ImageData: ImageData,
    Video: HTMLVideoElement,
    createCanvasElement: () => document.createElement('canvas'),
    createImageElement: () => document.createElement('img')
    })
}*/


const MODEL_URL = "http://37.187.22.210/data/faceapi/models/"

let model = null;

const modelParams = {
    flipHorizontal: true,   // flip e.g for video 
    imageScaleFactor: 0.7,  // reduce input image size for gains in speed.
    maxNumBoxes: 20,        // maximum number of boxes to detect
    iouThreshold: 0.5,      // ioU threshold for non-max suppression
    scoreThreshold: 0.79,    // confidence threshold for predictions.
  }

  


let imgindex = 1
let isVideo = false;

        
// const config = {  };
// const handsfree = new Handsfree(config)

// handsfree.use('consoleLogger', ({ head }) => {
//     console.log('Morphs: ', head.morphs)
//     console.log('Rotation: ', head.rotation)
//     console.log('Translation: ', head.translation)
//   })

//   handsfree.use('consoleLogger', ({head}) => {
//     console.log(head.morphs)
//   })

var url = require('url');
var _ = require('underscore');
// import nodeUtils from '../js/nodeUtils.js'

// var Jimp = require('jimp');

/*
if(window.panosettings.useArtyom){
    global.artyom = new Artyom();
}
*/

// var getCurvePoints = require("cardinal-spline-js").getCurvePoints;


const screenchotsize = 256;

class GigascopephotoSplitted extends Component {

    constructor(props) {
        super(props);

        this.start = Date.now();
        
        window.bookmarksVisible = true;         
        
        this.isAdmin = false;
        this.isAdminAudio = false;
        this.isAdminOpenPlace = false;

        this.usefaceapi = false;
        this.useAyrtom = false;
        this.useHandTrack = false;

        this.predictions = [];
        
        this.lightboxvirgin = true;  

      //  let test = {position: {position:[0,0],zoom: 1}}
      //  console.log("position",JSON.stringify(test))
       
        // console.log('getBookmarks00',Date.now() - this.start)

        this.panonameparam = props.match.params.panoname;

        if (this.panonameparam == "rosesaintetestnoprompt") this.panonameparam = "rosesaitnetest";
        
        window.panoname = this.panonameparam;

        // this.askedbookmark = this.props.match.params.bookmark;
        
        this.showBookmarkInfoDiv = this.showBookmarkInfoDiv.bind(this);
        this.onBookmarkClicked = this.onBookmarkClicked.bind(this);
        this.bookmarck_clicked = this.bookmarck_clicked.bind(this);
        this.nextBookmark = this.nextBookmark.bind(this);
        this.prevBookmark = this.prevBookmark.bind(this);
        this.openFirstBookmark = this.openFirstBookmark.bind(this);
        this.showPlaceInfoDiv = this.showPlaceInfoDiv.bind(this);
        this.readCurrentBookmark = this.readCurrentBookmark.bind(this);

        this.setShowPanoInfo = this.setShowPanoInfo.bind(this);

        this.bookmarck_clicked_edit = this.bookmarck_clicked_edit.bind(this);
        this.showLightBox = this.showLightBox.bind(this);
        this.startVisit = this.startVisit.bind(this);
        this.showPhoto = this.showPhoto.bind(this);

        this.processClick = this.processClick.bind(this);


        this.state = { 
            isOpen: false,
            currentbookmark : null,
            bookmarks : [],
            showAskLogin: false,
            showPanoInfo:!this.isAdmin && this.props.match.params.bookmark == null,
            isadmin : this.isAdmin,
            isauth: nodeUtils.isAuthenticated(),
            isLightBoxOpen:true,
            lightboxurl:"",
            isChoixAudioOpen:false,
            isAudio: false,
            showPlaceInfo: false,
            isShowPhoto: false,
            showBookmarkInfo: false,
            audioevent: null
         };

        this.Bookmarks = [];



        this.currentbookmark = null;

        // console.log("props",props)
        // console.log("props !!!!!!!!!!!!!",props.match.params.bookmark)

        this.bref = window.bref;
        
        window.firebase = firebase;


        // console.log(faceapi.nets);

        // await faceapi.loadSsdMobilenetv1Model(MODEL_URL)
        // await faceapi.loadFaceLandmarkModel(MODEL_URL)
        // await faceapi.loadFaceRecognitionModel(MODEL_URL)

        // if (this.useHandTrack){

        //     let self = this;
        //     handTrack.load(modelParams).then(lmodel => {
        //         model = lmodel;
        //         console.log('handtrack started')
        //         self.runDetection()
        //       });
        // }
       




        // handsfree.use('consoleLogger', ({ weboji }) => {
        //     if (weboji.pointer.state === 'mousedown') {
        //       console.log(`Clicked at: (${weboji.pointer.x}, ${weboji.pointer.y})`)
        //       console.log('Clicked on:', weboji.pointer.$target)
        //     }
        //   })

        //   handsfree.on('data', data => {
        //     console.log('handsfree data', data)
        //   })

        if (this.useAyrtom){
            global.artyom.initialize({
                lang:"fr-FR",
                debug:false,
                continuous:true,
                listen:false
            }).then(function(){
                console.log("Artyom Artyom has been correctly initialized");
                console.log("Artyom The following array shouldn't be empty" , global.artyom.getVoices());
                // artyom.say("Hello");
            }).catch(function(){
                console.error("Artyom An error occurred during the initialization");
            });
            
            let self = this;
            global.artyom.addCommands([
                // English Command
                {
                    indexes: ["plein écran"],
                    action: function(){
                        nodeUtils.togglePanoFullScreen();
                    },                
                },
                {
                    indexes: ["suivant"],
                    action: function(){
                        self.nextBookmark();
                    },                
                },
                {
                    indexes: ["ferme la photo"],
                    action: function(){
                        self.showPlaceInfoDiv(false);
                    },                
                },{
                    indexes: ["lis le texte", "lis le commentaire"],
                    action: function(){
                        self.readCurrentBookmark();
                    },                
                },
                {
                    indexes: ["tais-toi", "arrête de parler"],
                    action: function(){
                        global.artyom.shutUp();
                    },                
                },
                {
                    indexes: ["ouvre le premier bookmark","ouvre le premier commentaire"],
                    action: function(){
                        self.onBookmarkClicked(self.getFirstBookmark());
                    },                
                },
                
                {
                    indexes: ["Bonjour bob"],
                    action: function(){
                        if (self.state.age > 20) {
                            if (self.state.gender == 'male'){
                                global.artyom.say("bonjour cyril")
                            } else {
                                global.artyom.say("bonjour Mamour!")
                            }
                            
                        } else    global.artyom.say("coucou marilou")
                    },                
                }])
    
        }

       
            
          
          this.isplaying = false;
        
        if (props.match.params.bookmark){
            
            // this.bref.where('index', '==', this.panonameparam + "_" + props.match.params.bookmark).get()
            // .then(bookmark => {

            //     window.UiItemtobeShown = {
            //         label : bookmark.label,
            //         pos : bookmark.pos,
            //         zoom : bookmark.zoom
            //     }
            
            // })
            // .catch(err => {
            // console.log('Error getting documents', err);
            // });
            
        }

        

        nodeUtils.getNextBookmarkIndex(this.panonameparam,this.bref);

        // console.log('getBookmarks0',Date.now() - this.start)
        

        // this.bref.where('panoname', '==', props.match.params.panoname  ).get()
       

       
      
        
        this.ref = window.ref;

        const q = query(window.ref, where("panoname", "==", this.panonameparam));
  
        getDocs(q)
        .then((snapshot) => {
            //console.log('getBookmarks101', JSON.stringify(snapshot.docs[0].data()).length);
            // console.log('getBookmarks11',Date.now() - this.start)
            if (!snapshot.empty) {
                var pano = snapshot.docs[0].data()
                let panodata = {
                    id: snapshot.docs[0].id,
                    panoname : pano.panoname,
                    theme: pano.theme,
                    titre: pano.titre,
                    info: pano.info,
                    width: pano.width,
                    height: pano.height,
                    numtiles: pano.numtiles,
                    numimages: 1,
                    witilesize: pano.witilesize,
                    zoomini: 4,
                    url: pano.url}
                    // console.log('getBookmarks',Date.now() - this.start)

                    if (panodata.url == null) {
                        panodata.url = "https://data2.gigascope.net/pano/"
                    }

                    this.state.panorama = panodata;

                    if (nodeUtils.isBeta() & !this.isAdmin ) this.state.isChoixAudioOpen = true;

                    this.setState(this.state);

                   /*  nodeUtils.setContent("gigaphotoinfotext",nodeUtils.getHtmlText(panodata.info,"1.25em",false)) */

                    nodeUtils.addGigaphotoEvent(panodata);

                    this.getBookmarks(pano.panoname);
                    window.loadPano( { panodata :panodata},true);
                    // window.loadPano2( { panodata :panodata},true);
                   // window.loadPano( { path: "http://37.187.22.210/pano/", pano :pano},true);
                }
            })
            .catch(err => {
            console.log('Error getting documents', err);
            });

            let name = "rosesaintetest";
            if (this.panonameparam == "brueghelold") name = "bruegheljeu";
            if (this.panonameparam == "mbao_velasquez") name = "mbao_velasquezir";
            if (this.panonameparam == "brueghel") name = "bruegheljeu";
            if (this.panonameparam == "archedenoe") name = "archedenoejeu";
            if (this.panonameparam == "louistestinfrarougevisible") name = "louistestinfrarouge";
            if (this.panonameparam == "tapisserieministere2recto") name = "tapisserieministere2verso";
            if (this.panonameparam == "ivoire") name = "ivoirewb";
            if (this.panonameparam == "AukF4ipAHo6S") name = "AukF4ipAHo6Suv";
            if (this.panonameparam == "AukF4ipAHo6SpreV") name = "AukF4ipAHo6S";
            if (this.panonameparam == "2Ljdw4IWlV1q") name = "2Ljdw4IWlV1q";
            if (this.panonameparam == "suiveurbellini") name = "suiveurbelliniuv";
            if (this.panonameparam == "mbao_velasquez2023") name = "mbao_velasquezir";
            console.log("name",name);
            const q2 = query(window.ref, where("panoname", "==", name));
  
            getDocs(q2)            
            .then(snapshot => {
                //console.log('getBookmarks101', JSON.stringify(snapshot.docs[0].data()).length);
                // console.log('getBookmarks11',Date.now() - this.start)
                if (!snapshot.empty) {
                    var pano = snapshot.docs[0].data()
                    let panodata = {
                        id: snapshot.docs[0].id,
                        panoname : pano.panoname,
                        theme: pano.theme,
                        titre: pano.titre,
                        info: pano.info,
                        width: pano.width,
                        height: pano.height,
                        numtiles: pano.numtiles,
                        numimages: 1,
                        witilesize: pano.witilesize,
                        zoomini: 4,
                        url: pano.url}
                        // console.log('getBookmarks',Date.now() - this.start)

                        if (panodata.url == null) {
                            panodata.url = "https://data2.gigascope.net/pano/"
                        }
    
                        this.state.panorama2 = panodata;    
                            
                        this.setState(this.state);
    
                       /*  nodeUtils.setContent("gigaphotoinfotext",nodeUtils.getHtmlText(panodata.info,"1.25em",false)) */
    
                        // nodeUtils.addGigaphotoEvent(panodata);
    
                        // this.getBookmarks(pano.panoname);
                        // window.loadPano( { panodata :panodata},true);
                        window.loadPano2( { panodata :panodata},true);

                        // console.log("t1",window.thepano.omap.getTarget());
                        // console.log("t2",window.thepano2.omap.getTarget());
                        window.thepano.sync();
                        window.thepano2.sync();

                        window.thepano.setClickCallback(this,this.processClick);
                        window.thepano2.setClickCallback(this,this.processClick);
                       // window.loadPano( { path: "http://37.187.22.210/pano/", pano :pano},true);
                    }
                })
                .catch(err => {
                console.log('Error getting documents', err);
                });

            let thiscomp = this;
        /*    firebase.auth().onAuthStateChanged(function(user) {
                if (user) {
                //   console.log('1User is signed in.')
                //   console.log('1user: ',user)
          
                  thiscomp.state.isauth = true;
                  thiscomp.setState(thiscomp.state);
                //   if (nodeUtils.ReadString("InfoBig") == "1") this.toggleBig();
                //   else this.toggleSmall();
                  
                } else {
                    thiscomp.state.isauth = false;
                //   console.log('No user is signed in.')
                  
                }
              });*/
                          
          }

          showPhoto(show,audioevent){
              this.state.audioevent = audioevent;
              this.state.showPhoto = show;
              this.setState(this.state);
          }

          showLightBox(show,duration,url,titre,position){
              let self = this;
            //   console.log('duration',duration)
            console.log('showLightBox show',show)
              console.log('showLightBox titre',titre)
            //   console.log(titre)
            //   duration = 3;
            if (show){
                this.state.isLightBoxOpen = show              

                this.state.lightboxurl = url
                this.state.lightboxtitre = titre

                this.state.lightboxposition = position

                this.setState(this.state);

                if (!global.lightboxisshown){
                     window.setTimeout(function(){ nodeUtils.setOpacityFaded('lightbox',show,2) }, 1000)
                    // window.setTimeout(function(){ self.showLightBox(false); }, duration*1000)
                
                }
                global.lightboxisshown = true;
               
            } else {
                let tt =2
                nodeUtils.setOpacityFaded('lightbox',show,2)
                global.lightboxisshown = false;
            }


            //   nodeUtils.setOpacity('lightbox',show)
              
          }

          showAskLogin(show){
            this.state.showAskLogin = show;
            this.setState(this.state);
            /* nodeUtils.setVisible("GigascopephotoSplittedlogin",show); */
        }

        setShowPanoInfo(show){
            this.state.showPanoInfo = false;
            this.setState(this.state);

           
            /* nodeUtils.setVisible("GigascopephotoSplittedlogin",show); */
        }

        demandeaide(){
            alert("Aidez Gigascope à faire découvrir le Mont Blanc ! En cette période de confinement, je suis en train de préparer un système permettant de synchroniser une promenade dans la gigaphoto avec des commentaires écrits et audio synchronisés. Ce serait génial si certains d'entre vous voulait en profiter pour partager leur amour du Mont Blanc. L'idée est que les personnes intéressées choisissent des points d’intérêts dans la gigaphoto et écrivent des commentaires (qui peuvent inclure des photos, par exemple photo de la vue à l'endroit du commentaire). On pourrait par exemple imaginer parcourir la voie royale avec des commentaires d'alpinistes au fur et à mesure de l’ascension. Ou une promenade à travers les glaciers. Si vous êtes intéressé ou connaissez quelqu'un qui pourrait l'être merci de me contacter. Cela peut être un travail collectif aussi si vous vous y mettez à plusieurs.. Donc si certains d'entre vous veulent participer à enrichir cette photo contactez moi sur cyrilpreiss@gigascope.net")
        }
    
        getBookmarks(panoname){
           
          }

    //   componentDidMount(){

    //   }

    processClick(parent,evt){
        console.log('evt.coordinate',evt.coordinate)
        let helptest = 0;
        let node = document.getElementById('clickresult');
        parent.state.jeuxerreurs.forEach(element => {
            console.log(element.getDist(evt))         

            

            if (element.getDist(evt) < 2000){
                
                if (!element.show){
                    element.show = true;
                    console.log("cool")
                    parent.state.bookmarks.push(element)
                    parent.setState(parent.state);
                    helptest = 2;
                }
            } else if (element.getDist(evt) < 15000){
                if (helptest <2) helptest = 1
            } else {

            }
        });

        if (helptest == 2) node.style.backgroundColor = 'green';
        if (helptest == 1) node.style.backgroundColor = 'orange';
        if (helptest == 0) node.style.backgroundColor = 'red';
    }

      async componentDidMount() {

        // nodeUtils.setVisible100('lightbox',false)
        nodeUtils.setOpacityFaded('lightbox',false,2)



        // fs.readdir(testFolder, (err, files) => {
        //     files.forEach(file => {
        //       console.log(file);
        //     });
        //   });

/*
        if (this.usefaceapi){
            const video = document.getElementById('video')

            Promise.all([
                // faceapi.loadSsdMobilenetv1Model('./models/')
            // await faceapi.loadFaceLandmarkModel(MODEL_URL)
            // await faceapi.loadFaceRecognitionModel(MODEL_URL)
            faceapi.nets.tinyFaceDetector.loadFromUri(MODEL_URL),
            faceapi.nets.faceLandmark68Net.loadFromUri(MODEL_URL),
            faceapi.nets.faceRecognitionNet.loadFromUri(MODEL_URL),
            faceapi.nets.faceExpressionNet.loadFromUri(MODEL_URL),        
            faceapi.nets.ageGenderNet.loadFromUri(MODEL_URL)
            ]).then(function(){
                navigator.getUserMedia(
                    { video: {} },
                    stream => {
                        video.srcObject = stream},
                    err => {
                        console.error(err)}
                )
            })
            .catch(err => {
                console.log('Error getting documents', err);
                });
    
            
    
            function startVideo() {
            
            }
    
            let self = this;
            video.addEventListener('play', () => {
                const canvas = faceapi.createCanvasFromMedia(video)
                document.body.append(canvas)
                const displaySize = { width: video.width, height: video.height }
                faceapi.matchDimensions(canvas, displaySize)
                setInterval(async () => {
                    const detections = await faceapi.detectAllFaces(video, new faceapi.TinyFaceDetectorOptions()).withFaceLandmarks().withFaceExpressions().withAgeAndGender()
                    // const resizedDetections = faceapi.resizeResults(detections, displaySize)
                    // canvas.getContext('2d').clearRect(0, 0, canvas.width, canvas.height)
                    // faceapi.draw.drawDetections(canvas, resizedDetections)
                    // faceapi.draw.drawFaceLandmarks(canvas, resizedDetections)
                    // faceapi.draw.drawFaceExpressions(canvas, resizedDetections)
                    if (detections[0]) {
                        // console.log(detections[0].age)
                        self.state.age = detections[0].age;
                        self.state.gender = detections[0].gender;
                        self.state.userage = detections[0].age + " " + detections[0].gender
                        self.setState(self.state)
                    }
                    self.runDetection();
                    
                }, 100)
             //   this.runDetection()
            })
        }

        */

        // if (!nodeUtils.isMe()) this.isAdmin = false;
       

        // messaging.subscribeToTopic(registrationTokens, topic)
        // .then(function(response) {
        //   // See the MessagingTopicManagementResponse reference documentation
        //   // for the contents of response.
        //   console.log('Successfully subscribed to topic:', response);
        // })
        // .catch(function(error) {
        //   console.log('Error subscribing to topic:', error);
        // });

        // messaging.requestPermission()
        //   .then(async function() {
        //     const token = await messaging.getToken();
        //     console.log('componentDidMount token',token)
        //         })
        //         .catch(function(err) {
        //             console.log("Unable to get permission to notify.", err);
        //         });
        // console.log('componentDidMount');
        // navigator.serviceWorker.addEventListener("message", (message) => console.log(message));

        
    }

    runDetection() {

        let video = document.getElementById('video')

        if (!video) return

        let self = this;

        model.detect(video).then(predictions => {
            self.processHandPrediction(predictions)
            
         //   model.renderPredictions(predictions, canvas, context, video);
            //if (isVideo) {
              //  requestAnimationFrame(self.runDetection.bind(self));
          //  }
        });
    }

    processHandPrediction(predictions){
        if (predictions.length != 1) return;
        let prediction = predictions[0];
        console.log("Predictions: ", prediction.bbox);
        console.log("Predictions: ", prediction.score);
    }

    componentWillMount() {

        //console.log("hello1",window.thepano)
      }

      userAction() {
        window.isNode = false
      //  window.loadPano( { path: "http://37.187.22.210/pano/", panoname :'valtat'},true);
        window.loadPano( { path: "http://37.187.22.210/pano/", panoname :'taslitzky2512'},true);
       // window.thepano.omap.setTarget("htmlTarget");
      }
    
      toggleModal = () => {
        //   console.log("togglemodal");
/*         this.setState({
          isOpen: !this.state.isOpen,
          bookmarktoedit: 1
        }); */
        this.state.isOpen = !this.state.isOpen;
        this.state.bookmarktoedit = 1;

        this.setState(this.state);
        
      }

      getFirstBookmark(){
          return this.state.bookmarks[0]
      }

      readCurrentBookmark(){
          let text = nodeUtils.extractTextFromHTML(this.currentbookmark.text);
          global.artyom.say(text);
      }

      editBookmark(bookmark){
            this.state.isOpen = !this.state.isOpen;
            this.state.bookmarktoedit = bookmark;
        // this.state.currentbookmark = bookmark;

        this.setState(this.state);
      }

      bookmarck_clicked_edit(bookmark){
        // console.log('clickedclicked ', 'clickedclicked ')
        // console.log('clicked ', bookmark)

        

        if (nodeUtils.isMe()){
            this.editBookmark(bookmark)
        }

        
        
        
        /* this.state.bookmarktoedit = 2;
        this.bookmarktoedit = bookmark.index; */

      //  this.addScreenshot(this.currentbookmark);
        
        // console.log('bookmarck_clicked ', nodeUtils.setBookmarkThumb(this.bref,this.panonameparam, index,"test"));
    }

      bookmarck_clicked(bookmark){

        // console.log('bookmark ', bookmark);
       // nodeUtils.SetFullscreen('htmlTarget');

      

       
       
       
       this.setCurrentBookmark(bookmark);

     //   this.showPanoInfo(true);



}




    canAccessBookmark(bookmark){
        if (nodeUtils.isAuthenticated()) return true
        else {
            let bookmarkindex = this.getBookMarkIndex(bookmark)
            return bookmarkindex < 3;
        }
    }

    getBookMarkIndex(bookmark){
        return this.state.bookmarks.findIndex(elem => elem.index === bookmark.index);
    }

    getBookMark(position){
        return this.state.bookmarks[position];
    }

    onBookmarkClicked(bookmark){

        // artyom.say("Coucou Marilou ! Comment ça va ?");
        switch (bookmark.type){
            case 'bookmark':
                this.setCurrentBookmark(bookmark)
              break;
            case 'place':
                this.setCurrentPlace(bookmark)
                break;
          } 
    }

    setCurrentPlace(bookmark){
        this.showPlaceInfoDiv(true);
        nodeUtils.setVisibleBookmarks(false);
        this.currentbookmark = bookmark;
        this.state.currentbookmark = bookmark;
        this.setState(this.state);

        console.log('getWidth',nodeUtils.getWidth())
        console.log('getWidthforZoom',nodeUtils.getWidthforZoom(nodeUtils.getZoom()+1))

        if (nodeUtils.isVertical()) bookmark.moveToRelative(0,50);    
        else bookmark.moveToRelative(-50,0);    

    }

    showPlaceInfoDiv(show){
        this.state.showPlaceInfo = show;
        this.setState(this.state);
    }

    setCurrentBookmark(bookmark){
        
        // if (this.canAccessBookmark(bookmark)){

            nodeUtils.addBookmarkEvent( bookmark);

            nodeUtils.setVisibleBookmarks(false);

            this.showBookmarkInfoDiv(true);

            this.currentbookmark = bookmark;
            this.state.currentbookmark = bookmark;
            this.state.bookmarkindex = this.getBookMarkIndex(bookmark);
            this.setState(this.state);
            window.currentbookmark = bookmark;

            

            let zoom = bookmark.zoom;
            
            window.thepano.flyTo2([bookmark.posx,bookmark.posy],zoom)
        // }
        // else {
        //     this.showAskLogin(true)
        // }

    }

    showBookmarkInfoDiv(show){
        console.log('showBookmarkInfoDiv')
        this.state.showBookmarkInfo = show;
        this.setState(this.state);
    }

    openFirstBookmark(){
        this.setCurrentBookmark(this.getBookMark(0));
    }



    nextBookmark(){       

        if (this.state.bookmarks && this.state.currentbookmark){
            let foundbookmark = this.state.bookmarks.find(element => parseInt(element.index) > parseInt(this.state.currentbookmark.index));
            if (!foundbookmark) foundbookmark = this.state.bookmarks[0]
            this.setCurrentBookmark(foundbookmark); 
        }
        
    }
    prevBookmark(){

        if (this.state.bookmarks && this.state.currentbookmark){
            let foundbookmark = this.state.bookmarks.reverse().find(element => parseInt(element.index) < parseInt(this.state.currentbookmark.index));
            if (!foundbookmark) foundbookmark = this.state.bookmarks[0]
            this.state.bookmarks.reverse();
            this.setCurrentBookmark(foundbookmark); 
        }
        
    }

    setBookmarkInfo(bookmark){
        nodeUtils.toggleVisible('bookmarkInfo')
        nodeUtils.setContent('bookmarkInfoTitre',bookmark.label)
        nodeUtils.setContent('bookmarkInfoText',bookmark.text)
    }

    buttontest(){
        if (window.isaddingFeature) window.addFeature(false);
        else window.addFeature(true);
    }

    addScreenshot(bookmark){
        var screenshot = window.getPanoScreenshot();
        // Jimp.read(screenshot)
        // .then(image => {
        //     image.cover( screenchotsize, screenchotsize ); 

        //     image.quality(60);

        //     image.getBase64(Jimp.MIME_JPEG,function(err,data){
        //         // console.log(data);
        //         // this.saveBookmarck(data)
        //         nodeUtils.addThumbtoBookmark(bookmark.key,data)
        //         // window.bref.add({label: "label to be set",posx: window.thepano.getCenter()[0],posy: window.thepano.getCenter()[1],zoom: window.thepano.getZoom(),index: window.newindex, panoname: window.thepano.panoname,thumburl:data,author:''})
        //         // nodeUtils.getNextBookmarkIndex(window.thepano.panoname,window.bref);
        //         // nodeUtils.setBookmarkThumb(window.bref,panoname,index,data)
        //     });

        // })
        // .catch(err => {
        //     console.error('oops, something went wrong!', err);
        //     // Handle an exception.
        // });
    }

    takeScreenshot(index){
        // var index = 1;
        var panoname = window.panoname;

        var screenshot = window.getPanoScreenshot();
        // Jimp.read(screenshot)
        // .then(image => {
        //     image.cover( screenchotsize, screenchotsize ); 

        //     // console.log("image width",screenchotsize)

        //     // if (ratio > 1){
        //     //     image.crop( (width - screenchotsize)/2, 0, screenchotsize, screenchotsize );  
        //     // } else if (ratio < 1){
        //     //     image.crop(0, (width - screenchotsize)/2,  screenchotsize, screenchotsize );  
        //     // }

        //     image.quality(60);

        //     image.getBase64(Jimp.MIME_JPEG,function(err,data){
        //         // console.log(data);
        //         // this.saveBookmarck(data)
        //         nodeUtils.addBookmark({data: data})
        //         // window.bref.add({label: "label to be set",posx: window.thepano.getCenter()[0],posy: window.thepano.getCenter()[1],zoom: window.thepano.getZoom(),index: window.newindex, panoname: window.thepano.panoname,thumburl:data,author:''})
        //         nodeUtils.getNextBookmarkIndex(window.thepano.panoname,window.bref);
        //         // nodeUtils.setBookmarkThumb(window.bref,panoname,index,data)
        //     });

        // })
        // .catch(err => {
        //     console.error('oops, something went wrong!', err);
        //     // Handle an exception.
        // });



        

        
    }




    onMessageBackgroundClick(){
        this.showAskLogin(false)
    }
    onPanoinfoBackgroundClick(){
        this.showPanoInfo(false)
    }

    startVisit(isAudio){

        this.state.isChoixAudioOpen = false;
        this.state.isAudio = isAudio;
        this.setState(this.state);
        
        global.isvisitAudio = isAudio;
        // this.openFirstBookmark()
    }

    testButton(){

        let test = 2;
/*         handsfree.start(function(){
            console.log("handsfree has been correctly initialized");
            // console.log("Artyom The following array shouldn't be empty" , artyom.getVoices());
            // artyom.say("Hello");
        }) */
        
        // console.log(this.state.bookmarks[3])

        // let tab = [];
        // tab.push(nodeUtils.getPathPointsFromBookmark(this.state.bookmarks[3]))
        // tab.push(nodeUtils.getPathPointsFromBookmark(this.state.bookmarks[8]))
        // tab.push(nodeUtils.getPathPointsFromBookmark(this.state.bookmarks[14]))
        // window.thepano.setAutoMove({pathpoints: tab})

    }

    getAdminpanel(){
        if (this.isAdmin){
            return (
                <div id='adminpanel' className="clickeable">
                <EditBookmark className="EditBookmark" show={this.state.isOpen}
         onClose={this.toggleModal}  bookmark={{bookmark:this.state.bookmarktoedit}}
          >                   
     </EditBookmark>
     
     <button   onClick={() => this.takeScreenshot(1)} >
         Add Bookmark
         </button>
         <button   onClick={() => this.testButton()} >
         Test Button
         </button>
                 </div>     
            )
        }
    }

    onMouseDown = (e) => {
        // only left mouse button
        // if (e.button !== 0) return;
        console.log('onMouseDown event',e.event)
        console.log('onMouseDown',e.target)
        // if (e.target.className == "panelGroup"){
        //     let node = document.getElementById('htmlTarget');
        //     node.dispatchEvent(e.event);
        // }
      };

    render() {
        let bookmarktoedit= 1;
        let hide = false;
        let isAdmin = this.isadmin;

        const {isShowPhoto,audioevent,lightboxposition,isLightBoxOpen,lightboxurl,lightboxtitre,isChoixAudioOpen,isAudio,showPlaceInfo,showBookmarkInfo,userage} = this.state;

        // console.log('showBookmarkInfo',showBookmarkInfo)

        
        return(
            <div id="htmlContainer" style={{ width: "100%", height: "100%" }}>           


            <div className="d-flex justify-content-center">

            <div id="htmlTarget2"  className="map htmltargetright" style={{ width: "50%", height: "100%" }}> </div>
            <div id="htmlTarget"  className="map" style={{ width: "50%", height: "100%" }}></div>

            <div id="clickresult" className="clickres"></div>
                    
                <div  id="htmlUi" className="notclickeable" style={{visibility:"visible",height: "100%"}} >

                    {/*(this.usefaceapi || this.useHandTrack) && 
                            <div className="userage">
                                
                            <video id="video" width="600" height="400" autoPlay muted></video>
                            <div id='userage' >{userage}</div>
                            </div>
                    */ }
                    <PanotopSplit  panorama={this.state.panorama} setShowPanoInfo={this.setShowPanoInfo}/>
                    {this.getAdminpanel()} 

                    <Bookmarks  className="clickeable" panoname={this.panonameparam} bookmarks={this.state.bookmarks} 
                        onBookmarkClicked={this.onBookmarkClicked} bookmarck_clicked_edit={this.bookmarck_clicked_edit}
                        style={{visibility:window.bookmarksVisible }}/>

                       
            

                    {showPlaceInfo && <div id="panelGroupdiv" className="notclickeable full100">

                        <PanelGroup  id="panelGroup" borderColor="grey"   >
                        <div id="hiddenpanel" className="full100h"></div>
                        {showPlaceInfo && 
                                    <PlaceInfo bookmark={this.state.currentbookmark} bookmarkindex={this.state.bookmarkindex} 
                                        nextBookmark={this.nextBookmark} prevBookmark={this.prevBookmark}
                                        showPlaceInfoDiv={this.showPlaceInfoDiv} showLightBox={this.showLightBox}/>}
                        {!showPlaceInfo && 
                                    <div className="full100h"></div>}

                            </PanelGroup>

                    </div>}
                         

                    {(isAudio && showBookmarkInfo)  && <Player bookmark={this.state.currentbookmark}  askshowLightBox={this.askshowLightBox}
                    nextBookmark={this.nextBookmark} prevBookmark={this.prevBookmark} showPhoto={this.showPhoto} openFirstBookmark={this.openFirstBookmark}/>}

                    {(!isAudio && showBookmarkInfo) && 
                        <BookmarksInfo  bookmark={this.state.currentbookmark} showBookmarkInfoDiv={this.showBookmarkInfoDiv}
                        bookmarkindex={this.state.bookmarkindex} isauth={this.state.isauth}
                            nextBookmark={this.nextBookmark} prevBookmark={this.prevBookmark} showLightBox={this.showLightBox} readCurrentBookmark={this.readCurrentBookmark}/>}

                            {isShowPhoto && <Photo audioevent={audioevent}></Photo>}
                
                </div>
                

            </div>

            
                
                
           



                
                {/* <GigaphotoInfo showPanoInfo={this.state.showPanoInfo} panorama={this.state.panorama} setShowPanoInfo={this.setShowPanoInfo} /> */}

                {/* <LightBox  id='lightbox' lightboxurl={lightboxurl}/> */}

                
                <LightBox   lightboxurl={lightboxurl} lightboxtitre={lightboxtitre} position={lightboxposition}/>

                {isChoixAudioOpen && <ChoixAudio  panorama={this.state.panorama} startVisit={this.startVisit}/>}
                {/* {isLightBoxOpen && ( <LightBox  id='lightbox' lightboxurl={lightboxurl} lightboxtitre={lightboxtitre}/>  )} */}
               
                    

                    
                    
                    
                {/* {this.getLoginMessage(this.state.showAskLogin)}     */}

                    
               {/*  {this.getGigaphotoInfo(this.state.showPanoInfo,this.state.panorama)}    */}

                {/* <div className="square" id="htmlTargetscreenshot" ></div> */}
               
                
            </div>

          );
    }



    addBookmarck(){

    }

    saveBookmarck(thumb){
        // console.log("newindex",window.newindex)
        // console.log("centre",window.thepano.getCenter())
        // let bookmark = new Bookmark("label to be set",window.thepano.getCenter(),window.thepano.getZoom(),window.newindex)
        this.bref.add({label: "label to be set",text:"text à completer",posx: window.thepano.getCenter()[0],posy: window.thepano.getCenter()[1],zoom: window.thepano.getZoom(),index: window.newindex, panoname: window.thepano.panoname,thumburl:thumb,author:''})
        nodeUtils.getNextBookmarkIndex(this.panonameparam,this.bref);
    }

    getLoginMessage(show){
    if (show) return (
        <div  id="gigascopephotologin" className="infront full d-flex justify-content-center align-items-center"
        onClick={() => this.onMessageBackgroundClick()}>
        <div className="messageBox">
            <AskIdMessage />
        </div>
        
    </div>
      );
    }

    getGigaphotoInfo(show,panorama){
        if (show && panorama) return (
            <div  id="gigascopephotologin" className="infront full d-flex justify-content-center align-items-center"
            onClick={() => this.onPanoinfoBackgroundClick()}>
            <div className="panoinfomessagebox darkmessageBox">

            <div className="d-flex justify-content-between">

                < div className="d-flex">
                <div ><FontAwesomeIcon className="closebicon" icon="times"  onClick={() => this.close()}/></div>

                </div>

                <div>
                <div className="d-flex">
                <div id="bookmarksinfoplus" > <FontAwesomeIcon icon="search-plus"  onClick={() => this.toggleBig()}/></div>
                <div id="bookmarksinfomoins" ><FontAwesomeIcon icon="search-minus"  onClick={() => this.toggleSmall()}/></div>
                </div>
                    <div ></div>
                </div>

            </div>
            <div className="d-flex flex-column align-items-center ">



                <h2 className="panoinfomessagetitre">{panorama.titre}</h2>

                <div >
                     <img  className="panoinfomessageimg" src={panorama.url + panorama.panoname + "/thumb.jpg"} />
                </div>
               

                <div id="gigaphotoinfotext" className="panoinfomessagetext">

                </div>


                </div>
            </div>
            
        </div>
          );
        }
}



/* class LoginMessage extends Component {
    render() {
      return (
        <div  id="gigascopephotologin" className="infront full d-flex justify-content-center align-items-center"
        onClick={() => this.onMessageBackgroundClick()}>
        <div className="messageBox">
            <AskIdMessage />
        </div>
        
    </div>
      );
    }
  }

class Greeting extends Component {
    render() {
      return <h1>{this.props.greeting}</h1>;
    }
  } */

  
export default GigascopephotoSplitted;

let Content = function(props) {
    return <div style={{ padding: 8 }}>{props.children}</div>;
  };