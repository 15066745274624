import React, { Component } from 'react';
import { Link } from 'react-router-dom';
// import './App.css';
import firebase from '../Firebase';
import withFirebaseAuth from 'react-with-firebase-auth'
import 'firebase/auth';
import HeaderComp from './HeaderComp';
/* import { browserHistory } from 'react-router'; */

import { doc, where, onSnapshot,query,getDocs } from "firebase/firestore";

import nodeUtils from '../js/nodeUtils.js'

// import nodeUtils from '../js/nodeUtils.js'



// const dbRef = firebase.database().ref("internalmessages")
// console.log(dbRef);
// dbRef.on("value", snap => {
//   console.log(snap); // this key will output users
//   console.log(snap.key); // this method will return full user
// });

class PhotosEvent extends Component {
  constructor(props) {
    super(props);
    this.ref = window.events;
    this.unsubscribe = null;
    this.state = {
      gigaphotos: []
    };

    this.event = this.props.match.params.event;
  
    this.targets = [];

    this.allgigaphotos = [];

    this.gigaphotos = [];

    this.isready = false;

    const qalias = query(window.events, where("event", "==", this.event));
      
            getDocs(qalias)
            .then((snapshot) => {
                if (!snapshot.empty) {
                    
                    var doc = snapshot.docs[0].data()

                    console.log("alias pano",doc.target);

                    let isdateok = doc.enddate == null || doc.enddate == "";

                    let enddate = new Date(doc.enddate);
                    let today = new Date();

                    if (isdateok || today <= enddate)  this.targets = doc.liens;
                    else console.log("too late !!!!!!!!!")

                    console.log(this.targets)
                    console.log("isok", "montblanc2");    

                    this.isready = true;

                    this.getValidTargets();

                    this.setState(this.state);
                } 
    
                else {
                    //this.findpano(panoname)
                }
              })
              .catch(err => console.log("there is an error in alieases", err));
    
  }

  getValidTargets(){

    this.state.gigaphotos = [];
    this.allgigaphotos.forEach((doc) => {
      if (this.istargetinevent(doc.panoname)) {
        this.state.gigaphotos.push(doc);
      }

    })
  }

  istargetinevent(target){

    let isok = false;

    this.targets.forEach((doc) => {
      if (doc == target) isok = true;
    })
    
    return isok;
  }

  onCollectionUpdate = (querySnapshot) => {
    //const gigaphotos = [];
    querySnapshot.forEach((doc) => {
      const { titre,
        panoname,
        client,
        theme,
        info,
        width,
        height,
        numtiles,
        witilesize,
        url,
        uiGroups } = doc.data();
      
        this.allgigaphotos.push({
          key: doc.id,
          doc, // DocumentSnapshot
          titre,
          panoname,
          theme,
          info,
          width,
          height,
          numtiles,
          witilesize,
          url,
          uiGroups
        });
      
      
    });

    this.getValidTargets();

    this.setState(this.state);
   
  }

  componentDidMount() {

    const q = query(global.ref);
  const unsubscribe = onSnapshot(q, (querySnapshot) => {
  const cities = [];
  this.onCollectionUpdate(querySnapshot);});

  }




  render() {
    return (
      <div className="container">
        {/* <HeaderComp></HeaderComp> */}
        <div className="panel panel-default">
          <div className="panel-heading">
            <h3 className="panel-title">
              Gigaphotos
            </h3>
          </div>
          <div className="panel-body">
            
            <table className="table table-stripe">
              <thead>
                <tr>
                  {/* <th>Panoname</th> */}
                  <th>Titre</th>
                  {/* <th>Theme</th>
                  <th>Thumb</th> */}
                </tr>
              </thead>
              <tbody>
                {this.state.gigaphotos.map(gigaphoto =>
                  <tr key={gigaphoto.key}>
                    {/* <td><Link to={`/edit/${gigaphoto.key}`}>{gigaphoto.panoname}</Link></td> */}
                    <td><Link to={`/photo/${gigaphoto.panoname}`}>{gigaphoto.titre}</Link></td>
                    <td><img src={gigaphoto.url + gigaphoto.panoname + "/thumb.jpg"} style={{  height: "128px" }} /> </td>
                    {/* <td>{gigaphoto.theme}</td> */}
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

export default PhotosEvent;
