import React, { Component } from 'react';
import Container from 'react-bootstrap/Container'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import parse from 'html-react-parser';

import AskIdMessage from './AskIdMessageComp';
import MapContainer from './MapContainer';

import PanelGroup from "../../node_modules/react-panelgroup/lib/PanelGroup";

import firebase from '../Firebase';

import nodeUtils from '../js/nodeUtils.js'

class PlaceInfo extends Component {
  constructor(props) {
    super(props);
    this.bookmark = props.bookmark;

    this.size = "3em";

    this.askshowLightBox = this.askshowLightBox.bind(this);
    
    
     this.state = {
       shown:true,
      bookmark: props.bookmark,
      big:false,
      showlogin:false,
      isaudio: global.isvisitAudio
    }; 

  }
  
  componentDidMount() {
  // if (nodeUtils.ReadString("InfoBig") == "1") this.toggleBig();
  }

  componentWillUpdate(nextProps, nextState) {

    if (this.isclosing){
      this.isclosing = false;
      return
    }

    if (nextProps.bookmark != this.state.bookmark || nextProps.isauth != this.state.isauth || this.state.bookmarkindex != nextProps.bookmarkindex){

      this.state.bookmark =nextProps.bookmark;
      this.state.shown = true
      this.state.bookmarkindex = nextProps.bookmarkindex

      this.setState(
        this.state
      )
      
    }

  }



  componentDidUpdate(){

    
  }

  getHtml(text) {
    return {__html: text};
  }

  close(){

    this.state.shown = false;
    this.state.bookmarkindex = -1;
    this.isclosing = true;
    
    this.setState(
      this.state
    )

  }

  

  BookmarkInfoText() {
    //return this.state.bookmark.text;
    if (nodeUtils.ReadString("InfoBig") == "1") {
      let text =  "<style type=\"text/css\">p {font-size: 0.3em;line-height: 110%;}</style>" + this.state.bookmark.text;
      return text;
    } else {
      return this.state.bookmark.text;
    }


  }

  getSize(){
    return "3em"
  }

  getMessage(){
     return (
      (this.state.showlogin)?    
      <div  id="bookmarklogin" className="bklogin d-flex justify-content-center"><AskIdMessage /></div>    
            :
            <div id="bkinfo">
              
              <div id="bookmarkInfoTextid" className="bookmarkInfoText" ></div>
            </div>
            
      // <div>
  
      // <div  id="bookmarklogin" className="bklogin d-flex justify-content-center"><AskIdMessage /></div>    
      //       <div id="bkinfo">
      //         <div id="bookmarkInfoTextid" className="bookmarkInfoText" ></div>
      //       </div>
      // </div>
               
    
      );
    }

    askshowLightBox(show,duration,url,titre){
      this.props.showLightBox(show,duration,url,titre)
    }

    onUpdate(panels){
      console.log("panels",panels)
      console.log("onupdate",nodeUtils.getparentHeight("placeInfoPhotoId"))

    }


  render() {

    let nextBookmark = this.props.nextBookmark;
    let prevBookmark = this.props.prevBookmark;
    let showPlaceInfoDiv = this.props.showPlaceInfoDiv;

    const {bookmark} = this.state;
       
    // let size = this.size;

    // const {isaudio} = this.state;

    let containerClassname = 'placeInfoContainer';
    let mapwidth = "80%"
    let mapheight = "90%"
    if (nodeUtils.isVertical()) {
      containerClassname += ' placeInfoBottom';
      // mapwidth = "90%"
    } else {
      containerClassname += ' placeInfoRight';
    }

    let photoclassname = 'placeInfoPhoto';

    let mapclassname = 'placeInfoMap';

    //console.log('map w',)

    

    return (
      
        




  <div className="d-flex flex-column justify-content-center clickeable whiteBackground maxwidth100" >        
    <div className="d-flex justify-content-between">

    < div className="d-flex">
      <div ><FontAwesomeIcon className="closebicon" icon="times"  onClick={() => showPlaceInfoDiv(false)}/></div>
    </div>


      <div>
      <div className="d-flex">
      <FontAwesomeIcon  icon="chevron-left"  onClick={() => prevBookmark()}/> 
      <FontAwesomeIcon className="nextbicon" icon="chevron-right"  onClick={() => nextBookmark()}/>
      </div>
        <div ></div>
      </div>
      
    </div>

    <div className="placeInfotitre">{bookmark.label}</div>  

    
    

    <PanelGroup direction="column" onUpdate={(panels) => this.onUpdate(panels)} panelWidths={[
  {size: 200, resize: "stretch"},
  {size: 10, resize: "fixed"},
  {size: 100, resize: "stretch"}]}>

<img  id="placeInfoPhotoId" src={bookmark.getImage()} className={photoclassname} /> 

<div className='horizontalsplitter'></div>
  <MapContainer bookmark={bookmark} width={mapwidth} height={mapheight} className={mapclassname} /> 
  {/* <div>panel 3</div> */}
</PanelGroup>

 
  
  


          
            
          
          <div className="d-flex flex-column justify-content-center">

            {/* <img  src={bookmark.getImage()} className={photoclassname} />      */}

                

          </div>
          {/* <div className={mapclassname}>
          <MapContainer bookmark={bookmark} width={mapwidth} height={mapheight} className={mapclassname} /> 
          </div> */}

          
                      
          
          

      </div>
      
      
    );
  }
}

export default PlaceInfo;
