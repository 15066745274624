import React from 'react';

import { withFirebase } from '../Firebase';

import firebase from '../Firebase';


function signout() {
  console.log('sign')
}

const SignOutButton = () => (
  // <button type="button" onClick={firebase.doSignOut}>
  <button type="button" onClick={signout()}>
    Sign Out
  </button>
);

export default withFirebase(SignOutButton);
