import React, { Component } from 'react';

import Panotop from './PanotopComp';

import YouTube from 'react-youtube';

import nodeUtils from '../js/nodeUtils.js'



class VideoComp extends Component {
  constructor(props) {
    super(props);


    const opts = {
      height: '100%',
      width: '100%',
      playerVars: {
        // https://developers.google.com/youtube/player_parameters
        autoplay: 1,
      },
    };

    // const playerStyle = classNames(className, {
    //   true: fill,
    // });

    this.videoname = this.props.match.params.videoname;

    this.isyoutube =true;

    this.titre = "Piranesi's Vase - British Museum - Uncut Video - Digital Twin by Gigascope";

    
    this.videoref = "kqlps1DKYSs"

    if (this.videoname == "schiele2") {
      this.videoref = "GPxNyYdlOfo"
      this.titre = 'Egon Schiele - Portrait of Wally Neuzil - 1912 - Digital twin by Gigascope'
    }  else 
    if (this.videoname == "schiele3") {
      this.videoref = "nKO2hh_UE1I"
      this.titre = 'Egon Schiele - Dead Mother - 1910 - Digital twin by Gigascope'
    } else
    if (this.videoname == "schiele1") {
      this.videoref = "W6JgCKlgx-0"
      this.titre = 'Egon Schiele - Self Portrait with Chinese lantern - 1912 - Digital twin by Gigascope'
    }
 if (this.videoname == "royalmintdraft1") {
      this.videoref = "3PYzoBwQ9s8"
      this.titre = 'Royal Mint first draft Video - Digital twin by Gigascope'
    }
    if (this.videoname == "royalmint_7_v2") {
      this.videoref = "6xQI5O9DlSQ"
      this.titre = 'The Platinum Jubilee of Her Majesty The Queen 2022 x7 Draft V2- Digital twin by Gigascope'
    }
    if (this.videoname == "royalmintpoc1") {
      this.videoref = "0WqsEUsELhU"
      this.titre = 'POC of a video showing the creation of the The Platinum Jubilee of Her Majesty The Queen 2022 coin'
    }    
    if (this.videoname == "royalmintpoc2") {
      this.videoref = "KjZ_wl_79Co"
      this.titre = 'POC v2 of a video showing the creation of the The Platinum Jubilee of Her Majesty The Queen 2022 coin'
    }    
    if (this.videoname == "royalmint_700_v2") {
      this.videoref = "TjDnG-YNDi8"
      this.titre = 'The Platinum Jubilee of Her Majesty The Queen 2022 x7 Draft V2- Digital twin by Gigascope'
    }
    if (this.videoname == "reel2022") {
      this.videoref = "EOWFTueMt5U"
      this.titre = 'Reel Gigascope 2022'
    }
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    // this.toggleFullscreen = this.toggleFullscreen.bind(this);


    
    
     this.state = {
      opts:opts,
      titre:this.titre,
      isyoutube: this.isyoutube,
      url: "https://www.youtube.com/embed/"+this.videoref+"?autoplay=1&amp;vq=haute résolution&amp;loop=1&amp;rel=0&amp;mute=1&amp;controls=1&amp;modestbranding=1&amp;enablejsapi=1&amp;showinfo=0&amp;widgetid=5"
     }
      
    // }; 

    
  }
  
  componentDidMount() {
   
  }

  componentWillUpdate(nextProps, nextState) {
    

      this.setState(
        this.state
      )
    
 
  }

  button1(){
    console.log("bouton1")

    // console.log("trad",)

    // console.log("trad",nodeUtils.translateText(this.state.texttosend,'en'))

    // nodeUtils.translateText(this.state.texttosend,'en').then((value) => console.log("trad",value));
    
    // sayText('...Bonjour...',1,4,2);	
    // nodeUtils.sendMessager("eb419390-c795-11eb-8568-075bdb4298e2",{"type":"message","content":"hello there"});

    nodeUtils.sendMessager("eb419390-c795-11eb-8568-075bdb4298e2",{"type":"avatarsay","content":this.state.texttosend});
    // nodeUtils.sendMessager("eb419390-c795-11eb-8568-075bdb4298e2",{"type":"hideavatar"});
  }

  button2(){
    console.log("bouton1")
    
    // sayText('...Bonjour...',1,4,2);	
    // nodeUtils.sendMessager("eb419390-c795-11eb-8568-075bdb4298e2",{"type":"message","content":"hello there"});

    nodeUtils.sendMessager("eb419390-c795-11eb-8568-075bdb4298e2",{"type":"showavatar"});
  }

  handleSubmit(event) {
    // alert('Le nom a été soumis : ' + this.state.value);
    console.log("event.target.value",event.target.value)
    nodeUtils.sendMessager("eb419390-c795-11eb-8568-075bdb4298e2",{"type":"message","content":event.target.value});
  }

  handleChange(event) {
    // console.log("tt" )
    // console.log(event.target.value )
    console.log('this.state.texttosend',this.state.texttosend)
    if (event.target.value != this.state.texttosend) {
      // console.log("hh")
      // this.setState({texttosend: event.target.value});
      this.state.texttosend = event.target.value
    }  
  }
 
  _onReady(event) {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  }

  render() {

    const {opts,url,titre,isyoutube } = this.state;

   
    return (
      <div className="arksan blackBackground" >


        
                    
        <div className="d-flex infront logotop notclickeable" >
            {/* <img className="logotopright clickeable infront" src={'https://data2.gigascope.net/data/logo_full_url_white.png'} alt="Logo" onClick={nodeUtils.openInNewTab('https://www.gigascope.net')}/>  */}
            <img className="logotopright clickeable infront" src={'https://data2.gigascope.net/data/logo_full_url_white.png'} alt="Logo" />   
            <div className="youtubetitre notclickeable">{titre}</div>
        </div>
        {/* <div className="youtube">
          <YouTube videoId="kqlps1DKYSs" opts={opts} onReady={this._onReady} />
        </div> */}

     {isyoutube &&    
      <iframe frameborder="0" allowfullscreen="1" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
        title="YouTube video player" width="100%" height="100%" src={url} id="widget6"></iframe>
     }
      {/* <div className="password infront">
          dd
        </div>   */}
      </div>
      
    );
  }
}

export default VideoComp;
