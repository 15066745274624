import React, { Component } from 'react';

import Panotop from './PanotopComp';

import nodeUtils from '../js/nodeUtils.js'



class Niracomp extends Component {
  constructor(props) {
    super(props);


    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    // this.toggleFullscreen = this.toggleFullscreen.bind(this);

    // this.arskannb = "https://viewer.arskan.com/objects/share/626a8e89779c85210af98be2"
    this.arskannb = "https://gigascope.nira.app/a/MdTg9ufoSM2J2Xq-f7NqxQ/1"

    
     this.state = {
       texttosend:''
     }
      
    // }; 

  }
  
  componentDidMount() {
   
  }

  componentWillUpdate(nextProps, nextState) {
    

      this.setState(
        this.state
      )
    
 
  }

  button1(){
    console.log("bouton1")

    // console.log("trad",)

    // console.log("trad",nodeUtils.translateText(this.state.texttosend,'en'))

    // nodeUtils.translateText(this.state.texttosend,'en').then((value) => console.log("trad",value));
    
    // sayText('...Bonjour...',1,4,2);	
    // nodeUtils.sendMessager("eb419390-c795-11eb-8568-075bdb4298e2",{"type":"message","content":"hello there"});

    nodeUtils.sendMessager("eb419390-c795-11eb-8568-075bdb4298e2",{"type":"avatarsay","content":this.state.texttosend});
    // nodeUtils.sendMessager("eb419390-c795-11eb-8568-075bdb4298e2",{"type":"hideavatar"});
  }

  button2(){
    console.log("bouton1")
    
    // sayText('...Bonjour...',1,4,2);	
    // nodeUtils.sendMessager("eb419390-c795-11eb-8568-075bdb4298e2",{"type":"message","content":"hello there"});

    nodeUtils.sendMessager("eb419390-c795-11eb-8568-075bdb4298e2",{"type":"showavatar"});
  }

  handleSubmit(event) {
    // alert('Le nom a été soumis : ' + this.state.value);
    console.log("event.target.value",event.target.value)
    nodeUtils.sendMessager("eb419390-c795-11eb-8568-075bdb4298e2",{"type":"message","content":event.target.value});
  }

  handleChange(event) {
    // console.log("tt" )
    // console.log(event.target.value )
    console.log('this.state.texttosend',this.state.texttosend)
    if (event.target.value != this.state.texttosend) {
      // console.log("hh")
      // this.setState({texttosend: event.target.value});
      this.state.texttosend = event.target.value
    }  
  }
 

  render() {

   
   
    return (
      <div className="arksan blackBackground" >

      


{/* <iframe frameborder="0" width="100%" height="100%" loading="lazy" style="background:black" allowfullscreen src="https://gigascope.nira.app/a/MdTg9ufoSM2J2Xq-f7NqxQ/1"></iframe>                     */}
<iframe width="100%" height="100%" src={this.arskannb} allowfullscreen></iframe>

        <div className="d-flex infront logotop notclickeable" >
            <img className="logotopright clickeable infront" src={'https://data2.gigascope.net/data/logo_full_url_white.png'} alt="Logo" />   
            <div className="arksantitre notclickeable">
              <div>Draft of the Piranesi's Vase 3D Model</div>
              <div className="arksansoustitre">resolution is 1/100 of the final result</div>
              <div className="arksansoustitre">select HD in the toolbox to get high res textures</div>
            </div>
        </div>
        
      </div>
      
    );
  }
}

export default Niracomp;
