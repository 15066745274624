import React, { Component } from 'react';
import 'aframe';
// import { Loader3DTiles } from 'three-loader-3dtiles';
// import './aframe3dtiles';
// import 'aframe-particle-system-component';
import {Entity, Scene} from 'aframe-react';

// require('aframe');
// require('aframe-loader-3dtiles-component');

import nodeUtils from '../js/nodeUtils.js'

// require('aframe');
// require('aframe-loader-3dtiles-component');

class VrComp extends Component {
  constructor(props) {
    super(props);


    
     this.state = {
       texttosend:''
     }
      
    // }; 

    
  }
  
  componentDidMount() {
   
  }

  componentWillUpdate(nextProps, nextState) {
    

      this.setState(
        this.state
      )
    
 
  }

  button1(){
    console.log("bouton1")


  }

  button2(){
    console.log("bouton1")
    

  }

 

  render() {

   
   
    return (
      <Scene>
        {/* <a-entity
        id="freeman-tiles"
        position="0 2.6 0"
        rotation="-90 180 0"
        scale="2 2 2"
        loader-3dtiles="
         url: https://int.nyt.com/data/3dscenes/ONA360/TILESET/0731_FREEMAN_ALLEY_10M_A_36x8K__10K-PN_50P_DB/tileset_tileset.json; 
         maximumSSE: 48;
         cameraEl: #camera;
        "
      >
      </a-entity> */}
      <Entity geometry={{primitive: 'box'}} material={{color: 'red'}} position={{x: 0, y: 0, z: -5}}/>
      <Entity light={{type: 'point'}}/>
        <Entity gltf-model={{src: 'virtualcity.gltf'}}/>
        <Entity text={{value: 'Hello, WebVR!'}}/>
        {/* <a-entity 
          geometry="primitive: box" 
          position="-1 0.5 -3" 
          rotation="0 45 0" 
          material="color: #4CC3D9" />
        <a-entity 
          geometry="primitive: sphere; radius: 1.25;" 
          position="0 1.25 -5" 
          material="color: #EF2D5E" />
        <a-entity 
          geometry="primitive: cylinder; radius: 0.5, height: 1.5" 
          position="1 0.75 -3" 
          material="color: #FFC65D" />
        <a-entity 
          geometry="primitive: plane; width: 4; height: 4" 
          position="0 0 -4" 
          rotation="-90 0 0" 
          material="color: #7BC8A4" /> */}
      </Scene>
      
    );
  }
}

export default VrComp;
