import { Provider } from 'react-redux';
import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import * as SWRTC from '@andyet/simplewebrtc';

import RtcApp from "../webrtc/App";

import getConfigFromMetaTag from '../webrtc/utils/metaConfig';


/* import { faCoffee } from '@fortawesome/free-solid-svg-icons'
 */
import nodeUtils from '../js/nodeUtils.js'
// import nodeUtils from '../js/nodeUtils.js'

const API_KEY = 'acef0b8cfe4cadc2e1f9f3e0';
// ====================================================================

const ROOM_NAME = 'test';
// const ROOM_NAME = '';
const ROOM_PASSWORD = '1234';
const CONFIG_URL = `https://api.simplewebrtc.com/config/guest/${API_KEY}`;

// const configUrl = getConfigFromMetaTag('config-url');
// const CONFIG_URL = configUrl ? configUrl : '';

const userData = getConfigFromMetaTag('user-data');
const USER_DATA = userData ? userData : '';

const store = SWRTC.createStore();

class RtcComp extends Component {
  constructor(props) {
    super(props);
    
    
     this.state = {
      
    }; 

    
  }
  
  componentDidMount() {
   
  }

  componentWillUpdate(nextProps, nextState) {
     
     
  }

  

  render() {

    
    return (
      
    //   <Provider store={store}>
    //   <RtcApp
    //     configUrl={CONFIG_URL}
    //     roomName={ROOM_NAME}
    //     roomPassword={''}
    //   />
    // </Provider>
    <Provider store={store}>
    <RtcApp
      roomName={ROOM_NAME}
      configUrl={CONFIG_URL}
      userData={USER_DATA}
      initialPassword={ROOM_PASSWORD}
      roomConfig={{
        openToPublic : true,
        showHostVideo : true,
        showVisitorVideo: true ,
        allowInvites : true,
        allowShareScreen: true,
        allowWalkieTalkieMode: true,
        allowChat: false,
      }}
    />
  </Provider>
    );
  }
}

export default RtcComp;
