import { Loader3DTiles, LoaderProps, Runtime } from 'three-loader-3dtiles'
import { Loader, Object3D,Euler,Vector3 } from 'three'
import { useLoader, useThree, useFrame } from '@react-three/fiber'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';


class Loader3DTilesBridge extends Loader {
  props: LoaderProps | undefined;

  load(url:any, onLoad:any, onProgress:any, onError:any) {
    const loadTileset = async () => {
      try {
        // const result = await Loader3DTiles.load({
        //    url,
        //    ...this.props,
        //   onProgress
        //  })
        const result = await Loader3DTiles.load(
          {
            url,
            options: {
              dracoDecoderPath: 'https://unpkg.com/three@0.123.0/examples/js/libs/draco/gltf/',
              maximumScreenSpaceError: window.errorvalue,
              viewDistanceScale : 1
            },
            onProgress
          })
          
         onLoad(result);
      }
      catch(e) {
        console.log("Error loading 3d tiles!", e);
        onError(e);
      }
    }
    // console.log("errorvalue !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",window.errorvalue );
    loadTileset();
  }
  setProps(props:any) {
    this.props = props;
  }
};

// class Loader3DTilesBridge2 extends Loader {
//   props: LoaderProps | undefined;

//   load(url:any, onLoad:any, onProgress:any, onError:any) {
//     const loadTileset = async () => {
//       try {
//         const result = await GLTFLoader.load({
//            url,
//            ...this.props,
//           onProgress
//          })
//          let result2
//          onLoad(result);
//       }
//       catch(e) {
//         console.log("Error loading 3d tiles!", e);
//         onError(e);
//       }
//     }
//     loadTileset();
//   }
//   setProps(props:any) {
//     this.props = props;
//   }
// };


export  function Loader3DTilesR3FAsset(props:any) {
  const threeState = useThree();
  const loaderProps = {
    renderer: threeState.gl,
    options: {
      ...props
    }
  }

  // TODO: Getting type error
  // @ts-ignore
  const { model, runtime } = useLoader(Loader3DTilesBridge, window.url, (loader:Loader3DTilesBridge) => {
    loader.setProps(loaderProps);    
  })

  // console.log("errorvalue !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",window.errorvalue );

  runtime.setShading(2);

  // model.rotateOnAxis(new Vector3(1,0,0), Math.PI*0.5)

  useFrame(({ gl, camera }, dt) => {
    runtime.update(dt, gl, camera);
  });

  

  return (
    <group {...props} dispose={runtime.dispose}>
      <primitive object={model} 
      position = {[0,0.343,0]}
      scale={window.modelresize }
      rotation={window.modelrotation}
      />
    </group>
  )
}


