import { Position } from "./Position";

export class BrowseEvent{
    constructor(options) {
        var self = this;

    this.type = options.type;
    this.data = options;
      }
    
      getthetype() {
          return this.type;
      }

      getToken(){
          return this.data.data.token
      }

      getExtent(){
        return this.data.data.extent
    }

      isFromThisUser(){
          return this.getToken() == global.token
      }

      getPosition(){
          return new Position(this.data.data.center,this.data.data.zoom);
      }

      getPanoname(){
        return this.data.data.panoname
      }

      isFromCurrentPano(){
        return this.getPanoname() == window.thepano.panoname;
      }

      getText(){
        return this.data.data.text
      }

};

BrowseEvent.prototype.setPano = function(options){

}