import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import nodeUtils from '../js/nodeUtils.js'
// import * as three from 'three'

// import { DebugTilesRenderer } from '3d-tiles-renderer';

// import {
// 	DebugTilesRenderer,
// 	NONE,
// 	SCREEN_ERROR,
// 	GEOMETRIC_ERROR,
// 	DISTANCE,
// 	DEPTH,
// 	RELATIVE_DEPTH,
// 	IS_LEAF,
// 	RANDOM_COLOR,
// } from 'DebugTilesRenderer';

import {
	DebugTilesRenderer,
	NONE,
	SCREEN_ERROR,
	GEOMETRIC_ERROR,
	DISTANCE,
	DEPTH,
	RELATIVE_DEPTH,
	IS_LEAF,
	RANDOM_COLOR,
} from '3d-tiles-renderer';

import { TilesRenderer } from '3d-tiles-renderer';
import { TilesRendererBase } from '3d-tiles-renderer';
// import nodeUtils from '../js/nodeUtils.js'

import { LRUCache } from '3d-tiles-renderer';
import { PriorityQueue } from '3d-tiles-renderer';

import { FlyOrbitControls } from '../js/tiled/FlyOrbitControls';

import { BufferGeometryUtils } from 'three/examples/jsm/utils/BufferGeometryUtils.js';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js';

// import * as dat from 'three/examples/jsm/libs/dat.gui.module.js';

import Stats from 'three/examples/jsm/libs/stats.module.js';

// import { TilesRenderer } from '../../src/data/tileset.json';

// import Stats from '../../src/data/tileset.json';

import {
	Scene,
	DirectionalLight,
	AmbientLight,
	WebGLRenderer,
	PerspectiveCamera,
	CameraHelper,
	Box3,
	Raycaster,
	Vector2,
	Mesh,
	CylinderBufferGeometry,
	MeshBasicMaterial,
	Group,
	TorusBufferGeometry,
	OrthographicCamera,
	sRGBEncoding,
} from 'three';

const dat = require('dat.gui');

const ALL_HITS = 1;
const FIRST_HIT_ONLY = 2;

const hashUrl = window.location.hash.replace( /^#/, '' );
let camera, controls, scene, renderer, tiles, cameraHelper;
let thirdPersonCamera, thirdPersonRenderer, thirdPersonControls;
let secondRenderer, secondCameraHelper, secondControls, secondCamera;
let orthoCamera, orthoCameraHelper;
let box;
let raycaster, mouse, rayIntersect, lastHoveredElement;
let offsetParent;
let statsContainer, stats;




class TestTiles2 extends Component {
  constructor(props) {
    super(props);
        
     this.state = {
      
    }; 

    this.animate = this.animate.bind(this);
    this.renderScene = this.renderScene.bind(this);
    this.reinstantiateTiles = this.reinstantiateTiles.bind(this);

    console.log("1111111111111111111111111111",this.props.match.params.name)

    this.modelename = this.props.match.params.name

    this.params = {

      'enableUpdate': true,
      'raycast': NONE,
      'enableCacheDisplay': false,
      'enableRendererStats': false,
      'orthographic': false,
    
      'errorTarget': 1,
      'errorThreshold': 60,
      'maxDepth': 15,
      'loadSiblings': true,
      'stopAtEmptyTiles': true,
      'displayActiveTiles': false,
      'resolutionScale': 1.0,
      'up': '+Y',
      // 'up': hashUrl ? '+Z' : '+Y',
      'displayBoxBounds': true,
      'colorMode': 0,
      'showThirdPerson': false,
      'showSecondView': false,
      'reload': this.reinstantiateTiles,
          
    };

    
    

  }

  init(){


    this.scene = new Scene();

    this.renderer = new WebGLRenderer( { antialias: true } );
    this.renderer.setPixelRatio( window.devicePixelRatio );
    this.renderer.setSize( window.innerWidth, window.innerHeight );
    this.renderer.setClearColor( 0x151c1f );
    this.renderer.outputEncoding = sRGBEncoding;   

    document.getElementById("tileContainer").appendChild( this.renderer.domElement );
    this.renderer.domElement.tabIndex = 1;

    

    // this.camera = new PerspectiveCamera( 60, window.innerWidth / window.innerHeight, 1, 4000 );
    this.camera = new PerspectiveCamera( 60, window.innerWidth / window.innerHeight, 0.01, 4000 );
    // this.camera = new PerspectiveCamera( 60, 0.7305045871559633, 1, 4000 );
	this.camera.position.set( 400, 400, 400 );
  // this.camera.position.set( 0, 0, -400 );
  // this.camera.lookAt( 0, 0, 0 );
	this.cameraHelper = new CameraHelper( this.camera );
	this.scene.add( this.cameraHelper );

	this.orthoCamera = new OrthographicCamera();
	this.orthoCameraHelper = new CameraHelper( this.orthoCamera );
	this.scene.add( this.orthoCameraHelper );

	// secondary camera view
	// this.secondCamera = new PerspectiveCamera( 60, window.innerWidth / window.innerHeight, 1, 4000 );
	// this.secondCamera.position.set( 400, 400, - 400 );
	// this.secondCamera.lookAt( 0, 0, 0 );

	// secondRenderer = new WebGLRenderer( { antialias: true } );
	// secondRenderer.setPixelRatio( window.devicePixelRatio );
	// secondRenderer.setSize( window.innerWidth, window.innerHeight );
	// secondRenderer.setClearColor( 0x151c1f );
	// secondRenderer.outputEncoding = sRGBEncoding;

	// document.body.appendChild( secondRenderer.domElement );
	// secondRenderer.domElement.style.position = 'absolute';
	// secondRenderer.domElement.style.right = '0';
	// secondRenderer.domElement.style.top = '0';
	// secondRenderer.domElement.style.outline = '#0f1416 solid 2px';
	// secondRenderer.domElement.tabIndex = 1;

	// secondControls = new FlyOrbitControls( secondCamera, secondRenderer.domElement );
	// secondControls.screenSpacePanning = false;
	// secondControls.minDistance = 1;
	// secondControls.maxDistance = 2000;

	// secondCameraHelper = new CameraHelper( secondCamera );
	// this.scene.add( secondCameraHelper );

	// Third person camera view
	// thirdPersonCamera = new PerspectiveCamera( 60, window.innerWidth / window.innerHeight, 1, 4000 );
	// thirdPersonCamera.position.set( 50, 40, 40 );
	// thirdPersonCamera.lookAt( 0, 0, 0 );

	// thirdPersonRenderer = new WebGLRenderer( { antialias: true } );
	// thirdPersonRenderer.setPixelRatio( window.devicePixelRatio );
	// thirdPersonRenderer.setSize( window.innerWidth, window.innerHeight );
	// thirdPersonRenderer.setClearColor( 0x0f1416 );
	// thirdPersonRenderer.outputEncoding = sRGBEncoding;

	// document.body.appendChild( thirdPersonRenderer.domElement );
	// thirdPersonRenderer.domElement.style.position = 'fixed';
	// thirdPersonRenderer.domElement.style.left = '5px';
	// thirdPersonRenderer.domElement.style.bottom = '5px';
	// thirdPersonRenderer.domElement.tabIndex = 1;

	// thirdPersonControls = new FlyOrbitControls( thirdPersonCamera, thirdPersonRenderer.domElement );
	// thirdPersonControls.screenSpacePanning = false;
	// thirdPersonControls.minDistance = 1;
	// thirdPersonControls.maxDistance = 2000;

	// controls
	this.controls = new FlyOrbitControls( this.camera, this.renderer.domElement );
	this.controls.screenSpacePanning = false;
	this.controls.minDistance = 1;
	this.controls.maxDistance = 2000;

	// lights
	const dirLight = new DirectionalLight( 0xffffff );
	dirLight.position.set( 1, 2, 3 );
	this.scene.add( dirLight );

	const ambLight = new AmbientLight( 0xffffff, 0.2 );
	this.scene.add( ambLight );

	box = new Box3();

	this.offsetParent = new Group();
  // this.offsetParent.position.setX(this.offsetParent.position.x-50);
  //   this.offsetParent.position.setY(this.offsetParent.position.y-50);
    // this.offsetParent.position.setZ(-50);
	this.scene.add( 	this.offsetParent );

	// Raycasting init
	this.raycaster = new Raycaster();
	this.mouse = new Vector2();

	this.rayIntersect = new Group();

	const rayIntersectMat = new MeshBasicMaterial( { color: 0xe91e63 } );
  this.rayMesh = new Mesh( new CylinderBufferGeometry( 0.25, 0.25, 6 ), rayIntersectMat );
  this.rayMesh.rotation.x = Math.PI / 2;
  this.rayMesh.position.z += 3;
	this.rayIntersect.add( 	this.rayMesh );

  this.rayRing = new Mesh( new TorusBufferGeometry( 1.5, 0.2, 16, 100 ), rayIntersectMat );
	this.rayIntersect.add( 	this.rayRing );
	this.scene.add( this.rayIntersect );
	this.rayIntersect.visible = false;

	this.reinstantiateTiles();

  // GUI
	const gui = new dat.GUI();
	gui.width = 300;

	const tileOptions = gui.addFolder( 'Tiles Options' );
	tileOptions.add( this.params, 'loadSiblings' );
	tileOptions.add( this.params, 'stopAtEmptyTiles' );
	tileOptions.add( this.params, 'displayActiveTiles' );
	tileOptions.add( this.params, 'errorTarget' ).min( 0 ).max( 50 );
	tileOptions.add( this.params, 'errorThreshold' ).min( 0 ).max( 1000 );
	tileOptions.add( this.params, 'maxDepth' ).min( 1 ).max( 100 );
	tileOptions.add( this.params, 'up', [ '+Y', '+Z', '-Z' ] );
	tileOptions.open();

	const debug = gui.addFolder( 'Debug Options' );
	debug.add( this.params, 'displayBoxBounds' );
	debug.add( this.params, 'colorMode', {

		NONE,
		SCREEN_ERROR,
		GEOMETRIC_ERROR,
		DISTANCE,
		DEPTH,
		RELATIVE_DEPTH,
		IS_LEAF,
		RANDOM_COLOR,

	} );
	debug.open();

	const exampleOptions = gui.addFolder( 'Example Options' );
	exampleOptions.add( this.params, 'resolutionScale' ).min( 0.01 ).max( 2.0 ).step( 0.01 ).onChange( this.onWindowResize );
	exampleOptions.add( this.params, 'orthographic' );
	exampleOptions.add( this.params, 'showThirdPerson' );
	exampleOptions.add( this.params, 'showSecondView' ).onChange( this.onWindowResize );
	exampleOptions.add( this.params, 'enableUpdate' ).onChange( v => {

		tiles.parseQueue.autoUpdate = v;
		tiles.downloadQueue.autoUpdate = v;

		if ( v ) {

			tiles.parseQueue.scheduleJobRun();
			tiles.downloadQueue.scheduleJobRun();

		}

	} );
	exampleOptions.add( this.params, 'raycast', { NONE, ALL_HITS, FIRST_HIT_ONLY } );
	exampleOptions.add( this.params, 'enableCacheDisplay' );
	exampleOptions.add( this.params, 'enableRendererStats' );
	exampleOptions.open();

	gui.add( this.params, 'reload' );
	gui.open();
    
  }

  onWindowResize() {

    // thirdPersonCamera.aspect = window.innerWidth / window.innerHeight;
    // thirdPersonCamera.updateProjectionMatrix();
    // thirdPersonRenderer.setSize( Math.floor( window.innerWidth / 3 ), Math.floor( window.innerHeight / 3 ) );
  
    // if ( params.showSecondView ) {
  
    //   camera.aspect = 0.5 * window.innerWidth / window.innerHeight;
    //   renderer.setSize( 0.5 * window.innerWidth, window.innerHeight );
  
    //   secondCamera.aspect = 0.5 * window.innerWidth / window.innerHeight;
    //   secondRenderer.setSize( 0.5 * window.innerWidth, window.innerHeight );
    //   secondRenderer.domElement.style.display = 'block';
  
    // } else {
  
      this.camera.aspect = window.innerWidth / window.innerHeight;
      this.renderer.setSize( window.innerWidth, window.innerHeight );
  
      // secondRenderer.domElement.style.display = 'none';
  
    // }
    this.camera.updateProjectionMatrix();
    this.renderer.setPixelRatio( window.devicePixelRatio * this.params.resolutionScale );
  
    // secondCamera.updateProjectionMatrix();
    // secondRenderer.setPixelRatio( window.devicePixelRatio );
  
    // updateOrthoCamera();
  
  }

  render2() {

    // updateOrthoCamera();
  
    this.cameraHelper.visible = false;
    // this.orthoCameraHelper.visible = false;
    // this.secondCameraHelper.visible = false;
  
    // render primary view
    if ( 	this.params.orthographic ) {
  
      const dist = 	this.orthoCamera.position.distanceTo( 	this.rayIntersect.position );
      this.rayIntersect.scale.setScalar( dist / 150 );
  
    } else {
  
      const dist = 	this.camera.position.distanceTo( 	this.rayIntersect.position );
      this.rayIntersect.scale.setScalar( dist * 	this.camera.fov / 6000 );
  
    }
    this.renderer.render( 	this.scene, 	this.params.orthographic ? 	this.orthoCamera : 	this.camera );
  /*
    // render secondary view
    if ( 	this.params.showSecondView ) {
  
      const dist = 	this.secondCamera.position.distanceTo( 	this.rayIntersect.position );
      this.rayIntersect.scale.setScalar( dist * 	this.secondCamera.fov / 6000 );
      this.secondRenderer.render( 	this.scene, 	this.secondCamera );
  
    }
  
    // render third person view
    this.thirdPersonRenderer.domElement.style.visibility = 	this.params.showThirdPerson ? 'visible' : 'hidden';
    if ( params.showThirdPerson ) {
  
      cameraHelper.update();
      cameraHelper.visible = ! params.orthographic;
  
      orthoCameraHelper.update();
      orthoCameraHelper.visible = params.orthographic;
  
      if ( params.showSecondView ) {
  
        secondCameraHelper.update();
        secondCameraHelper.visible = true;
  
      }
  
      const dist = thirdPersonCamera.position.distanceTo( rayIntersect.position );
      rayIntersect.scale.setScalar( dist * thirdPersonCamera.fov / 6000 );
      thirdPersonRenderer.render( scene, thirdPersonCamera );
  
    }
  */

    /*
    const cacheFullness = 	this.tiles.lruCache.itemList.length / 	this.tiles.lruCache.maxSize;
    let str = `Downloading: ${ 	this.tiles.stats.downloading } Parsing: ${ 	this.tiles.stats.parsing } Visible: ${ 	this.tiles.group.children.length - 2 }`;
  
    if ( 	this.params.enableCacheDisplay ) {
  
      const geomSet = new Set();
      this.tiles.traverse( tile => {
  
        const scene = 	this.tile.cached.scene;
        if ( 	this.scene ) {
  
          this.scene.traverse( c => {
  
            if ( c.geometry ) {
  
              geomSet.add( c.geometry );
  
            }
  
          } );
  
        }
  
      } );
  
      let count = 0;
      geomSet.forEach( g => {
  
        count += BufferGeometryUtils.estimateBytesUsed( g );
  
      } );
      str += `<br/>Cache: ${ ( 100 * cacheFullness ).toFixed( 2 ) }% ~${ ( count / 1000 / 1000 ).toFixed( 2 ) }mb`;
  
    }
  
    if ( 	this.params.enableRendererStats ) {
  
      const memory = 	this.renderer.info.memory;
      const programCount = 	this.renderer.info.programs.length;
      str += `<br/>Geometries: ${ memory.geometries } Textures: ${ memory.textures } Programs: ${ programCount }`;
  
    }
  
    if ( 	this.statsContainer.innerHTML !== str ) {
  
      this.statsContainer.innerHTML = str;
  
    }
  
    */
  }
  

  // reinstantiateTiles() {

  //   // const url = hashUrl || "https://data.gigascope.net/3d/piecefullnolum2/tileset.json";
  //   // const url = "https://data.gigascope.net/3d/dome1/tileset.json";
  //   const url = "https://nasa-ammos.github.io/3DTilesRendererJS/example/data/tileset.json";
  //   // const url = "../../src/data/tileset.json";
  

  
  //   if ( this.tiles ) {
  
  //     this.offsetParent.remove( this.tiles.group );
  //     this.tiles.dispose();
  
  //   }
  
  //   // Note the DRACO compression files need to be supplied via an explicit source.
  //   // We use unpkg here but in practice should be provided by the application.
  //   this.dracoLoader = new DRACOLoader();
  //   this.dracoLoader.setDecoderPath( 'https://unpkg.com/three@0.123.0/examples/js/libs/draco/gltf/' );

  //   const loader = new GLTFLoader( this.tiles.manager );
  //   loader.setDRACOLoader( dracoLoader );
  
  //   this.tiles.fetchOptions.mode = 'cors';
  //   this.tiles.manager.addHandler( /\.gltf$/, loader );
  //   offsetParent.add( this.tiles.group );
     
  //   // this.tiles = new TilesRenderer( url );
  //   // this.tiles.fetchOptions.mode = 'cors';
  //   // this.tiles.manager.addHandler( /\.gltf$/, {
  
  //   //   parse( ...args ) {
  
  //   //     const loader = new GLTFLoader( this.tiles.manager );
  //   //     loader.setDRACOLoader( this.dracoLoader );
  //   //     return loader.parse( ...args );
  
  //   //   }
  
  //   // } );
  //   // this.offsetParent.add( this.tiles.group );
  
  // }

  reinstantiateTiles() {

    let url = '../../src/data/tileset.json';
    // url = 'https://data2.gigascope.net/3d/exemplestiles/TilesetWithDiscreteLOD/tileset.json';
    // const url = "https://nasa-ammos.github.io/3DTilesRendererJS/example/data/tileset.json";
    // const url = "https://data.gigascope.net/3d/dome1/tileset.json";
    url = hashUrl || "https://data.gigascope.net/3d/piecefullnolum2/tileset.json";
    // const url = hashUrl || "https://data.gigascope.net/3d/datalimoges/tileset.json";

    // const url = "https://data2.gigascope.net/3d/tileset.json";

    if (this.modelename == "ammos") url = "https://nasa-ammos.github.io/3DTilesRendererJS/example/data/tileset.json";
    if (this.modelename == "piece") url = "https://data.gigascope.net/3d/piecefullnolum2/tileset.json";
    if (this.modelename == "limoges") url = "https://data.gigascope.net/3d/datalimoges/tileset.json";


    if ( this.tiles ) {
  
      offsetParent.remove( this.tiles.group );
      this.tiles.dispose();
  
    }
  
    // this.tiles = new TilesRenderer( url );
    this.tiles = new DebugTilesRenderer( url );
  
    // Note the DRACO compression files need to be supplied via an explicit source.
    // We use unpkg here but in practice should be provided by the application.
    const dracoLoader = new DRACOLoader();
    dracoLoader.setDecoderPath( 'https://unpkg.com/three@0.123.0/examples/js/libs/draco/gltf/' );
  
    const loader = new GLTFLoader( this.tiles.manager );
    loader.setDRACOLoader( dracoLoader );
  
    this.tiles.fetchOptions.mode = 'cors';
    this.tiles.manager.addHandler( /\.gltf$/, loader );
    this.offsetParent.add( this.tiles.group );
  
  }

  start() {
    if (!this.frameId) {
      this.frameId = requestAnimationFrame(this.animate)
    }
  }

  animate() {

    // requestAnimationFrame( this.animate );
  
    // update options
    // let u = 2;
    
    // let mu = 2;

    // console.log(this.params)
    this.tiles.errorTarget = this.params.errorTarget;
    this.tiles.errorThreshold = this.params.errorThreshold;
    this.tiles.loadSiblings = this.params.loadSiblings;
    this.tiles.stopAtEmptyTiles = this.params.stopAtEmptyTiles;
    this.tiles.displayActiveTiles = this.params.displayActiveTiles;
    this.tiles.maxDepth = this.params.maxDepth;
    this.tiles.displayBoxBounds = this.params.displayBoxBounds;
    this.tiles.colorMode = parseFloat( this.params.colorMode );
  
    if ( this.params.orthographic ) {
  
      this.tiles.deleteCamera( this.camera );
      this.tiles.setCamera( this.orthoCamera );
      this.tiles.setResolutionFromRenderer( this.orthoCamera, this.renderer );
  
    } else {
  
      this.tiles.deleteCamera( this.orthoCamera );
      this.tiles.setCamera( this.camera );
      this.tiles.setResolutionFromRenderer( this.camera, this.renderer );
  
    }
  
    // if ( this.params.showSecondView ) {
  
    //   this.tiles.setCamera( this.secondCamera );
    //   this.tiles.setResolutionFromRenderer( this.secondCamera, secondRenderer );
  
    // } else {
  
    //   this.tiles.deleteCamera( this.secondCamera );
  
    // }
  
    this.offsetParent.rotation.set( 0, 0, 0 );
    if ( this.params.up === '-Z' ) {
  
      this.offsetParent.rotation.x = Math.PI / 2;
  
    } else if ( this.params.up === '+Z' ) {
  
      this.offsetParent.rotation.x = - Math.PI / 2;
  
    }
  
    this.offsetParent.updateMatrixWorld( true );
  
    // update tiles center
    if ( this.tiles.getBounds( box ) ) {
  
      box.getCenter( this.tiles.group.position );
      this.tiles.group.position.multiplyScalar( - 1 );
  
    }
  
    if ( parseFloat( this.params.raycast ) !== NONE && lastHoveredElement !== null ) {
  
      if ( this.lastHoveredElement === this.renderer.domElement ) {
  
        this.raycaster.setFromCamera( mouse, this.params.orthographic ? orthoCamera : camera );
  
      } else {
  
        // this.raycaster.setFromCamera( mouse, secondCamera );
  
      }
  
      this.raycaster.firstHitOnly = parseFloat( this.params.raycast ) === FIRST_HIT_ONLY;
  
      const results = this.raycaster.intersectObject( this.tiles.group, true );
      if ( results.length ) {
  
        const closestHit = results[ 0 ];
        const point = closestHit.point;
        this.rayIntersect.position.copy( point );
  
        // If the display bounds are visible they get intersected
        if ( closestHit.face ) {
  
          const normal = closestHit.face.normal;
          normal.transformDirection( closestHit.object.matrixWorld );
          this.rayIntersect.lookAt(
            point.x + normal.x,
            point.y + normal.y,
            point.z + normal.z
          );
  
        }
  
        this.rayIntersect.visible = true;
  
      } else {
  
        this.rayIntersect.visible = false;
  
      }
  
    } else {
  
      this.rayIntersect.visible = false;
  
    }
  
    // update tiles
    window.tiles = this.tiles;
    if ( this.params.enableUpdate ) {
  
      // this.secondCamera.updateMatrixWorld();
      this.camera.updateMatrixWorld();
      this.orthoCamera.updateMatrixWorld();
      this.tiles.update();
  
    }

    this.render2();

    this.renderScene()
    this.frameId = window.requestAnimationFrame(this.animate)
  
    
    // stats.update();
  
  }

  renderScene() {
    this.renderer.render(this.scene, this.camera)
  }
  
  
  componentDidMount() {
    this.init();
    this.start();
  }

  componentWillUpdate(nextProps) {



  }

  componentDidUpdate(){
        
  }

  testButton(){
    // console.log(this.camera.position);
    // console.log(this.offsetParent.position);
    this.offsetParent.position.setX(this.offsetParent.position.x-50);
    this.offsetParent.position.setY(this.offsetParent.position.y-50);

    // console.log(this.offsetParent.position);
    
  }

  render() {

    // const {lightboxtitre,lightboxurl,isplaying} = this.state;
    // console.log(lightboxtitre)

    return (
      
      
      // (lightboxurl != "") &&

      <div id="tileContainer" style={{ width: "100%", height: "100%" }}>

<button   onClick={() => this.testButton()} >
                Test Button
            </button>
        
              HEllo
            
      </div>
      
    );
  }
}

export default TestTiles2;
