// var sizeof = require('object-sizeof');
import { v1 as uuidv1 } from 'uuid';

import { Messager } from './Messager';

import { doc,collection, query, where, getDocs } from "firebase/firestore";

// const translate = require('translate-google');

const BrowseEvent = require('./browsinghistory.js')




export default {
  

  "sourceType": "unambiguous",

  openInNewTab(url){
    console.log(url)
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  },

  translateText(text,target) {
    // translate(text, {to: 'en'}).then(res => {
    //   console.log(res.from.text.value);
    //   //=> I [speak] Dutch
    // }).catch(err => {
    //     console.error(err);
    // });
    
  },

  extractTextFromHTML(s) {
    var span = document.createElement('span');
    span.innerHTML = s;
    return span.textContent || span.innerText;
  },


    getNextBookmarkIndex: async function (panoname,collection) {
      
      // console.log("getNextBookmarkIndex" + panoname)

      let index = 1;
      window.newindex = 1;

      const q = query(global.messagingref, where("panoname", "==", panoname));
  

      const snapshot = await getDocs(q);
      snapshot.forEach((doc) => {
        if (doc.data().index == index) index = index +1 ;
        window.newindex = index;
      });

    },
    bar: function () {
      // whatever
    },

    getPathPointsFromBookmark(bookmark){
      return {x: bookmark.posx,y: bookmark.posy,zoom : bookmark.zoom}
    },

    addHistoryPoint: function (params) {
      window.bref.add({label: "label to be set",posx: window.thepano.getCenter()[0],posy: window.thepano.getCenter()[1],zoom: window.thepano.getZoom(),index: window.newindex, panoname: window.thepano.panoname,thumburl:params.data,author:''})
    },

    getTime:function () {
      return new Date().getTime();
    },

    addAnnotation: function name(annotation) {

      // console.log(annotation)
      // console.log(annotation.feature.toString())

      window.annotationsref.add({panoid: annotation.panoid ,panoname: annotation.panoname ,data:annotation.getSaveData()})
      .then(function(docRef) {
        annotation.id = docRef.id;
        window.gigascopephoto.addNewAnnotationWithId(annotation);
      })


    },

    updateAnnotation:function name(annotation) {

      window.annotationsref.doc(annotation.id).update({"data":annotation.getSaveData()});
   
    },

    addMyPopup: function name(mypopup) {
      // let popup = mypopup;
      window.popupsref.add({panoname: mypopup.panoname ,index: window.maxMyPopupIndex+1,data:JSON.stringify(mypopup.data)})
      .then(function(docRef) {
        let tt = 2;
        console.log("Document written with ID: ", docRef.id);
        mypopup.id = docRef.id;
        window.gigascopephoto.addNewPopupWithId(mypopup);
      })
    },

    updateMyPopup:function name(mypopup) {

      if (mypopup.hasText()) window.popupsref.doc(mypopup.id).update({"data":JSON.stringify(mypopup.data)})
      else window.popupsref.doc(mypopup.id).delete();
    },

    addTextToMyPopup: function (popup,action) {
      console.log(popup,action)

      var text = prompt("Texte à ajouter");
      if (text != ""){
          var qui = prompt("Qui êtes vous ?",this.getLastPopupAuthor());
          if (qui != ""){
              this.setLastPopupAuthor(qui)              
              popup.addText(qui,text);
              // nodeUtils.addMyPopup(mypopup);

              window.popupsref.doc(popup.id).update({"data":JSON.stringify(popup.data)})
          }                            
      }      
    },

    async setMessager(){

      const q = query(global.messagingref, where("owner", "==", this.getLocalId()));
  

      const snapshot = await getDocs(q);
      snapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        console.log(doc.id, " => ", doc.data());
        if (!snapshot.empty) {
          console.log("All data in 'messagingref' collection", snapshot.docs[0].data());
          if (!global.messagerelem){
            global.messagerelem = snapshot.docs[0];
            global.messagerelemid = snapshot.docs[0].id;
            global.messager = new Messager(snapshot.docs[0].data());
            console.log("messager",global.messager);
          } else {
            console.log("messager0",snapshot.docs[0].data());
            this.processMessager(snapshot.docs[0].data());
          }
          
        }       
      });


    },

    sendMessager(target,data){
      console.log("target",target)
      window.messagingref.where('owner', '==', target).get()
      .then((snapshot) => {
        if (!snapshot.empty) {
          window.messagingref.doc(snapshot.docs[0].id).update({"data":data, "date": this.getTime()})
          // snapshot.docs[0].update({"data":data})
        }
      });
      
    },

    processMessager(messager){
      console.log("new messager", messager.data.content)
      let type = messager.data.type;
      let content = messager.data.content;

      global.myEmitter.emit('messengerReceived', messager.data);
      // console.log("new messager type", type)
      // console.log("new message content", messager.data)
      // }
      // switch(type){
      //   case 'message':
      //     console.log("new message",messager.data.content);
      //     global.myEmitter.emit('messengerReceived', {"type":"message","content":content});
      //     break;
      //   case 'action':
      //       // console.log("new message",messager.data.content);
      //       global.myEmitter.emit('messengerReceived', {"type":"action","data":messager.data});
      //       break;
          
      // }
      // if (messager.data.type == "message")  
      // {
      //       console.log("new message", messager.content)
      // }
    },

    addBookmark: function name(params) {
      console.log("addBookmark",params)
      window.bref.add({label: "label to be set",posx: window.thepano.getCenter()[0],posy: window.thepano.getCenter()[1],zoom: window.thepano.getZoom(),index: window.maxMyPopupIndex+1, panoname: window.thepano.panoname,thumburl:params.data,author:''})
    },

    addBookmarkFull: function name(params) {
      window.bref.add({label: params.label,posx: params.posx,posy: params.posy,zoom: params.zoom,index: params.index, panoname: params.panoname,thumburl:params.thumb,author:params.author})
      
    },

    addThumbtoBookmark: function(id,thumb){
      window.bref.doc(id).update({"thumburl":thumb})
    },

        saveImage: function(image,panoname,index,collection,firebase) {

          var test = 2;
        // 1 - We add a message with a loading icon that will get updated with the shared image.
        // collection.add({
        // //   name: getUserName(),
        // //   imageUrl: LOADING_IMAGE_URL,
          
        //   profilePicUrl: getProfilePicUrl(),
        //   timestamp: firebase.firestore.FieldValue.serverTimestamp()
        // }).then(function(messageRef) {
          // 2 - Upload the image to Cloud Storage.
         // var filePath = firebase.auth().currentUser.uid + '/' + messageRef.id + '/' + file.name;
         var databasepath = "Bookmark/" + panoname + "/" + index + ".jpg"
          return firebase.storage().ref(databasepath).put(image).then(function(fileSnapshot) {
            // 3 - Generate a public URL for the file.
            return fileSnapshot.ref.getDownloadURL().then((url) => {
              // 4 - Update the chat message placeholder with the image's URL.
              this.setBookmarkThumb(collection,panoname,index,url)
              // return messageRef.update({
              //   imageUrl: url,
              //   thumburl: fileSnapshot.metadata.fullPath
              // });
            });
          // });
        }).catch(function(error) {
          console.error('There was an error uploading a file to Cloud Storage:', error);
        });
      },

      addBrowseEvent(type,event,upload){
        
        if (!window.panosettings.saveBrowseEvents) return;
        let BEvent = {t: type,d:Math.round(Date.now()/1000),e:event}
        let userhistory = this.getBrowseHistory();
        userhistory.history.push(BEvent)
        this.saveBrowseHistory(userhistory);
        // if (upload) this.uploadUserHistory();
      },
      
      getUserBrowseString(){
        return this.ReadString("BrowseString");
      },
      getBrowseHistory(){
        let userhistoryString = this.getUserBrowseString();
        if (!userhistoryString || userhistoryString == "") {
          let userhistory = {history : []}
          return userhistory;
        } else {
          return JSON.parse(userhistoryString);
        }
      },
      
      saveBrowseHistory(userhistory){
        let string = JSON.stringify(userhistory)
        this.SaveString("BrowseString",string);
      },

      setLastPopupAuthor(name){
        this.SaveString("LastPopupAuthor",name);
      },

      getLastPopupAuthor(){
        let result = this.ReadString("LastPopupAuthor","");
        if (result == "null") result = "";
        return result;
      },

      hasToSendBrowseEvent(){        
        return global.ismaster;
      },

      isMaster(){        
        return global.ismaster;
      },

      isSlave(){        
        return global.isslave;
      },

      isCommunicating(){        
        return this.isMaster() || this.isSlave();
      },

      updateInternalMessage(collection,type,message){
        // let bref = firebase.firestore().collection('bookmarks');
        collection.where('type', '==', type).get()
      .then(snapshot => {

        
        // console.log(snapshot.docs[0].id);
        // console.log(global.token);

        if (!snapshot.empty) {
          let id = snapshot.docs[0].id;
          // à remettre:
          collection.doc(id).update({"message":message,"sender":global.token})
          
        } 
      
      })
      .catch(err => {
        console.log('Error getting documents', err);
        
      });
      },

      sendSpeechToText(text){
        if (this.hasToSendBrowseEvent()){
          // var test = global.user != null;
          // if (test) test = global.user.email == 'cyrilpreiss@gmail.com'
        
          // if (!test) return
      
          var browseEvent = new BrowseEvent({type: 'speechtotext', panoname: window.panoname,time: new Date().getTime(),textmessage: text})
          this.updateInternalMessage(global.imref,'speechtotext',JSON.stringify(browseEvent))
      
          console.log("browseEventtext",browseEvent)
          
          /*if  (window.user)  {
            // console.log('browsed: ' + window.panoname + window.user.email, data);
            var browseEvent = new BrowseEvent({type: 'endMove', panoname: window.panoname,time: new Date().getTime(),extent: data.extent,zoom: data.zoom,user: window.user,token: global.token})
            
            nodeUtils.updateInternalMessage(global.imref,'sync',JSON.stringify(browseEvent))
            // console.log(browseEvent);
          }
          else  {
            var browseEvent = new BrowseEvent({type: 'endMove', panoname: window.panoname,time: new Date().getTime(),extent: data.extent,zoom: data.zoom,user: 'nosuser',token: global.token})
            nodeUtils.updateInternalMessage(global.imref,'sync',JSON.stringify(browseEvent))
        
            // console.log('fooJSON: ' , fooJSON.getthetype());
            // console.log('browsed: ' + window.panoname , data);
          } */
        }
      },


    // saveImageMessage: function(file) {
    //     // 1 - We add a message with a loading icon that will get updated with the shared image.
    //     firebase.firestore().collection('messages').add({
    //       name: getUserName(),
    //       imageUrl: LOADING_IMAGE_URL,
    //       profilePicUrl: getProfilePicUrl(),
    //       timestamp: firebase.firestore.FieldValue.serverTimestamp()
    //     }).then(function(messageRef) {
    //       // 2 - Upload the image to Cloud Storage.
    //       var filePath = firebase.auth().currentUser.uid + '/' + messageRef.id + '/' + file.name;
    //       return firebase.storage().ref(filePath).put(file).then(function(fileSnapshot) {
    //         // 3 - Generate a public URL for the file.
    //         return fileSnapshot.ref.getDownloadURL().then((url) => {
    //           // 4 - Update the chat message placeholder with the image's URL.
    //           return messageRef.update({
    //             imageUrl: url,
    //             storageUri: fileSnapshot.metadata.fullPath
    //           });
    //         });
    //       });
    //     }).catch(function(error) {
    //       console.error('There was an error uploading a file to Cloud Storage:', error);
    //     });
    //   },

      setBookmarkThumb(collection,panoname,index,thumb){
        // let bref = firebase.firestore().collection('bookmarks');
        collection.where('panoname', '==', panoname).where('index', '==', index).get()
      .then(snapshot => {

        if (!snapshot.empty) {
          let id = snapshot.docs[0].id;
          collection.doc(id).update({"thumburl":thumb})
          
        } 
      
      })
      .catch(err => {
        console.log('Error getting documents', err);
        
      });
      },
    setHeight(anId,height){
        let node = document.getElementById(anId);
        if (!node) return;
        node.style.height = height;       
    },
    getHeight(anId){
      let node = document.getElementById(anId);
      if (!node) return 0;
      return node.style.height;       
  },
  getparentHeight(anId){
    let node = document.getElementById(anId);
    if (!node) return 0;
    return node.parentElement.style.height;       
},
    setMaxHeight(anId,height){
      let node = document.getElementById(anId);
      if (!node) return;
      node.style.maxHeight = height;
  },
    setFontSize(anId,size){
      let node = document.getElementById(anId);
      if (!node) return;
      node.style.fontSize = size;
  },
  setVisible2(anId,visible){
    let node = document.getElementById(anId);
    if (!node) return;
    if (visible){


        node.style.display = 'block';
        
        //node.style.height = "auto";	
        //node.style.width = "auto";		// Optionnel rétablir la hauteur
    }
    else
    {
        // Contenu visible, le cacher

        node.style.display = 'none';
        //node.style.height = "0";
        //node.style.width = "0";			// Optionnel libérer l'espace
    }
},
setVisible(anId,visible){
  let node = document.getElementById(anId);
  if (!node) return;
  if (visible){

      node.style.visibility = "visible";
      //node.style.height = "auto";	
      //node.style.width = "auto";		// Optionnel rétablir la hauteur
  }
  else
  {
      // Contenu visible, le cacher
      node.style.visibility = "hidden";
      //node.style.height = "0";
      //node.style.width = "0";			// Optionnel libérer l'espace
  }
},
setVisibleFull(anId,visible){
  let node = document.getElementById(anId);
  if (!node) return;
  if (visible){

      node.style.visibility = "visible";
      node.style.height = "auto";	
      node.style.width = "auto";		// Optionnel rétablir la hauteur
  }
  else
  {
      // Contenu visible, le cacher
      node.style.visibility = "hidden";
      node.style.height = "0";
      node.style.width = "0";			// Optionnel libérer l'espace
  }
},
setVisible100(anId,visible){
  let node = document.getElementById(anId);
  if (!node) return;
  if (visible){

      node.style.visibility = "visible";
      node.style.height = "100%";	
      node.style.width = "100%";		// Optionnel rétablir la hauteur
  }
  else
  {
      // Contenu visible, le cacher
      node.style.visibility = "hidden";
      node.style.height = "0";
      node.style.width = "0";			// Optionnel libérer l'espace
  }
},
setDimensions(anId,width,height){
  let node = document.getElementById(anId);
  if (!node) return;
  node.style.height = width;
  node.style.width = height;
},
setOpacity(anId,visible){
  let node = document.getElementById(anId);
  if (!node) return;
  if (visible){
    // this.setVisible100(anId,true) 
      node.style.opacity = "100";
  }
  else
  {
    node.style.opacity = "0";
    let self = this;
      // window.setTimeout(function(){ self.setVisible100(anId,false) }, 3000)
  }  
},
setOpacityFaded(anId,visible,delay){
  let node = document.getElementById(anId);
  if (!node) return;
  // node.style.transition = 'opacity ' + delay +'s';
  if (visible){
    this.setVisible(anId,visible);
    this.setOpacity(anId,visible);
  }
  else
  {
    this.setOpacity(anId,visible);
    var self = this;
    window.setTimeout(function(){ self.setVisible(anId,visible) }, delay * 1100)
  }  

},

      toggleVisible(anId){
        let node = document.getElementById(anId);
        if (node.style.visibility=="hidden")
        {
            // Contenu caché, le montrer
            node.style.visibility = "visible";
            //node.style.height = "auto";	
            //node.style.width = "auto";		// Optionnel rétablir la hauteur
        }
        else
        {
            // Contenu visible, le cacher
            node.style.visibility = "hidden";
            //node.style.height = "0";
            //node.style.width = "0";			// Optionnel libérer l'espace
        }
    },
    setVisibleBookmarks(show){
      console.log("setVisibleBookmarks 2",show)
      let node = document.getElementById('Bookmarks');
      if (show)
      {
          // Contenu caché, le montrer
          node.style.visibility = "visible";
          node.style.height = "auto";	
          //node.style.width = "auto";		// Optionnel rétablir la hauteur
      }
      else
      {
       
          // Contenu visible, le cacher
          node.style.visibility = "hidden";
          node.style.height = "0";
          //node.style.width = "0";			// Optionnel libérer l'espace
      }
    },
    toggleVisibleBookmarks(){
      console.log("toggleVisibleBookmarks")
      let node = document.getElementById('Bookmarks');
      if (!node) return
      this.setVisibleBookmarks(node.style.visibility=="hidden");
  },
  toggleVisibleBookmarkInfo(){
    
    let node = document.getElementById('bookmarksinfodiv');
    if (!node) return
    if (node.style.visibility=="hidden")
    {
        // Contenu caché, le montrer
        node.style.visibility = "visible";
/*         if (this.ReadString("InfoBig") == "1") this.setMaxHeight("bookmarksinfodiv","50vh");
        else this.setMaxHeight("bookmarksinfodiv","25vh"); */
        
       // node.style.height = "auto";	
        //node.style.width = "auto";		// Optionnel rétablir la hauteur
    }
    else
    {
        // Contenu visible, le cacher
        node.style.visibility = "hidden";
        this.setVisible("bookmarksinfoplus",false);
        this.setVisible("bookmarksinfomoins",false);
/*         node.style.height = "0"; */

      //  node.style.height = "0";
        //node.style.width = "0";			// Optionnel libérer l'espace
    }
},
showBookmarkInfo(){
  let node = document.getElementById('bookmarksinfodiv');

  if (node)
      node.style.visibility = "visible";
     // node.style.height = "auto";	
      //node.style.width = "auto";		// Optionnel rétablir la hauteur
  
},
getZoom(){
  return window.thepano.getZoom();
},
getWidth(){
  return window.thepano.getWidth();
},
getHeight(){
  return window.thepano.getHeight();
},
getWidthforZoom(zoom){
  return window.thepano.getWidthforZoom(zoom);
},
isMobileVertical(){
  return (  window.innerWidth <= 1024 );
},
isVertical(){
  return (  window.innerWidth <= window.innerHeight );
},
    setContent(anId,content){
      try {
        document.getElementById(anId).innerHTML = content;
      }
      catch(error) {
        console.error(error);
        // expected output: ReferenceError: nonExistentFunction is not defined
        // Note - error messages will vary depending on browser
      }
      
      
    },
    SetPanoSplitFullscreen(){
      this.SetFullscreen('htmlContainer'); 
    },
    toggleSplitFullScreen(){
      (document.fullscreen)? 
      this.ExitFullscreen() : this.SetPanoSplitFullscreen();
    },
    SetPanoFullscreen(){
      this.SetFullscreen('htmlTarget'); 
    },
    togglePanoFullScreen(){
      (document.fullscreen)? 
      this.ExitFullscreen() : this.SetPanoFullscreen();
    },

    togglePanoFullScreen(){
      this.SetFullscreen('htmlTarget'); 
    },


    
    SetFullscreen(id) {
      let docElm = document.getElementById(id);
      global.isFullScreen = true;

     
      if (docElm.requestFullscreen) {
          docElm.requestFullscreen();
      }
      else if (docElm.mozRequestFullScreen) {
          docElm.mozRequestFullScreen();
      }
      else if (docElm.webkitRequestFullScreen) {
          docElm.webkitRequestFullScreen();
      }
      else if (docElm.msRequestFullscreen) {
          docElm.msRequestFullscreen();
      }

    },

    // SetFullscreen(id,isfull) {

    //   console.log("fullScreen",isfull)

    //   global.isFullScreen = isfull;

    //   if (isfull){
    //     this.SetFullscreen(id);
    //   } else {
    //     this.ExitFullscreen();
    //   }
     
    // },

    ExitFullscreen(){
      global.isFullScreen = false;
      if (document.exitFullscreen) {
        document.exitFullscreen();
    }
    else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
    }
    else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
    }
    else if (document.msExitFullscreen) {
        document.msExitFullscreen();
    }
    },
    SaveString(name,string){
      console.log("SaveString",name,string)
      try {
        localStorage.setItem(name, string);
      } catch (error) {
        console.error(error);
        // expected output: ReferenceError: nonExistentFunction is not defined
        // Note - error messages will vary depending on browser
      }
      
      
    },
    ReadString(name,string){
      return localStorage.getItem(name, string);
    },
    SaveFloat(name,value){
      // console.log("SaveFloat",name,string)
     this.SaveString(name,value.toString())     
      
    },
    ReadFloat(name,defaul){
      return this.ReadString(name,defaul.toString())
    },
    ReadBoolean(name,defaul){
      let def = "1";
      if (!defaul) def = "0";
      return localStorage.getItem(name, def) == "1";
    },
    SaveBoolean(name,val){
      if (val) this.SaveString(name,"1")
      else this.SaveString(name,"0")
    },
    getUser(){
      return global.user;
    },
    isAuthenticated(){
      return global.islogged;
  },
  isMe(){
    let isautoh = this.isAuthenticated();
    let user  = this.getUser();
    return  isautoh && user.email == "cyrilpreiss@gmail.com"
  },
  isBeta(){
    let isautoh = this.isAuthenticated();
    let user  = this.getUser();
    return  isautoh && (user.email == "cyrilpreiss@gmail.com" || user.email == "camillemichel76@gmail.com")
  },
  isUrlLegit(){
    let theurl = window.location.href;
    return (theurl.includes("dtwin") || theurl.includes("localhost")) 
  },
  getUserHistoryString(){
    return this.ReadString("HistoryString");
  },
  getUserHistory(){
    let userhistoryString = this.getUserHistoryString();
    
    if (!userhistoryString || userhistoryString == "") {
      let userhistory = {history : []}
      return userhistory;
    } else {
      return JSON.parse(userhistoryString);
    }
  },
  
  saveUserHistory(userhistory){
    let string = JSON.stringify(userhistory)
    this.SaveString("HistoryString",string);
  },

  addHistoryEvent(type,event,upload){

    if (!window.panosettings.saveBrowseEvents) return;
    
    let historyEvent = {t: type,d:Math.round(Date.now()/1000),e:event}
    let userhistory = this.getUserHistory();
    userhistory.history.push(historyEvent)
    this.saveUserHistory(userhistory);
    if (upload) this.uploadUserHistory();
  },
  addBookmarkEvent(bookmark){
    this.addHistoryEvent('b', {p: bookmark.panoname, l: bookmark.label,k: bookmark.key})    

/*     let dates = new Date(Date.now()).toISOString();
    dates = dates.slice(2,10) + " " +dates.slice(11,19)
    this.addHistoryEvent({type: "bk",  ds: dates,d: Date.now(),bk : {pano: bookmark.panoname, lab: bookmark.label,key: bookmark.key}})    */ 
  },
  addGigaphotoEvent(panorama){

    this.addHistoryEvent('g', {i: panorama.id})   
    /* let dates = new Date(Date.now()).toISOString();
    dates = dates.slice(2,10) + " " +dates.slice(11,19)
    this.addHistoryEvent({type: "gig",  ds: dates,d: Date.now(),gig : {pano: panorama.panoname, id: panorama.id}})   */  
  },
  addNewLocalUserEvent(){

    this.addHistoryEvent('lc', {id : this.getLocalId()})  

/*     let dates = new Date(Date.now()).toISOString();
    dates = dates.slice(2,10) + " " +dates.slice(11,19)
    this.addHistoryEvent({type: "newl",ds: dates, d: Date.now(), uuid : this.getLocalId()})   */  
  },
  addLoginEvent(){  
    this.addHistoryEvent('l', {email : this.getUserEmail()})     
    /* this.addHistoryEvent({type: "login",ds: this.getDateString(), d: Date.now(), email : this.getUserEmail()}) */   
    if (window.historykey){
      window.userHist.doc(window.historykey).update({user:this.getUser().email})
   }  
  },
  addNewVisitEvent(ip){    

    if (!window.panosettings.saveBrowseEvents) return;

    let newvisitevent = {ref : document.referrer, href : window.parent.location.href, 
      ipinfo:{ip:ip.ip, city: ip.city,country: ip.country,lat: ip.latitude,long:ip.longitute,
      timezone: ip.timezone, utc:ip.utc_offset}};

    this.addHistoryEvent('v', {v : newvisitevent},true)     

    // this.addHistoryEvent({type: "newV",ds: this.getDateString(), d: Date.now(), 
    // ref : document.referrer, href : window.parent.location.href, ipinfo:{ip:ip.ip, city: ip.city,country: ip.country,lat: ip.latitude,long:ip.longitute,
    // timezone: ip.timezone, utc:ip.utc_offset}},true)   

  },
  getDateString(){
    let dates = new Date(Date.now()).toISOString();
    dates = dates.slice(2,10) + " " +dates.slice(11,19)
    return dates
  },
  getLocalId(){    

    return this.ReadString("localid",this.setLocalId());
  },
  setLocalId(){
    /*  nodeUtils.SaveString("localid",""); */
     
     let id = this.ReadString("localid")
     if (!id || id == "") {
       id = uuidv1();
       this.SaveString("localid",id);
       this.addNewLocalUserEvent();
       this.uploadUserHistory() 
     }
     return id;
   },
  getUserEmail(){
    let email = this.getUser();
    if (email) email = email.email;
    else email = "";
    return email;
  },
  uploadUserHistory() {

    if (this.isAuthenticated() && this.isUserHistorydowloaded()){
      
      if (!window.isemailsaved){
        this.addLoginEvent();
      }      
    }
    /* window.userHist.add({uuid : this.getLocalId(), user: this.getUserEmail(),UserHistory: this.getUserHistory}) */
   // 
    if (this.getSavedUserHistoryKey()){  
      
      this.uploadBigString(window.userHist,this.getUserHistoryString());
      

      // window.userHist.doc(this.getSavedUserHistoryKey()).update({UserHistory:this.getUserHistoryString()})

      window.pendingupload = false;
   } else { 
      window.pendingupload = true;
   }
    
  },
  byteLength(str) {
    // returns the byte length of an utf8 string
    var s = str.length;
    for (var i=str.length-1; i>=0; i--) {
      var code = str.charCodeAt(i);
      if (code > 0x7f && code <= 0x7ff) s++;
      else if (code > 0x7ff && code <= 0xffff) s+=2;
      if (code >= 0xDC00 && code <= 0xDFFF) i--; //trail surrogate
    }
    return s;
  },
  processIp(ip){
    // console.log("ipipip",ip)
    this.addNewVisitEvent(ip);
  },
  saveUserHistoryMap(histo){
    window.downloadedHistory = histo;
  },
  getSavedUserHistoryMap(){
    return window.downloadedHistory;
  },
  getSavedUserHistoryKey(){
    if (!this.isUserHistorydowloaded()) return null
    let key = this.getSavedUserHistoryMap().id
    return key;
  },
  getSavedUserHistoryData(){
    if (!this.isUserHistorydowloaded()) return null
    return this.getSavedUserHistoryMap().data();
  },
  isUserHistorydowloaded(){
    return this.getSavedUserHistoryMap() != null
  },
  downloadUserHistory(){
    if (!window.panosettings.saveBrowseEvents) return;

    window.userHist.where('id', '==', this.getLocalId()).get()
        .then(snapshot => {
              if (!snapshot.empty) {
                var histo = snapshot.docs[0].data()
                let test = JSON.stringify(histo);
                this.joinBigString(histo);
                window.isemailsaved = histo.user != "";
                this.saveUserHistoryMap(snapshot.docs[0]);
                
                if (window.pendingupload) this.uploadUserHistory();
/*                 let test = JSON.parse(histo.UserHistory);
                let test2 = JSON.parse(histo); */
              } else {
                let test = window.userHist.add({id : this.getLocalId(), user: this.getUserEmail(),UserHistory: this.getUserHistoryString()})
                  test.then((info) => {
                    let test = info;
                    this.saveUserHistoryMap(info);
                  })
              }
            })
            .catch(err => {
            console.log('Error getting documents', err);
            });
  },
  joinBigString(data){
   
    // console.log('{"history":[{"t":"l","d":1601219559,"e":{"email":"cyrilpreiss@gmail.com"}},{"t":"v","d":1601219560,"e":{"v":{"ref":"http://localhost:3000/","href":"http://localhost:3000/","ipinfo":{"ip":"81.251.86.100","city":"Amiens","country":"FR","lat":49.9007,"timezone":"Europe/Paris","utc":"+0200"}}}},{"t":"g","d":1601219571,"e":{"i":"7ouL10BUqrSx578mDvns"}},{"t":"l","d":1601220403,"e":{"email":"cyrilpreiss@gmail.com"}},{"t":"v","d":1601220405,"e":{"v":{"ref":"http://localhost:3000/photo/newsaintechapelle","href":"http://localhost:3000/photo/newsaintechapelle","ipinfo":{"ip":"81.251.86.100","city":"Amiens","country":"FR","lat":49.9007,"timezone":"Europe/Paris","utc":"+0200"}}}},{"t":"g","d":1601220409,"e":{"i":"7ouL10BUqrSx578mDvns"}}]}')
    let i = 0;
    let result = "";
    for (i = 0; i < data.nbhistostrings; i++) {
      result += data["UserHistory" + i];
    }
    let test = JSON.parse(result);
    // console.log(result);
  },
  uploadBigString(ref){

    if (!this.isUserHistorydowloaded()) return

    // let tab = this.string_chop(data,1000);
    let tab = this.string_chop(this.getUserHistoryString(),1040000);

    let index = 0;
    
    let UserHistoryMap = this.getSavedUserHistoryMap();
    let jsonstring = "{"
    tab.forEach(doc => {
      let name = 'UserHistory' + index;
      jsonstring = jsonstring + "\"" + name + "\":" + JSON.stringify(tab[index]) + ","    
      index++ 
    });

    jsonstring = jsonstring + "\"nbhistostrings\":\"" + tab.length + "\","

    tab = this.string_chop(this.getUserBrowseString(),1040000);

    index = 0;

    tab.forEach(doc => {
      let name = 'UserBrowseHistory' + index;
      jsonstring = jsonstring + "\"" + name + "\":" + JSON.stringify(tab[index]) + ","    
      index++ 
    });

    jsonstring = jsonstring + "\"nbbrowsehistostrings\":\"" + tab.length + "\","

    jsonstring = jsonstring + "\"id\":\"" +  this.getLocalId() + "\","

    let email = UserHistoryMap.user;
    if (!email || email == "") email = this.getUserEmail();

    jsonstring = jsonstring + "\"user\":\"" + email + "\"}"

    let smap2 = JSON.parse(jsonstring);
    console.log(jsonstring.length);
    // console.log(sizeof(smap2));

    if(jsonstring.length * 2 > 1040000) return

     ref.doc(this.getSavedUserHistoryKey()).set(smap2)
     .then(function() {
      console.log("Document successfully written!");
  })
  .catch(function(error) {
      console.error("Error writing document: ", error);
  });    

    
    return tab;
  },
  string_chop (str, size){
    if (str == null) return [];
    str = String(str);
    size = ~~size;
    return size > 0 ? str.match(new RegExp('.{1,' + size + '}', 'g')) : [str];
  },
  isTextBig(){
    return (this.ReadString("InfoBig") == "1")
  },
  getHtmlText(data,fontsize,bigonly) {
    //return this.state.bookmark.text;
    if (this.isTextBig() || bigonly) {
      let text =  "<style type=\"text/css\">p {font-size: "+fontsize+";line-height: 110%;}</style>" + data;
      return text;
    } else {
      return data;
    }

    return data;
    
    return "<div style={{ fontSize: \"4em\" }} >"+this.state.bookmark.text + "</ div>";
    //return this.getHtml("<div style={{ fontSize: \"2em\" }} >"+this.state.bookmark.text + "</ div>" );
  }
  };