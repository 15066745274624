

(function (global, factory) {
	typeof exports === 'object' && typeof module !== 'undefined' ? module.exports = factory(require('ol/Overlay')) :
	// typeof define === 'function' && define.amd ? define(['ol/Overlay'], factory) :
  // (global.Popup = factory(global.ol.Overlay));
  global.Popup = factory(global.ol.Overlay);
}(this, (function (Overlay) { 'use strict';

Overlay = 'default' in Overlay ? Overlay['default'] : Overlay;

var classCallCheck = function (instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
};

var createClass = function () {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ("value" in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }

  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
}();









var inherits = function (subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
  }

  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
};











var possibleConstructorReturn = function (self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }

  return call && (typeof call === "object" || typeof call === "function") ? call : self;
};

/**
* OpenLayers Popup Overlay.
* See [the examples](./examples) for usage. Styling can be done via CSS.
* @constructor
* @extends {ol.Overlay}
* @param {olx.OverlayOptions} opt_options options as defined by ol.Overlay. Defaults to
* `{autoPan: true, autoPanAnimation: {duration: 250}}`
*/

var Popup = function (_Overlay) {
    inherits(Popup, _Overlay);

    function Popup(opt_options) {
        classCallCheck(this, Popup);


        var options = opt_options || {};

        if (options.autoPan === undefined) {
            options.autoPan = true;
        }

        if (options.showClose === undefined) {
          options.showClose = false;
      }

        if (options.autoPanAnimation === undefined) {
            options.autoPanAnimation = {
                duration: 250
            };
        }

        this.issmall = options.issmall;

        this.mypopup = options.mypopup;

        var element = document.createElement('div');

        
        

        options.element = element;

        var _this = possibleConstructorReturn(this, (Popup.__proto__ || Object.getPrototypeOf(Popup)).call(this, options));

        var that = _this;

        _this.iconbar = document.createElement('div');
        _this.container = element;
        

        _this.setToolbar(this.issmall);

        if (this.issmall){

          _this.container.className = 'ol-popup-small';

        } else {

          _this.container.className = 'ol-popup';
          
          _this.content = document.createElement('div');
          _this.content.className = 'ol-popup-content';
          _this.container.appendChild(_this.content);

          Popup.enableTouchScroll_(_this.content);

          
        }

        // _this.content = document.createElement('div');
        // _this.content.className = 'ol-popup-content';
        // _this.container.appendChild(_this.content);
      
        _this.container.appendChild(_this.iconbar);

          


    // } 

        // Apply workaround to enable scrolling of content div on touch devices
        

        return _this;
    }

    /**
    * Show the popup.
    * @param {ol.Coordinate} coord Where to anchor the popup.
    * @param {String|HTMLElement} html String or element of HTML to display within the popup.
    * @returns {Popup} The Popup instance
    */


    createClass(Popup, [{
        key: 'show',
        value: function show(coord, html) {
            // this.issmall = issmall;

            if (!this.issmall){
              if (html instanceof HTMLElement) {
                this.content.innerHTML = "";
                this.content.appendChild(html);
            } else {
                this.content.innerHTML = html;
            }
            this.content.scrollTop = 0;
            }

            

            // if (issmall){
            //   this.content.style.height = '0px';
            //   // this.iconbar.style.height = '0px';
            // }

            this.container.style.display = 'block';
            
            this.setPosition(coord);
            return this;
        }

        /**
        * @private
        * @desc Determine if the current browser supports touch events. Adapted from
        * https://gist.github.com/chrismbarr/4107472
        */

    }, 
    {
      key: 'showsmall',
      value: function showsmall(html) {
          if (html instanceof HTMLElement) {
              this.content.innerHTML = "";
              this.content.appendChild(html);
          } else {
              this.content.innerHTML = html;
          }
          return this;
      }

      /**
      * @private
      * @desc Determine if the current browser supports touch events. Adapted from
      * https://gist.github.com/chrismbarr/4107472
      */

  },
    {
      key: 'showsBig',
      value: function showsBig(html) {
        this.content.style.height = 'auto';
        // this.iconbar.style.height = 'auto';
        this.issmall = false;
          return this;
      }

      /**
      * @private
      * @desc Determine if the current browser supports touch events. Adapted from
      * https://gist.github.com/chrismbarr/4107472
      */

  },
  {
    key: 'setToolbar',
    value: function setToolbar(issmall) {

      var that = this;

      console.log("setToolbar",issmall)
      this.iconbar = document.createElement('div');
      

      if (issmall){
        this.open = document.createElement('i');
        this.open.className = 'ol-popup-openbutton fa fa-comment-dots';
        this.iconbar.appendChild(this.open);
        this.open.addEventListener('click', function (evt) {
          window.popupClicked = that.mypopup;
          window.openPopup();
      }, false);

      } else {
        if (that.mypopup.getNbTexts()>1){

        // let indextext =  that.mypopup.getTextNum() + "/" + that.mypopup.getNbTexts() + '  '
        // _this.indextext = document.createTextNode(indextext);
        // _this.indextext.className = 'ol-popup-button ol-popup-indextext';
        // iconbar.appendChild(_this.indextext);

        this.prevtext = document.createElement('i');
        this.prevtext.className = 'ol-popup-buttonfirst fa fa-chevron-left';
        this.prevtext.href = '#';
        this.iconbar.appendChild(this.prevtext);
        this.prevtext.addEventListener('click', function (evt) {
          that.mypopup.setPrevText();

          that.updateText(that.mypopup.getHtml())
      }, false);
                  
        this.nexttext = document.createElement('i');
        this.nexttext.className = 'ol-popup-button fa fa-chevron-right';
        this.nexttext.href = '#';
        this.iconbar.appendChild(this.nexttext);
        this.nexttext.addEventListener('click', function (evt) {
          that.mypopup.setNextText();

          that.updateText(that.mypopup.getHtml())
      }, false);

      
      }

      this.addtext = document.createElement('i');
      this.addtext.className = 'ol-popup-button fa fa-comment-medical';
      this.addtext.href = '#';
      this.iconbar.appendChild(this.addtext);

      this.addtext.addEventListener('click', function (evt) {
        // console.log(that.mypopup)
        // global.currentpopup = that.mypopup;
        // window.popupClick(that.mypopup,'add');
        window.popupClicked = that.mypopup;
        window.addPopupText();
        // node
        // that.container.style.display = 'none';
        // that.closer.blur();
        // evt.preventDefault();
    }, false);

      this.deletepopup = document.createElement('i');
      this.deletepopup.className = 'ol-popup-button fa fa-pen';
      // this.deletepopup.href = '#';
      this.iconbar.appendChild(this.deletepopup);

      this.deletepopup.addEventListener('click', function (evt) {
        
        // window.popupClick(that.mypopup,'edit');
        window.popupClicked = that.mypopup;
        window.editPopup();

    }, false);
    this.open = document.createElement('i');
        this.open.className = 'ol-popup-button fa fa-eye-slash';
        this.iconbar.appendChild(this.open);
        this.open.addEventListener('click', function (evt) {
          window.popupClicked = that.mypopup;
          window.openPopup();
      }, false);
    }

    
      }

      

    /**
    * @private
    * @desc Determine if the current browser supports touch events. Adapted from
    * https://gist.github.com/chrismbarr/4107472
    */

},
    {
      key: 'updateText',
      value: function show(html) {
          if (html instanceof HTMLElement) {
              this.content.innerHTML = "";
              this.content.appendChild(html);
          } else {
              this.content.innerHTML = html;
          }
          return this;
      }

      /**
      * @private
      * @desc Determine if the current browser supports touch events. Adapted from
      * https://gist.github.com/chrismbarr/4107472
      */

  },{
        key: 'hide',


        /**
        * Hide the popup.
        * @returns {Popup} The Popup instance
        */
        value: function hide() {
            this.container.style.display = 'none';
            return this;
        }

        /**
        * Indicates if the popup is in open state
        * @returns {Boolean} Whether the popup instance is open
        */

    }, {
        key: 'isOpened',
        value: function isOpened() {
            return this.container.style.display == 'block';
        }
    }], [{
        key: 'isTouchDevice_',
        value: function isTouchDevice_() {
            try {
                document.createEvent("TouchEvent");
                return true;
            } catch (e) {
                return false;
            }
        }

        /**
        * @private
        * @desc Apply workaround to enable scrolling of overflowing content within an
        * element. Adapted from https://gist.github.com/chrismbarr/4107472
        */

    }, {
        key: 'enableTouchScroll_',
        value: function enableTouchScroll_(elm) {
            if (Popup.isTouchDevice_()) {
                var scrollStartPos = 0;
                elm.addEventListener("touchstart", function (event) {
                    scrollStartPos = this.scrollTop + event.touches[0].pageY;
                }, false);
                elm.addEventListener("touchmove", function (event) {
                    this.scrollTop = scrollStartPos - event.touches[0].pageY;
                }, false);
            }
        }
    }]);
    return Popup;
}(Overlay);

if (window.ol && window.ol.Overlay) {
    window.ol.Overlay.Popup = Popup;
}

return Popup;

})));
