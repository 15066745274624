import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import firebase from '../Firebase';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Bookmark } from '../js/Bookmark.js';

class EditBookmark extends Component {

  constructor(props) {
    super(props);
    this.bookmark = props.bookmarktoedit;

/*     this.state = {
      bookmarks: props.bookmark
    };  */
    /* this.bref = firebase.firestore().collection('bookmarks');*/
    this.state = {
      
      key:'',
      label: '',
      text:'',
      posx:0,
      posy:0,
      index: -1,
      zoom: 1,
      panoname: '',
      thumburl: '',
      author:'',
      audioname:'',
      audioevents:[],
      otherid:"",
      type:'',
      coord:''
    }; 
 //  this.new = (this.props) ? true : false;
  }

  componentDidMount() {
    //  if (!this.new){
          
  }

  componentWillUpdate(nextProps) {

    let bookmark = new Bookmark(nextProps.bookmark.bookmark);

    if (bookmark && bookmark.key != this.state.key){

      this.state = bookmark.getObject();
      this.state.bookmark = bookmark

      this.setState(this.state);
      }
    

    /* console.log("this.props.bookmark.index",this.props.bookmark);
    console.log('EditBookmark ',this.props.bookmark.panoname)
      console.log('EditBookmark index',this.props.thebookmarktoedit)

     this.bref.where('panoname', '==', this.props.bookmark.panoname).where('index', '==', this.props.thebookmarktoedit).get()
      .then(snapshot => {

        if (!snapshot.empty) {
          const bookmark =  snapshot.docs[0].data();
          console.log("key bookmark" , snapshot.docs[0].id)
          this.setState({
            key: snapshot.docs[0].id,
            label: bookmark.label,
            text: bookmark.text,
            posx: bookmark.posx,
            posy: bookmark.posy,
            index: bookmark.index,
            zoom: bookmark.zoom,
            panoname: bookmark.panoname,
            thumburl: bookmark.thumburl,
            author: bookmark.author
          });
        } else {
          console.log("No such document!");
        }
      
      })
      .catch(err => {
      console.log('Error getting documents', err);
      }); */
  }

  onChange = (e) => {
    const state = this.state
    state[e.target.name] = e.target.value;
    this.setState(state);
    
  }

  deleteBookmark(){
    console.log(this.state.key);
    window.bref.doc(this.state.key).delete().then(function() {
      console.log("Document successfully deleteBookmark!");
  })
  .catch(function(error) {
      console.error("Error writing deleteBookmark: ", error);
  });
  }

  onSubmit = (e) => {
    e.preventDefault();

    if (!this.state.audioname) this.state.audioname = "none"
    if (!this.state.audioevents) this.state.audioevents = "none"
    if (!this.state.coord) this.state.coord = ""
    

    const { label,text,posx,posy,zoom,panoname,index,thumburl,author,audioname,audioevents,type,coord } = this.state;

    // this.state.index = parseInt(this.state.index);

    // console.log("key" , this.state.key)

  //   this.state.bookmark.updateDB(function() {
  //     console.log('update done')
  //     this.props.onClose();
  // })
     
        const updateRef = firebase.firestore().collection('bookmarks').doc(this.state.key);
        updateRef.set({
          label,text,posx,posy,zoom,panoname,index,thumburl,author,audioname,audioevents,type,coord}).then((docRef) => {

            this.props.onClose();
        })
        .catch((error) => {
            console.error("Error adding document: ", error);
        });
          
   // }

    
  }

  copybookmark(){
//     firebase.firestore().collection('bookmarks').doc(this.state.otherid).get()
//     .then((docRef) => { 
//       let bookmark =docRef.data(); 
//       console.log(docRef.data().label)
//       this.state.label = bookmark.label
//       this.state.text = bookmark.text
//       this.state.index = bookmark.index
//       this.state.author = bookmark.author

//       if (bookmark.audioname) this.state.audioname = bookmark.audioname
//       else this.state.audioname = "none"
//       if (bookmark.audioevents) this.state.audioevents = bookmark.audioevents
//       else this.state.audioevents = "none"
//       this.setState(this.state);

//       const { label,text,posx,posy,zoom,panoname,index,thumburl,author,audioname,audioevents } = this.state;

//       const updateRef = firebase.firestore().collection('bookmarks').doc(this.state.key);
//         updateRef.set({
//           label,text,posx,posy,zoom,panoname,index,thumburl,author,audioname,audioevents,type}).then((docRef) => {

//             this.props.onClose();
//         })
//         .catch((error) => {
//             console.error("Error adding document: ", error);
//         });

//  })
//   .catch((error) => { })
  }

  render() {
    const { bookmark,otherid,key,label,text,posx,posy,zoom,panoname,index,thumburl,author,audioname,audioevents,type,coord } = this.state;
    // Render nothing if the "show" prop is false
    if(!this.props.show) {
      return null;
    }

    // The gray background
    const backdropStyle = {
      position: 'fixed',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      backgroundColor: 'rgba(0,0,0,0.3)',
      padding: 50
    };

    // The modal "window"
    const modalStyle = {
      backgroundColor: '#fff',
      borderRadius: 5,
      maxWidth: 500,
      minHeight: 300,
      margin: '0 auto',
      padding: 30
    };

    return (

      <div className="bookmarkeditform">

<div className="backdrop" style={{backdropStyle}}>
        <div className="moddal" style={{modalStyle}}>

        
           <div className="panel-heading">
            <h4 className="panel-title">
              Edit Bookmark
            </h4>
          </div>
          <div className="panel-body">
            
            <form onSubmit={this.onSubmit}>

            <div className="d-flex justify-content-between">
            <div className="form-group">
                <label htmlFor="key">key:</label>
                <input type="text" className="form-control" name="key" value={key} onChange={this.onChange} placeholder="label" />
              </div>
              <div className="form-group">
                <label htmlFor="label">Label:</label>
                <input type="text" className="form-control" name="label" value={label} onChange={this.onChange} placeholder="label" />
              </div>
              <div className="form-group">
                <label htmlFor="index">index:</label>
                <input type="numeric" className="form-control" name="index" value={index} onChange={this.onChange} placeholder="index" />
              </div>
              <div className="form-group">
                <label htmlFor="zoom">zoom:</label>
                <input type="text" className="form-control" name="zoom" value={zoom} onChange={this.onChange} placeholder="zoom" />
              </div>
              <div className="form-group">
                <label htmlFor="panoname">panoname:</label>
                <input type="text" className="form-control" name="panoname" value={panoname} onChange={this.onChange} placeholder="panoname" />
              </div>
              {/* <div className="form-group">
                <label htmlFor="otherid">otherid:</label>
                <input type="text" className="form-control" name="otherid" value={otherid} onChange={this.onChange} placeholder="otherid" />
              </div> */}
              <div className="form-group">
                <label htmlFor="type">type:</label>
                <input type="text" className="form-control" name="type" value={type} onChange={this.onChange} placeholder="type" />
              </div>
            </div>
            
              <div className="form-group">
                <label htmlFor="text">Text:</label>
                <input type="text" className="form-control" name="text" value={text} onChange={this.onChange} placeholder="text" />
              </div>
              <div className="d-flex justify-content-between">
                <div className="form-group">
                  <label htmlFor="audioname">Audioname:</label>
                  <input type="text" className="form-control" name="audioname" value={audioname} onChange={this.onChange} placeholder="audioname" />
                </div>
                <div className="form-group">
                  <label htmlFor="audioname">Audioevents:</label>
                  <input type="text" className="form-control" name="audioevents" value={audioevents} onChange={this.onChange} placeholder="audioevents" />
                </div>
                <div className="form-group">
                  <label htmlFor="posx">Pos X:</label>
                  <input type="numeric" className="form-control" name="posx" value={posx} onChange={this.onChange} placeholder="posx" />
                </div>
                <div className="form-group">
                  <label htmlFor="posy">Pos Y:</label>
                  <input type="numeric" className="form-control" name="posy" value={posy} onChange={this.onChange} placeholder="posy" />
                </div>
                <div className="form-group">
                  <label htmlFor="coord">Coord:</label>
                  <input type="numeric" className="form-control" name="coord" value={coord} onChange={this.onChange} placeholder="coord" />
                </div>
              </div>
              

              <div className="form-group">
                <label htmlFor="thumburl">thumburl:</label>
                <input type="text" className="form-control" name="thumburl" value={thumburl} onChange={this.onChange} placeholder="thumburl" />
              </div>
              
              <button  onClick={() => this.copybookmark()} >
                        Copy
                        </button>
              <button  onClick={() => this.deleteBookmark()} >
                        Delete
                        </button>
              
              <button type="submit" className="btn btn-success">Submit</button>
              <button onClick={this.props.onClose}>
              Close
            </button>
            </form>
          
      </div>

          <div className="footer">

          </div> 
        </div>
      </div>
      </div>

      
      
    );
  }
}

EditBookmark.propTypes = {
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool,
  children: PropTypes.node
};

export default EditBookmark;