import nodeUtils from '../js/nodeUtils.js'

export class Position{

    constructor(x,y,zoom){
        if (Array.isArray(x)){
            this.x = x[0];
            this.y = x[1];
            this.zoom = y;
        }
        else if (y){
            this.x = x;
            this.y = y;
            this.zoom = zoom;
        } else {
            this.x = x.x;
            this.y = x.y;
            this.zoom = x.zoom;
        }
        
    }

    center(){
        return [this.x,this.y];
    }
    pos(){
        return {position:[this.x,this.y],zoom: this.zoom};
    }
    move(x,y,zoom){
        this.x += x;
        this.y += y;
        this.zoom += zoom;
    }

    getCenterRelative (xoffset,yoffset){
        // let center = position.center();
        let pos = new Position(this)
        let currentzoom = nodeUtils.getZoom();
        pos.x = this.x - nodeUtils.getWidth() / 2 * xoffset / 100
        pos.y = this.y - nodeUtils.getHeight() / 2 * yoffset / 100
        return pos;
    }

}