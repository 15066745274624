import React, { Component } from 'react';
import Container from 'react-bootstrap/Container'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FullScreen } from 'ol/control';
import SignInPage from './SignIn';
import Rounded from "react-rounded-image";
import app from 'firebase/compat/app';
/* import { faCoffee } from '@fortawesome/free-solid-svg-icons'
 */
import nodeUtils from '../js/nodeUtils.js'



class AskIdMessage extends Component {
  constructor(props) {
    super(props);
    
    
     this.state = {
      
    }; 
  }
  
  componentDidMount() {
   
  }
/* 
  componentWillUpdate(nextProps, nextState) {
     if (nextProps.panorama != this.state.panorama){
    
    this.state.panorama =nextProps.panorama;

    this.setState(
      this.state
    )
    }
 
  } */

  

  signout() {
    
    app.auth().signOut();
    // firebase.doSignOut()
  }

  getAccuntIcon(){
    return (nodeUtils.isAuthenticated()) ? 
    <div className="photoaccount" onClick={() => this.signout()}>
              <Rounded  image={global.user.photoURL} imageWidth="24"
                        imageHeight="24"
                        roundedSize="1" />
            </div>
            : <div></div>
  }

  render() {


    return (

      <div className="AskIdMessage">
        <div className="d-flex flex-column align-items-center">

          <h2>Se connecter</h2>

          <p>
            Pour accéder à la suite de la visite merci de vous connecter 
            <br></br>(Hors connection seuls les 5 premiers éléments sont disponibles)
            <br></br>Cela permet également d'améliorer votre expérience en enregistrant votre activité <br></br>
            afin de pouvoir vous proposer les informations que vous n'avez pas encore consultées et de <br></br>
            vous permettre de visualiser les zones de la gigaphoto qu'il vous reste à découvrir (à venir)
            </p>

            <SignInPage />
         

        </div>

      </div>
      
    );
  }
}

export default AskIdMessage;
