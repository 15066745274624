import firebase from '../Firebase';
import nodeUtils from './nodeUtils';

export class Messager{

    constructor(messager){
        if (!messager) messager = this.getEmptyObject()
        this.messager = messager;
        this.owner = messager.owner;
        this.data = messager.data;
        // this.key= bookmark.key;
        // this.label= bookmark.label;
        // this.text= bookmark.text;
        // this.posx= bookmark.posx;
        // this.posy= bookmark.posy;
        // this.index= bookmark.index;
        // this.zoom= bookmark.zoom;
        // this.panoname= bookmark.panoname;
        // this.thumburl= bookmark.thumburl;
        // this.author= bookmark.author;
        // this.audioname=bookmark.audioname;
        // this.audioevents=bookmark.audioevents;
        // this.otherid=bookmark.otherid;
        // this.coord=bookmark.coord;

        // if (this.coord == "") this.coord = this.posx + "," + this.posy;

        // this.type = bookmark.type;
        // if (this.type == null) this.type = 'bookmark';

        // this.show = bookmark.show;
        // if (this.show == null) this.show = true;
    }

    getObject(){
        return {
            owner: this.owner,
            data: this.data,
            // text: this.text,
            // posx: this.posx,
            // posy: this.posy,
            // index: this.index,
            // zoom: this.zoom,
            // panoname: this.panoname,
            // thumburl: this.thumburl,
            // author: this.author,
            // audioname:this.audioname,
            // audioevents:this.audioevents,
            // type:this.type,
            // coord:this.coord,
            // otherid:this.otherid
          }
    }
    getEmptyObject(){
        return {
            owner: nodeUtils.getLocalId(),
            data: []
            // label: '',
            // text: '',
            // posx: '',
            // posy: '',
            // index: '',
            // zoom: '',
            // panoname: '',
            // thumburl: '',
            // author: '',
            // audioname:'',
            // audioevents:'',
            // type:'',
            // coord:'',
            // otherid:''
          }
    }


    getData(){
        return this.data;
    }

    addData(element){
        this.data.push(element);
    }

    getLastData(){
        return this.data[0];
    }

    update(data){
        console.log(this.data)
        this.data = data;
        this.processUpdate()
    }

    processUpdate(){
        console.log(this.data.type)
        switch (this.data.type){
            case "message":
                console.log("new message", this.data.content)
        }
    }


   

}