import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import AskIdMessage from './AskIdMessageComp';


import nodeUtils from '../js/nodeUtils.js'

// import nodeUtils from '../js/nodeUtils.js'

class BookmarkInfo extends Component {
  constructor(props) {
    super(props);
    this.bookmark = props.bookmark;

    this.size = "3em";

    this.askshowLightBox = this.askshowLightBox.bind(this);
    
    
     this.state = {
       shown:true,
      bookmark: props.bookmark,
      // bookmark:new Bookmark(),
      big:false,
      showlogin:false,
      isaudio: global.isvisitAudio
    }; 

    

    // let thiscomp = this;

    // firebase.auth().onAuthStateChanged(function(user) {
    //   // if (this.state.bookmark){
    //     if (user) {

    //       thiscomp.state.time = Date.now()
    //       thiscomp.setState(
    //         thiscomp.state
    //       )

    //       nodeUtils.setVisible('bkinfo',true)
    //       nodeUtils.setVisible('bookmarklogin',false)
          
    //       if (nodeUtils.ReadString("InfoBig") == "1") thiscomp.toggleBig();
    //       else thiscomp.toggleSmall();
           
         
        
    //   } else {
    //     console.log('No user is signed in.')
        
    //   }
      // }
      
    // });
  }
  
  componentDidMount() {
  // if (nodeUtils.ReadString("InfoBig") == "1") this.toggleBig();
    this.updateContent();
  }

  componentWillUpdate(nextProps, nextState) {

    if (this.isclosing){
      this.isclosing = false;
      return
    }

    if (nextProps.bookmark !== this.state.bookmark || nextProps.isauth !== this.state.isauth || this.state.bookmarkindex !== nextProps.bookmarkindex){

      this.state.isauth = nextProps.isauth
          
      this.state.bookmark =nextProps.bookmark;
      this.state.shown = true;
      this.state.bookmarkindex = nextProps.bookmarkindex;

      this.state.showlogin = !this.canAccessBookmark(nextProps.bookmark)

      this.state.isaudio = global.isvisitAudio;

      this.setState(
        this.state
      )
      
    
      // nodeUtils.setVisible('bookmarksinfobottom',true)

      // if (nextProps.bookmark) nodeUtils.showBookmarkInfo();

    }
    
    // nodeUtils.setVisible('bookmarksinfodiv',true);

  }

  canAccessBookmark(bookmark){
    return true;
    // if (nodeUtils.isAuthenticated()) return true
    // else {
        
    //     return this.state.bookmarkindex < 5;
    // }
}

updateContent(){
  if (nodeUtils.ReadString("InfoBig") === "1") this.toggleBig();
  else this.toggleSmall();
}

  componentDidUpdate(){

    if (this.state.shown){
      this.updateContent()
    } else {
      // nodeUtils.setVisible('bookmarksinfodiv',false);
    }
    

    // if (!this.state.showlogin){
    //   if (nodeUtils.ReadString("InfoBig") == "1") this.toggleBig();
    //   else this.toggleSmall();
    // } else {
      
    // }

    // if (this.state.showlogin){
    //   nodeUtils.setContent("bookmarkInfoTextid","")
    // }
    nodeUtils.setVisible('bkinfo',!this.state.showlogin && this.state.shown && !global.isvisitAudio)
    nodeUtils.setVisible('bookmarklogin',this.state.showlogin && this.state.shown && !global.isvisitAudio) 
    
  }

  getHtml(text) {
    return {__html: text};
  }

  close(){


    // nodeUtils.setVisible('bookmarksinfodiv',false);
    // nodeUtils.setVisible("bookmarksinfoplus",false);
    // nodeUtils.setVisible("bookmarksinfomoins",false);
    // this.state.shown = false;
    // this.state.bookmarkindex = -1;
    // this.isclosing = true;
    
    // this.setState(
    //   this.state
    // )
    // nodeUtils.setVisible('bookmarksinfobottom',false)
/*     this.state.bookmark = "null";
    this.state.shown = false;
    
    this.setState(
      this.state
    ) */
  }

  toggleBig(){
    if (this.state.bookmark){
          nodeUtils.setMaxHeight("bookmarksinfodiv","50vh");
    nodeUtils.setFontSize("bookmarksinfodiv","1.5em");
    nodeUtils.SaveString("InfoBig","1");
    nodeUtils.setVisible("bookmarksinfoplus",false);
    nodeUtils.setVisible("bookmarksinfomoins",true);
   /*  let testtext = "<div style={{ fontSize: \"2em\" }} >"+this.state.bookmark.text + "</ div>" ;*/
   if (!this.state.showlogin) nodeUtils.setContent("bookmarkInfoTextid",nodeUtils.getHtmlText(this.state.bookmark.text,"1.5em")) 
    }
  }
  toggleSmall(){
    if (this.state.bookmark){
      if (this.state.showlogin){
        nodeUtils.setMaxHeight("bookmarksinfodiv","50vh");
      } else {
        nodeUtils.setMaxHeight("bookmarksinfodiv","40vh");
      }
    
    nodeUtils.setFontSize("bookmarksinfodiv","1em");
    nodeUtils.SaveString("InfoBig","0");
    nodeUtils.setVisible("bookmarksinfoplus",true);
    nodeUtils.setVisible("bookmarksinfomoins",false);
    if (!this.state.showlogin) nodeUtils.setContent("bookmarkInfoTextid",nodeUtils.getHtmlText(this.state.bookmark.text,"1.5em")) 
    }
  }

  BookmarkInfoText() {
    //return this.state.bookmark.text;
    if (nodeUtils.ReadString("InfoBig") === "1") {
      let text =  "<style type=\"text/css\">p {font-size: 0.3em;line-height: 110%;}</style>" + this.state.bookmark.text;
      return text;
    } else {
      return this.state.bookmark.text;
    }

    // return this.state.bookmark.text;
    
    // return "<div style={{ fontSize: \"4em\" }} >"+this.state.bookmark.text + "</ div>";
    //return this.getHtml("<div style={{ fontSize: \"2em\" }} >"+this.state.bookmark.text + "</ div>" );
  }

  getSize(){
    return "3em"
  }

  getMessage(){
     return (
      (this.state.showlogin)?    
      <div  id="bookmarklogin" className="bklogin d-flex justify-content-center"><AskIdMessage /></div>    
            :
            <div id="bkinfo">
              
              <div id="bookmarkInfoTextid" className="bookmarkInfoText" ></div>
            </div>
            
      // <div>
  
      // <div  id="bookmarklogin" className="bklogin d-flex justify-content-center"><AskIdMessage /></div>    
      //       <div id="bkinfo">
      //         <div id="bookmarkInfoTextid" className="bookmarkInfoText" ></div>
      //       </div>
      // </div>
               
    
      );
    }

    askshowLightBox(show,duration,url,titre){
      this.props.showLightBox(show,duration,url,titre)
    }

  render() {

    let nextBookmark = this.props.nextBookmark;
    let prevBookmark = this.props.prevBookmark;
    let showBookmarkInfoDiv = this.props.showBookmarkInfoDiv
    let readCurrentBookmark = this.props.readCurrentBookmark;
    
    // let size = this.size;

    const {isaudio} = this.state;

    

    return (
      // (this.state.bookmark)?
        
      <div id="bookmarksinfodiv" className="bookmarkInfoFooter clickeable" >
        <div className="d-flex justify-content-between">

        < div className="d-flex">
          <div ><FontAwesomeIcon className="closebicon" icon="times"  onClick={() => showBookmarkInfoDiv(false)}/></div>
          <div id="bookmarksinfoplus" > <FontAwesomeIcon icon="search-plus"  onClick={() => this.toggleBig()}/></div>
          <div id="bookmarksinfomoins" ><FontAwesomeIcon icon="search-minus"  onClick={() => this.toggleSmall()}/></div>
          <div ><FontAwesomeIcon icon="search-plus"  onClick={() => readCurrentBookmark()}/></div>
        </div>

        
          <div>
          <div className="d-flex">
          <FontAwesomeIcon  icon="chevron-left"  onClick={() => prevBookmark()}/> 
          <FontAwesomeIcon className="nextbicon" icon="chevron-right"  onClick={() => nextBookmark()}/>
          </div>
            <div ></div>
          </div>
          
        </div>
            <div className="bookmarkInfoTitre"   >{this.state.bookmark.label}</div>            
            
          <div>

          {!isaudio && 

            <div id="bookmarksinfobottom">
              {this.getMessage()} 
            </div>
        
          }
        
        
           
            {/* <div id="bookmarkInfoTextid" className="bookmarkInfoText" >{parse(this.BookmarkInfoText())}</div> */}
           {/*  <div id="bookmarkInfoTextid" className="bookmarkInfoText" >{parse(this.state.bookmark.text)}</div> */}
 
           {/*  <div id="bookmarkInfoTextid" className="bookmarkInfoText"  dangerouslySetInnerHTML={this.BookmarkInfoText()}/> */}
           </div>
      </div>
      // :
      //   <div></div>
/*       <div className="bookmarkInfoTitre">{this.state.bookmark.label}</div>:           
      <div className="bookmarkInfoTitre">fail</div> */
      
    );
  }
}

export default BookmarkInfo;
