import { Clock, Vector3, Vector4 } from 'three';
import { PointerLockControls } from 'three/examples/jsm/controls/PointerLockControls.js';
// import { FirstPersonControls } from 'three/examples/jsm/controls/FirstPersonControls.js';

const changeEvent = { type: 'fly-change' };
const startEvent = { type: 'fly-start' };
const endEvent = { type: 'fly-end' };
const tempVector = new Vector4( 0, 0, 0, 0 );
const tempRotVector = new Vector4( 0, 0, 0, 0 );

export class FPSControls extends PointerLockControls {

	constructor( camera, domElement ) {

		// Disable use of shift key so we can use it for acceleration
		const disableShiftKeyCallback = e => {

			if ( this.enabled ) {

				Object.defineProperty( e, 'shiftKey', { get() { return false } } );

			}

		};
	

		domElement.addEventListener( 'pointerdown', disableShiftKeyCallback );

		super( camera, domElement );

		// this.enabled = false;

		this.speed = 0.1;
		this.enableKeys = false;
		this.enableFlight = true;
		this.baseSpeed = 1;
		this.fastSpeed = 4;
		this.forwardKey = 'z';
		this.backKey = 's';
		this.leftKey = 'q';
		this.rightKey = 'd';
		this.upKey = 'a';
		this.downKey = 'e';
		this.fastKey = 'shift';

		this.rotleftkey = 'arrowleft';
		this.rotrightkey = 'arrowright';
		// this.rotleftkey = 'o';


		let fastHeld = false;
		let forwardHeld = false;
		let backHeld = false;
		let leftHeld = false;
		let rightHeld = false;
		let upHeld = false;
		let downHeld = false;

		let rotleftHeld = false;
		let rotrighttHeld = false;

		let originalDistance = 0;
		let originalMinDistance = 0;
		let originalMaxDistance = 0;
		let rafHandle = - 1;
		const originalTarget = new Vector3();
		const clock = new Clock();

		const endFlight = () => {

			if ( rafHandle !== - 1 ) {

				// cancel the animation playing
				cancelAnimationFrame( rafHandle );
				rafHandle = - 1;

				// store the original distances for the controls
				this.minDistance = originalMinDistance;
				this.maxDistance = originalMaxDistance;

				const targetDistance = Math.min( originalDistance, camera.position.distanceTo( originalTarget ) );
				tempVector
					.set( 0, 0, - 1, 0 )
					.applyMatrix4( camera.matrixWorld );
				this
					.target
					.copy( camera.position )
					.addScaledVector( tempVector, targetDistance );

				this.dispatchEvent( endEvent );

			}

			// console.log("endflight",JSON.stringify(camera.position))
			// global.myEmitter.emit('3dbrowsed', {position: camera.position,rotation: camera.rotation});
			

		};

		const updateFlight = () => {

			if ( ! this.enabled || ! this.enableFlight ) {

				return;

			}

			rafHandle = requestAnimationFrame( updateFlight );

			// get the direction
			tempVector.set( 0, 0, 0, 0 );
			if ( forwardHeld ) tempVector.z -= 1*this.speed;
			if ( backHeld ) tempVector.z += 1*this.speed;
			if ( leftHeld ) tempVector.x -= 1*this.speed;
			if ( rightHeld ) tempVector.x += 1*this.speed;
			if ( upHeld ) tempVector.y += 1*this.speed;
			if ( downHeld ) tempVector.y -= 1*this.speed;
			tempVector.applyMatrix4( camera.matrixWorld );

			// apply the movement
			const delta = 60 * clock.getDelta();
			const speed = fastHeld ? this.fastSpeed : this.baseSpeed;
			camera
				.position
				.addScaledVector( tempVector, speed * delta );
			this
				.target
				.addScaledVector( tempVector, speed * delta );

			tempRotVector.set( 0, 0, 0, 0 );
			// if ( forwardHeld ) tempVector.z -= 1*this.speed;
			// if ( backHeld ) tempVector.z += 1*this.speed;
			// if ( leftHeld ) tempVector.x -= 1*this.speed;
			// if ( rightHeld ) tempVector.x += 1*this.speed;
			// if ( upHeld ) tempVector.y += 1*this.speed;
			if ( rotleftHeld ) tempRotVector.y -= -0.1*this.speed;
			if ( rotrighttHeld ) tempRotVector.y -= 0.1*this.speed;
			tempRotVector.applyMatrix4( camera.matrixWorld );

			camera.rotation.set(camera.rotation.x,camera.rotation.y+ tempRotVector.y,camera.rotation.z)

			console.log("camera.position",camera.position)

			global.myEmitter.emit('3dbrowsed', {position: camera.position,rotation: camera.rotation});

			global.needsRerender = true;

			this.dispatchEvent( changeEvent );

		};

		const keyDownCallback = e => {

			const key = e.key.toLowerCase();

			global.needsRerender = true;

			if ( rafHandle === - 1 ) {

				originalMaxDistance = this.maxDistance;
				originalMinDistance = this.minDistance;
				originalDistance = camera.position.distanceTo( this.target );
				originalTarget.copy( this.target );

			}

			switch ( key ) {

				case this.forwardKey:
					forwardHeld = true;
					break;
				case this.backKey:
					backHeld = true;
					break;
				case this.leftKey:
					leftHeld = true;
					break;
				case this.rightKey:
					rightHeld = true;
					break;
				case this.upKey:
					upHeld = true;
					break;
				case this.downKey:
					downHeld = true;
					break;
				case this.fastKey:
					fastHeld = true;
					break;
				case this.rotleftkey:
					rotleftHeld = true;
					break;
				case this.rotrightkey:
					rotrighttHeld = true;
					break;

			}

			switch ( key ) {

				case this.fastKey:
				case this.forwardKey:
				case this.backKey:
				case this.leftKey:
				case this.rightKey:
				case this.upKey:
				case this.downKey:
				case this.rotleftkey:
				case this.rotrightkey:
					e.stopPropagation();
					e.preventDefault();

			}

			if ( forwardHeld || backHeld || leftHeld || rightHeld || upHeld || downHeld || fastHeld || rotleftHeld || rotrighttHeld) {

				this.minDistance = 0.01;
				this.maxDistance = 0.01;

				// Move the orbit target out to just in front of the camera
				tempVector
					.set( 0, 0, - 1, 0 )
					.applyMatrix4( camera.matrixWorld );
				this
					.target
					.copy( camera.position )
					.addScaledVector( tempVector, 0.01 );

				if ( rafHandle === - 1 ) {

					// start the flight and reset the clock
					this.dispatchEvent( startEvent );
					clock.getDelta();
					updateFlight();

				}

			}

		};

		const keyUpCallback = e => {

			const key = e.key.toLowerCase();

			switch ( key ) {

				case this.fastKey:
				case this.forwardKey:
				case this.backKey:
				case this.leftKey:
				case this.rightKey:
				case this.upKey:
				case this.downKey:
				case this.rotleftkey:
				case this.rotrightkey:
					e.stopPropagation();
					e.preventDefault();

			}

			switch( key ) {

				case this.forwardKey:
					forwardHeld = false;
					break;
				case this.backKey:
					backHeld = false;
					break;
				case this.leftKey:
					leftHeld = false;
					break;
				case this.rightKey:
					rightHeld = false;
					break;
				case this.upKey:
					upHeld = false;
					break;
				case this.downKey:
					downHeld = false;
					break;
				case this.fastKey:
					fastHeld = false;
					break;
				case this.rotleftkey:
					rotleftHeld = false;
					break;
				case this.rotrightkey:
					rotrighttHeld = false;
						break;

			}

			if ( ! ( forwardHeld || backHeld || leftHeld || rightHeld || upHeld || downHeld || fastHeld || rotleftHeld || rotrighttHeld) ) {

				endFlight();

			}

		};

		const blurCallback = () => {

			endFlight();

		};

		this.blurCallback = blurCallback;
		this.keyDownCallback = keyDownCallback;
		this.keyUpCallback = keyUpCallback;
		this.disableShiftKeyCallback = disableShiftKeyCallback;

		this.domElement.addEventListener( 'blur', blurCallback );
		this.domElement.addEventListener( 'keydown', keyDownCallback );
		this.domElement.addEventListener( 'keyup', keyUpCallback );

	}



	// onMouseDown( event ) {

	// 	console.log("onmousedown")

	// 	if ( scope.enabled === false ) return;

	// 	// Prevent the browser from scrolling.

	// 	event.preventDefault();

	// 	// Manually set the focus since calling preventDefault above
	// 	// prevents the browser from setting it automatically.

	// 	scope.domElement.focus ? scope.domElement.focus() : window.focus();

	// 	switch ( event.button ) {

	// 		case scope.mouseButtons.LEFT:

	// 			if ( event.ctrlKey || event.metaKey || event.shiftKey ) {

	// 				if ( scope.enablePan === false ) return;

	// 				handleMouseDownPan( event );

	// 				state = STATE.PAN;

	// 			} else {

	// 				if ( scope.enableRotate === false ) return;

	// 				handleMouseDownRotate( event );

	// 				state = STATE.ROTATE;

	// 			}

	// 			break;

	// 		case scope.mouseButtons.MIDDLE:

	// 			if ( scope.enableZoom === false ) return;

	// 			handleMouseDownDolly( event );

	// 			state = STATE.DOLLY;

	// 			break;

	// 		case scope.mouseButtons.RIGHT:

	// 			if ( scope.enablePan === false ) return;

	// 			handleMouseDownPan( event );

	// 			state = STATE.PAN;

	// 			break;

	// 	}

	// 	if ( state !== STATE.NONE ) {

	// 		document.addEventListener( 'mousemove', onMouseMove, false );
	// 		document.addEventListener( 'mouseup', onMouseUp, false );

	// 		scope.dispatchEvent( startEvent );

	// 	}

	// }

	dispose() {

		super.dispose();

		this.domElement.removeEventListener( 'blur', this.blurCallback );
		this.domElement.removeEventListener( 'keydown', this.keyDownCallback );
		this.domElement.removeEventListener( 'keyup', this.keyUpCallback );
		this.domElement.removeEventListener( 'pointerdown', this.disableShiftKeyCallback );

	}

}
