import React, { Component } from 'react';
import { Canvas } from '@react-three/fiber'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import nodeUtils from '../js/nodeUtils.js'

import Gamepad from 'warp-browser-gamepad';

// import { VRButton } from 'three/examples/jsm/webxr/VRButton.js';

import { FirstPersonControls } from 'three/examples/jsm/controls/FirstPersonControls.js';

import RtcComp from './RtcComp';

import ReactNipple from 'react-nipple';

import { Clock, Vector3, Vector4 } from 'three';

import SubtilteComp from './SubtilteComp';



// import * as three from 'three'

// import { DebugTilesRenderer } from '3d-tiles-renderer';

// import {
// 	DebugTilesRenderer,
// 	NONE,
// 	SCREEN_ERROR,
// 	GEOMETRIC_ERROR,
// 	DISTANCE,
// 	DEPTH,
// 	RELATIVE_DEPTH,
// 	IS_LEAF,
// 	RANDOM_COLOR,
// } from 'DebugTilesRenderer';

import {
	DebugTilesRenderer,
	NONE,
	SCREEN_ERROR,
	GEOMETRIC_ERROR,
	DISTANCE,
	DEPTH,
	RELATIVE_DEPTH,
	IS_LEAF,
	RANDOM_COLOR,
} from '3d-tiles-renderer';

import { TilesRenderer } from '3d-tiles-renderer';
import { TilesRendererBase } from '3d-tiles-renderer';
// import nodeUtils from '../js/nodeUtils.js'

// import { LRUCache } from '../../src/utilities/LRUCache.js';
// import { PriorityQueue } from '../../src/utilities/PriorityQueue.js';

// import { FlyOrbitControls } from '../../src/js/tiled/FlyOrbitControls';
import { FlyOrbitControls } from '../../src/js/tiled/FlyOrbitControlsIni';

import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';

import { FPSControls } from '../../src/js/tiled/FPSControls';

import { BufferGeometryUtils } from 'three/examples/jsm/utils/BufferGeometryUtils.js';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js';

// import * as dat from 'dat.gui';

import Stats from 'three/examples/jsm/libs/stats.module.js';

// import { TouchEventDemuxer, JoystickDemuxed } from 'joystick-component-lib';

// import { TilesRenderer } from '../../src/data/tileset.json';

// import Stats from '../../src/data/tileset.json';

// import RNGamePad from 'react-native-game-pad';

// import { JoystickControls } from 'three-joystick';

import { Joystick } from '../../src/js/tiled/Joystick';




import {
	Scene,
	DirectionalLight,
	AmbientLight,
	WebGLRenderer,
	PerspectiveCamera,
	CameraHelper,
	Box3,
	Raycaster,
	Vector2,
	Mesh,
	CylinderBufferGeometry,
	MeshBasicMaterial,
	Group,
	TorusBufferGeometry,
	OrthographicCamera,
	sRGBEncoding,
  MOUSE,
  Matrix4,
  Quaternion
} from 'three';

const dat = require('dat.gui');

const ALL_HITS = 1;
const FIRST_HIT_ONLY = 2;

var QRCode = require('qrcode.react');

// const hashUrl = window.location.hash.replace( /^#/, '' );
const hashUrl = window.location.hash;
let camera, controls, scene, renderer, tiles, cameraHelper;
let thirdPersonCamera, thirdPersonRenderer, thirdPersonControls;
let secondRenderer, secondCameraHelper, secondControls, secondCamera;
let orthoCamera, orthoCameraHelper;
let box;
let raycaster, mouse, rayIntersect, lastHoveredElement;
let offsetParent;
let statsContainer, stats;

const startPos = new Vector2();
const endPos = new Vector2();


class TestTiles extends Component {
  constructor(props) {
    super(props);

     global.isslave = window.location.pathname.includes('client'); 
        

    global.ismaster = window.location.pathname.includes('master'); 

    

    let isstereo = window.location.pathname.startsWith('/3dstereo');
        
     this.state = {
      fullScreen: false,
      showvirtualgamepad: !nodeUtils.isSlave() && !isstereo,
      texttospeechreceived : "",
      stereoOffset : nodeUtils.ReadFloat("stereoOffset",0),
      stereoOffset2 : nodeUtils.ReadFloat("stereoOffset2",0),
      StereoEnabled :window.location.pathname.startsWith('/3dstereo'),
      message: ""
      // StereoEnabled :this.props.match.params.mode === '3d'
    }; 

    this.showgui = true;

    // this.StereoEnabled = window.location.pathname.startsWith('/3dstereo'); 
    // this.StereoEnabled = this.props.match.params.mode == '3d'; 

    this.cameraset = false;

    this.secondCameraenabled = false;

    global.appmode = "3d";

    this.updateAlways = false;

    this.loadlocal = window.location.pathname.includes('loc'); ;

    this.allowrotvert = false;

    this.movetype = "fps";

    const onButtonChangeHandler = (button, gamepad) => this.onGamePadButton(button);;
    const onAxesChangeHandler = (axes, gamepad) => this.onGamePadAxe(axes);
    const onConnectionChangeHandler = (gamepad) => {
      console.log("gamepad!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!");
      this.state.showvirtualgamepad = false;
      this.setState(this.state);
    }
    
    
     
    this.myGamepadInstance = new Gamepad(
      onButtonChangeHandler,
      onAxesChangeHandler,
      onConnectionChangeHandler
    );

    // global.isslave = window.location.pathname.startsWith('/3dclient'); 
        

    // global.ismaster = window.location.pathname.startsWith('/3dmaster');

    this.joystickon = false;
    this.joystickon2 = false;
    this.nbjoystickon = 0;

    this.joystickangle = 0;
    this.joystickangleUp = 0;
		this.joystickz = 0;
    this.joysticky = 0;
    this.joystickx = 0;

    this.joyspeed = 0.05;
    this.joyrotspeed = 0.02;

    this.lefttriggeron = false;
    this.righttriggeron = false;

    // this.stereoOffset = 0.01;

    this.clock = new Clock();
    
    this.testButton = this.testButton.bind(this);
    this.testButton2 = this.testButton2.bind(this);

    this.animate = this.animate.bind(this);
    this.renderScene = this.renderScene.bind(this);
    this.reinstantiateTiles = this.reinstantiateTiles.bind(this);
    this.browsenow = this.browsenow.bind(this);
    this.setCameraPosition = this.setCameraPosition.bind(this);
    this.setCameraRotation = this.setCameraRotation.bind(this);
    this.onrMoveJoystick = this.onrMoveJoystick.bind(this);
    this.onrMoveJoystick2 = this.onrMoveJoystick2.bind(this);
    this.onEndJoystick = this.onEndJoystick.bind(this);
    this.onStartJoystick = this.onStartJoystick.bind(this);
    this.onEndJoystick2 = this.onEndJoystick.bind(this);
    this.onStartJoystick2 = this.onStartJoystick.bind(this);
    this.updateJoyCamera = this.updateJoyCamera.bind(this);
    this.resetCamera = this.resetCamera.bind(this);
    this.onWindowResize = this.onWindowResize.bind(this);

    this.onGamePadAxe = this.onGamePadAxe.bind(this);
    this.onGamePadButton = this.onGamePadButton.bind(this);

    this.internalmessageReceived = this.internalmessageReceived.bind(this);
    this.animateOnce = this.animateOnce.bind(this);
this.moveUp = this.moveUp.bind(this);
    global.needsRerender = true;

    // console.log("1111111111111111111111111111",this.props.match.params.name)

    this.modelename = this.props.match.params.name

    let resolution = 1;
    if (this.isstereo) resolution = 2;

    this.params = {

      'enableUpdate': true,
      'raycast': NONE,
      'enableCacheDisplay': false,
      'enableRendererStats': false,
      'orthographic': false,
    
      'errorTarget': 14,
      'errorThreshold': 60,
      'maxDepth': 15,
      'loadSiblings': true,
      'stopAtEmptyTiles': true,
      'displayActiveTiles': false,
      'resolutionScale': 1.0,
      'up': '+Y',
      // 'up': hashUrl ? '+Z' : '+Y',
      'displayBoxBounds': false,
      'colorMode': 0,
      'showThirdPerson': false,
      'showSecondView': false,
      'reload': this.reinstantiateTiles,
          
    };

    var self = this;

    global.myEmitter.on('3dbrowsenow', function (data) {
        self.browsenow(data.browseEvent);
   
       
    });

    global.myEmitter.on('messagereceived', function (data) {
      self.internalmessageReceived(data);
     
  });


    

  }

  internalmessageReceived(message){
    console.log('3dmessagereceived ',message.internalmessage.getText());
    // console.log('messagereceived type',message.internalmessage.type);
    // console.log('messagereceived!!!!! message',message.internalmessage.message);

    
    this.state.texttospeechreceived = message.internalmessage.getText();
    this.setState(this.state);
    
  }



  browsenow(browseEvent){
      // console.log("browseEventbrowseEvent",browseEvent)

      this.setCameraPosition(browseEvent.data.data.position)  


      console.log("browseEventbrowseEvent rotation",browseEvent.data.data.rotation._y)
      this.setCameraRotation(browseEvent.data.data.rotation)

      this.animateOnce();
  }

  setCameraPosition(position){
    this.camera.position.set(position.x,position.y,position.z)
  }

  setCameraRotation(rotation){

    this.camera.rotation.set(rotation._x,rotation._y,rotation._z)
    // this.camera.rotation.y = rotation.y;
  }

//   onMouseDown() {
//     console.log("onmousedown")
// }

  init(){


    this.scene = new Scene();

    let width = window.innerWidth;

    if (this.state.StereoEnabled) width = width/2;

    this.renderer = new WebGLRenderer( { antialias: true } );
    this.renderer.setPixelRatio( window.devicePixelRatio );
    this.renderer.setSize( width, window.innerHeight );
    this.renderer.setClearColor( 0x151c1f );
    this.renderer.outputEncoding = sRGBEncoding;   

    document.getElementById("tileContainer").appendChild( this.renderer.domElement );

    // console.log("tileContainer",nodeUtils.getHeight("tileContainer"));

    // this.renderer.domElement.tabIndex = 1;

    // this.renderer.xr.enabled = true;

    // this.renderer2 = new WebGLRenderer( { antialias: true } );
    // this.renderer2.setPixelRatio( window.devicePixelRatio );
    // this.renderer2.setSize( window.innerWidth, window.innerHeight );
    // this.renderer2.setClearColor( 0x151c1f );
    // this.renderer2.outputEncoding = sRGBEncoding;   

    // this.renderer2.domElement.tabIndex = 1;

    // document.getElementById("tileContainer").appendChild( this.renderer2.domElement );

    // console.log("tileContainer",nodeUtils.getHeight("tileContainer"));

    



    // let self = this;
    // this.renderer.setAnimationLoop( function () {

    //   self.renderer.render( scene, camera );
    
    // } );    

    this.camera = new PerspectiveCamera( 60, window.innerWidth / window.innerHeight, 1, 1000 );
    // this.camera = new OrthographicCamera( window.innerWidth / -2,  window.innerHeight/-2, 1, 1000 );
    // this.camera = new OrthographicCamera();
    // this.camera = new PerspectiveCamera( 60, window.innerWidth / window.innerHeight, 0.01, 4000 );
    // this.camera = new PerspectiveCamera( 60, 0.7305045871559633, 1, 4000 );
	this.camera.position.set( 400, 400, 400);
	this.camera.rotation.set( 0, 0, 0 );  

  // this.camera.eulerOrder = "YXZ"
  // this.camera.position.set( 0, 0, -400 );
  // this.camera.lookAt( 0, 0, 0 );
  
    // this.cameraHelper = new CameraHelper( this.camera );
    // this.scene.add( this.cameraHelper );

	// this.orthoCamera = new OrthographicCamera();
	// this.orthoCameraHelper = new CameraHelper( this.orthoCamera );
	// this.scene.add( this.orthoCameraHelper );

	// secondary camera view
	// this.secondCamera = new PerspectiveCamera( 60, window.innerWidth / window.innerHeight, 1, 4000 );
  // this.secondCamera = new OrthographicCamera();
	// this.secondCamera.position.set( 400, 400, - 400 );
	// this.secondCamera.lookAt( 0, 0, 0 );

	// secondRenderer = new WebGLRenderer( { antialias: true } );
	// secondRenderer.setPixelRatio( window.devicePixelRatio );
	// secondRenderer.setSize( window.innerWidth, window.innerHeight );
	// secondRenderer.setClearColor( 0x151c1f );
	// secondRenderer.outputEncoding = sRGBEncoding;

	// document.body.appendChild( secondRenderer.domElement );
	// secondRenderer.domElement.style.position = 'absolute';
	// secondRenderer.domElement.style.right = '0';
	// secondRenderer.domElement.style.top = '0';
	// secondRenderer.domElement.style.outline = '#0f1416 solid 2px';
	// secondRenderer.domElement.tabIndex = 1;

	// secondControls = new FlyOrbitControls( secondCamera, secondRenderer.domElement );
	// secondControls.screenSpacePanning = false;
	// secondControls.minDistance = 1;
	// secondControls.maxDistance = 2000;

	// secondCameraHelper = new CameraHelper( secondCamera );
	// this.scene.add( secondCameraHelper );

	// Third person camera view
  // this.thirdPersonCamera = new OrthographicCamera();

  if (this.state.StereoEnabled){
    this.stereoCamera = new PerspectiveCamera( 60, window.innerWidth / window.innerHeight, 1, 4000 );
    this.stereoCamera.position.set( 40, 40, 10 );
    this.stereoCamera.lookAt( 0, 0, 0 );
    // this.thirdPersonCamera.position.setX(40);
    // this.thirdPersonCamera.position.setY(40);
    // this.thirdPersonCamera.position.setZ(0); 
    // this.thirdPersonCamera.rotation.set(0,0,0)     
  
  
    let stereocointainer = document.getElementById("tileContainerStereo");
  
    // console.log("orthocointainer",nodeUtils.getHeight("tileContainer2"));
  
    this.stereoRenderer = new WebGLRenderer( { antialias: true } );
    this.stereoRenderer.setPixelRatio( window.devicePixelRatio );
    // this.thirdPersonRenderer.setSize( window.innerWidth, window.innerHeight );
    this.stereoRenderer.setSize(width, window.innerHeight  );
    this.stereoRenderer.setClearColor( 0x0f1416 );
    this.stereoRenderer.outputEncoding = sRGBEncoding;
    
  
    stereocointainer.appendChild( this.stereoRenderer.domElement );
    // document.body.appendChild( thirdPersonRenderer.domElement );
    this.stereoRenderer.domElement.style.position = 'fixed';
    this.stereoRenderer.domElement.style.right = '0px';
    this.stereoRenderer.domElement.style.bottom = '0px';
    // thirdPersonRenderer.domElement.tabIndex = 1;
  
    this.stereoRenderer.domElement.tabIndex = 1;
  
    // this.stereoControls = new FlyOrbitControls( this.stereoCamera, this.stereoRenderer.domElement );
    // this.stereoControls.screenSpacePanning = false;
    // this.stereoControls.minDistance = 1;
    // this.stereoControls.maxDistance = 2000;
  }

  if (this.secondCameraenabled){
    this.thirdPersonCamera = new PerspectiveCamera( 60, window.innerWidth / window.innerHeight, 1, 4000 );
    // this.thirdPersonCamera.position.set( 40, 40, 10 );
    this.thirdPersonCamera.lookAt( 0, 0, 0 );
    this.thirdPersonCamera.position.setX(40);
    this.thirdPersonCamera.position.setY(40);
    this.thirdPersonCamera.position.setZ(0); 
    this.thirdPersonCamera.rotation.set(0,0,0)     
  
  
    let orthocointainer = document.getElementById("tileContainer2");
  
    // console.log("orthocointainer",nodeUtils.getHeight("tileContainer2"));
  
    this.thirdPersonRenderer = new WebGLRenderer( { antialias: true } );
    this.thirdPersonRenderer.setPixelRatio( window.devicePixelRatio );
    // this.thirdPersonRenderer.setSize( window.innerWidth, window.innerHeight );
    this.thirdPersonRenderer.setSize( 300, 300 );
    this.thirdPersonRenderer.setClearColor( 0x0f1416 );
    this.thirdPersonRenderer.outputEncoding = sRGBEncoding;
    
  
    orthocointainer.appendChild( this.thirdPersonRenderer.domElement );
    // document.body.appendChild( thirdPersonRenderer.domElement );
    this.thirdPersonRenderer.domElement.style.position = 'fixed';
    this.thirdPersonRenderer.domElement.style.left = '5px';
    this.thirdPersonRenderer.domElement.style.bottom = '5px';
    // thirdPersonRenderer.domElement.tabIndex = 1;
  
    this.thirdPersonRenderer.domElement.tabIndex = 1;
  
    this.thirdPersonControls = new FlyOrbitControls( this.thirdPersonCamera, this.thirdPersonRenderer.domElement );
    this.thirdPersonControls.screenSpacePanning = false;
    this.thirdPersonControls.minDistance = 1;
    this.thirdPersonControls.maxDistance = 2000;
  }


	// controls
	// this.controls = new FPSControls( this.camera, this.renderer.domElement );
  // this.controls = new FlyOrbitControls( this.camera, this.renderer.domElement );
  this.controls = new OrbitControls( this.camera, this.renderer.domElement );
  // this.controls = new FirstPersonControls( this.camera, this.renderer.domElement );
	this.controls.screenSpacePanning = false;
	this.controls.minDistance = 1;
	this.controls.maxDistance = 2000;



  this.controls.mouseButtons = {
    LEFT: -1,
    MIDDLE: -1,
    RIGHT: -1
  }

  this.controls.touches = {
    ONE: -1,
    TWO: -1
  }

  this.controls.enableZoom = false;

  // this.joystick = new Joystick(
  //   camera
  // );

	// lights
	const dirLight = new DirectionalLight( 0xffffff );
	dirLight.position.set( 1, 2, 3 );
	this.scene.add( dirLight );

	const ambLight = new AmbientLight( 0xffffff, 0.2 );
	this.scene.add( ambLight );

	box = new Box3();

	this.offsetParent = new Group();
  // this.offsetParent.position.setX(this.offsetParent.position.x-50);
  //   this.offsetParent.position.setY(this.offsetParent.position.y-50);
    // this.offsetParent.position.setZ(-50);
	this.scene.add( 	this.offsetParent );

	// Raycasting init
	this.raycaster = new Raycaster();
	this.mouse = new Vector2();

	this.rayIntersect = new Group();

	const rayIntersectMat = new MeshBasicMaterial( { color: 0xe91e63 } );
  this.rayMesh = new Mesh( new CylinderBufferGeometry( 0.25, 0.25, 6 ), rayIntersectMat );
  this.rayMesh.rotation.x = Math.PI / 2;
  this.rayMesh.position.z += 3;
	this.rayIntersect.add( 	this.rayMesh );

  this.rayRing = new Mesh( new TorusBufferGeometry( 1.5, 0.2, 16, 100 ), rayIntersectMat );
	this.rayIntersect.add( 	this.rayRing );
	this.scene.add( this.rayIntersect );
	this.rayIntersect.visible = false;

	this.reinstantiateTiles();

  if (this.showgui){
    const gui = new dat.GUI();
    gui.width = 300;
  
    const tileOptions = gui.addFolder( 'Tiles Options' );
    tileOptions.add( this.params, 'loadSiblings' );
    tileOptions.add( this.params, 'stopAtEmptyTiles' );
    tileOptions.add( this.params, 'displayActiveTiles' );
    tileOptions.add( this.params, 'errorTarget' ).min( 0 ).max( 50 );
    tileOptions.add( this.params, 'errorThreshold' ).min( 0 ).max( 1000 );
    tileOptions.add( this.params, 'maxDepth' ).min( 1 ).max( 100 );
    tileOptions.add( this.params, 'up', [ '+Y', '+Z', '-Z' ] );
    tileOptions.open();
  
    const debug = gui.addFolder( 'Debug Options' );
    debug.add( this.params, 'displayBoxBounds' );
    debug.add( this.params, 'colorMode', {
  
      NONE,
      SCREEN_ERROR,
      GEOMETRIC_ERROR,
      DISTANCE,
      DEPTH,
      RELATIVE_DEPTH,
      IS_LEAF,
      RANDOM_COLOR,
  
    } );
    debug.open();
  
    const exampleOptions = gui.addFolder( 'Example Options' );
    exampleOptions.add( this.params, 'resolutionScale' ).min( 0.01 ).max( 2.0 ).step( 0.01 ).onChange( this.onWindowResize );
    exampleOptions.add( this.params, 'orthographic' );
    exampleOptions.add( this.params, 'showThirdPerson' );
    exampleOptions.add( this.params, 'showSecondView' ).onChange( this.onWindowResize );
    exampleOptions.add( this.params, 'enableUpdate' ).onChange( v => {
  
      tiles.parseQueue.autoUpdate = v;
      tiles.downloadQueue.autoUpdate = v;
  
      if ( v ) {
  
        tiles.parseQueue.scheduleJobRun();
        tiles.downloadQueue.scheduleJobRun();
  
      }
  
    } );
    exampleOptions.add( this.params, 'raycast', { NONE, ALL_HITS, FIRST_HIT_ONLY } );
    exampleOptions.add( this.params, 'enableCacheDisplay' );
    exampleOptions.add( this.params, 'enableRendererStats' );
    exampleOptions.open();
  
    gui.add( this.params, 'reload' );
    gui.open();

    // onWindowResize();
    
  }
  // GUI


	
    
  }

  onWindowResize() {

    console.log("onWindowResizeonWindowResize")

    // thirdPersonCamera.aspect = window.innerWidth / window.innerHeight;
    // thirdPersonCamera.updateProjectionMatrix();
    // thirdPersonRenderer.setSize( Math.floor( window.innerWidth / 3 ), Math.floor( window.innerHeight / 3 ) );
  
    // if ( params.showSecondView ) {
  
    //   camera.aspect = 0.5 * window.innerWidth / window.innerHeight;
    //   renderer.setSize( 0.5 * window.innerWidth, window.innerHeight );
  
    //   secondCamera.aspect = 0.5 * window.innerWidth / window.innerHeight;
    //   secondRenderer.setSize( 0.5 * window.innerWidth, window.innerHeight );
    //   secondRenderer.domElement.style.display = 'block';
  
    // } else {

    

      let width = window.innerWidth;

      if (this.state.StereoEnabled) width = width/2;
  
      this.camera.aspect = width / window.innerHeight;
      this.renderer.setPixelRatio( window.devicePixelRatio * this.params.resolutionScale );

    this.camera.updateProjectionMatrix();

    this.renderer.setSize( width, window.innerHeight );
    

    if (this.state.StereoEnabled) {
      this.stereoCamera.aspect = width / window.innerHeight;
      this.renderer.setPixelRatio( window.devicePixelRatio * this.params.resolutionScale );

    this.stereoCamera.updateProjectionMatrix();

    this.stereoRenderer.setSize( width, window.innerHeight );

    }

       

    this.animate();
  
    // secondCamera.updateProjectionMatrix();
    // secondRenderer.setPixelRatio( window.devicePixelRatio );
  
    // updateOrthoCamera();
  
  }

  render2() {

    // updateOrthoCamera();
  
    // this.cameraHelper.visible = false;

    // this.orthoCameraHelper.visible = false;
    // this.secondCameraHelper.visible = false;
  
    // render primary view
    if ( 	this.params.orthographic ) {
  
      const dist = 	this.orthoCamera.position.distanceTo( 	this.rayIntersect.position );
      this.rayIntersect.scale.setScalar( dist / 150 );
  
    } else {
  
      const dist = 	this.camera.position.distanceTo( 	this.rayIntersect.position );
      this.rayIntersect.scale.setScalar( dist * 	this.camera.fov / 6000 );
  
    }
    this.renderer.render( 	this.scene, 	this.params.orthographic ? 	this.orthoCamera : 	this.camera );
  

    if (this.state.StereoEnabled) this.stereoRenderer.render( this.scene, 	this.stereoCamera);

    if (this.secondCameraenabled) this.thirdPersonRenderer.render( this.scene, 	this.camera);

    // this.thirdPersonRenderer.render( this.scene, this.thirdPersonCamera );
  
    /*
    // render secondary view
    if ( 	this.params.showSecondView ) {
  
      const dist = 	this.secondCamera.position.distanceTo( 	this.rayIntersect.position );
      this.rayIntersect.scale.setScalar( dist * 	this.secondCamera.fov / 6000 );
      this.secondRenderer.render( 	this.scene, 	this.secondCamera );
  
    }
  
    // render third person view
    this.thirdPersonRenderer.domElement.style.visibility = 	this.params.showThirdPerson ? 'visible' : 'hidden';
    if ( params.showThirdPerson ) {
  
      cameraHelper.update();
      cameraHelper.visible = ! params.orthographic;
  
      orthoCameraHelper.update();
      orthoCameraHelper.visible = params.orthographic;
  
      if ( params.showSecondView ) {
  
        secondCameraHelper.update();
        secondCameraHelper.visible = true;
  
      }
  
      const dist = thirdPersonCamera.position.distanceTo( rayIntersect.position );
      rayIntersect.scale.setScalar( dist * thirdPersonCamera.fov / 6000 );
      thirdPersonRenderer.render( scene, thirdPersonCamera );
  
    }
  */

    /*
    const cacheFullness = 	this.tiles.lruCache.itemList.length / 	this.tiles.lruCache.maxSize;
    let str = `Downloading: ${ 	this.tiles.stats.downloading } Parsing: ${ 	this.tiles.stats.parsing } Visible: ${ 	this.tiles.group.children.length - 2 }`;
  
    if ( 	this.params.enableCacheDisplay ) {
  
      const geomSet = new Set();
      this.tiles.traverse( tile => {
  
        const scene = 	this.tile.cached.scene;
        if ( 	this.scene ) {
  
          this.scene.traverse( c => {
  
            if ( c.geometry ) {
  
              geomSet.add( c.geometry );
  
            }
  
          } );
  
        }
  
      } );
  
      let count = 0;
      geomSet.forEach( g => {
  
        count += BufferGeometryUtils.estimateBytesUsed( g );
  
      } );
      str += `<br/>Cache: ${ ( 100 * cacheFullness ).toFixed( 2 ) }% ~${ ( count / 1000 / 1000 ).toFixed( 2 ) }mb`;
  
    }
  
    if ( 	this.params.enableRendererStats ) {
  
      const memory = 	this.renderer.info.memory;
      const programCount = 	this.renderer.info.programs.length;
      str += `<br/>Geometries: ${ memory.geometries } Textures: ${ memory.textures } Programs: ${ programCount }`;
  
    }
  
    if ( 	this.statsContainer.innerHTML !== str ) {
  
      this.statsContainer.innerHTML = str;
  
    }
  
    */
  }
  

  // reinstantiateTiles() {

  //   // const url = hashUrl || "https://data.gigascope.net/3d/piecefullnolum2/tileset.json";
  //   // const url = "https://data.gigascope.net/3d/dome1/tileset.json";
  //   const url = "https://nasa-ammos.github.io/3DTilesRendererJS/example/data/tileset.json";
  //   // const url = "../../src/data/tileset.json";
  

  
  //   if ( this.tiles ) {
  
  //     this.offsetParent.remove( this.tiles.group );
  //     this.tiles.dispose();
  
  //   }
  
  //   // Note the DRACO compression files need to be supplied via an explicit source.
  //   // We use unpkg here but in practice should be provided by the application.
  //   this.dracoLoader = new DRACOLoader();
  //   this.dracoLoader.setDecoderPath( 'https://unpkg.com/three@0.123.0/examples/js/libs/draco/gltf/' );

  //   const loader = new GLTFLoader( this.tiles.manager );
  //   loader.setDRACOLoader( dracoLoader );
  
  //   this.tiles.fetchOptions.mode = 'cors';
  //   this.tiles.manager.addHandler( /\.gltf$/, loader );
  //   offsetParent.add( this.tiles.group );
     
  //   // this.tiles = new TilesRenderer( url );
  //   // this.tiles.fetchOptions.mode = 'cors';
  //   // this.tiles.manager.addHandler( /\.gltf$/, {
  
  //   //   parse( ...args ) {
  
  //   //     const loader = new GLTFLoader( this.tiles.manager );
  //   //     loader.setDRACOLoader( this.dracoLoader );
  //   //     return loader.parse( ...args );
  
  //   //   }
  
  //   // } );
  //   // this.offsetParent.add( this.tiles.group );
  
  // }

  reinstantiateTiles() {

    let url = '../../src/data/tileset.json';
    // url = 'https://data2.gigascope.net/3d/exemplestiles/TilesetWithDiscreteLOD/tileset.json';
    // const url = "https://nasa-ammos.github.io/3DTilesRendererJS/example/data/tileset.json";
    // const url = "https://data.gigascope.net/3d/dome1/tileset.json";
    url = hashUrl || "https://data.gigascope.net/3d/piecefullnolum2/tileset.json";
    // const url = hashUrl || "https://data.gigascope.net/3d/datalimoges/tileset.json";

    // const url = "https://data2.gigascope.net/3d/tileset.json";

    if (this.modelename == "ammos") url = "https://nasa-ammos.github.io/3DTilesRendererJS/example/data/tileset.json";
    if (this.modelename == "piece") url = "https://data.gigascope.net/3d/piecefullnolum2/tileset.json";
    if (this.modelename == "limogesvoute") url = "https://data2.gigascope.net/3d/limogesvoute/tileset.json";
    if (this.modelename == "lorin") url = "https://data2.gigascope.net/3d/lorin/tileset.json";
    //if (this.modelename == "lorin") url = "https://data2.gigascope.net/3d/expolorinmedium/tileset.json";
    if (this.modelename == "lorinlow") url = "https://data.gigascope.net/3d/expolorin1/tileset.json";
    if (this.modelename == "lorin2") url = "https://data2.gigascope.net/3d/expolorin1/tileset.json";
    if (this.modelename == "streetart1") url = "https://data2.gigascope.net/3d/streetart1/tileset.json";

    if (this.modelename == "notredame") url = "https://data2.gigascope.net/3d/notredame/tileset.json";

    if (this.modelename == "coeurchartres") url = "https://data2.gigascope.net/3d/coeurchartres/tileset.json";

    if (this.modelename == "saintechapelle3d") url = "https://data2.gigascope.net/3d/saintechapelle3d/tileset.json";

    if (this.modelename == "testmihaly") url = "https://data2.gigascope.net/3d/saintechapelle3d/tileset.json";
    
 
    if (this.modelename == "streetart1ok") url = "https://data2.gigascope.net/3d/streetart1ok/tileset.json";

    if (this.modelename == "gerberoy") url = "https://api.cesium.com/v1/assets/771311/endpoint";

    if (this.modelename == "chartres_streetart_lapin") url = "https://data2.gigascope.net/3d/chartres_streetart_lapin3/tileset.json";

    if (this.modelename == "gerberoy2") url = "https://data2.gigascope.net/3d/gerberoygiga/tileset.json";

    if (this.modelename == "bmvase") url = "https://data2.gigascope.net/3d/bmvase/tileset.json";

    if (this.modelename == "dome") url = "https://data2.gigascope.net/3d/dome/tileset.json";

    if (this.modelename == "montre") url = "https://data2.gigascope.net/3d/montre/tileset.json";


    if (this.loadlocal){
      url = url.replace("https://data2.gigascope.net/3d/","http://localhost/3dmodeles/")
      url = url.replace("https://data.gigascope.net/3d/","http://localhost/3dmodeles/")
    } 

    // url = "https://nasa-ammos.github.io/3DTilesRendererJS/example/data/tileset.json";


    console.log("url",url)

    if (url.endsWith("tileset.json")){
      this.setRegularTiles(url)
    } else {
      this.setIonTiles(url)
    }

    // if ( this.tiles ) {
  
    //   this.offsetParent.remove( this.tiles.group );
    //   this.tiles.dispose();
  
    // }

    // global.modelname = this.modelename;
  
    // // this.tiles = new TilesRenderer( url );
    // this.tiles = new DebugTilesRenderer( url );

    // this.tiles.onLoadModel = function ( scene ) {

    //   // create a custom material for the tile
    //   scene.traverse( c => {
    
    //     if ( c.material ) {
    
    //       // c.originalMaterial = c.material;
    //       c.material.side = 2;
    
    //     }
    
    //   } );
    
    // };

    

    // this.tiles.onLoadTileSet = () => global.needsRerender = true;
    // this.tiles.onLoadModel = () => global.needsRerender = true;
  
    // Note the DRACO compression files need to be supplied via an explicit source.
    // We use unpkg here but in practice should be provided by the application.
    // const dracoLoader = new DRACOLoader();
    // dracoLoader.setDecoderPath( 'https://unpkg.com/three@0.123.0/examples/js/libs/draco/gltf/' );
  
    // const loader = new GLTFLoader( this.tiles.manager );
    // loader.setDRACOLoader( dracoLoader );
  
    // this.tiles.fetchOptions.mode = 'cors';
    // this.tiles.manager.addHandler( /\.gltf$/, loader );
    // this.offsetParent.add( this.tiles.group );

    // this.resetCamera();
  
  }


onPointerDown( e ) {

	const bounds = this.getBoundingClientRect();
	startPos.set( e.clientX - bounds.x, e.clientY - bounds.y );

}

onPointerUp( e ) {

	const bounds = this.getBoundingClientRect();
	endPos.set( e.clientX - bounds.x, e.clientY - bounds.y );
	if ( startPos.distanceTo( endPos ) > 2 ) {

		return;

	}

	if ( lastHoveredElement === secondRenderer.domElement ) {

		raycaster.setFromCamera( mouse, this.secondCamera );

	} else {

		raycaster.setFromCamera( mouse, this.params.orthographic  ? this.orthoCamera : this.camera );

	}

	raycaster.firstHitOnly = true;
	const results = raycaster.intersectObject( tiles.group, true );
	if ( results.length ) {

		const object = results[ 0 ].object;
		const info = tiles.getTileInformationFromActiveObject( object );

		let str = '';
		for ( const key in info ) {

			let val = info[ key ];
			if ( typeof val === 'number' ) {

				val = Math.floor( val * 1e5 ) / 1e5;

			}

			let name = key;
			while ( name.length < 20 ) {

				name += ' ';

			}

			str += `${ name } : ${ val }\n`;

		}
		console.log( str );

	}

}

  setRegularTiles(url){
    if ( this.tiles ) {
  
      this.offsetParent.remove( this.tiles.group );
      this.tiles.dispose();
  
    }

    global.modelname = this.modelename;
  
    // this.tiles = new TilesRenderer( url );
    this.tiles = new DebugTilesRenderer( url );

    this.tiles.onLoadModel = function ( scene ) {

      // create a custom material for the tile
      scene.traverse( c => {
    
        if ( c.material ) {
    
          // c.originalMaterial = c.material;
          c.material.side = 2;
    
        }
    
      } );
    
    };

    this.tiles.onLoadTileSet = () => global.needsRerender = true;
    this.tiles.onLoadModel = () => global.needsRerender = true;

    this.setupTiles();
  }

  setIonTiles(urlstring){

    this.params.ionAssetId = urlstring;

    if ( this.tiles ) {
  
      this.offsetParent.remove( this.tiles.group );
      this.tiles.dispose();
  
    }
    
    let url = new URL( urlstring);
		url.searchParams.append( 'access_token', "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiIxN2JiMzNlMy04OWRhLTRmMmEtYWJhMC03NmMwZjQ1ODQ0YWMiLCJpZCI6NDM4MzgsImlhdCI6MTYxMzIzNzQ3NH0.9nFe3n-s2iWVxnx6roDylQTIBAoJh6IehZxezQx3puM" );

    let self = this;

		fetch( url, { mode: 'cors' } )
			.then( ( res ) => {

        

				if ( res.ok ) {

					return res.json();

				} else {

					return Promise.reject( `${res.status} : ${res.statusText}` );

				}

			} )
			.then( ( json ) => {

        // console.log('testtexttesttexttesttexttesttexttesttexttesttexttesttexttesttexttesttexttesttext',self.testtext)
				url = new URL( json.url );
				const version = url.searchParams.get( 'v' );

        this.tiles = new TilesRenderer( url );
				// this.tiles = new DebugTilesRenderer( url );
				this.tiles.fetchOptions.headers = {};
				this.tiles.fetchOptions.headers.Authorization = `Bearer ${json.accessToken}`;

				this.tiles.preprocessURL = uri => {

					uri = new URL( uri );
					uri.searchParams.append( 'v', version );
					return uri;

				};

        

				this.tiles.onLoadTileSet = () => {

					const box = new Box3();
					const matrix = new Matrix4();
					this.tiles.getOrientedBounds( box, matrix );
					const position = new Vector3().setFromMatrixPosition( matrix );
					const distanceToEllipsoidCenter = position.length();

					const surfaceDirection = position.normalize();
					const up = new Vector3( 0, 1, 0 );
					const rotationToNorthPole = this.rotationBetweenDirections( surfaceDirection, up );

					this.tiles.group.quaternion.x = rotationToNorthPole.x;
					this.tiles.group.quaternion.y = rotationToNorthPole.y;
					this.tiles.group.quaternion.z = rotationToNorthPole.z;
					this.tiles.group.quaternion.w = rotationToNorthPole.w;

					this.tiles.group.position.y = - distanceToEllipsoidCenter;

				};

        this.setupTiles();

				// const dracoLoader = new DRACOLoader();
        // dracoLoader.setDecoderPath( 'https://unpkg.com/three@0.123.0/examples/js/libs/draco/gltf/' );
      
        // const loader = new GLTFLoader( self.tiles.manager );
        // loader.setDRACOLoader( dracoLoader );
      
        // self.tiles.fetchOptions.mode = 'cors';
        // self.tiles.manager.addHandler( /\.gltf$/, loader );
        // self.offsetParent.add( this.tiles.group );

        // this.resetCamera();
			} )
			.catch( err => {

				console.error( 'Unable to get ion tileset:', err );

			} );
  }

  rotationBetweenDirections( dir1, dir2 ) {

    const rotation = new Quaternion();
    const a = new Vector3().crossVectors( dir1, dir2 );
    rotation.x = a.x;
    rotation.y = a.y;
    rotation.z = a.z;
    rotation.w = 1 + dir1.clone().dot( dir2 );
    rotation.normalize();
  
    return rotation;
  
  }

  setupTiles(){

    this.tiles.fetchOptions.mode = 'cors';

    const dracoLoader = new DRACOLoader();
    dracoLoader.setDecoderPath( 'https://unpkg.com/three@0.123.0/examples/js/libs/draco/gltf/' );
  
    const loader = new GLTFLoader( this.tiles.manager );
    loader.setDRACOLoader( dracoLoader );
  
    this.tiles.fetchOptions.mode = 'cors';
    this.tiles.manager.addHandler( /\.gltf$/, loader );
    this.offsetParent.add( this.tiles.group );

    this.resetCamera();
  }

  setupTiles2(){

    this.tiles.fetchOptions.mode = 'cors';
    
    const dracoLoader = new DRACOLoader();
    dracoLoader.setDecoderPath( 'https://unpkg.com/three@0.123.0/examples/js/libs/draco/gltf/' );
  
    const loader = new GLTFLoader( this.tiles.manager );
    loader.setDRACOLoader( dracoLoader );
  
    this.tiles.fetchOptions.mode = 'cors';
    this.tiles.manager.addHandler( /\.gltf$/, loader );
    this.offsetParent.add( this.tiles.group );

    this.resetCamera();
  }

  resetCamera(){

/*
    if (this.modelename == "bmvase") {
      // this.camera.position.setX(0);
      // this.camera.position.setY(5);
      // this.camera.position.setZ(5);

      // this.camera.rotation.set(0,90,0)
    }


    if (this.modelename == "lorinlow") {
      this.camera.position.setX(46);
      this.camera.position.setY(-42);
      this.camera.position.setZ(33);

      this.camera.rotation.set(0,0,0)
    }

    if (this.modelename == "lorin") {
      this.camera.position.setX(-34);
      this.camera.position.setY(-56);
      this.camera.position.setZ(55);

      this.camera.rotation.set(0,0.034,0)

    


      if (this.state.StereoEnabled){
        this.stereoCamera.position.setX(-46);
      this.stereoCamera.position.setY(-78);
      this.stereoCamera.position.setZ(60);
  
        this.stereoCamera.rotation.set(0,0,0)
      }

      if (this.secondCameraenabled){
        this.thirdPersonCamera.position.setX(-40);
        this.thirdPersonCamera.position.setY(-2);
        this.thirdPersonCamera.position.setZ(41);
  
        this.thirdPersonCamera.rotation.set(-Math.PI/2,0,Math.PI/2)
      }

      
      
    }

    if (this.modelename == "chartres_streetart_lapin") {
      // this.camera.position.setX(-4.5);
      // this.camera.position.setY(-5);
      // this.camera.position.setZ(-2.3);

      this.camera.position.setX(0);
      this.camera.position.setY(-4);
      this.camera.position.setZ(0);
  
      this.camera.rotation.set(0,-90,0)
    }

    if (this.modelename == "streetart1") {
      // this.camera.position.setX(-4.5);
      // this.camera.position.setY(-5);
      // this.camera.position.setZ(-2.3);

      this.camera.position.setX(-13);
      this.camera.position.setY(-5);
      this.camera.position.setZ(-17);
  
      this.camera.rotation.set(0,-2.6,0)
    }

    if (this.modelename == "saintechapelle3d") {
      // this.camera.position.setX(-4.5);
      // this.camera.position.setY(-5);
      // this.camera.position.setZ(-2.3);

      
      this.movetype = "vertical";
      this.camera.position.setX(30.5);
      this.camera.position.setY(5);
     this.camera.position.setZ(46.7);
  
      this.camera.rotation.set(0,1.44,-.055)
    }

    if (this.modelename == "notredame") {

      this.movetype = "vertical";
      // this.params.up = '+Z';
      this.camera.position.setX(-8.15);
      this.camera.position.setY(-8.7);
      this.camera.position.setZ(20);      

      this.camera.rotation.set(-0.23,0,0)

      if (this.state.StereoEnabled){
        this.stereoCamera.position.setX(-8.15);
      this.stereoCamera.position.setY(-8.7);
      this.stereoCamera.position.setZ(20);      

      this.stereoCamera.rotation.set(-0.23,0,0)
      }
    }

    if (this.modelename == "coeurchartres") {

      this.movetype = "vertical";
      // this.params.up = '+Z';
      this.camera.position.setX(-3.5);
      this.camera.position.setY(5.74);
      this.camera.position.setZ(26.54);      

      this.camera.rotation.set(-0.23,0,0)

      if (this.state.StereoEnabled){
        this.stereoCamera.position.setX(-3.5);
        this.stereoCamera.position.setY(5.74);
        this.stereoCamera.position.setZ(26.54);     

      this.stereoCamera.rotation.set(-0.23,0,0)
      }
    }
if (this.modelename == "limogesvoute") {

      this.movetype = "vertical";
      this.params.up = '+Z';
      this.camera.position.setX(-17.5);
      this.camera.position.setY(-4.53);
      this.camera.position.setZ(-61);      

      this.camera.rotation.set(-2.9,0,0)

      if (this.state.StereoEnabled){
        this.stereoCamera.position.setX(-17.5);
      this.stereoCamera.position.setY(-4.53);
      this.stereoCamera.position.setZ(-61);      

      this.stereoCamera.rotation.set(-2.9,0,0)
      }
    }
    console.log("this.state.stereoOffset",this.state.stereoOffset)
    if (this.state.StereoEnabled) {
    //   this.stereoCamera.position.set(this.camera.position);
    //   this.stereoCamera.rotation.set(this.camera.rotation);

      this.stereoCamera.rotateOnWorldAxis(new Vector3(0.0, 1.0, 0.0), this.state.stereoOffset)
    }
*/
    this.animateOnce();
  }

  start() {
    if (!this.frameId) {
      this.frameId = requestAnimationFrame(this.animate)
    }

  }

   ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
   ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
   ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
   ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
   ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

   animateOnce(){
    global.needsRerender = true;
    this.animate();

   }

  animate() {


    // console.log("11111111111111111111111 animate");
    // requestAnimationFrame( this.animate );

    this.controls.update();
  
    // update options
    // let u = 2;
    
    // let mu = 2;

    if ( ! this.tiles ) return;

    // console.log(this.params)
    this.tiles.errorTarget = this.params.errorTarget;
    this.tiles.errorThreshold = this.params.errorThreshold;
    this.tiles.loadSiblings = this.params.loadSiblings;
    this.tiles.stopAtEmptyTiles = this.params.stopAtEmptyTiles;
    this.tiles.displayActiveTiles = this.params.displayActiveTiles;
    this.tiles.maxDepth = this.params.maxDepth;
    this.tiles.displayBoxBounds = this.params.displayBoxBounds;
    this.tiles.colorMode = parseFloat( this.params.colorMode );
  
    if (!this.cameraset){
    if ( this.params.orthographic ) {
  
      this.tiles.deleteCamera( this.camera );
      this.tiles.setCamera( this.orthoCamera );
      this.tiles.setResolutionFromRenderer( this.orthoCamera, this.renderer );
  
    } else {
  
      this.tiles.deleteCamera( this.orthoCamera );
      this.tiles.setCamera( this.camera );
      this.tiles.setResolutionFromRenderer( this.camera, this.renderer );
  
    }
  }

    this.cameraset = true;

    if (this.state.StereoEnabled){
      this.tiles.setCamera( this.stereoCamera );
      this.tiles.setResolutionFromRenderer( this.stereoCamera, this.stereoRenderer );
    }

    if (this.secondCameraenabled){
      this.tiles.setCamera( this.thirdPersonCamera );
    this.tiles.setResolutionFromRenderer( this.thirdPersonCamera, this.thirdPersonRenderer );
    }

    
  
    // if ( this.params.showSecondView ) {
  
    //   this.tiles.setCamera( this.secondCamera );
    //   this.tiles.setResolutionFromRenderer( this.secondCamera, secondRenderer );
  
    // } else {
  
    //   this.tiles.deleteCamera( this.secondCamera );
  
    // }
  
    this.offsetParent.rotation.set( 0, 0, 0 );
    if ( this.params.up === '-Z' ) {
  
      this.offsetParent.rotation.x = Math.PI / 2;
  
    } else if ( this.params.up === '+Z' ) {
  
      this.offsetParent.rotation.x = - Math.PI / 2;
  
    }
  
    this.offsetParent.updateMatrixWorld( true );
  
    // update tiles center
    if ( this.tiles.getBounds( box ) ) {
  
      box.getCenter( this.tiles.group.position );
      this.tiles.group.position.multiplyScalar( - 1 );
  
    }
  
    if ( parseFloat( this.params.raycast ) !== NONE && lastHoveredElement !== null ) {
  
      if ( this.lastHoveredElement === this.renderer.domElement ) {
  
        this.raycaster.setFromCamera( mouse, this.params.orthographic ? orthoCamera : camera );
  
      } else {
  
        // this.raycaster.setFromCamera( mouse, secondCamera );
  
      }
  
      this.raycaster.firstHitOnly = parseFloat( this.params.raycast ) === FIRST_HIT_ONLY;
  
      const results = this.raycaster.intersectObject( this.tiles.group, true );
      if ( results.length ) {
  
        const closestHit = results[ 0 ];
        const point = closestHit.point;
        this.rayIntersect.position.copy( point );
  
        // If the display bounds are visible they get intersected
        if ( closestHit.face ) {
  
          const normal = closestHit.face.normal;
          normal.transformDirection( closestHit.object.matrixWorld );
          this.rayIntersect.lookAt(
            point.x + normal.x,
            point.y + normal.y,
            point.z + normal.z
          );
  
        }
  
        this.rayIntersect.visible = true;
  
      } else {
  
        this.rayIntersect.visible = false;
  
      }
  
    } else {
  
      this.rayIntersect.visible = false;
  
    }
  
    // update tiles
    window.tiles = this.tiles;
    if ( this.params.enableUpdate && (global.needsRerender || this.joystickon || this.joystickon2 || this.updateAlways)) {
  
      // this.secondCamera.updateMatrixWorld();
      global.needsRerender = false;
      this.camera.updateMatrixWorld();
      
      // this.orthoCamera.updateMatrixWorld();
      this.tiles.update();
      this.renderer.render(this.scene, this.camera)

      if (this.state.StereoEnabled) {
        this.stereoCamera.updateMatrixWorld();
        this.stereoRenderer.render(this.scene, this.stereoCamera)
      }

      if (this.secondCameraenabled) {
        this.thirdPersonCamera.updateMatrixWorld();
        this.thirdPersonRenderer.render(this.scene, this.thirdPersonCamera)
      }
  
      console.log("animate")
    }


    if ( this.joystickon) this.updateJoyCamera();
    

    // this.render2();

    // this.renderScene()
    this.frameId = window.requestAnimationFrame(this.animate)

    
  
    
    // stats.update();
  
  }

  renderScene() {
    
  }
  
  
  componentDidMount() {
    this.init();
    this.start();

    window.addEventListener( 'resize', this.onWindowResize, false );
    if (!nodeUtils.isSlave()) this.myGamepadInstance.start();

    

  }

  componentWillUpdate(nextProps) {



  }

  componentDidUpdate(){

  }

  testButton(){

    // this.controls.lock()
    console.log("this.camera",this.camera);

    this.stereoOffset += 0.0005;
    this.stereoCamera.rotateOnWorldAxis(new Vector3(0.0, 1.0, 0.0), 0.0005)

    this.state.stereoOffset = this.stereoOffset;

    this.state.message = this.stereoOffset;
    nodeUtils.SaveFloat("stereoffset",this.stereoOffset)
    this.setState(this.state);

    
    // alert(this.stereoOffset);

    // this.camera.up.set( 0, 0, 1 );
    // console.log(this.camera);

    // this.camera.position.setX(-4.5);
    // this.camera.position.setY(-5);
    // this.camera.position.setZ(-2.3);

    // this.camera.rotation.set(0,-2.6,0)
    // // console.log(this.offsetParent.position);
    // this.offsetParent.position.setX(this.offsetParent.position.x-50);
    // this.offsetParent.position.setY(this.offsetParent.position.y-50);

    // console.log(this.offsetParent.position);
    
  }

  
  testButton2(){

    // this.controls.lock()
    
    // console.log("this.camera.position");
    // console.log(this.camera);

    // this.camera.position.setX(48);
    // this.camera.position.setY(-38);
    // this.camera.position.setZ(44);

    // this.camera.rotateOnWorldAxis(new Vector3(1.0, 0.0, 0.0), 1)
    // this.camera.rotateOnWorldAxis(new Vector3(0.0, 1.0, 0.0), 0.5)

    this.stereoOffset -= 0.0005;
    this.stereoCamera.rotateOnWorldAxis(new Vector3(0.0, 1.0, 0.0), -0.0005)

    this.state.stereoOffset = this.stereoOffset;
    this.state.message = this.stereoOffset;
    this.setState(this.state);

    console.log(this.stereoOffset);
    

    // alert(this.stereoOffset);

    // this.camera.rotation.set(this.camera.rotation.x,this.camera.rotation.y,0)
    // this.camera.rotation.set(0,0.2,0)
    // console.log(this.offsetParent.position);
    // this.offsetParent.position.setX(this.offsetParent.position.x-50);
    // this.offsetParent.position.setY(this.offsetParent.position.y-50);

    // console.log(this.offsetParent.position);
    
  }

  showmessage(message){
    this.state.message = message;
    this.setState(this.state);
  }

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  onGamePadButton(button){

    console.log(button.name)

    // this.showmessage(button.name)

    switch (button.name){
      case "BUTTON_16":
        this.resetCamera();
        break;
      case "BUTTON_3":
        this.resetCamera();
        break;
      case "BUTTON_5":
        this.moveUp(2)
        break;
      case "BUTTON_4":
        this.moveUp(-2)
          break;
      case "BUTTON_14":
        if (this.state.StereoEnabled){
          this.state.stereoOffset += 0.0005;
          this.stereoCamera.rotateOnWorldAxis(new Vector3(0.0, 1.0, 0.0), 0.0005)
      
          // this.state.stereoOffset = this.stereoOffset;
      
          // this.state.message = this.state.stereoOffset;
          nodeUtils.SaveFloat("stereoOffset",this.state.stereoOffset)
          // this.setState(this.state);
        }
        break;
      case "BUTTON_15":
        if (this.state.StereoEnabled){
          this.state.stereoOffset -= 0.0005;
          this.stereoCamera.rotateOnWorldAxis(new Vector3(0.0, 1.0, 0.0), -0.0005)
      
          // this.state.stereoOffset = this.stereoOffset;
      
          // this.state.message = this.state.stereoOffset;
          nodeUtils.SaveFloat("stereoOffset",this.state.stereoOffset)
          // this.setState(this.state);
        }
        break;
        case "BUTTON_12":
        if (this.state.StereoEnabled){
          this.state.stereoOffset2 += 0.01;
         
      
          this.state.stereoOffset = this.stereoOffset;
      
          this.state.message = this.stereoOffset;
          nodeUtils.SaveFloat("stereoffset",this.stereoOffset)
          this.setState(this.state);
        }
        break;
      // case "BUTTON_13":
      //   if (this.state.StereoEnabled){
      //     this.stereoOffset -= 0.0005;
      //     this.stereoCamera.rotateOnWorldAxis(new Vector3(0.0, 1.0, 0.0), 0.0005)
      
      //     this.state.stereoOffset = this.stereoOffset;
      
      //     this.state.message = this.stereoOffset;
      //     nodeUtils.SaveFloat("stereoffset",this.stereoOffset)
      //     this.setState(this.state);
      //   }
      //   break;
      case "BUTTON_6":
        this.lefttriggeron = !this.lefttriggeron;
              break;  
      case "BUTTON_7":
        this.righttriggeron = !this.righttriggeron;
              break;   
    }

    this.updateJoyCamera();
    this.animateOnce();

    this.resetJoysValues();

  }

  leftTrigger(){

  }

  onGamePadAxe(axes){
    
    // console.log(axes)

    let val1 = axes[1];

    if (Math.abs(val1) < 0.2) val1 = 0;

		let val2 = axes[0];

    if (Math.abs(val2) < 0.2) val2 = 0;

    let val3 = axes[2];

    if (Math.abs(val3) < 0.2) val3 = 0;

		let val4 = axes[3];

    if (Math.abs(val4) < 0.2) val4 = 0;

    // console.log("val4",val4)

    switch (this.movetype){
      case "fps":
        this.Straf(val2*2)
        this.moveForward(-val1*2)
        this.rotateCamHoriz(val3*2)
        if (this.lefttriggeron)  this.moveUp(-val4*2)
        if (this.righttriggeron)  this.rotateCamVert(-val4*2)
        
        break;
      case "vertical":
        this.Straf(val2*2)
        this.moveForward(-val1*2)
        this.rotateCamHoriz(val3*2)
        if (!this.righttriggeron) this.moveUp(-val4*2)
        if (this.righttriggeron)  this.rotateCamVert(-val4*2)
        break;
    }

    this.joystickon = val1 != 0 || val2 != 0 || val3 != 0 || val4 != 0;
    // this.updateJoyCamera();

  }

  Straf(value){    
    this.joysticky = value;
    
  }

  moveForward(value){    
    this.joystickz = value;
  }

  moveUp(value){    
    this.joystickx = value;
  }

  rotateCamHoriz(value){
    this.joystickangle = value;
    // console.log(this.camera.rotation)
  }

  rotateCamVert(value){
    this.joystickangleUp = value;
  }


  onrMoveJoystick(evt,data){
    // this.joystick.onMove(data);
    let angle = data.angle;
		let force = data.force;

		let val1 = force * Math.cos(angle.radian)

    if (Math.abs(val1) < 0.2) val1 = 0;

		let val2 = force * Math.sin(angle.radian)

    if (Math.abs(val2) < 0.2) val2 = 0;

    

    switch (this.movetype){
      case "fps":
        this.Straf(val1)
        this.moveForward(val2)
        break;
      case "vertical":
        this.Straf(val1)
        this.moveForward(val2)
        break;
    }

    console.log("camera",this.camera)

    

    
  }



  onrMoveJoystick2(evt,data){
    // this.joystick.onMove(data);
    let angle = data.angle;
		let force = data.force;

    let val1 = force * Math.cos(angle.radian)

    if (Math.abs(val1) < 0.2) val1 = 0;

    let val2 = force * Math.sin(angle.radian)

    if (Math.abs(val2) < 0.2) val2 = 0;

    // this.joystickx = force * Math.sin(angle.radian)

    // if (Math.abs(this.joystickx) < 0.2) this.joystickx = 0;

    switch (this.movetype){
      case "fps":
        this.rotateCamHoriz(val1)
        if (this.allowrotvert) this.rotateCamVert(val2)
        // this.rotateCamVert(val1)
        // this.moveForward(val2)
        break;
      case "vertical":
        this.rotateCamHoriz(val1)
        // if (this.allowrotvert) this.rotateCamVert(val2)
        // this.rotateCamVert(val1)
        this.moveUp(val2)
        break;
    }
  }

  onStartJoystick(evt,data){
    // this.joystick.onStart(this.camera);
    // console.log("onStartJoystick",data)
    // this.nbjoystickon += 1;
    this.joystickon = true;
    this.updateJoyCamera();
  }

  onStartJoystick2(evt,data){
    // this.joystick.onStart(this.camera);
    // console.log("onStartJoystick",data)
    // this.nbjoystickon += 1;
    this.joystickon2 = true;
    this.updateJoyCamera();
  }

  onEndJoystick(evt,data){
    // this.nbjoystickon -= 1;
    this.joystickon = false;
    if (this.nbjoystickon == 0) this.resetJoysValues();

  }

  onEndJoystick2(evt,data){
    // this.nbjoystickon -= 1;
    this.joystickon2 = false;
    if (this.nbjoystickon == 0) this.resetJoysValues();


    console.log("camera",this.camera)

  }

  resetJoysValues(){
    
    this.joystickangle = 0;
    this.joystickangleUp = 0;
		this.joystickz = 0;
    this.joysticky = 0;
    this.joystickx = 0;
  }

  updateJoyCamera(){

    const tempVector = new Vector4( 0, 0, 0, 0 );
    const tempRotVector = new Vector4( 0, 0, 0, 0 );

    tempVector.set( 0, 0, 0, 0 );
    
    // tempVector.x +=  x * 1 *this.speed;

    tempVector.z -= this.joystickz * 1*this.joyspeed;

    tempVector.x = this.joysticky * 1*this.joyspeed;

    tempVector.y = this.joystickx * 1*this.joyspeed;
    
    tempVector.applyMatrix4( this.camera.matrixWorld );

    // tempVector.y = 0;

    // apply the movement
    const delta = 60 * this.clock.getDelta();
    // const speed = fastHeld ? this.fastSpeed : this.baseSpeed;
    this.camera
      .position
      .addScaledVector( tempVector,  delta );

      if (this.state.StereoEnabled) this.stereoCamera
      .position
      .addScaledVector( tempVector,  delta );
    // this
    // 	.target
    // 	.addScaledVector( tempVector, speed * delta );

    
    tempRotVector.set( 0, 0, 0, 0 );
    // if ( forwardHeld ) tempVector.z -= 1*this.speed;
    // if ( backHeld ) tempVector.z += 1*this.speed;
    // if ( leftHeld ) tempVector.x -= 1*this.speed;
    // if ( rightHeld ) tempVector.x += 1*this.speed;
    // if ( upHeld ) tempVector.y += 1*this.speed;
    tempRotVector.y -= 0.5*this.joystickangle*this.joyrotspeed;
    tempRotVector.z = 0.5*this.joystickangleUp*this.joyrotspeed;

    // if (this.allowrotvert) this.camera.rotateOnWorldAxis(new Vector3(1.0, 0.0, 0.0), tempRotVector.z)
    this.camera.rotateOnWorldAxis(new Vector3(1.0, 0.0, 0.0), tempRotVector.z)

    this.camera.rotateOnWorldAxis(new Vector3(0.0, 1.0, 0.0), tempRotVector.y)

    
    
    if (this.state.StereoEnabled) {
      this.stereoCamera.rotateOnWorldAxis(new Vector3(0.0, 1.0, 0.0), tempRotVector.y)
      if (this.allowrotvert) this.stereoCamera.rotateOnWorldAxis(new Vector3(1.0, 0.0, 0.0), tempRotVector.z)
    }

    


    // tempRotVector.applyMatrix4( this.camera.matrixWorld );

    // tempRotVector.z = 0;

    // this.camera.rotation.set(this.camera.rotation.x+ tempRotVector.z,this.camera.rotation.y+ tempRotVector.y,0)
    // this.camera.rotation.set(this.camera.rotation.x,this.camera.rotation.y+ tempRotVector.y,this.camera.rotation.z)

    

    global.myEmitter.emit('3dbrowsed', {position: this.camera.position,rotation: this.camera.rotation});

    global.needsRerender = true;

    
  }

  rotateToWorld = function() {
    var p = new Vector3();
    var o = new Vector3();
    return function rotateToWorld(camera, controls) {
        p.copy(camera.position);
        o.copy(controls.target);

        // position relative to the control
        p.sub(o);

        var Dxyz = Math.sqrt(p.x*p.x + p.y*p.y + p.z*p.z);
        var Dxz = Math.sqrt(p.x*p.x + p.z*p.z);

        return {
            x: Math.acos(Dxz/Dxyz) * (p.y>0?1:-1), // Angle Rotation on X axis camera
            y: Math.acos(p.z/Dxz) * (p.x>0?-1:1), // Angle Rotation on Y axis camera
            z: 0 // No rotation around z axis
        };
    }
}();

toggleFullscreen(){

  this.state.fullScreen=  !this.state.fullScreen;

  let isfull = this.state.fullScreen;

  console.log("this.state.fullScreen",isfull)

  // nodeUtils.SetFullscreen('3dcontainer',isfull);  
  
  global.isFullScreen = isfull;

  if (isfull){
    nodeUtils.SetFullscreen("3dcontainer");
  } else {
    nodeUtils.ExitFullscreen();
  }

  // this.onWindowResize()
 
  this.setState(this.state);
  
}


fullscreenIcon() {
  if (!document.fullscreen) {
    return <FontAwesomeIcon icon="expand"  onClick={() => this.toggleFullscreen}/>;
  }
  return <FontAwesomeIcon icon="compress"  onClick={() => this.toggleFullscreen}/>;
}
  
  render() {

    // const {lightboxtitre,lightboxurl,isplaying} = this.state;
    // console.log(lightboxtitre)

    const { fullScreen,texttospeechreceived,stereoOffset,StereoEnabled,message,showvirtualgamepad } = this.state;
    const joystick = this.joystick;

    let url = "https://gigascopejs.firebaseapp.com/3dclient/" + this.modelename;

    let righticon = 'topright1 whiteBackground clickeable padding075em';

    let lefticon = 'topleft1 whiteBackground clickeable padding075em';
    return (
      

      // (lightboxurl != "") &&
      <div id="3dcontainer">

      <div id="canvas-container">

		  </div>
         <div id="tileContainer" style={{ width: "50%", height: "100%" }}/>

         {(StereoEnabled) && <div id="tileContainerStereo" className="stereoView" />}

         {(this.secondCameraenabled) && <div id="tileContainer2" className="secondView" />}

          <div className="infront" >

          {(!StereoEnabled) && <img className="logocenter" src={"https://data.gigascope.net/data/Logo_full_white.png"} alt="Logo" />}
  
          {(nodeUtils.isCommunicating() && true) && 
                <div id="photortctopbar" className="rtctopbar clickeable">
                        <RtcComp />
                </div>
            }

            {(this.showgui) && (
            <button   onClick={() => this.testButton()} >
                Test Button
            </button>
          )}
          {(this.showgui) && (
            <button   onClick={() => this.testButton2()} >
                Test Button2
            </button>
            
            )}

          <div className={lefticon}>

            <FontAwesomeIcon icon="home"  onClick={() => this.resetCamera()}  />

          </div>
           
            <div className={righticon}>
              {!fullScreen &&  <FontAwesomeIcon icon="expand"  onClick={() => this.toggleFullscreen()}/>}
              {fullScreen &&  <FontAwesomeIcon icon="compress"  onClick={() => this.toggleFullscreen()}/>}
            </div>

          {showvirtualgamepad &&
          <div className="rightjoystick">
              <ReactNipple
                    // supports all nipplejs options
                    // see https://github.com/yoannmoinet/nipplejs#options
                    options={{ mode: 'static', position: { top: '50%', left: '50%' } }}
                    // any unknown props will be passed to the container element, e.g. 'title', 'style' etc
                    style={{
                        // outline: '1px dashed red',
                        width: 150,
                        height: 150
                        // if you pass position: 'relative', you don't need to import the stylesheet
                    }}
                    // all events supported by nipplejs are available as callbacks
                    // see https://github.com/yoannmoinet/nipplejs#start
                    // onMove={(evt, data) => this.onrightJoystick}
                    onMove={this.onrMoveJoystick2}
                    onStart={this.onStartJoystick2}
                    onEnd={this.onEndJoystick2}
                />
            </div>
            }

            {showvirtualgamepad &&
            <div className="leftjoystick">
              <ReactNipple
                    // supports all nipplejs options
                    // see https://github.com/yoannmoinet/nipplejs#options
                    options={{ mode: 'static', position: { top: '50%', left: '50%' } }}
                    // any unknown props will be passed to the container element, e.g. 'title', 'style' etc
                    style={{
                        // outline: '1px dashed red',
                        width: 150,
                        height: 150
                        // if you pass position: 'relative', you don't need to import the stylesheet
                    }}
                    // all events supported by nipplejs are available as callbacks
                    // see https://github.com/yoannmoinet/nipplejs#start
                    // onMove={(evt, data) => this.onrightJoystick}
                    onMove={this.onrMoveJoystick}
                    onStart={this.onStartJoystick}
                    onEnd={this.onEndJoystick}
                />
            </div>
             }

{(message!="") && 
                            <SubtilteComp  text={message} />}

            {(global.isslave && texttospeechreceived!="") && 
                            <SubtilteComp  text={texttospeechreceived} />}

            {(nodeUtils.isMaster()) && <QRCode className="rightcenter1" value={"url"} />}
          {/* </div> */}

     
        </div>

            

       
       {/* {VRButton} */}
      </div>
      
    );
  }
}

export default TestTiles;
