
function info() {

	try {
		const e = new Error();
	const regex = /\((.*):(\d+):(\d+)\)window.$/
	const match = regex.exec(e.stack.split("\n")[2]);

	let name = match[1];
	let namePosition = name.lastIndexOf('/') + 1;
	if (namePosition != 0) name = name.substr(namePosition);
	name = name.replace('.js','');
		
	// return
	return name + " " +  match[2];
	
	  } catch (e) {
		return "";
	  }
	
  };
export function Ui(pano,panodata){
    this.panoname = pano.panoname;
    this.uiGroups = [];
    // this.uiItems = panodata.uiItems;

    // let existing = this.Read();
    let existing;

   
    if (existing) {
        // existing = Object.create(original);
        // this.Clone(existing);   
        this.setUiGroups(existing.uiGroups);     
        // this.IsReady(false);
    }
    else {
        this.isReady = false;
        
        window.consoleM(info(),window.name,'panodata.uiGroups.length',panodata.uiGroups.length);

        if (panodata.uiGroups.length > 0){
            this.setUiGroups(panodata.uiGroups);
        } else{
            // this.setUiGroupsFromPopups(panodata.popups);
        }
        
    }

    
    // this.setUiGroups(panodata.uiGroups);

    // window.consoleM(info(),window.name,'this',this);

    // setUiItems();
};


// Ui.prototype.setThumb = function(index,thumb){
//     this.uiItems[index].thumb = thumb;
// };

Ui.prototype.AreThumbReady = function() {

    window.consoleM(info(),window.name,'this.uiGroups',this.uiGroups);
    if (!this.uiGroups) return true;
    
    for(var i=0;i<this.uiGroups.length;i++) {
        
        let uiGroup = this.uiGroups[i];
  
        if (!uiGroup.AreThumbReady()) return false;
        
    } 
    return true;   
};

Ui.prototype.getCurrentUiGroup = function(){
    return this.getUiGropup(window.panostates.currentUIGroup);
}

Ui.prototype.getAllIiItems = function(){
    let result = [];
    this.uiGroups.forEach(uiGroup => {
        result = result.concat(uiGroup.uiItems)
    });
    return result;
}


Ui.prototype.getUiItem = function(uiItemId){
    for(var i=0;i<this.uiGroups.length;i++) {
        let uiItem = this.uiGroups[i].getUiItem(uiItemId);
        if (uiItem){
            return uiItem;
        }
    } 
};

Ui.prototype.Clone = function(src) {

    for (let prop in src) {
      if (src.hasOwnProperty(prop)) {
        this[prop] = src[prop];
      }
    }
  };

Ui.prototype.IsReady = function(needSave){    
    this.isReady = true
    if (needSave) this.Save();
    this.updateAframe();
};

Ui.prototype.updateAframe = function(){
    window.thepano.window.AframeAction('UiReady',{rien:{}});
};

Ui.prototype.Save = function(){
    window.saveJSonToLocalstorage(this.panoname + "_Ui",this);
};

Ui.prototype.SaveNoThumb = function(){
    window.saveJSonToLocalstorageNoThumb(this.panoname + "_Ui_NoThumb",this.uiGroups[0]);
};

Ui.prototype.Read = function(){
    return window.readJSonFromLocalstorage(this.panoname + "_Ui");
};

Ui.prototype.getUiItems = function(uiItem){
    var uiGroup = this.getCurrentUiGroup();
    if (!uiGroup) return [];
    return uiGroup.uiItems;
};

Ui.prototype.getUiGropup = function(UiGroupId){
    for(var i=0;i<this.uiGroups.length;i++) {
        let uiGroup = this.uiGroups[i];
        if (uiGroup.id === UiGroupId){
            return uiGroup;
        }
    } 
};

Ui.prototype.updateUiItem = function(uiItem){
    for(var i=0;i<this.uiGroups.length;i++) {
        this.uiGroups[i].updateUiItem(uiItem);
        if (this.uiGroups[i].AreThumbReady()) this.Save();
    }    
};

Ui.prototype.addUiItem = function(uiItemData,uiGroupId){
    let uiGroup = this.getUiGropup(uiGroupId);
    if (!uiGroup){
        this.addUiGroup(this.panoname,0,'New Group',[uiItemData])
    } else{
        uiGroup.addUiItem(uiItemData);
    }    
    window.thepano.window.AframeAction('UpdateUi',{rien:{}});
}

Ui.prototype.getFirstUiItemwithoutThumb = function(){
    for(var i=0;i<this.uiGroups.length;i++) {
        let uiItem = this.uiGroups[i].getFirstUiItemwithoutThumb();
        if (uiItem){
            return uiItem;
        }
    } 
};



Ui.prototype.setUiGroups = function(uiGroupsData){ 
    
    this.uiGroups = [];
    for(var i=0;i<uiGroupsData.length;i++) {
        // window.consoleM(info(),window.name,'popup',popups[i]);
        let uiGroup = new UiGroup(this.panoname,uiGroupsData[i]);
        window.consoleM(info(),window.name,'uiGroup',uiGroup);        
        this.uiGroups.push(uiGroup);
    }    
};

Ui.prototype.setUiGroupsFromPopups = function(popups){ 
    
    this.uiGroups = [];

    let iTembuttons = [];

    for (var label in popups) {

        // let label = popup;
        let pos = popups[label].pos;
        let zoom = popups[label].zoom;
        
        let uiItem = new UiItem('thumb',label,pos,zoom);

        iTembuttons.push(uiItem);

        // window.consoleM(info(),window.name,'popup',popup);
        // window.consoleM(info(),window.name,'popup',popups[popup]);
    }

    let uiGroup = new UiGroup('Thumbs',0,'Vitraux',iTembuttons);

    this.uiGroups.push(uiGroup);

    window.saveJSonToLocalstorage(this.panoname + '_conv',this);
    

    // for(var i=0;i<uiGroupsData.length;i++) {
    //     // window.consoleM(info(),window.name,'popup',popups[i]);
    //     let uiGroup = new UiGroup(uiGroupsData[i]);
    //     // window.consoleM(info(),window.name,'uiItem',uiItem);        
    //     this.uiGroups.push(uiGroup);
    // }    
};

Ui.prototype.setUiGroupsfromJson = function(src){ 
    
    this.uiGroups = [];
    for(var i=0;i<src.uiGroups.length;i++) {
        // window.consoleM(info(),window.name,'popup',popups[i]);
    //    let uiGroup = new UiGroup(uiGroupsData[i]);
        // window.consoleM(info(),window.name,'uiItem',uiItem);        
    //    this.uiGroups.push(uiGroup);
    }    
};

// Ui.prototype.getControllerButtons = function(buttons){
//     let uiGoupData = {
//         id : leftcontrollerbuttons,
//         label : "Left Controller Buttons",
//         type: 'Buttons',
//         uiItems : [
//           {
//               label : "img2",
//               pos : [19162.350549647275,-55099.956909012],
//               zoom : 9
//           },
//           {
//               label : "img3",
//               pos : [25509.372738279766,-74128.77281384951],
//               zoom : 9
//           },
//           {
//               label : "img4",
//               pos : [38235.33333333333,-62828],
//               zoom : 9
//           },
//           {
//               label : "img5",
//               pos : [29843.79296393559,-80166.18781717731],
//               zoom : 9
//           }
//         ]
//     },
//     // return iTembuttons;

// };

// function UiGroup(type,id,label,uiItems){
//     this.id = id;
//     this.label = label;
//     this.type = type;
//     this.uiItems = uiItems;

// };



Ui.prototype.addUiGroup = function(panoname,id,label,type,uiItems){    
    let uiGroupdata = {
        id : id,        
        label : label,
        type : type,
        uiItems : uiItems
    };
    this.uiGroups.push(new UiGroup(panoname,uiGroupdata));
}

function UiGroup(panoname,uiGroupdata){
    this.id = uiGroupdata.id;
    this.panoname = panoname;
    this.label = uiGroupdata.label;
    this.type = uiGroupdata.type;
    this.setUiItems(uiGroupdata.uiItems);

};

UiGroup.prototype.addUiItem = function(uiItemData){
    let uiItem = new UiItem(this.panoname,this.id,this.uiItems.length,uiItemData);
    this.uiItems.push(uiItem);
}

Ui.prototype.getControllerButtons = function(buttons){
    let iTembuttons = [];
    buttons.forEach(element => {
        let itemButton = new UiItem(element);
        iTembuttons.push(itemButton);
    });
    return iTembuttons;

};

UiGroup.prototype.setUiItems = function(uiItemsData){

    // window.consoleM(info(),window.name,'uiItemsData',uiItemsData);    
    
    this.uiItems = [];

    switch (this.type){
        case 'Thumbs':
            for(var i=0;i<uiItemsData.length;i++) {
                // window.consoleM(info(),window.name,'popup',popups[i]);
                if (!uiItemsData[i].type) uiItemsData[i].type = 'thumb';
                // else let type = 'thumb';
        
                let uiItem = new UiItem(this.panoname,this.id,i,uiItemsData[i]);
                // window.consoleM(info(),window.name,'uiItem',uiItem);
                this.uiItems.push(uiItem);
            }    
        break;
        case 'Buttons':        
         /*   buttons.forEach(element => {
                let itemButton = new UiItem(element);
                iTembuttons.push(itemButton);
            });*/
        break;
    }
    
};

UiGroup.prototype.updateUiItem = function(uiItem){
    for(var i=0;i<this.uiItems.length;i++) {
        if (this.uiItems[i].equal(uiItem)){
            let theuiItem = this.uiItems[i];
            theuiItem.thumb = uiItem.thumb;
            window.AframeAction('updateUiThumb', {data:uiItem.Id()});
            // saveToLocalstorage(theuiItem.getThumbFilename(),theuiItem.thumb);
            // saveToLocalstorage(theuiItem.getFilename(),theuiItem);
        }
    } 
}

UiGroup.prototype.getFirstUiItemwithoutThumb = function(){
    for(var i=0;i<this.uiItems.length;i++) {
        let uiItem = this.uiItems[i];
        if (!uiItem.thumb){
            // let thumb = readFromLocalstorage(uiItem.getThumbFilename());
            // if (thumb) uiItem.thumb = thumb;
            // else 
            return this.uiItems[i];
        }
    } 
};

UiGroup.prototype.getUiItem = function(uiItemId){
    for(var i=0;i<this.uiItems.length;i++) {
        let uiItem = this.uiItems[i];
        if (uiItem.Id() === uiItemId){
            return uiItem;
        }
    } 
};

UiGroup.prototype.AreThumbReady = function() {
    let ready = true;
    for(var i=0;i<this.uiItems.length;i++) {
        let uiItem = this.uiItems[i];
        if (!uiItem.isThumbReady()){
            return false;
        }
    }
    return true;
}

// function UiItem(type,label,pos,zoom){
//     this.label = label;
//     this.pos = pos;
//     this.zoom = zoom;
//     this.type = type;
// };

function UiItem(panoname,uigroupid,index,uiItemdata){

    this.uigroupid = uigroupid;
    this.panoname = panoname;
    this.index = index;

    this.type = uiItemdata.type;

    switch(this.type){
        case 'thumb':
            this.label = uiItemdata.label;
            // let x = uiItemdata.pos[0] * 2;
            // let y = uiItemdata.pos[1] * 2;
            // this.pos = [x,y];

           this.pos = uiItemdata.pos;
            this.zoom = uiItemdata.zoom;
            this.thumb = uiItemdata.thumb;
        break;
        case 'button':
            this.buttontype = uiItemdata.buttontype;
            switch (this.buttontype){
                case ('zoommap'):
                    this.label = 'Zoom Map';
                    this.thumb ='#closewhite';
                break;
            }            
        break;
    }   


};

UiItem.prototype.isThumbReady = function(){
    return !(!this.thumb  || this.thumb === 'empty' || this.thumb === 'pending');
}

UiItem.prototype.getThumb = function(){
    if (!this.isThumbReady()) {
        if (!this.thumb  || this.thumb === 'empty'){
            this.thumb = 'pending';
            window.thepano.getUiItemThumb(this);            
        }
        
        return "#hourglass"
    }
    else return this.thumb;
}

UiItem.prototype.getThumbFilename = function(){
    return this.label + this.pos + "_UiItemThumb";
};

// UiItem.prototype.getThumbImg = function(){
//     return "data:image/png;base64," + this.thumb;
// };

UiItem.prototype.getFilename = function(){
    return this.label + "_" + this.pos + "_UiItem";
};

UiItem.prototype.Id = function(){
    switch(this.type){
        case 'thumb':
            let poss = (this.panoname + '_' + this.uigroupid + '_' + this.index).toString();
            poss = poss.replace('.','');
            poss = poss.replace('-','');
            return poss;        
    }       
};

UiItem.prototype.getType = function(){
    return this.type;
};

UiItem.prototype.equal = function(uiItem){
    return this.label === uiItem.label && this.pos === uiItem.pos && this.zoom === uiItem.zoom;
};




