import firebase from 'firebase/compat/app';

import React from 'react';
import { createRoot } from 'react-dom';
import { useSigninCheck, FirebaseAppProvider, useAuth } from 'reactfire';

import AuthenticationButtons from './AuthenticationButtons';

// Omitted

function HomeComp() {
  const auth = useAuth();
  const signIn = async () => {
    await auth.signInWithPopup(new firebase.auth.GoogleAuthProvider());
  };
  const signOut = async () => {
    await auth.signOut();
  };
  // When authenticated, show the Sign out button, else Sign in
  return (
    <div>
        hello
    </div>
  );
}

export default HomeComp;
