import firebase from '../Firebase';
import olPopup from './ol-popup/dist/ol-popup';
import { Position } from './Position';
import Draw, {createRegularPolygon, createBox} from 'ol/interaction/Draw';
import {Vector as VectorSource} from 'ol/source';
import {Vector as VectorLayer} from 'ol/layer';
import nodeUtils from './nodeUtils';

import GeoJSON from "ol/format/GeoJSON";

export class Annotation{

    constructor(){
        
        
    }

    setNew(data){
        this.parent = data.parent;
        this.pano = data.pano;
        this.panoid = data.pano.id;
        this.panoname = this.pano.panoname;
        this.map = data.pano.omap;
        this.source = data.pano.vectorsource;
        this.type = data.type;
        this.setInteraction();
        this.time = nodeUtils.getTime();
    }

    setExisting(parent,pano,data){
        this.parent = parent;
        this.pano = pano;
        this.panoid = data.panoid;
        this.panoname = data.panoname;
        this.thumb = data.data.thumb;
        this.time = data.data.time;
        this.type =  data.data.type;

        this.map = this.pano.omap;
        this.source = this.pano.vectorsource;
        
        this.data = data.data;
        
    }

    isNew(){
        return this.id != null;
    }

    setInteraction(){
        switch(this.type){
            case 'point':
                this.draw = new Draw({ 
                    type: 'Point',
                    source: this.source
                  });
        }
        let that = this;
        this.draw.on('drawend', function(e){
            that.feature = e.feature;
            that.map.removeInteraction(e.target);
            // that.pano.showNotification("hello",-1);
            that.parent.showGetAnnotationThumb(true,that);
        });
    }

    setFeature(){
        let features = (new GeoJSON()).readFeatures(this.data.feature)
        this.feature = features[0];
        this.source.addFeature(this.feature);        
    }

    getCenter(){
        return this.feature.getGeometry().getCoordinates();
    }

    getPosition(){
        return new Position(this.getCenter(),this.pano.getZoom());  
    }

    addInteraction(){    
        this.map.addInteraction(this.draw)
    }

    setThumb(thumb){
        this.thumb = thumb;
    }

    getSaveData(){
        var format = new GeoJSON();
        var routeFeatures = format.writeFeatures([this.feature]);

        return {type: this.type,feature:routeFeatures,thumb : this.thumb,time:this.time,author:this.author,text:this.text};
    }

    addNewToFirebase(){        
        nodeUtils.addAnnotation(this);
    }

    updateFirebase(){        
        nodeUtils.updateAnnotation(this);
    }

    setText(name,text){
        this.author = name;
        this.text = text;
    }

    getText(){
        return this.text;
    }

    getName(){
        return this.name;
    }





}