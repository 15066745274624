import React, { Component } from 'react';
import firebase from '../Firebase';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// var popup = require('popups');

class Create extends Component {

  constructor() {
    super();

    this.shown = true;
    
    this.ref = firebase.firestore().collection('gigaphotos');
    this.state = {
      titre: '',
      panoname: '',
      theme: '',
      info: '',
      width: 0,
      height: 0,
      numtiles: 0,
      witilesize: 0,
      url:'http://37.187.22.210/pano/'
    };
    
  }

  validate()  {


    this.ref.where('panoname', '==', this.state.panoname).get()
    .then(snapshot => {
      if (snapshot.empty) {

        if (this.state.panoname == '' || this.state.titre == '' || this.state.panoname == '' || 
        this.state.theme == '' || this.state.width == 0 || this.state.height == 0 || this.state.numtiles == 0 || 
        this.state.witilesize == 0 || this.state.url == ''){

            toast("Verify Data");

        } else{
            const { titre,panoname,theme,info,width,height,numtiles,witilesize,url } = this.state;

            this.ref.add({titre,panoname,theme,info,width,height,numtiles,witilesize,url}).then((docRef) => {
        /*  this.setState({
            titre: '',
          panoname: '',
          theme: '',
          info: '',
          width: 0,
          height: 0,
          numtiles: 0,
          witilesize: 0,
          url:'http://37.187.22.210/pano/'
          });*/
          this.props.history.push("/")
        })
        .catch((error) => {
          console.error("Error adding document: ", error);
        });
            return;
        }

      }  else {
        toast("panoname déjà pris");
        console.log("test");

      }

      
                 
    //  alert("testd");
      snapshot.forEach(doc => {
        console.log(doc.id, '=>', doc.data());
      });
     // render(<Toast />);
    })
    .catch(err => {
      console.log('Error getting documents', err);
    });
  }


  onChange = (e) => {
    const state = this.state
    state[e.target.name] = e.target.value;
    this.setState(state);
  }

  onSubmit = (e) => {
    e.preventDefault();

    this.validate()   
  }

  render() {

    

    const { titre,
        panoname,
        theme,
        info,
        width,
        height,
        numtiles,
        witilesize,url } = this.state;
    return (
      <div class="container">
          <ToastContainer />
        <div className="panel panel-default">
          <div className="panel-heading">
            <h3 className="panel-title">
              Ajouter Gigaphoto
            </h3>
          </div>
          <div class="panel-body">
            <h4><Link to="/" className="btn btn-primary">Book List</Link></h4>
            <form onSubmit={this.onSubmit}>
              <div className="form-group">
                <label htmlFor="panoname">Panoname:</label>
                <input type="text" className="form-control" name="panoname" value={panoname} onChange={this.onChange} placeholder="Panoname" />
              </div>
              <div className="form-group">
                <label htmlFor="titre">Title:</label>
                <input type="text" className="form-control" name="titre" value={titre} onChange={this.onChange} placeholder="Titre" />
              </div>
              <div className="form-group">
                <label htmlFor="theme">Theme:</label>
                <input type="text" className="form-control" name="theme" value={theme} onChange={this.onChange} placeholder="Theme" />
              </div>
              <div className="form-group">
                <label htmlFor="info">Info:</label>
                <textArea className="form-control" name="info" onChange={this.onChange} placeholder="info" cols="80" rows="3">{info}</textArea>
              </div>
              <div className="form-group">
                <label htmlFor="width">width:</label>
                <input type="number" className="form-control" name="width" value={width} onChange={this.onChange} placeholder="width" />
              </div>
              <div className="form-group">
                <label htmlFor="height">height:</label>
                <input type="number" className="form-control" name="height" value={height} onChange={this.onChange} placeholder="height" />
              </div>
              <div className="form-group">
                <label htmlFor="numtiles">numtiles:</label>
                <input type="number" className="form-control" name="numtiles" value={numtiles} onChange={this.onChange} placeholder="numtiles" />
              </div>
              <div className="form-group">
                <label htmlFor="witilesize">witilesize:</label>
                <input type="number" className="form-control" name="witilesize" value={witilesize} onChange={this.onChange} placeholder="witilesize" />
              </div>
              <div className="form-group">
                <label htmlFor="url">Url:</label>
                <input type="text" className="form-control" name="url" value={url} onChange={this.onChange} placeholder="Url" />
              </div>
              
              <button type="submit" className="btn btn-success">Submit</button>
            </form>
          </div>
        </div>
        <div class="toast">
  <div class="toast-header">
    Toast Header
  </div>
  <div class="toast-body">
    Some text inside the toast body
  </div>
</div>
      </div>
    );
  }
 

  
}

export default Create;