import React, { Component, Suspense } from 'react';
import { Link } from 'react-router-dom';
import { Canvas  } from '@react-three/fiber'
import {Loader3DTilesR3FAsset}from './Loader3DTilesR3FAsset.tsx';
import {MyScene,OtherScene}from './MyScene.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import nodeUtils from '../js/nodeUtils.js'

import { Grid, Center, AccumulativeShadows, RandomizedLight, Environment, useGLTF, CameraControls } from '@react-three/drei'

import { OrbitControls,Stage,Clone } from '@react-three/drei'

import Gamepad from 'warp-browser-gamepad';

// import { VRButton } from 'three/examples/jsm/webxr/VRButton.js';

import { FirstPersonControls } from 'three/examples/jsm/controls/FirstPersonControls.js';

import RtcComp from './RtcComp';

import ReactNipple from 'react-nipple';

import { Clock, Vector3, Vector4 } from 'three';

import SubtilteComp from './SubtilteComp';

import {
	DebugTilesRenderer,
	NONE,
	SCREEN_ERROR,
	GEOMETRIC_ERROR,
	DISTANCE,
	DEPTH,
	RELATIVE_DEPTH,
	IS_LEAF,
	RANDOM_COLOR,
} from '3d-tiles-renderer';

import { TilesRenderer } from '3d-tiles-renderer';

import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js';


import {
	Euler,
	Scene,
	DirectionalLight,
	AmbientLight,
	WebGLRenderer,
	PerspectiveCamera,
	CameraHelper,
	Box3,
	Raycaster,
	Vector2,
	Mesh,
	CylinderBufferGeometry,
	MeshBasicMaterial,
	Group,
	TorusBufferGeometry,
	OrthographicCamera,
	sRGBEncoding,
  MOUSE,
  Matrix4,
  Quaternion
} from 'three';

import { useLoader, useThree, useFrame } from '@react-three/fiber'

//import { useControls } from 'leva'

// import kk from '../data/tileset.json'
let offsetParent;



class Tiles extends Component {
    constructor(props) {
      super(props);

	  this.modelename = this.props.match.params.name
	  window.modelename  = this.modelename;
	  window.errorvalue = 1;
	  if (this.props.match.params.error){
		window.errorvalue = parseInt(this.props.match.params.error);
	  }	  

	  console.log("errorvalue !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",this.props.match.params.error);
	//   console.log("Error loading 3d tiles!");


	  this.state = {
		isready : false
	  }

	  this.offsetParent = new Group();
	  window.modelresize =  [0,0,0];
	  window.modelrotation = new Euler(Math.PI, Math.PI ,0);
		this.cameraposition = [15, 15, 15];
		window.url = "https://data2.gigascope.net/3d/montre/tileset.json";	

		if (this.modelename == "montre"){
			window.url = "https://data2.gigascope.net/3d/montre/tileset.json";

			this.cameraposition  = [0,0,0.4];
		   window.modelrotation = new Euler(-Math.PI,Math.PI/2, 0);
		   window.modelresize =  [0.01,0.01,0.01];
	   }

		if (this.modelename == "watch"){
			 window.url = "https://data2.gigascope.net/3d/montre2/tileset.json";

			 this.cameraposition  = [0,0,0.4];
			window.modelrotation = new Euler(-Math.PI,Math.PI/2, 0);
			window.modelresize =  [0.01,0.01,0.01];
		}
		if (this.modelename == "leo"){
			window.url = "https://data2.gigascope.net/3d/leo/tileset.json";
			// window.url = "https://data2.gigascope.net/3d/montre/tileset.json";
		
			this.cameraposition  = [0,0,1.5];
			window.modelrotation = new Euler(-Math.PI/2,0, 0);
			window.modelresize =  [0.01,0.01,0.01];

			// window.modelrotation = new Euler(0,0, 0);
		} 
		if (this.modelename == "guernesey_salleamanger") {
			window.url = "https://data2.gigascope.net/3d/guernesey_salleamanger/tileset.json";
			window.modelrotation = new Euler( -Math.PI/6,0, 0);
			this.cameraposition  = [0,0,2];
		}

	  

    }

	
	componentDidMount() {

		this.init();

	}

    init(){


		

		let url = "https://nasa-ammos.github.io/3DTilesRendererJS/example/data/tileset.json";
		this.setRegularTiles(url);
    }

	setRegularTiles(url){
		if ( this.tiles ) {
	  
		  this.offsetParent.remove( this.tiles.group );
		  this.tiles.dispose();
	  
		}
	
		global.modelname = this.modelename;
	  
		// this.tiles = new TilesRenderer( url );
		this.tiles = new DebugTilesRenderer( url );
	
		// this.tiles.onLoadModel = function ( scene ) {
	
		//   // create a custom material for the tile
		//   scene.traverse( c => {
		
		// 	if ( c.material ) {
		
		// 	  // c.originalMaterial = c.material;
		// 	  c.material.side = 2;
		
		// 	}
		
		//   } );
		
		// };

		this.tiles.onLoadTileSet = () => global.needsRerender = true;
		this.tiles.onLoadModel = () => global.needsRerender = true;
	
		this.setupTiles();
	}

	setupTiles(){

		this.tiles.fetchOptions.mode = 'cors';
	
		const dracoLoader = new DRACOLoader();
		dracoLoader.setDecoderPath( 'https://unpkg.com/three@0.123.0/examples/js/libs/draco/gltf/' );

		// const gltf = useLoader(GLTFLoader, '3D_Model/model.glb')
	  
		const loader = new GLTFLoader( this.tiles.manager );
		loader.setDRACOLoader( dracoLoader );
	  
		this.tiles.fetchOptions.mode = 'cors';
		this.tiles.manager.addHandler( /\.gltf$/, loader );
		this.offsetParent.add( this.tiles.group );


		
		this.state.isready = true;
      	this.setState(this.state);
		// this.resetCamera();
	  }

	  getTiles(){

		window.modelrotation = new Euler(Math.PI, Math.PI ,0);
		window.modelresize =  [0,0,0];
		this.cameraposition = [15, 15, 15];
		window.url = "https://data2.gigascope.net/3d/montre/tileset.json";	

		if (this.modelename == "montre") window.url = "https://data2.gigascope.net/3d/montre/tileset.json";
		if (this.modelename == "leo"){
			window.url = "https://data2.gigascope.net/3d/leo/tileset.json";
			this.cameraposition  = [0,1,0];
			window.camerarotation = new Euler(Math.PI,0, 0);
			window.modelrotation = new Euler(Math.PI,0, 0);
			window.modelresize =  [0.1,0.1,0.1];
		} 
		if (this.modelename == "guernesey_salleamanger") {
			window.url = "https://data2.gigascope.net/3d/guernesey_salleamanger/tileset.json";
			window.modelrotation = new Euler( -Math.PI/6,0, 0);
			this.cameraposition  = [0,0,2];
		}
		// window.url = "https://data2.gigascope.net/3d/coeurchartres/tileset.json";
		// window.url = "https://data2.gigascope.net/3d/notredame/tileset.json";	
		// window.url = "https://data2.gigascope.net/3d/gerberoy/tileset.json";	
			// window.url = "../montre/tileset.json";
		// window.url = "http://localhost/3dmodeles/dome/tileset.json";
		// window.url = "https://nasa-ammos.github.io/3DTilesRendererJS/example/data/tileset.json";
		// window.url = "https://storage.googleapis.com/ogc-3d-tiles/baltimore/tileset.json";
        return (
			<Loader3DTilesR3FAsset castShadow receiveShadow/>
				)
    } 

	
	  

	render(){

		const { isready } = this.state;

		console.log("is ready ?????",isready);

		

		
		// return (
		// 	<div id="canvas-container" className='full blackBackground'>
		// 	<Suspense fallback={<h1>Chargement du profil...</h1>}>
			
		// 	<Canvas camera={{ position: this.cameraposition , fov: 60 }}>
		// 		<MyScene />
		// 	</Canvas>
		// 	</Suspense>
		//   </div>
		// );

		return (

			// <OtherScene />
			// 	<div id="canvas-container" className='full blackBackground'>
			// 	<Suspense fallback={<h1>Chargement du profil...</h1>}>
				
			<div id="canvas-container" className='full threeBackground'>
				{/* <Link to="https://www.gigascope.net" >
					  <Image  className="logotopright" src={"https://data.gigascope.net/data/Logo_full_white.png"} alt="Logo" />
      		</Link> */}

			  <a href={ "https://www.gigascope.net" } target="_blank" rel="noopener noreferrer">
  <img className="resume" src={ "https://data.gigascope.net/data/Logo_full_white.png" } alt="Gigascope Website" className="logotopright"/>
</a>

			<Canvas shadows camera={{ position: this.cameraposition, fov: 35 }}>
				
				<OtherScene />
				
			</Canvas>
		  		</div>
			);
   
		// return (
		// 	<div id="canvas-container" className='full'>
		// 	<Suspense fallback={<h1>Chargement du profil...</h1>}>
			
		// 	<Canvas shadows camera={{ position: this.cameraposition , fov: 60 }}>


		// 	{this.getTiles()}  
			
		// 	<ambientLight intensity={0.1} />
		// 	{/* <directionalLight color="red" position={[0, 0, 5]} /> */}
		// 	<OrbitControls />
		// 	{/* <CameraControls
        //   // ref={cameraControlsRef}
        //   // minDistance={minDistance}
        //   // enabled={enabled}
        //   // verticalDragToForward={verticalDragToForward}
        //   dollyToCursor={true}
        //   // infinityDolly={infinityDolly}
        // /> */}

		// 	</Canvas>
		// 	</Suspense>
		//   </div>
		// );
	}
}

function Model(props) {

	return <Clone object={window.getTiles} castShadow receiveShadow {...props} />
}
export default Tiles;