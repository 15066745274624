import React, { Component } from 'react';
import { Link } from 'react-router-dom';
// import './App.css';
import firebase from '../Firebase';
import withFirebaseAuth from 'react-with-firebase-auth'
import 'firebase/auth';
import HeaderComp from './HeaderComp';
/* import { browserHistory } from 'react-router'; */

import nodeUtils from '../js/nodeUtils.js'
import { doc, onSnapshot,query } from "firebase/firestore";

// import nodeUtils from '../js/nodeUtils.js'



// const dbRef = firebase.database().ref("internalmessages")
// console.log(dbRef);
// dbRef.on("value", snap => {
//   console.log(snap); // this key will output users
//   console.log(snap.key); // this method will return full user
// });

class Photos extends Component {
  constructor(props) {
    super(props);
    this.ref = window.ref;
    this.unsubscribe = null;
    this.state = {
      gigaphotos: []
    };

    console.log("!!!!!!!!!!!!!!!!!!!!!!!!!url!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!"); 
    console.log(global.testurl); 

    
    
  }

  onCollectionUpdate = (querySnapshot) => {
    const gigaphotos = [];
    querySnapshot.forEach((doc) => {
      const { titre,
        panoname,
        theme,
        info,
        width,
        height,
        numtiles,
        witilesize,
        url,
        uiGroups } = doc.data();
      gigaphotos.push({
        key: doc.id,
        doc, // DocumentSnapshot
        titre,
    panoname,
    theme,
    info,
    width,
    height,
    numtiles,
    witilesize,
    url,
        uiGroups
      });
    });
    this.setState({
      gigaphotos
   });

   let showall = false;
   if (this.props.match.params.opt){
    showall = this.props.match.params.opt == 'showall';
   }

   console.log(this.props.match.params.opt)
   //if (!showall && !nodeUtils.isMe()) this.props.history.push("/photo/rosenord");
  }

  componentDidMount() {
    // this.unsubscribe = this.ref.onSnapshot(this.onCollectionUpdate);
    const q = query(global.ref);
  const unsubscribe = onSnapshot(q, (querySnapshot) => {
  const cities = [];
  this.onCollectionUpdate(querySnapshot);});
    
  }

  loadpano(){
    
    // window.downloadPano({ path: "http://www.preiss.fr/pano/", panoname :'nocesdecana'},function (params) {
      // window.downloadPano({ path: "https://data2.gigascope.net/pano/", panoname :'solesme_mur1'},function (params) {
       window.downloadPano({ path: "https://data2.gigascope.net/pano/", panoname :'klint_visage'},function (params) {
  
    // window.downloadPano({ path: "D:/Zoomified/", panoname :'mbao_velasquezir'},function (params) {
    // window.downloadPano({ path: "http://data.gigascope.net/pano/", panoname :'charpente2'},function (params) {

    // window.downloadPano({ path: "http://37.187.22.210/pano/", panoname :'noisiel'},function (params) {
      let panoname = window.panodatas[0].panoname;
      let theme = window.panodatas[0].theme;
      let titre = window.panodatas[0].titre;
      let info = window.panodatas[0].info;
      let width = window.panodatas[0].width;
      let height = window.panodatas[0].height;
      let numtiles = window.panodatas[0].numtiles;
      let numimages = window.panodatas[0].numimages;
      let witilesize = window.panodatas[0].witilesize;
      let uiGroups = window.panodatas[0].uiGroups;
      // let url = "http://www.preiss.fr/pano/";
      let url = "https://data2.gigascope.net/pano/";
      let users = "";
      // let url = "D:/Zoomified/";
      // this.ref = firebase.firestore().collection('gigaphotos');
      window.ref.add({titre,panoname,theme,info,width,height,numtiles,witilesize,url,uiGroups,users});
      // console.log(liste[0]);
    })
  }

  logout(){
    firebase.auth().signOut()
  }

  render() {
    return (
      <div className="container">
        {/* <HeaderComp></HeaderComp> */}
        <div className="panel panel-default">
          <div className="panel-heading">
            <h3 className="panel-title">
              Gigaphotos
            </h3>
          </div>
          <div className="panel-body">
            <h4><Link to="/create">Add Gigaphoto</Link></h4>
            <button  onClick={() => this.loadpano()} >
                        Load pano
                        </button>
                        <button  onClick={() => this.logout()} >
                        Logout
                        </button>
            <table className="table table-stripe">
              <thead>
                <tr>
                  <th>Panoname</th>
                  <th>Titre</th>
                  <th>Theme</th>
                  <th>Thumb</th>
                </tr>
              </thead>
              <tbody>
                {this.state.gigaphotos.map(gigaphoto =>
                  <tr key={gigaphoto.key}>
                    <td><Link to={`/edit/${gigaphoto.key}`}>{gigaphoto.panoname}</Link></td>
                    <td><Link to={`/photo/${gigaphoto.panoname}`}>{gigaphoto.titre}</Link></td>
                    <td><img src={gigaphoto.url + gigaphoto.panoname + "/thumb.jpg"} style={{  height: "128px" }} /> </td>
                    <td>{gigaphoto.theme}</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

export default Photos;
