import nodeUtils from '../js/nodeUtils.js'
const axios = require('axios').default;
// var multer = require('multer');

var Jimp = require('jimp');

const screenchotsize = 256;

function buttonActionFunction(){
    window.consoleM(window.info(),window.window.name,'buttonActionFunction','buttonActionFunction');
}

function gecontrolsettings(hand) {

    if (hand == 'right') return window.controlsettings.right;
    else return window.controlsettings.left;
  }


window.sendImage= function(data){



    // fetch("http://localhost:5001/newgigascopejs/us-central1/app/test/"+data, {
    //     method: 'post',
    //     // body: bodyFormData
    // })
    //     .then((res) => console.log(res))
    //     .catch((err) => ("Error occured", err));

    // axios.get('https://us-central1-newgigascopejs.cloudfunctions.net/app/hello-world')
    //   .then(function (response) {
    //     console.log(response);
    //   })
    //   .catch(function (error) {
    //     console.log(error);
    //   });

    var bodyFormData = new FormData();

    let buff = new Buffer(data, 'base64');


    bodyFormData.append('image_name', "testname.jpg");

    bodyFormData.append('image_data', data);


    bodyFormData.append('testdata2', "hello2");

    bodyFormData.append('testdata3', "hello2");
    // var formData = {
    //     name: 'file1',
    //     file: {
    //       value:  fs.createReadStream('C:/kristian/Devbeasts-small.png'),
    //       options: {
    //         filename: 'Logo_flame.png',
    //         contentType: 'image/png'
    //       }
    //     }
    //   };

    // console.log(bodyFormData)

    // fetch("http://localhost:5001/newgigascopejs/us-central1/app/test2", {
    // fetch("http://localhost:5001/newgigascopejs/us-central1/app/sendpic", {
    //     method: 'post',
    //     body: bodyFormData
    // })
    //     .then((res) => console.log(res))
    //     .catch((err) => ("Error occured", err));

    axios({
        method: "post",
        // url: "http://localhost:5001/newgigascopejs/us-central1/app/hello-world/",
        // url: "http://localhost:5001/newgigascopejs/us-central1/app/sendpic",
        // url: "https://auto.gigascope.net/sendpic",
        url: "http://localhost:3000/sendpic",
        data: bodyFormData,
        headers: {
           "Content-Type": "multipart/form-data"
        },
     })
     .then(function(response) {
        //handle success
        console.log(response);
     })
     .catch(function(response) {
        //handle error
        console.log(response);
     });

    // axios.get('http://localhost:5001/newgigascopejs/us-central1/app/sendpic', {
    //     firstName: 'Fred',
    //     lastName: 'Flintstone'
    //   })
    //   .then(function (response) {
    //     console.log(response);
    //   })
    //   .catch(function (error) {
    //     console.log(error);
    //   });
}
window.takeScreenshot = function(index,type){
        // var index = 1;
        var panoname = window.panoname;

        var screenshot = window.getPanoScreenshot();

        // console.log('screenshot',screenshot)

        Jimp.read(screenshot)
        .then(image => {
            image.cover( screenchotsize, screenchotsize ); 

            image.quality(60);

            image.getBase64(Jimp.MIME_JPEG,function(err,data){

                switch (type){
                    case 'bookmark':
                        console.log('getBase64',data)
                        nodeUtils.addBookmark({data: data})
                        nodeUtils.getNextBookmarkIndex(window.thepano.panoname,window.bref);
                        break;
                    case 'popup':
                        

                        break;
                    case 'sendmail':
                        // console.log('getBase64',data)
                        window.sendImage(data)
                        // global.myEmitter.emit('sendemail', {'data':data});
                        break;
                }
                            
            });

        })
        .catch(err => {
            console.error('oops, something went wrong!', err);
            // Handle an exception.
        }); 

    }

window.emptyDiv = function(id){
    let parent  = document.getElementById(id);
    var divs = parent.getElementsByTagName("div");
    while(divs[0]) {
        divs[0].parentNode.removeChild(divs[0])
    }
}

window.popupClick = function (popup,action) {
    nodeUtils.addTextToMyPopup(popup,action);
}

function moveElement(elem,movevec){
    
    window.consoleM(window.info(),window.window.name,'movevec',movevec);
              var pos = getElementPosition(elem);
              window.consoleM(window.info(),window.window.name,'pos',pos);
            //   pos = pos + movevec;
              pos.x = pos.x + movevec.x;
              pos.y = pos.y + movevec.y;
              pos.z = pos.z + movevec.z;
              window.consoleM(window.info(),window.window.name,'posmovevec',pos);

            //   pos.z = pos.z + movevec[0] / 100 * speed;
              setElementPosition(elem, pos);
}

function moveElementMaxZ(elem,movevec,maxz){
    
    window.consoleM(window.info(),window.window.name,'movevec',movevec);
              var pos = getElementPosition(elem);
              window.consoleM(window.info(),window.window.name,'pos',pos);
            //   pos = pos + movevec;
              pos.x = pos.x + movevec.x;
              pos.y = pos.y + movevec.y;
              pos.z = pos.z + movevec.z;
              if (pos.z > maxz) pos.z = maxz;
              window.consoleM(window.info(),window.window.name,'posmovevec',pos);

            //   pos.z = pos.z + movevec[0] / 100 * speed;
              setElementPosition(elem, pos);
}

function setElementPosition(elem,pos){
    elem.setAttribute('position', pos);
}

function getElementPosition(elem){
    return elem.getAttribute('position');
}

function setElementPositionZ(elem,zpos){
    var pos = getElementPosition(elem);
    pos.z = zpos;
    setElementPosition(elem, pos);
}

function getThePanoElement(){
    return document.querySelector('#ThePano');
}

function getHomeElement(){
    return document.querySelector('#home');
}

function setElementVisible(elem,visible){
    if (visible) elem.setAttribute('visible',true);
    else elem.setAttribute('visible',false);
}

function setDivSize(id){
    document.getElementById(id).style.height= window.panosettings.mapheight;
    document.getElementById(id).style.width= window.panosettings.mapwidth;   
}

function getTargetDiv(parent,id){
    var element = document.createElement("div");
    element.id = id;
    element.classList.add('map');
    document.getElementById(parent).appendChild(element);
}

window.addDiv = function (parent,id){
    var element = document.createElement("div");
    element.id = id;
    element.classList.add('map');
    if (window.isVR) {
        element.style.height= getMapHeight();
        element.style.width= getMapWidth(); 
    } else if (window.isAndroid){

        element.style.height = "100%";
        element.style.width= "100%";
      //  element.style.height = "3096px";
     //   element.style.width= "3096px";
    }
   
    document.getElementById(parent).appendChild(element);
}

function getMapHeight(){
    return window.panosettings.mapheight;
}

function getMapWidth(){
    return window.panosettings.mapwidth;
}

function addScreenShotDiv(parent,id){
    var element = document.createElement("div");
    element.id = id;
    element.classList.add('map');
    element.style.height= window.panosettings.ui.thumbsize;
    element.style.width= window.panosettings.ui.thumbsize; 
    document.getElementById(parent).appendChild(element);
}

function convertCanvasToImage(canvas) {
    var image = new Image();
    image.src = canvas.toDataURL('image/jpeg', 0.5);
    return image;
}

window.convertCanvasToImageString = function(canvas) {
	// var image = new Image();
	var image = canvas.toDataURL('image/jpeg', 0.6);
	// image.replace(/^data:image\/(png|jpg);base64,/, "");

	return image;
}

window.getPanoScreenshot = function(){
    return window.convertCanvasToImageString(window.thepano.PanoCanvas)
}

window.getThumbScreenshot = function(){
    console.log("getThumbScreenshot",window.getPanoScreenShot().PanoCanvas)
    return window.convertCanvasToImageString(window.getPanoScreenShot().PanoCanvas)
}

function saveToLocalstorage(name,data){
    try {
        localStorage.setItem(name, data);
    } catch (error) {
        window.consoleM(window.info(),'saveToLocalstorage','error',error);
    }
    
}

function readFromLocalstorage(name){
    return localStorage[name];
}

function saveJSonToLocalstorage(name,data){
    let str = JSON.stringify(data);
    saveToLocalstorage(name,str);
}

function saveJSonToLocalstorageNoThumb(filename,data){
    let str = JSON.stringify(data, function(key, val) {
        if (key !== "thumb" && key !== "name" && key !== "uigroupid" )
            return val;
    });    
    saveToLocalstorage(filename,str);
}

function readJSonFromLocalstorage(name){
    let data = readFromLocalstorage(name);
    if (!data) return;
    return JSON.parse(data);
}

window.getPanoramasRequest = function(){

    window.consoleM(window.info(),window.name,'getPanoramasRequest','getPanoramasRequest');

    var request = new XMLHttpRequest();

// Open a new connection, using the GET request on the URL endpoint
request.open('GET', 'https://gigascope.appspot.com/panorama', true)

request.onload = function () {
  // Begin accessing JSON data here
  var data = JSON.parse(this.response);
  console.log("pnosss",data);
  window.consoleM(window.info(),window.name,'getPanoramasRequest',data);
  
};

// Send request
request.send();
}

window.detectMob= function() {
    const toMatch = [
        /Android/i,
        /webOS/i,
        /iPhone/i,
        /iPad/i,
        /iPod/i,
        /BlackBerry/i,
        /Windows Phone/i
    ];

    return toMatch.some((toMatchItem) => {
        return navigator.userAgent.match(toMatchItem);
    });
}


window.Utils = (function() {
    const DIGITS = 6;
    function numberToFixed (number) {
        return parseFloat(number.toFixed(DIGITS));
    }

    function arrayNumbersToFixed (array) {
        for (var i = 0; i < array.length; i++) {
            array[i] = numberToFixed(array[i]);
        }
        return array;
    }

    function getTooltips (controllername) {
        var tooltips;
        var tooltipwindowname;
        switch (controllername) {
            case 'windows-motion-samsung-controls': {
                tooltipwindowname = '.windows-motion-samsung-tooltips';
                break;
            }
            case 'windows-motion-controls': {
                tooltipwindowname = '.windows-motion-tooltips';
                break;
            }
            case 'oculus-touch-controls': {
                tooltipwindowname = '.oculus-tooltips';
                break;
            }
            case 'vive-controls': {
                tooltipwindowname = '.vive-tooltips';
                break;
            }
            default: {
                break;
            }
        }

        tooltips = Array.prototype.slice.call(document.querySelectorAll(tooltipwindowname));
        return tooltips;
    }

    return {
        numberToFixed: numberToFixed,
        arrayNumbersToFixed: arrayNumbersToFixed,
        getTooltips: getTooltips
    }
}());


  
