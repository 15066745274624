// import { AudioObject } from '../js/AudioObject.js';

export class AudioTimeLine{

    constructor(audioevents){
        this.timetab = [{time: 0, type: 'play'}];
        this.pauseduration = 0;
        let pauseaudioevents = audioevents.filter(event => event.isPause()).sort(function (a, b) {
            return parseInt(a.time) - parseInt(b.time);
            });;
        pauseaudioevents.forEach(audioevent => {
            this.timetab.push({time: audioevent.time(), type: 'pause'})
            this.timetab.push({time: audioevent.time() + audioevent.duration(), type: 'play'})
            this.pauseduration += audioevent.duration();
        });

    }

    getState(position){
        let reversed = Array.from(this.timetab).reverse();
        let resultevent = reversed.find(audioevent => audioevent.time < position);
        if (!resultevent) return 'play'
        return resultevent.type;
    }

    getSoundPosition(position){
        let soundsevent = this.timetab.filter(event => event.time < position).reverse();
        let pausetime = 0;
        let lastplaytime = 0;
        soundsevent.forEach(event => {
            if (event.type == 'pause'){
                if (pausetime == 0 && lastplaytime == 0){
                    pausetime = position - event.time;
                } else {
                    pausetime += lastplaytime - event.time;
                }
            } else {
                lastplaytime = event.time;
            }
        });
        return position - pausetime;
    }
}