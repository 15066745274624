import ReactDOM from 'react-dom'
import React, { Component } from 'react';
import { useState, useRef, Suspense } from 'react'
// import { Canvas } from '@react-three/fiber'
// import { PerspectiveCamera, OrbitControls } from '@react-three/drei'
// import { ErrorBoundary } from 'react-hooks-fetch';
import { Matrix4, Euler } from 'three'

class Vr2Comp extends Component {
  constructor(props) {
    super(props);


    
     this.state = {
       texttosend:''
     }
      
    // }; 

    
  }
  
  componentDidMount() {
   
  }

  componentWillUpdate(nextProps, nextState) {
    

      this.setState(
        this.state
      )
    
 
  }

  button1(){
    console.log("bouton1")


  }

  button2(){
    console.log("bouton1")
    

  }

 

  render() {

   
   
    return (
      <div></div>
      
    );
  }
}

export default Vr2Comp;
