import firebase from '../Firebase';
import { Position } from './Position';

export class Bookmark{

    constructor(bookmark){
        if (!bookmark) bookmark = this.getEmptyObject()
        this.bookmark = bookmark;
        this.index = bookmark.index;
        this.key= bookmark.key;
        this.label= bookmark.label;
        this.text= bookmark.text;
        this.posx= bookmark.posx;
        this.posy= bookmark.posy;
        this.index= bookmark.index;
        this.zoom= bookmark.zoom;
        this.panoname= bookmark.panoname;
        this.thumburl= bookmark.thumburl;
        this.author= bookmark.author;
        this.audioname=bookmark.audioname;
        this.audioevents=bookmark.audioevents;
        this.otherid=bookmark.otherid;
        this.coord=bookmark.coord;

        if (this.coord == "") this.coord = this.posx + "," + this.posy;

        this.type = bookmark.type;
        if (this.type == null) this.type = 'bookmark';

        this.show = bookmark.show;
        if (this.show == null) this.show = true;
    }

    getObject(){
        return {
            key: this.key,
            label: this.label,
            text: this.text,
            posx: this.posx,
            posy: this.posy,
            index: this.index,
            zoom: this.zoom,
            panoname: this.panoname,
            thumburl: this.thumburl,
            author: this.author,
            audioname:this.audioname,
            audioevents:this.audioevents,
            type:this.type,
            coord:this.coord,
            otherid:this.otherid
          }
    }
    getEmptyObject(){
        return {
            key: '',
            label: '',
            text: '',
            posx: '',
            posy: '',
            index: '',
            zoom: '',
            panoname: '',
            thumburl: '',
            author: '',
            audioname:'',
            audioevents:'',
            type:'',
            coord:'',
            otherid:''
          }
    }

    showBookmark(show){
        this.show = show;
    }

    getLat(){
        return this.coord.split(",")[0];
    }

    getLng(){
        return this.coord.split(",")[1];
    }

    getCoordZoom(){
        if (this.coord.length < 3) return 14;
        return parseInt(this.coord.split(",")[2]);
    }

    getCoordObj(){
        return {
            lat: this.getLat(),
            lng:  this.getLng()
          };
    }

    getDist(evt){
        let v1 = (this.getLat() - evt.coordinate[0] ) * (this.getLat() - evt.coordinate[0] );
        let v2 = (this.getLng() - evt.coordinate[1] ) * (this.getLng() - evt.coordinate[1] );
        return Math.sqrt(v1 + v2)
    }

    // getCoordObj(){
    //     return {
    //         lat: this.getLat(),
    //         lng:  this.getLng()
    //       };
    // }


    getThumbUrl(){
        if (this.thumburl.includes('wiki')) return this.getThumb256()
        else return this.thumburl
    }

    getThumb256(){
        let path = window.thepano.getPanoFullPath() + '/' + this.thumburl
        path.replace('.jpg','-thumb256.jpg');
        path.replace('.png','-thumb256.png');
        return path;
    }

    getImage(){
        let path = window.thepano.getPanoFullPath() + '/' + this.thumburl
        return path;
    }

    updateDB(aftersave){
        const updateRef = firebase.firestore().collection('bookmarks').doc(this.key);
        updateRef.set(this.getObject()).then((docRef) => {

            aftersave();
            // this.props.onClose();
        })
        .catch((error) => {
            console.error("Error adding document: ", error);
        });
    }

    position(){
        return new Position(this.posx,this.posy,this.zoom)
    }

    moveToRelative(xoffset,yoffset){
        window.thepano.flyToRelative(this.position(),xoffset,yoffset)
    }

    isBookmark(){
        // return true;
        return this.type == 'bookmark';
    }

    isJeuxErreur(){
        return this.type == 'jeuxerreur';
    }

}